import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './Modal.scss';

const Popup = ({ title, onClose, onConfirm = null, onConfirmLabel = 'OK', children }) => {

    const intl = useIntl();

    return (
        <div className="dialog">
            <div className="content wide">
                <h3>{title}</h3>
                {children}
                <form>
                    <fieldset>
                        {!!onConfirm && (
                            <button className="ok" onClick={e => { e.preventDefault(); onConfirm(); }}>{onConfirmLabel}</button>
                        )}
                        {onClose && (
                            <button className="ok" onClick={e => { e.preventDefault(); onClose(); }}>{!!onConfirm ? intl.formatMessage({ id: 'App.info.Annulla' }) : intl.formatMessage({ id: 'App.info.Chiudi' })}</button>
                        )}
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

export default Popup;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { doRequest, errorRead, setToken } from '../../../actions';
import { FormattedMessage } from 'react-intl';

const urlPropsQueryConfig = {
    t: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    doRequest: payload => dispatch(doRequest('approveSessionAuth', payload)),
    errorRead: () => dispatch(errorRead()),
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error
});

const _SessionAuth = ({ data, errorRead, doRequest, stateError, t }) => {
    const [canActivate, setCanActivate] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [processed, setProcessed] = useState(false);
    const [errors, setErrors] = useState("");

    useEffect(
        () => {
            if (firstLoad && !processed) {
                doRequest(t || '');
                setProcessed(true);
            }
            if (stateError) {
                setProcessed(false);
                setCanActivate(false);
            }
            return () => setFirstLoad(false);
        },
        [errorRead, errors, firstLoad, setErrors, setFirstLoad, stateError, t, doRequest, setCanActivate, setProcessed, processed],
    );

    if (data && processed) {
        setProcessed(false);
        setCanActivate(true);
    }

    return (
        <section>
            <div>
                <img className="mainLogo" src="/static/images/testalince.png" alt="Lince" title="Lince Clouding" />
            </div>
            <div>
                {
                    processed
                        ?
                        <form>
                            <legend>
                                <FormattedMessage id="App.info.AutorizzazioneAccesso" />
                            </legend>
                            <p>
                                <FormattedMessage id="App.info.VerificaInCorso" />
                            </p>
                        </form>
                        :
                        canActivate
                            ?
                            <form>
                                <legend>
                                    <FormattedMessage id="App.info.AutorizzazioneAccesso" />
                                </legend>
                                <p>
                                    <FormattedMessage id="App.info.AutorizzazioneAvvenuta" />
                                </p>
                            </form>
                            :
                            <form>
                                <p><FormattedMessage id="App.info.Errore" /></p>
                            </form>
                }
            </div>
        </section>
    );
};

const SessionAuth = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_SessionAuth));

export default SessionAuth;
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import get_bits from '../../../../lib/bytesFunctions';

import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError)),
});

const _Uscite = ({ system, socket, doSocketVariation, data_socket }) => {
    const codeForm = useRef(null);
    const trama = 0x00E3;
    const [firstLoad, setFirstLoad] = useState(true);
    const [current, setCurrent] = useState(null);
    const [processed, setProcessed] = useState(false);
    const status = !!system && !!socket ? system.store.status : null;
    const intl = useIntl();

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, trama, current, doSocketVariation, processed, setProcessed, socket]
    );

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let _current = {
                trama,
                payload: _x
            };
            setCurrent(_current);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.ImpiantoNonConnesso" })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox != 3) {
                return (
                    <Modal
                        title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                        text={intl.formatMessage({ id: "App.info.NecessariPermessiDiInstallatore" })}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = [];
        for (let i = 0; i < 4; i++) {
            payload.push((
                (form['antintrusione_' + i].checked ? 1 : 0) +
                (form['fuoco_' + i].checked ? 2 : 0) +
                (form['silenzioso_' + i].checked ? 4 : 0) +
                (form['campanello_' + i].checked ? 8 : 0) +
                (form['elettroserratura_' + i].checked ? 16 : 0) +
                (form['gext_' + i].checked ? 32 : 0) +
                (form['preallarme_' + i].checked ? 64 : 0)
            ));
        }
        payload.push((
            (form['cncna'].value > 0 ? 1 : 0) +
            (form['aux'].value > 0 ? 2 : 0) +
            (form['lm'].value > 0 ? 4 : 0) +
            (form['off'].value > 0 ? 8 : 0) +
            (form['w'].value > 0 ? 16 : 0) +
            (form['wbus'].value > 0 ? 32 : 0) +
            (form['out'].value > 0 ? 64 : 0) +
            (form['at'].value > 0 ? 128 : 0)
        ));
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, current.trama, payload);
    }

    const renderChecks = num => {
        return (
            <>
                <p>
                    <input type="checkbox" id={`antintrusione_${num}`} name={`antintrusione_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 0)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`antintrusione_${num}`}>
                        <FormattedMessage id="App.info.A" /> <span />
                    </label>
                </p>
                <p>
                    <input type="checkbox" id={`preallarme_${num}`} name={`preallarme_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 6)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`preallarme_${num}`}>
                        <FormattedMessage id="App.info.K" /> <span />
                    </label>
                </p>
                <p>
                    <input type="checkbox" id={`fuoco_${num}`} name={`fuoco_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 1)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`fuoco_${num}`}>
                        <FormattedMessage id="App.info.F" /> <span />
                    </label>
                </p>
                <p>
                    <input type="checkbox" id={`silenzioso_${num}`} name={`silenzioso_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 2)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`silenzioso_${num}`}>
                        <FormattedMessage id="App.info.S" /> <span />
                    </label>
                </p>
                <p>
                    <input type="checkbox" id={`campanello_${num}`} name={`campanello_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 3)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`campanello_${num}`}>
                        <FormattedMessage id="App.info.C" /> <span />
                    </label>
                </p>
                <p>
                    <input type="checkbox" id={`elettroserratura_${num}`} name={`elettroserratura_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 4)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`elettroserratura_${num}`}>
                        <FormattedMessage id="App.info.E" /> <span />
                    </label>
                </p>
                <p>
                    <input type="checkbox" id={`gext_${num}`} name={`gext_${num}`} defaultValue="0" defaultChecked={get_bits(current.payload[num], 5)} onChange={() => null} disabled={teknox !== 3} />
                    <label htmlFor={`gext_${num}`}>
                        <FormattedMessage id="App.info.Gext" /> <span />
                    </label>
                </p>
            </>

        )
    };

    if (!!!current || firstLoad) {
        return <>
            <FormattedMessage id="App.info.Caricamento" />
        </>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            {current && (
                                <>
                                    <h1>
                                        <FormattedMessage id="App.info.LogicaUscite" />
                                    </h1>
                                    <fieldset className="outputsRack">
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.relePiuNPiuANc2" />
                                                </label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 0)} name="cncna" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w50 checkBoxInline">
                                                {renderChecks(2)}
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.ReleNc1" />
                                                </label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 1)} name="aux" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w50 checkBoxInline">
                                                {renderChecks(1)}
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label><FormattedMessage id="App.info.Out" /></label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 6)} name="out" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w50 checkBoxInline">
                                                {renderChecks(3)}
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label>AT</label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 7)} name="at" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w50 checkBoxInline">
                                                {renderChecks(0)}
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Lm" />
                                                </label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 2)} name="lm" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.PiuOff" />
                                                </label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 3)} name="off" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label><FormattedMessage id="App.info.W" /></label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 4)} name="w" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack borderBottom">
                                            <div className="w25">
                                                <label><FormattedMessage id="App.info.WBus" /></label>
                                            </div>
                                            <div className="w25">
                                                <select defaultValue={get_bits(current.payload[4], 5)} name="wbus" disabled={(teknox !== 3)}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Normale" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Invertita" />
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack" style={{ marginTop: "4em" }}>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setFirstLoad(true); }}>
                                                    <FormattedMessage id="App.info.Annulla" />
                                                </button>
                                                <button className="ok auto spaced" onClick={handleSubmit}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                        <h3><FormattedMessage id="App.info.Legenda" /></h3>
                                        <div className="rack">
                                            <div className="w100">
                                                <p><FormattedMessage id="App.info.Allarme1" /></p>
                                                <p><FormattedMessage id="App.info.Allarme2" /></p>
                                                <p><FormattedMessage id="App.info.AllarmeFuoco" /></p>
                                                <p><FormattedMessage id="App.info.AllarmeSilenzioso" /></p>
                                                <p><FormattedMessage id="App.info.CampanelloC" /></p>
                                                <p><FormattedMessage id="App.info.ElettroserraturaE" /></p>
                                                <p><FormattedMessage id="App.info.GextProgramma" /></p>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Uscite = connect(mapStateToProps, mapDispatchToProps)(_Uscite);

export default Uscite;
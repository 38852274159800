import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    errorRead,
    doRequest,
} from '../../../actions';
import Association from '../../elements/Forms/Association';
import Modal from '../../elements/Modal';
import Outcome from '../../elements/Outcome';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    createCAT: payload => dispatch(doRequest('createCAT', payload)),
    deleteAssociationCAT: id => dispatch(doRequest('deleteAssociation', id)),
    errorRead: () => dispatch(errorRead()),
    getAllSystem: () => dispatch(doRequest('getAllSystem')),
    getAssociationsCAT: () => dispatch(doRequest('getAssociationsCAT')),
});

const _CAT = ({ data, user, stateError, deleteAssociationCAT, errorRead, getAllSystem, getAssociationsCAT, createCAT }) => {

    const initialPayload = {
        associated_email: null,
        system_id: null,
        profile: 'cat',
        hours: 1
    };

    const [confirm, setConfirm] = useState(false);
    const [errors, setErrors] = useState("");
    const [firstTab, setFirstTab] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [processedAssociations, setProcessedAssociations] = useState(false);
    const [processedDelete, setProcessedDelete] = useState(false);
    const [processedSystems, setProcessedSystems] = useState(false);
    const [processedAdd, setProcessedAdd] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [success, setSuccess] = useState(false);
    const [associations, setAssociations] = useState(false);
    const [associationId, setAssociationId] = useState(false);
    const [systems, setSystems] = useState(false);
    const isPremium = !!user.premium_date || !!user.sim_premium_date;
    const intl = useIntl();

    useEffect(
        () => {
            if (!processedAssociations && firstLoad) {
                setFirstLoad(false);
                getAssociationsCAT();
                setProcessedAssociations(true);
                setErrors("");
                errorRead();
            }
            if (!firstTab && !processedSystems && systems === false) {
                getAllSystem();
                setProcessedSystems(true);
                setErrors("");
                errorRead();
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => setFirstLoad(false);
        },
        [errorRead, errors, setErrors, stateError, setFirstLoad, firstLoad, setProcessedSystems, setSystems, getAllSystem, systems, processedSystems, firstTab]
    );

    const tabHandler = value => setFirstTab(value);

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
        setAssociationId(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        setProcessedDelete(true);
        deleteAssociationCAT(associationId);
        window.location.reload();
    }

    const handlerChange = (e) => {
        const _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        setErrors("");
        setSuccess(false);
        setProcessedAdd(true);
        createCAT(payload);
    };

    const renderForm = () => (
        <Association
            handleChange={handlerChange}
            handleSubmit={handlerSubmit}
            systems={systems}
            cat={true}
        />
    );

    const renderList = () => (
        <div className="rack">
            <div className="w100">
                <form>
                    <fieldset>
                        <table className="responsiveTable">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="App.info.Utente" /></th>
                                    <th><FormattedMessage id="App.info.Scadenza" /></th>
                                    <th><FormattedMessage id="App.info.Impianto" /></th>
                                    <th><FormattedMessage id="App.info.Azioni" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    associations && associations.map(value => (
                                        <tr key={`a_${value.id}`} style={{ textAlign: "center", minHeight: "3em" }}>
                                            <td><span className="head">
                                                <FormattedMessage id="App.info.Utente" />
                                            </span><strong>{value.email2}</strong></td>
                                            <td><span className="head">
                                                <FormattedMessage id="App.info.Scadenza" />
                                            </span>{(new Date(value.expiration_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }))}</td>
                                            <td><span className="head">
                                                <FormattedMessage id="App.info.Impianto" />
                                            </span>{value.name}</td>
                                            <td>
                                                <span className="head">
                                                    <FormattedMessage id="App.info.Azioni" />
                                                </span>
                                                <Link title={intl.formatMessage({ id: "App.info.EliminaAbilitazione" })} to={`/`} onClick={(e) => { e.preventDefault(); setAssociationId(value.id); setConfirm(true); }} >
                                                    <i className="fa fa-trash" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </fieldset>
                </form>
            </div>
        </div>
    );

    if (data && processedAssociations) {
        setProcessedAssociations(false);
        setAssociations(data);
    }

    if (data && processedDelete) {
        setProcessedAssociations(false);
        setProcessedDelete(false);
        setAssociations(false);
        setAssociationId(false);
        setTimeout(() => {
            getAssociationsCAT();
            setProcessedAssociations(true);
            setConfirm(false);
        }, 500);
    }

    if (data && processedSystems) {
        setProcessedSystems(false);
        setSystems(data);
    }

    if (data && processedAdd) {
        setProcessedAdd(false);
        setSuccess(true);
        setAssociations(false);
        setProcessedAssociations(false);
        setTimeout(() => {
            getAssociationsCAT();
            setProcessedAssociations(true);
            setFirstTab(true);
        }, 500);
    }

    if (!isPremium) {
        return (
            <section>
                <div className="innerSection">
                    <br />
                    <h3>
                        <FormattedMessage id="App.info.AssistenzaTecnica" />
                    </h3>
                    <div className="rack box">
                        <div className="w100">
                            <blockquote>
                                <FormattedMessage id="App.info.PremiumOnly1" />
                                <p>
                                                                                        <FormattedMessage id={"App.info.ScopriVantaggi"} />
                                                    <Link to={`/premium`}>
                                                        <FormattedMessage id={"App.info.Abbonati"} />
                                                    </Link>
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

        );
    }

    return (
        <section>
            <div className="innerSection">
                <br />
                <h3>
                    <FormattedMessage id="App.info.AssistenzaTecnica" /></h3>
                {
                    success
                        ? (
                            <Outcome text={intl.formatMessage({ id: "App.info.OperazioneEffettuataConSuccesso" })} type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                {
                    confirm && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.CancellazioneAbilitazione" })}
                                text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitivaAbilitazione" })}
                                onClose={closeModal}
                                onConfirm={confirmDelete}
                            />
                        </>
                    )
                }
                <div className="rack box">
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'active' : 'disabled')}`} onClick={() => tabHandler(true)}>
                            <FormattedMessage id="App.info.UtentiAbilitati" />
                        </h2>
                    </div>
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'disabled' : 'active')}`} onClick={() => tabHandler(false)}>
                            <FormattedMessage id="App.info.NuovaAbilitazione" />
                        </h2>
                    </div>
                </div>
                {
                    firstTab
                        ? renderList()
                        : renderForm()
                }
            </div>
        </section>
    );
};

const CAT = connect(mapStateToProps, mapDispatchToProps)(_CAT);

export default CAT;
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../elements/Modal';
import { b2jFilareStat } from '../../../../lib/goldParser/physicalMap';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    setStore: data => dispatch(setStore(data)),
});

const _StatoIngressiFilari = ({ system, setStore, reset_dev_stats }) => {
    const [group, setGroup] = useState(0);
    const [ingressiFilari, setIngressiFilari] = useState([]);
    const intl = useIntl();

    const init = useCallback(
        () => {
            const _espansioni = [];
            for (let i = 0; i < system.store.pm.bus.length; i++) {
                if (system.store.pm.bus[i].num_tipo_periferica === 8) {
                    _espansioni.push(system.store.pm.bus[i]);
                }
            }
            const _ingressi = [];
            for (let i = 0; i < system.store.pm.filari.length; i++) {
                const _group = Math.floor(i / 16);
                const offset = system.model < 5 ? 2 : 5;
                const _i = i - offset;
                const expansion = i < offset ? false : (_espansioni[Math.floor(_i / 5)] || null);
                const line = expansion ? (_i % 5) + 1 : null;
                const __i = i - (_group * 16);
                if (expansion !== null) {
                    _ingressi.push({
                        idx: i,
                        group: _group,
                        expansion,
                        line,
                        stat: b2jFilareStat(system.store.dev_stats.filare[_group][__i]),
                        ...system.store.pm.filari[i]
                    });
                }
            }
            setIngressiFilari(_ingressi);
        },
        []
    );

    useEffect(
        () => {
            init();
            return () => null;
        },
        []
    );

    useEffect(
        () => {
            setStore({
                polling_requests: ['goldDevStat'],
                polling_payloads: [{ id_centrale: system.id_centrale, type: intl.formatMessage({ id: "App.info.Filare" }), group: group }]
            });
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        [group]
    );

    useEffect(
        () => {
            init();
        },
        [system.store.dev_stats.filare, reset_dev_stats]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.StatoIngressiFilari" />
                </h3>
                <br />
                <div className="rack">
                    <div className="w100 formSection">
                        <form>
                            <fieldset>
                                <div className="rack">
                                    <div className="w20">
                                        <label>
                                            <FormattedMessage id="App.info.SceltaGruppo" />
                                        </label>
                                        <select defaultValue={group} name="group" onChange={e => setGroup(parseInt(e.target.value))}>
                                            <option value={0}>
                                                {intl.formatMessage({ id: "App.info.Da1a16" })}
                                            </option>
                                            {ingressiFilari.length >= 16 && <option value={1}>
                                                {intl.formatMessage({ id: "App.info.Da17a30" })}
                                            </option>}
                                        </select>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th>
                                                <FormattedMessage id="App.info.NomeIngresso" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="App.info.Allarmi" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="App.info.Memorie" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="App.info.Stato" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="App.info.Programmi" />
                                            </th>
                                            <th>
                                                <FormattedMessage id="App.info.Attributi" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {ingressiFilari.length > 0
                                            ? (
                                                <>
                                                    {ingressiFilari.map((item, index) => {
                                                        const allarmi = [];
                                                        !!item.stat.allarme_24 && allarmi.push('24H');
                                                        !!item.stat.ingresso_aperto && allarmi.push('A');
                                                        const memorie = [];
                                                        !!item.stat.memoria_allarme_24 && memorie.push('24H');
                                                        !!item.stat.memoria_allarme && memorie.push('A');
                                                        const stato = [];
                                                        !!item.stat.ingresso_escluso && stato.push('E');
                                                        const attributi = [];
                                                        !!item.ronda && attributi.push('r');
                                                        !!item.percorso && attributi.push('P');
                                                        !!item.silenzioso && attributi.push('S');
                                                        !!item.parzializzabile && attributi.push('p');
                                                        !!item.a && attributi.push('A');
                                                        !!item.fuoco && attributi.push('F');
                                                        !!item.elettroserratura && attributi.push('E');
                                                        !!item.ritardato && attributi.push('R');
                                                        !!item.escluso && attributi.push('e');
                                                        !!item.test && attributi.push('T');
                                                        !!item.sempre_attivo && attributi.push('24H');
                                                        !!item.k && attributi.push('K');
                                                        !!item.campanello && attributi.push('C');
                                                        !!item.supervisione && attributi.push('s');
                                                        !!item.doppio_impulso && attributi.push('D');
                                                        return (
                                                            item.group === group
                                                                ? (
                                                                    <tr key={`row_${index}`}>
                                                                        <td><span className="head">
                                                                            <FormattedMessage id="App.info.NomeIngresso" />
                                                                        </span>{item.nome}</td>
                                                                        <td><span className="head">
                                                                            <FormattedMessage id="App.info.Allarmi" />
                                                                        </span>{(allarmi.join(", "))}</td>
                                                                        <td><span className="head">
                                                                            <FormattedMessage id="App.info.Memorie" />
                                                                        </span>{(memorie.join(", "))}</td>
                                                                        <td><span className="head">
                                                                            <FormattedMessage id="App.info.Stato" />
                                                                        </span>{(stato.join(", "))}</td>
                                                                        <td>
                                                                            <span className="head">
                                                                                <FormattedMessage id="App.info.Programmi" />
                                                                            </span>
                                                                            <i className="fa fa-circle" style={{ color: item.g1 ? "red" : "rgb(176, 176, 176)" }} />
                                                                            <i className="fa fa-circle" style={{ color: item.g2 ? "#ffc20a" : "rgb(176, 176, 176)" }} />
                                                                            <i className="fa fa-circle" style={{ color: item.g3 ? "rgb(40, 170, 40)" : "rgb(176, 176, 176)" }} />
                                                                        </td>
                                                                        <td><span className="head">
                                                                            <FormattedMessage id="App.info.Attributi" />
                                                                        </span>{(attributi.join(", "))}</td>
                                                                    </tr>
                                                                ) : (
                                                                    <React.Fragment key={`row_${index}`} />
                                                                )
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={2}>
                                                        <FormattedMessage id="App.info.CaricamentoInCorso" />
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w33">
                                        <h3>
                                            <FormattedMessage id="App.info.Legenda" />
                                        </h3>

                                        <div className="rack">
                                            <div className="w100">
                                                <p>
                                                    <FormattedMessage id="App.info.AllarmeA" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.MemoriaAllarme24H" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.EsclusoE" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w66">
                                        <h3>
                                            <FormattedMessage id="App.info.LegendaAttributi" />
                                        </h3>
                                        <div className="rack">
                                            <div className="w50">
                                                <p>
                                                    <FormattedMessage id="App.info.Rondar" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.PercorsoP" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.SilenziosoS" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.Parzializzabilep" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.AllarmeA" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.FuocoF" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.ElettroserraturaE" />
                                                </p>
                                            </div>
                                            <div className="w50">
                                                <p>
                                                    <FormattedMessage id="App.info.RitardatoR" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.Esclusoe" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.TestT" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.Allarme24H" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.AllarmeK" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.CampanelloC" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.DoppioImpulsoD" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoIngressiFilari = connect(mapStateToProps, mapDispatchToProps)(_StatoIngressiFilari);

export default StatoIngressiFilari;
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { arrayIntToString } from '../../../../lib/bytesFunctions';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    doSocketRequest
} from '../../../../actions';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketLogin: (socket, payload) => dispatch(doSocketRequest(socket, 0x00FB, payload, false)),
    doSocketProgram: (socket, payload, auth = true, noSuccess = false) => dispatch(doSocketRequest(socket, 0x00F0, payload, auth, noSuccess)),
    doSocketSuper: (socket, payload) => dispatch(doSocketRequest(socket, 0x00F1, payload)),
});

const _PannelloControllo = ({ system, doSocketLogin, doSocketProgram, doSocketSuper, socket }) => {
    const codiceForm = useRef(null);
    const programmiForm = useRef(null);
    const status = !!system && !!system.store && !!socket ? system.store.status : null;
    const [payloadLogin, setPayloadLogin] = useState([]);
    const [switched, setSwitched] = useState(false);
    const [touched, setTouched] = useState(false);
    const [g1, setG1] = useState(null);
    const [g2, setG2] = useState(null);
    const [g3, setG3] = useState(null);
    const [gExt, setGExt] = useState(null);
    const intl = useIntl();

    useEffect(
        () => {
            if (!!status && status.structs != null && status.structs.isTeknoxAuthorized.auth_level > 0) {
                if (switched || !touched) {
                    if (
                        g1 != status.structs.generali_3.attivo_g1
                        || g2 != status.structs.generali_3.attivo_g2
                        || g3 != status.structs.generali_3.attivo_g3
                        || gExt != status.structs.generali_3.attivo_gext
                    ) {
                        handleProgramChange(true);
                        setSwitched(false);
                    }
                } else {
                    handleProgramChange(false);
                }
            }
            return () => {
                sessionStorage.setItem('systemId', 0);
            };
        }
    );

    var teknox = null;
    if (!status) {
        return (
            <section>
                <div className="innerSection">
                    <br />
                    <br />
                    <h3>
                        <FormattedMessage id="App.info.PannelloDiControllo" />
                    </h3>
                    <div className="rack box">
                        <div className="w100">
                            <blockquote>
                                <FormattedMessage id="App.info.ConnessioneCloudNonRiuscita" />
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized;
        }
    }

    const st0 = system.store[0x00E5] != null ? arrayIntToString(system.store[0x00E5].split(',').map(x => parseInt(x)).slice(2, 34)) : ``;

    const systemStatusLed = (
        (status.structs.generali_1.allarme || status.structs.generali_1.servizio || status.structs.generali_1.as24_in)
            ? 'red'
            : (status.structs.generali_4.ingressi_aperti || status.structs.generali_4.ingressi_esclusi || status.structs.generali_2.status)
                ? '#ffc20a'
                : 'rgb(40, 170, 40)'
    );

    const handleChangeLogin = (e) => {
        setPayloadLogin(
            [...e.target.value.split('').filter(x => RegExp(/([0-9])+/).test(x)).map(x => Number('0x0' + (x))), ...new Array(6).fill(0xff)].slice(0, 6));
    };

    const handleProgramChange = switchTriggered => {
        if (switchTriggered) {
            const form = programmiForm.current;
            form[`g1`].checked = status.structs.generali_3.attivo_g1;
            form[`g2`].checked = status.structs.generali_3.attivo_g2;
            form[`g3`].checked = status.structs.generali_3.attivo_g3;
            form[`gext`].checked = status.structs.generali_3.attivo_gext;
        }
        setG1(status.structs.generali_3.attivo_g1);
        setG2(status.structs.generali_3.attivo_g2);
        setG3(status.structs.generali_3.attivo_g3);
        setGExt(status.structs.generali_3.attivo_gext);
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        setTouched(false);
        const form = codiceForm.current;
        doSocketLogin(socket, payloadLogin);
        form['codice'].value = '';
        sessionStorage.systemId = system.id;
    };

    const handleSubmitSwitch = (e) => {
        e.preventDefault();
        setTouched(false);
        const form = codiceForm.current;
        let found = false;
        let codice = form["codice"].value.split('').map(x => x == '0' ? 10 : parseInt(x)).join('');
        for (let i = 0x0063; i <= 0x00E2; i++) {
            let _s = system.store[i]
                .split(',')
                .map(x => parseInt(x));
            if (_s[4] & 128) {
                continue;
            }
            let _x = _s.slice(0, 3)
                .reduce((acc, cur) => { acc.push(cur & 0xF); acc.push(cur >> 4); return acc; }, [])
                .filter(x => x !== 0)
                .join('');
            if (_x == codice) {
                found = _s;
                break;
            }
        }
        handleSubmitLogin(e);
        if (found) {
            setSwitched(true);
            setTimeout(() => {
                let on = status.generali_3 & 0xF;
                let programs = found[3] & 0xF;
                let payload = null;
                if ((on & programs) === programs) {
                    if (programs === 7) {
                        payload = 0;
                    } else {
                        payload = (on &= ~programs);
                    }
                } else {
                    if (programs === 7 && (on & 7)) {
                        payload = (on &= ~programs);
                    } else {
                        payload = (on ^= programs);
                    }
                }
                if (payload != null) {
                    doSocketProgram(socket, [payload], false, true);
                }
            }, 1000);
        }
    };

    const handleSubmitProgram = (e) => {
        e.preventDefault();
        let byte = 0;
        const form = programmiForm.current;
        byte += form['g1'].checked ? 1 : 0;
        byte += form['g2'].checked ? 2 : 0;
        byte += form['g3'].checked ? 4 : 0;
        byte += form['gext'].checked ? 8 : 0;
        doSocketProgram(socket, [byte]);
    };

    const handleSubmitSuper = byte => {
        doSocketSuper(socket, [byte]);
    };

    return (
        <section>
            <div className="innerSection">
                <br />
                <br />
                <h3>
                    <FormattedMessage id="App.info.PannelloDiControllo" />
                </h3>
                <div className="rack">
                    <div className="w50">
                        <div className="padp">
                            <p>
                                <FormattedMessage id="App.info.Codice" />
                            </p>
                            <form ref={codiceForm}>
                                <fieldset>
                                    <input
                                        type="password"
                                        onKeyPress={e => {
                                            if (e.which < 48 || e.which > 57) {
                                                e.preventDefault();
                                            }
                                        }}
                                        maxLength={6}
                                        autoComplete={`off`}
                                        placeholder={intl.formatMessage({ id: "App.info.InserisciCodice" })}
                                        name="codice"
                                        onChange={handleChangeLogin}
                                        defaultValue={``}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                handleSubmitLogin(e);
                                            }
                                        }}
                                    />
                                    <p>
                                        <button className="yellowButton" onClick={handleSubmitLogin}>
                                            <FormattedMessage id="App.info.Login" />
                                        </button>&nbsp;
                                        <button className="yellowButton" onClick={handleSubmitSwitch}>
                                            <FormattedMessage id="App.info.CommutazioneRapida" />
                                        </button>
                                    </p>
                                    {teknox.auth_level === 0 && (
                                        <p>
                                            <small>
                                                <em><FormattedMessage id="App.info.AttendiDopoInserimento" /></em>
                                                <br />
                                                <em><FormattedMessage id="App.info.CodiceErratoProblemaComunicazione" /></em>
                                                <br />
                                                <em><FormattedMessage id="App.info.CentraleSiBlocca" /></em>
                                            </small>
                                        </p>
                                    )}
                                </fieldset>
                            </form>
                            {teknox.auth_level > 0 && (
                                <>
                                    <div>
                                        <i className="fa fa-circle" style={{ color: systemStatusLed }} />
                                        <FormattedMessage id="App.info.CentraleSiBlocca" />
                                    </div>
                                    <form ref={programmiForm}>
                                        <div className="bottomBorder">
                                            <div className="switchButton controlPanelButton" style={{ margin: 0 }}>
                                                <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" defaultChecked={status.structs.generali_3.attivo_g1} onChange={() => setTouched(true)} disabled={!teknox || !teknox.g1} />
                                                <label htmlFor={`program_label_g1`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW red">{system.g1}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="bottomBorder">
                                            <div className="switchButton controlPanelButton">
                                                <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" defaultChecked={status.structs.generali_3.attivo_g2} onChange={() => setTouched(true)} disabled={!teknox || !teknox.g2} />
                                                <label htmlFor={`program_label_g2`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW yellow">{system.g2}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="bottomBorder">
                                            <div className="switchButton controlPanelButton">
                                                <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" defaultChecked={status.structs.generali_3.attivo_g3} onChange={() => setTouched(true)} disabled={!teknox || !teknox.g3} />
                                                <label htmlFor={`program_label_g3`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW green">{system.g3}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="switchButton controlPanelButton">
                                                <input type="checkbox" className="switch" id="program_label_gext" name="gext" defaultValue="0" defaultChecked={status.structs.generali_3.attivo_gext} onChange={() => setTouched(true)} disabled={!teknox || !teknox.gext} />
                                                <label htmlFor={`program_label_gext`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <blockquote className="programLabel programLabelFW blue">{system.gext}</blockquote>
                                                </label>
                                            </div>
                                        </div>
                                        <fieldset>
                                            <div>
                                                <button className="yellowButton" onClick={handleSubmitProgram} disabled={!touched || teknox.auth_level === 3}>
                                                    <FormattedMessage id="App.info.ConfermaStatoProgrammi" />
                                                </button>&nbsp;
                                            </div>
                                        </fieldset>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="w50">
                        {teknox.auth_level > 0 && (
                            <div className="padp">
                                <p>{st0}</p>
                                <button className="superButton" onClick={e => { e.preventDefault(); handleSubmitSuper(1); }}>
                                    <FormattedMessage id="Tasto0" />
                                </button>
                                <br />
                                <p><FormattedMessage id="App.info.AttivitaRele" /></p>
                                <button className="superButton" onClick={e => { e.preventDefault(); handleSubmitSuper(2); }}>
                                    <FormattedMessage id="Tasto1" />
                                </button>
                                <br />
                                <p><FormattedMessage id="App.info.AttivaUscitaAt" /></p>
                                <button className="superButton" onClick={e => { e.preventDefault(); handleSubmitSuper(3); }}>
                                    <FormattedMessage id="Tasto2" />
                                </button>
                                <br />
                                <p><FormattedMessage id="App.info.AttivaUscitaAllarmeAt" /></p>
                                <button className="superButton" onClick={e => { e.preventDefault(); handleSubmitSuper(4); }}>
                                    <FormattedMessage id="Tasto3" />
                                </button>
                                <br />
                                <p></p>
                                <button className="superButton" onClick={e => { e.preventDefault(); handleSubmitSuper(5); }}>
                                    <FormattedMessage id="Tasto4" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const PannelloControllo = connect(mapStateToProps, mapDispatchToProps)(_PannelloControllo);

export default PannelloControllo;
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import Badge from 'react-bootstrap/Badge';

import { loadStripe } from '@stripe/stripe-js';

import { doRequest, errorRead } from '../../../actions';

import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

import Modal from '../../elements/Modal';

import Outcome from '../../elements/Outcome';

import NewSim from '../../elements/Forms/NewSim';

import '../../elements/Loaders/Loaders.scss';

import {
    getAllSystem, simCheckStripeSession, simRetrieveSession, simGetPrectivate, simChangeStripeRate, simMailActivate, simMailChangeRate, simMailDeactivate,
    simInsertStripeIdSession, simRetrieveSessionUrl, simDeleteStripeSession, simDeleteStripeSessionSubscription, simGetAllSim, simInsertAllHistory,
    simInsertNewSim, simGetChangeRate, simGetDeactivate, simDeleteStripeSubscription, simDeleteUtentiSim, simCheckSimSystem, simChangeSimSystem,
    simRetrieveStripeSubscription, simInsertStripeIdSubscription, simDeleteStripeIdSession, simGetHistory, simGetAvailableRates, simFullActivate, simFullDeactivate
} from '../../../api/Cloud';

import { simGetAuth } from '../../../api/Cloud';
import { simGetStatus } from '../../../api/Cloud';
import { simGetUserSim } from '../../../api/Cloud';
import { simCreateCheckoutSession } from '../../../api/Cloud';
import { simGetSimConsumption } from '../../../api/Cloud';
import { simChangePaymentMethod } from '../../../api/Cloud';
import { FormattedMessage, useIntl } from 'react-intl';

Chart.register(...registerables);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PK);

const urlPropsQueryConfig = {
    session_id: { type: UrlQueryParamTypes.string },
    delete_session: { type: UrlQueryParamTypes.string },
    new_sim: { type: UrlQueryParamTypes.string },
    id_centrale: { type: UrlQueryParamTypes.string },
    msisdn: { type: UrlQueryParamTypes.string },
    new_price: { type: UrlQueryParamTypes.string }
}

const mapDispatchToProps = (dispatch) => ({
    verifyCheckoutSession: payload => dispatch(doRequest('verifyCheckoutSession', payload)),
    errorRead: () => dispatch(errorRead()),
});

const mapStateToProps = state => ({
    user: state.user,
    data: state.data,
    stateError: state.error
});



const _Sim = ({ data, errorRead, stateError, session_id, user, verifyCheckoutSession, delete_session, new_sim, id_centrale, msisdn, new_price }) => {

    const intl = useIntl();

    const msisdn_ignore = ["467191207765450",
        "467191208372442",
        "467191208372437",
        "467191207765420",
        "467191207765421",
        "467191207765422",
        "467191207765459",
        "467191207765444",
        "467191209052296"];

    const initialPayload = {
        id_centrale: null,
        msisdn: null,
        price: null,
    };

    const initialConsumption = {
        data: {
            consumption: {
                data: 0,
                voice: 0,
                sms: 0
            }
        }
    }

    const initial_data_chart = {
        labels: ["", "", "", "",
            "", "", "", "", "", "", "", ""],
        datasets: [
            {
                label: "",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                backgroundColor: "#fce8b5",
                borderColor: "#edac09",
            }
        ]
    }

    const initial_sms_chart = {
        labels: ["", "", "", "",
            "", "", "", "", "", "", "", ""],
        datasets: [
            {
                label: "",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                backgroundColor: "#fce8b5",
                borderColor: "#edac09",
            }
        ]
    }

    const initial_voice_chart = {
        labels: ["", "", "", "",
            "", "", "", "", "", "", "", ""],
        datasets: [
            {
                label: "",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                backgroundColor: "#fce8b5",
                borderColor: "#edac09",
            }
        ]
    }



    const [confirm, setConfirm] = useState(false);
    const [confirm2, setConfirm2] = useState(false);
    const [confirm3, setConfirm3] = useState(false);
    const [confirm4, setConfirm4] = useState(false);
    const [confirm5, setConfirm5] = useState(false);
    const [confirm6, setConfirm6] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processedDelete, setProcessedDelete] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [errors, setErrors] = useState("");
    const [info, setInfo] = useState("");
    const [firstTab, setFirstTab] = useState(true);
    const [systems, setSystems] = useState(false);
    const [showNewSim, setShowNewSim] = useState(false);
    const [showSimStatus, setShowSimStatus] = useState(false);
    const [pianoTariffario, setPianoTariffario] = useState(false);
    const [auth, setAuth] = useState(false);
    const [simStatus, setStatus] = useState(false);
    const [simConsumption, setConsumption] = useState(initialConsumption);
    const [simIdx, setSimIdx] = useState(-1);
    const [simUser, setUserSim] = useState(false);
    const [simCheckoutSession, setSimCheckoutSession] = useState(false);
    const [arrSim, setArrSim] = useState([]);
    const [payload, setPayload] = useState(initialPayload);
    const [verifySim, setVerifySim] = useState(false);
    const [checkStripeSession, setCheckStripeSession] = useState(false);
    const [retrievedStripeSession, setRetrieveStripeSession] = useState(false);
    const [retrievedStripeSessionArr, setRetrievedStripeSession] = useState([])
    const [retrievedStripeSessionUrl, setRetrieveStripeSessionUrl] = useState(false);
    const [stripeIdSession, setStripeIdSession] = useState(false);
    const [insertNewSimC, setInsertNewSim] = useState(false);
    const [retrievedStripeSubscription, setRetrievedSubscription] = useState(false);
    const [simHistory, setSimHistory] = useState(false);
    const [data_chart, setDataChart] = useState(initial_data_chart);
    const [sms_chart, setSmsChart] = useState(initial_sms_chart);
    const [voice_chart, setVoiceChart] = useState(initial_voice_chart);
    const [simAvailableRates, setSimAvailableRates] = useState(false);
    const [currentMsisdn, setCurrentMsisdn] = useState(false);
    const [currentIdCentrale, setCurrentIdCentrale] = useState(false);

    var idx_sim = -1;

    useEffect(() => {
        getAuth();
        getUserSim();
        //getSimGetAllSim(); //temp
        pollSystem();
        const interval = setInterval(async () => {
            await pollSystem();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(
        () => {
            if (simUser) {
                for (let i = 0; i < simUser.length; i++) {
                    setConfirm4(true);
                    getStatus(simUser[i].msisdn);
                }
                if (simUser.length > 0)
                    tabHandler(false);
            }
        },
        [simUser]
    );

    useEffect(
        () => {
            if (simStatus) {

                let _simStatus = [];
                if (arrSim) {
                    _simStatus = arrSim;
                }

                if (!!simStatus.data) {
                    _simStatus.push(
                        simStatus.data.extension
                    );
                } else {
                    console.log(simStatus)
                }

                setArrSim(_simStatus);

                setShowSimStatus(true);
                setSimIdx(0);
                getConsumption(arrSim[0].msisdn);
                getSimGetHistory(simUser[0].msisdn);



                let idx = 0;
                for (idx = 0; idx < retrievedStripeSessionArr.length; idx++) {
                    let index = arrSim.map(e => e.msisdn).indexOf(retrievedStripeSessionArr[idx].msisdn);
                    let index_1 = simUser.map(e => e.msisdn).indexOf(retrievedStripeSessionArr[idx].msisdn);
                    let index_2 = systems.map(e => e.id_centrale).indexOf(simUser[index_1].id_centrale);

                    if (index != -1) {
                        arrSim[index].valid = (retrievedStripeSessionArr[idx].valid) ? intl.formatMessage({ id: 'App.info.Regolare' }) : intl.formatMessage({ id: 'App.info.NonRegolare' });

                        if (index_2 != -1) {
                            arrSim[index].nome_impianto = systems[index_2].nome_impianto;
                            arrSim[index].id_centrale = systems[index_2].id_centrale;
                        }
                    }
                }
                const interval1 = setInterval(async () => {
                    let idx = 0;
                    for (idx = 0; idx < retrievedStripeSessionArr.length; idx++) {
                        let index = arrSim.map(e => e.msisdn).indexOf(retrievedStripeSessionArr[idx].msisdn);
                        let index_1 = simUser.map(e => e.msisdn).indexOf(retrievedStripeSessionArr[idx].msisdn);
                        let index_2 = systems.map(e => e.id_centrale).indexOf(simUser[index_1].id_centrale);

                        if (index != -1) {
                            arrSim[index].valid = (retrievedStripeSessionArr[idx].valid) ? intl.formatMessage({ id: 'App.info.Regolare' }) : intl.formatMessage({ id: 'App.info.NonRegolare' });

                            if (index_2 != -1) {
                                arrSim[index].nome_impianto = systems[index_2].nome_impianto;
                                arrSim[index].id_centrale = systems[index_2].id_centrale;
                            }
                        }
                    }
                }, 500);
                return () => clearInterval(interval1);

            }
        },
        [simStatus]
    );

    useEffect(
        () => {
            if (!!session_id && firstLoad) {
                verifyCheckoutSession({ session_id });
                setFirstLoad(false);
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => setFirstLoad(false);
        },
        [errorRead, setErrors, stateError, session_id, setFirstLoad, firstLoad, verifyCheckoutSession, setProcessed2]
    );


    const tabHandler = value => setFirstTab(value);

    const handleClickChangeRate = async (e) => {
        e.preventDefault();

        if (e.target.name === '') {
            setErrors(intl.formatMessage({ id: 'App.info.NonDisponibileAlMomento' }));
            errorRead();
        }
        getSimAvailableRates(e.target.name);
        setCurrentMsisdn(e.target.name);
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
    }

    const confirmChangeRate = e => {
        e.preventDefault();
        setConfirm(false);

        let index = simUser.map(e => e.msisdn).indexOf(currentMsisdn);
        getSimChangeStripeRate(checkStripeSession[index].id_subscription);
    }

    const handleClickSuspend = async (e) => {
        e.preventDefault();
        setConfirm2(e.target.value);
    }

    const handleClickChangePayment = async (e) => {
        e.preventDefault();
        changePaymentMethod();
    }

    const closeModalSuspend = e => {
        e.preventDefault();
        setConfirm2(false);
    }

    const confirmSuspend = e => {
        e.preventDefault();
        let msisdn = confirm2;
        getSimFullDeactivate(msisdn);
        setConfirm2(false);
    }

    const handleChangeSystem = async (e) => {
        e.preventDefault();
        setConfirm3(true);
        setCurrentMsisdn(e.target.name);
        setCurrentIdCentrale(e.target.value);
    }

    const closeModalChangeSystem = e => {
        e.preventDefault();
        setConfirm3(false);
        window.location.replace(window.location.pathname);
    }

    const closeModalActivateSystem = e => {
        e.preventDefault();
        setConfirm6(false);
        window.location.replace(window.location.pathname);
    }

    const closeModalDeactivateSystem = e => {
        e.preventDefault();
        setConfirm5(false);
        window.location.replace(window.location.pathname);
    }

    const confirmChangeSystem = e => {
        e.preventDefault();
        setConfirm3(false);

        getSimCheckSystem(currentIdCentrale, currentMsisdn);
    }

    const handlerChange = (e) => {
        const _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        setConfirm4(true);

        const _payload = payload;
        if (pianoTariffario === 'WORLDSIM 4G 50MB 10 SMS 1 Min Voice') {
            _payload['price'] = '50MB IT-TL155';
        } else {
            _payload['price'] = '50MB IT-TL3';
        }


        setPayload({
            ...payload,
            ..._payload,
        });

        getStatusNewSim(_payload);
    };

    const handlerSim = (e) => {
        e.preventDefault();
        idx_sim = arrSim.map(e => e.msisdn).indexOf(e.target.textContent);
        setShowSimStatus(true);
        setSimIdx(idx_sim);
        getConsumption(arrSim[idx_sim].msisdn);
        getSimGetHistory(arrSim[idx_sim].msisdn);
        //changePaymentMethod();

        /*let desc = 'WORLDSIM 4G 50 MB 10 SMS 1 Min Voice';
        let price = '2,49 EUR';
       
        getSimMailActivate(arrSim[idx_sim].msisdn, desc, price);
        getSimMailChangeRate(arrSim[idx_sim].msisdn, desc, price);*/

    }

    const handleAfterClick = async sessionId => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
            setErrors(error.message);
        }
    };

    const handleAfterClickChangePayment = async sessionId => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
            setErrors(error.message);
        }
    };

    useEffect(
        () => {
            if (simCheckoutSession && processed) {

                if (!new_sim) {
                    setErrors("");
                    setProcessed(false);
                    const { id } = simCheckoutSession;
                    insertStripeIdSession(id, payload.msisdn);
                    handleAfterClick(id);
                    simCheckoutSession = null;
                }
            }
        },
        [simCheckoutSession, processed, payload]
    );



    useEffect(
        () => {
            if (delete_session) {
                deleteStripeSession();
            }
        },
        [delete_session]
    );

    useEffect(
        () => {
            if (new_sim) {
                //insertNewSim(id_centrale, msisdn);
                getSimFullActivate(msisdn, id_centrale, new_price)
            }
        },
        [new_sim, payload]
    );


    if (retrievedStripeSessionUrl && processed) {
        setErrors("");
        setProcessed(false);
        const { id } = retrievedStripeSessionUrl;
        handleAfterClick(id);
    }


    if (simCheckoutSession && processed) {
        //setErrors("");
        //setProcessed(false);
        //const { id } = data;
        //handleAfterClick(id);
        /*if (!new_sim) {
            setErrors("");
            setProcessed(false);
            const { id } = simCheckoutSession;     
            insertStripeIdSession(id, payload.msisdn);
            handleAfterClick(id);
            simCheckoutSession = null;
        }*/

    }



    if (false && processed2) {
        setErrors("");
        setProcessed2(false);
        //window.location.replace(window.location.pathname);
        return (<></>);
    }

    if (false && processedDelete) {
        setErrors("");
        setProcessedDelete(false);
        //window.location.replace(window.location.pathname);
        return (<></>);
    }



    const pollSystem = async () => {
        const [__data, __code] = await getAllSystem();
        if (__code === 200) {
            setSystems(__data);
        }
    };

    const getAuth = async () => {
        const [__data, __code] = await simGetAuth();
        if (__code === 200) {
            setAuth(__data);
            getCheckStripeSession();
        }
    };

    const getCheckStripeSession = async () => {
        const [__data, __code] = await simCheckStripeSession();
        if (__code === 200) {
            setCheckStripeSession(__data);
            if (__data.length > 0) {
                for (let i = 0; i < __data.length; i++) {
                    if (__data[i].id_session == null) {
                        if (!new_sim)
                            getRetrieveStripeSession(__data[i].id_subscription, __data[i].msisdn);
                    } else {
                        if (new_sim) {
                            if ((__data[i].msisdn) === msisdn) {
                                retrieveStripeSubscription(__data[i].id_session);
                                setInsertNewSim(false);
                                //new_sim = false;
                                i = __data.length;
                                //return;
                            }

                        } else {
                            getRetrieveStripeSessionUrl(__data[i].id_session);
                        }
                    }
                }
            }
        }
    };

    const getRetrieveStripeSession = async (id_subscription, msisdn) => {
        const payload =
        {
            id_subscription: id_subscription,
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simRetrieveSession(payload);
        if (__code === 200) {
            setRetrieveStripeSession(__data);
            setProcessed2(true);
            retrievedStripeSessionArr.push(__data);
        }
    };

    const getRetrieveStripeSessionUrl = async (id_session) => {
        const payload =
        {
            id_session: id_session,
        }
            ;
        const [__data, __code] = await simRetrieveSessionUrl(payload);
        if (__code === 200) {
            setRetrieveStripeSessionUrl(__data);
            setProcessed(true);
        }
    };

    const deleteStripeSession = async () => {

        const [__data, __code] = await simDeleteStripeSession();
        if (__code === 200) {
            delete_session = false;
        }
    };

    //
    const deleteStripeSessionSubscription = async (id_subscription, msisdn) => {
        const payload =
        {
            id_subscription: id_subscription,
        }
            ;
        const [__data, __code] = await simDeleteStripeSessionSubscription(payload);
        if (__code === 200) {
            //eliminare riga su utenti_sim
            console.log("deleteStripeSessionSubscription: " + msisdn);
            console.log(__data);
            getSimDeleteUtentiSim(msisdn);
        }
    };

    const getSimDeleteUtentiSim = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn,
        }

        const [__data, __code] = await simDeleteUtentiSim(payload);
        if (__code === 200) {
            console.log("getSimDeleteUtentiSim: " + msisdn);
            console.log(__data);
            getSimMailDeactivate(msisdn);
        }

    };


    const getSimCheckSystem = async (id_centrale, msisdn) => {
        const payload =
        {
            id_centrale: id_centrale,
        }

        const [__data, __code] = await simCheckSimSystem(payload);
        if (__code === 200) {
            if (__data[0].length > 0) {
                setErrors(intl.formatMessage({ id: 'App.info.ImpiantoGiaAssociataSim' }));
                errorRead();
            } else {
                getSimChangeSystem(id_centrale, msisdn);
            }
        }

    };

    const getSimChangeSystem = async (id_centrale, msisdn) => {
        const payload =
        {
            id_centrale: id_centrale,
            msisdn: msisdn,
        }

        const [__data, __code] = await simChangeSimSystem(payload);
        if (__code === 200) {
            window.location.replace(window.location.pathname);
        }

    };

    const insertNewSim = async (id_centrale, msisdn) => {
        const payload =
        {
            id_centrale: id_centrale,
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simInsertNewSim(payload);
        if (__code === 200) {
            setInsertNewSim(true);
            getCheckStripeSession();
        }
    }

    const retrieveStripeSubscription = async (id_session) => {
        const payload =
        {
            id_session: id_session,
        }
            ;
        const [__data, __code] = await simRetrieveStripeSubscription(payload);
        if (__code === 200) {
            setRetrievedSubscription(__data);
            insertStripeIdSubscription(__data.id_subscription);
        }
    };

    const insertStripeIdSubscription = async (id_subscription) => {
        const payload =
        {
            id_subscription: id_subscription,
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simInsertStripeIdSubscription(payload);
        if (__code === 200) {
            deleteStripeIdSession();
        }
    };


    const deleteStripeIdSession = async () => {
        let inner_new_rate = new_price; //'50MB IT-TL155';
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simDeleteStripeIdSession(payload);
        if (__code === 200) {
            //se SIM su lista non fare Preactivate ma subito mail
            let index = msisdn_ignore.indexOf(msisdn);
            if (index != -1) {
                let desc = 'WORLDSIM 4G 50 MB 10 SMS 1 Min Voice';
                let price = '2,49 EUR';
                if (inner_new_rate === '50MB IT-TL3') {
                    desc = 'WORLDSIM 4G 50MB 50 SMS 5 Min Voice';
                    price = '3,99 EUR';
                }
                getSimMailActivate(msisdn, desc, price);
            } else {
                getSimPreactivate(msisdn, inner_new_rate);
            }

            // window.location.replace('/sim');
        }
    };

    const getSimAvailableRates = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetAvailableRates(payload);
        if (__code === 200) {
            setSimAvailableRates(__data);
            setConfirm(true);
        }
    };

    const getSimPreactivate = async (msisdn, new_rate) => {
        const payload =
        {
            msisdn: msisdn,
            new_rate: new_rate
        }
            ;
        const [__data, __code] = await simGetPrectivate(payload);
        if (__code === 200) {
            let desc = 'WORLDSIM 4G 50 MB 10 SMS 1 Min Voice';
            let price = '2,49 EUR';
            if (new_rate === '50MB IT-TL3') {
                desc = 'WORLDSIM 4G 50MB 50 SMS 5 Min Voice';
                price = '3,99 EUR';
            }
            getSimMailActivate(msisdn, desc, price);
        }
        /*else {
            let desc = 'WORLDSIM 4G 50 MB 10 SMS 1 Min Voice';
            let price = '2,49 EUR';
            console.log("DESC: " + desc + " - PRICE: " + price);
            if (new_rate === '50MB IT-TL3') {
                desc = 'WORLDSIM 4G 50MB 50 SMS 5 Min Voice';
                price = '3,99 EUR';
            }
            console.log("DESC: " + desc + " - PRICE: " + price + " - NEWRATE: " + new_rate + " - MSISDN: " + msisdn + " - CODE: " + __code);
            getSimMailActivate(msisdn, desc, price);
        }*/
        //console.log("NO MAIL CODE: " + __code);
    };

    const getSimFullActivate = async (msisdn, id_centrale, new_rate) => {
        let tariffa_desc = 'WORLDSIM 4G 50 MB 10 SMS 1 Min Voice';
        let tariffa = '2,49 EUR';
        if (new_rate === '50MB IT-TL3') {
            tariffa_desc = 'WORLDSIM 4G 50MB 50 SMS 5 Min Voice';
            tariffa = '3,99 EUR';
        }
        const payload =
        {
            msisdn, id_centrale, new_rate, tariffa, tariffa_desc
        }
            ;
        const [__data, __code] = await simFullActivate(payload);
        if (__code == 200) {
            window.location.replace('/sim');
        } else {
            setConfirm6(intl.formatMessage({ id: "App.info.ImpossibileAttivareSimContattaAssistenza" }));
        }
    };

    const getSimFullDeactivate = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simFullDeactivate(payload);
        if (__code === 200) {
            setConfirm5(intl.formatMessage({ id: "App.info.SimDisattivata" }));
        } else {
            setConfirm5(intl.formatMessage({ id: "App.info.ImpossibileAttivareSimContattaAssistenza" }));
        }
    };

    const getSimDeactivate = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetDeactivate(payload);
        if (__code === 200) {
            let index = checkStripeSession.map(e => e.msisdn).indexOf(msisdn);
            console.log("getSimDeactivate: " + msisdn + " - " + checkStripeSession[index].id_subscription);
            getSimStripeDeactivate(msisdn, checkStripeSession[index].id_subscription);
        }
    };

    const getSimStripeDeactivate = async (msisdn, id_subscription) => {
        const payload =
        {
            msisdn: msisdn,
            id_subscription: id_subscription
        }

        const [__data, __code] = await simDeleteStripeSubscription(payload);
        if (__code === 200) {
            //delete id_subscription
            //fare delete su utenti_sim e stripe_sim
            console.log("getSimStripeDeactivate: " + msisdn + " - " + id_subscription);
            deleteStripeSessionSubscription(id_subscription, msisdn);

        }

    };



    const getSimChangeRate = async (msisdn, new_rate) => {
        const payload =
        {
            msisdn: msisdn,
            new_rate: new_rate
        }
            ;
        const [__data, __code] = await simGetChangeRate(payload);
        if (__code === 200) {
            //cambio tariffa avvenuto con successo  
            let desc = 'WORLDSIM 4G 50 MB 10 SMS 1 Min Voice';
            let price = '2,49 EUR';
            if (new_rate === '50MB IT-TL3') {
                desc = 'WORLDSIM 4G 50MB 50 SMS 5 Min Voice';
                price = '3,99 EUR';
            }
            getSimMailChangeRate(msisdn, desc, price);
            setInfo(intl.formatMessage({ id: "App.info.PianoTariffarioModificato" }));
            errorRead();
        }
    };


    const getSimChangeStripeRate = async (id_subscription) => {
        const payload =
        {
            id_subscription: id_subscription,
        }
            ;
        const [__data, __code] = await simChangeStripeRate(payload);
        if (__code === 200) {
            getSimChangeRate(currentMsisdn, simAvailableRates.data.rates[0].name);
        }
    };

    const getSimMailActivate = async (msisdn, tariffa_desc, tariffa) => {
        const payload =
        {
            msisdn: msisdn,
            tariffa_desc: tariffa_desc,
            tariffa: tariffa
        }
            ;
        const [__data, __code] = await simMailActivate(payload);
        if (__code === 200) {
            window.location.replace('/sim');
            //getDataIncHistory(msisdn);
        }
    };

    const getSimMailChangeRate = async (msisdn, tariffa_desc, tariffa) => {
        const payload =
        {
            msisdn: msisdn,
            tariffa_desc: tariffa_desc,
            tariffa: tariffa
        }
            ;
        const [__data, __code] = await simMailChangeRate(payload);
        if (__code === 200) {

        }
    };

    const getSimMailDeactivate = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn,
        }
            ;
        const [__data, __code] = await simMailDeactivate(payload);
        if (__code === 200) {
            console.log("getSimMailDeactivate: " + msisdn);
            console.log(__data);
        }
    };

    const getSimGetAllSim = async (msisdn) => {
        const [__data, __code] = await simGetAllSim();
        if (__code === 200) {
            /*let i = 0;
            for (i = 0; i < __data.length; i++)
                getAllSimConsumption(__data[i].msisdn);*/
        }
    };

    const getSimInsertAllHistory = async (msisdn, data, data_inc, dati, voce, sms) => {
        const payload =
        {
            msisdn: msisdn,
            data: data,
            data_inc: data_inc,
            dati: dati,
            voce: voce,
            sms: sms,
        }
            ;
        const [__data, __code] = await simInsertAllHistory(payload);
        if (__code === 200) {

        }
    };


    const getDataIncHistory = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetHistory(payload);

        if (__code === 200) {
            var max_val = 0;
            var i = 0;
            for (i = 0; i < __data.length; i++) {
                if (__data[i].data_inc > max_val) {
                    max_val = __data[i].data_inc;
                }
            }

            if (max_val === 0) {
                const data_corrente = new Date();
                const options = { month: 'short' };


                let j = 12;
                for (i = 1; i < 13; i++) {
                    data_corrente.setMonth(data_corrente.getMonth() - 1);
                    getSimInsertAllHistory(msisdn, data_corrente.toLocaleDateString('it-IT', options) + "-" + data_corrente.getFullYear(), j.toString(), '0', '0', '0');
                    j--;
                }
            }

        }

    }


    const getAllSimHistory = async (msisdn, data, voice, sms) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetHistory(payload);
        if (__code === 200) {
            const data_corrente = new Date();
            const options = { month: 'short' };

            var i = 0;
            var index = 0;
            var max_val = 0;
            for (i = 0; i < __data.length; i++) {
                if (__data[i].data_inc > max_val) {
                    max_val = __data[i].data_inc;
                    index = i;
                }
            }

            var data_inc = parseInt(max_val) + 1;

            /*console.log("");
            console.log("****** MSISDN " + msisdn + " INSERT HISTORY VALUE ******");
            console.log("msisdn    :   " + msisdn);
            console.log("data      :   " + "feb-"+data_corrente.getFullYear());   //data_corrente.toLocaleDateString('it-IT', options)+"-"+data_corrente.getFullYear());   
            console.log("data_inc  :   " + data_inc);   
            console.log("data      :   " + data);   
            console.log("voice     :   " + voice);   
            console.log("sms       :   " + sms);   
            console.log("************");  
            console.log("");*/

            getSimInsertAllHistory(msisdn, data_corrente.toLocaleDateString('it-IT', options) + "-" + data_corrente.getFullYear(), data_inc.toString(), data, voice, sms);
            //getSimInsertAllHistory(msisdn, "feb-"+data_corrente.getFullYear(), data_inc.toString(), data, voice, sms);
        }
    };

    const getSimGetHistory = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetHistory(payload);
        if (__code === 200) {
            setSimHistory(__data);

            let i = 0;
            let max_val = 0;
            let labels_chart = [];
            let labels_dati_chart = [];
            let labels_sms_chart = [];
            let labels_voice_chart = [];

            let _data_chart = {
                labels: ["", "", "", "",
                    "", "", "", "", "", "", "", ""],
                datasets: [
                    {
                        label: "MB",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        fill: true,
                        backgroundColor: "#fce8b5",
                        borderColor: "#edac09",
                    }
                ]
            };

            let _sms_chart = {
                labels: ["", "", "", "",
                    "", "", "", "", "", "", "", ""],
                datasets: [
                    {
                        label: "SMS",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        fill: true,
                        backgroundColor: "#fce8b5",
                        borderColor: "#edac09",
                    }
                ]
            };

            let _voice_chart = {
                labels: ["", "", "", "",
                    "", "", "", "", "", "", "", ""],
                datasets: [
                    {
                        label: "sec",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        fill: true,
                        backgroundColor: "#fce8b5",
                        borderColor: "#edac09",
                    }
                ]
            };

            for (i = 0; i < __data.length; i++) {
                if (__data[i].data_inc > max_val)
                    max_val = __data[i].data_inc;
            }

            var index = 0;
            for (i = __data.length - 1; i >= 0; i--) {
                if (index < 12) {
                    if (__data[i].data_inc === max_val) {

                        labels_chart[index] = __data[i].data;
                        labels_dati_chart[index] = (parseInt(__data[i].dati) / 1024).toFixed(2)
                        labels_sms_chart[index] = __data[i].sms;
                        labels_voice_chart[index] = __data[i].voce;
                        max_val--;
                        index++;

                    }
                }
            }

            _data_chart.labels = labels_chart.slice().reverse();
            _data_chart.datasets[0].data = labels_dati_chart.slice().reverse();
            _sms_chart.labels = labels_chart.slice().reverse();
            _sms_chart.datasets[0].data = labels_sms_chart.slice().reverse();
            _voice_chart.labels = labels_chart.slice().reverse();
            _voice_chart.datasets[0].data = labels_voice_chart.slice().reverse();

            setDataChart(_data_chart);
            setSmsChart(_sms_chart);
            setVoiceChart(_voice_chart);

        }
    };


    const insertStripeIdSession = async (id_session, msisdn) => {
        const payload =
        {
            id_session: id_session,
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simInsertStripeIdSession(payload);
        if (__code === 200) {
            setStripeIdSession(__data);
        }
    };

    const getUserSim = async () => {
        const [__data, __code] = await simGetUserSim();
        if (__code === 200) {
            setUserSim(__data);
        }
    };

    const getStatus = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetStatus(payload);
        if (__code === 200) {
            setStatus(__data);
            setConfirm4(false);
        }
    };

    const getStatusNewSim = async (payload_sim) => {
        /*if (payload_sim.id_centrale === null) {
            setConfirm4(false);
            setErrors("Selezionare un impianto.");
            errorRead();
            return;    
        }*/


        let index = msisdn_ignore.indexOf(payload_sim.msisdn);

        const payload =
        {
            msisdn: payload_sim.msisdn
        }
            ;
        const [__data, __code] = await simGetStatus(payload);
        if (__code === 200) {
            if (__data.success) {
                if (__data.data.extension.extension_status == 1) {
                    createCheckoutSession(payload_sim);
                    setConfirm4(false);
                    setVerifySim(false);
                    setProcessed(true);
                } else {
                    if (index != -1) {
                        createCheckoutSession(payload_sim);
                        setConfirm4(false);
                        setVerifySim(false);
                        setProcessed(true);
                    } else {
                        setErrors(intl.formatMessage({ id: "App.info.SimGiaRegistrata" }));
                        setConfirm4(false);
                        errorRead();
                    }

                    /*createCheckoutSession(payload_sim);
                    setConfirm4(false);
                    setVerifySim(false);
                    setProcessed(true);*/
                }
            } else {
                setErrors(intl.formatMessage({ id: "App.info.NonEsistonoSim" }));
                setConfirm4(false);
                errorRead();
            }

        }
    };


    const getConsumption = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetSimConsumption(payload);
        if (__code === 200) {
            setConsumption(__data);
        }

    };

    const getAllSimConsumption = async (msisdn) => {
        const payload =
        {
            msisdn: msisdn
        }
            ;
        const [__data, __code] = await simGetSimConsumption(payload);
        if (__code === 200) {
            getAllSimHistory(msisdn, __data.data.consumption.data, __data.data.consumption.voice, __data.data.consumption.sms);
        }

    };

    const createCheckoutSession = async (payload) => {
        const [__data, __code] = await simCreateCheckoutSession(payload);
        if (__code === 200) {
            setSimCheckoutSession(__data);
        }
    };

    //const changePaymentMethod = async (payload) => {
    const changePaymentMethod = async () => {

        const payload =
        {
            id_customer: retrievedStripeSessionArr[0].id_customer
        }

        const [__data, __code] = await simChangePaymentMethod(payload);
        if (__code === 200) {
            //setSimCheckoutSession(__data);
            const successUrl = __data.id;
            console.log(successUrl);
            handleAfterClickChangePayment(successUrl);
        }
    };

    const renderForm = () => (
        <div className="rack">
            {!!errors && errors.length
                ? <Outcome text={errors} type="ko" onClose={setErrors} />
                : <></>
            }
            {!!info && info.length
                ? <Outcome text={info} type="ok" onClose={setInfo} />
                : <></>
            }
            <div className="formSection">
                {
                    (confirm && (simAvailableRates.data)) && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.CambiaPianoTariffario" })}
                                text={`${intl.formatmessage({id:"App.info.PossibileCambiarePiano"})}` + simAvailableRates.data.rates[0].description
                                    + `${intl.formatmessage({id:"App.info.AllaTariffaDi"})}` + ((simAvailableRates.data.rates[0].name === '50MB IT-TL3') ? `3,99 EUR` : `2,49 EUR`) + `${intl.formatmessage({id:"App.info.AllaTariffaDi"})}`}
                                onClose={closeModal}
                                onConfirm={confirmChangeRate}
                            />
                        </>
                    )
                }
                {
                    confirm2 && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.DisattivaSim" }) + confirm2}
                                text={intl.formatMessage({ id: "App.info.ProcedereConDisattivazioneSim" })}
                                onClose={closeModalSuspend}
                                onConfirm={confirmSuspend}
                            />
                        </>
                    )
                }
                {
                    confirm3 && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.CambioImpianto" })}
                                text={intl.formatMessage({ id: "App.info.ProcedereCambioImpianto" })}
                                onClose={closeModalChangeSystem}
                                onConfirm={confirmChangeSystem}
                            />
                        </>
                    )
                }
                {
                    confirm4 && (
                        <>
                            <div className="overlay">
                                <div className="spinner">
                                    <div className="lds-dual-ring"></div>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    confirm5 && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.DisattivazioneSim" })}
                                text={confirm5}
                                onClose={closeModalDeactivateSystem}
                            />
                        </>
                    )
                }

                <form>
                    <fieldset>
                        <div className="rack">
                            <div className="w100">
                                <table className="responsiveTable">
                                    <thead>
                                        <tr>
                                            <th><FormattedMessage id="App.info.Utenza" /></th>
                                            <th><FormattedMessage id="App.info.Impianto" /></th>
                                            <th><FormattedMessage id="App.info.Operatore" /></th>
                                            <th><FormattedMessage id="App.info.IndirizzoIp" /></th>
                                            <th><FormattedMessage id="App.info.Descrizione" /></th>
                                            <th><FormattedMessage id="App.info.PianoTariffario" /></th>
                                            <th><FormattedMessage id="App.info.StattoAttivazioneSim" /></th>
                                            <th><FormattedMessage id="App.info.StatoGprs" /></th>
                                            <th><FormattedMessage id="App.info.StatoPagamenti" /></th>
                                            <th><FormattedMessage id="App.info.CambiaPagamento" /></th>
                                            <th><FormattedMessage id="App.info.Azioni" /></th>
                                            <th><FormattedMessage id="App.info.CambiaPiano" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrSim.map((value, index) => (
                                            <tr key={`tr_${index}`}>
                                                <td style={{ textAlign: "center" }}><button role="link" onClick={handlerSim}>{value.msisdn}</button></td>
                                                <td style={{ textAlign: "center" }}>
                                                    <select defaultValue={value.nome_impianto} name={value.msisdn} onChange={handleChangeSystem}>
                                                        <option value={value.id_centrale}>{value.nome_impianto}</option>
                                                        {
                                                            systems && (
                                                                systems
                                                                    .filter(el => el.profiles.split(','))
                                                                    .filter(el => el.valid === 1).filter(el => (el.name != value.nome_impianto))
                                                                    .map((value, index) => {
                                                                        return <option key={index} value={value.id_centrale}>{value.name} - {value.profiles}</option>
                                                                    })
                                                            )
                                                        }
                                                    </select>
                                                </td>
                                                <td style={{ textAlign: "center" }}>{value.operator}</td>
                                                <td style={{ textAlign: "center" }}>{value.actual_ip}</td>
                                                <td style={{ textAlign: "center" }}>{value.rate.description}</td>
                                                <td style={{ textAlign: "center" }}>{((value.rate.price == '1.55') ? '2,49' : '3,99') + ' ' + value.rate.currency}</td>
                                                <td style={{ textAlign: "center" }}>{value.extension_status_text}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    {(value.status_gprs === 'ON') ?
                                                        <img src="/static/images/on.png" style={{ width: '2rem', height: '2rem' }} />
                                                        :
                                                        <img src="/static/images/off.png" style={{ width: '2rem', height: '2rem' }} />
                                                    }
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {(value.valid === 'Regolare') ?
                                                        <img src="/static/images/ok.png" style={{ width: '2rem', height: '2rem' }} />
                                                        :
                                                        <img src="/static/images/nok.png" style={{ width: '2rem', height: '2rem' }} />
                                                    }
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <button role="link" name={value.msisdn} onClick={handleClickChangePayment} style={{ width: '2rem', height: '2rem', backgroundColor: 'white', backgroundImage: "url('/static/images/payment.png')", backgroundPosition: "center", backgroundRepeat: "none", backgroundSize: "100%" }}
                                                        title={intl.formatMessage({ id: "App.info.CambiaMetodoPagamento" })}>
                                                    </button>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <button role="link" name={value.msisdn} value={value.msisdn} onClick={handleClickSuspend} style={{ width: '2rem', height: '2rem', backgroundColor: 'white', backgroundImage: "url('/static/images/trash.png')", backgroundPosition: "center", backgroundRepeat: "none", backgroundSize: "100%" }}
                                                        title={intl.formatMessage({ id: "App.info.DisattivaSimSospendi" })}>
                                                    </button>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <button role="link" name={value.msisdn} onClick={handleClickChangeRate} style={{ width: '2rem', height: '2rem', backgroundColor: 'white', backgroundImage: "url('/static/images/change.png')", backgroundPosition: "center", backgroundRepeat: "none", backgroundSize: "100%" }}
                                                        title={intl.formatMessage({ id: "App.info.CambiaPianoTariffario" })}>
                                                    </button>{' '}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                        <div className='rack'>
                            <div className="w100">
                                {
                                    showSimStatus
                                        ? (
                                            <div style={{}}>
                                                <div className="row" style={{ marginLeft: '20px', marginRight: '20px' }}>
                                                    <div >
                                                        <span style={{ fontWeight: 'bold' }}>
                                                            <FormattedMessage id="App.info.Sim" /> {arrSim[simIdx].msisdn}
                                                        </span>
                                                        <span style={{ fontSize: 12 }}> {arrSim[simIdx].operator} </span>
                                                    </div>

                                                    <Badge bg="warning" text="dark" style={{ width: '5rem', marginLeft: '0px' }}>
                                                        {arrSim[simIdx].extension_status_text}
                                                    </Badge>{' '}

                                                    <div style={{ textAlign: "right", fontSize: 12 }}>
                                                        <p>
                                                            <FormattedMessage id="App.info.SmsApn" />
                                                        </p>
                                                    </div>


                                                    <hr className="bg-danger border-1 border-top border-secondary"></hr>
                                                </div>


                                                <div className='rack'>
                                                    <div className="w50">
                                                        <div style={{ marginLeft: '100px' }}>
                                                            <div>
                                                                <span className="fw-bold fs-4"><strong><font size="4">
                                                                    <FormattedMessage id="App.info.ConsumoMeseCorrente" /></font></strong></span>
                                                            </div>
                                                            <br></br>
                                                            <div className="column">
                                                                <span><font size="6">{(simConsumption.data.consumption.data / 1024).toFixed(2)}</font></span>
                                                                <span><font size="2"> <FormattedMessage id="App.info.Mb" /></font></span>
                                                                <>
                                                                    <div className="bar">
                                                                        <div className="progress" style={{ width: `10%` }} />
                                                                    </div>
                                                                </>
                                                            </div>
                                                            <div className="column">
                                                                {(arrSim[simIdx].rate.name === '50MB IT-TL155') ?
                                                                    <span><font size="6">
                                                                        {simConsumption.data.consumption.voice} <FormattedMessage id="App.info.slash1" />
                                                                    </font></span>
                                                                    :
                                                                    <span><font size="6">{simConsumption.data.consumption.voice} <FormattedMessage id="App.info.slash5" /></font></span>
                                                                }
                                                                <span><font size="2">
                                                                    <FormattedMessage id="App.info.Min" />
                                                                </font></span>
                                                            </div>
                                                            <div className="column">
                                                                {(arrSim[simIdx].rate.name === '50MB IT-TL155') ?
                                                                    <span><font size="6">{simConsumption.data.consumption.sms} <FormattedMessage id="App.info.Slash10" /></font></span>
                                                                    :
                                                                    <span><font size="6">{simConsumption.data.consumption.sms} <FormattedMessage id="App.info.Slash50" /></font></span>
                                                                }

                                                                <span><font size="2"> <FormattedMessage id="App.info.Imei" /></font></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w50">
                                                        <div className="row" >
                                                            <span className="fw-bold fs-6" style={{ color: '#edac09' }}>
                                                                <FormattedMessage id="App.info.SimStatus" />
                                                            </span>
                                                            <table className="responsiveTable">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <blockquote className="blockquote mb-0">
                                                                                <p>
                                                                                    <span className="fw-bold"><font size="3">
                                                                                        <FormattedMessage id="App.info.StatoAttuale" />
                                                                                    </font> </span>
                                                                                    <Badge bg="warning" text="white" style={{ width: '8rem', marginLeft: '10px' }}>
                                                                                        {arrSim[simIdx].extension_status_text === intl.formatMessage({ id: "App.info.Attivata" }) ? intl.formatMessage({ id: "App.info.Attiva" }) : arrSim[simIdx].extension_status_text}
                                                                                    </Badge>{' '}
                                                                                </p>
                                                                            </blockquote>
                                                                            <blockquote className="blockquote mb-0">
                                                                                <p>
                                                                                    <span className="fw-bold"><font size="3">
                                                                                        <FormattedMessage id="App.info.StatoSessioneDati" />
                                                                                    </font> </span>
                                                                                    <Badge bg="warning" text="white" style={{ width: '8rem', marginLeft: '10px' }}>
                                                                                        {arrSim[simIdx].status_gprs}
                                                                                    </Badge>{' '}
                                                                                </p>
                                                                            </blockquote>

                                                                        </td>
                                                                        <td>
                                                                            <blockquote className="blockquote mb-0">
                                                                                <p>
                                                                                    <span className="fw-bold">
                                                                                        <font size="3">
                                                                                            <FormattedMessage id="App.info.Imei" />
                                                                                        </font> </span>
                                                                                    <span ><font size="3">{arrSim[simIdx].imei}</font> </span>
                                                                                </p>
                                                                            </blockquote>
                                                                            <blockquote className="blockquote mb-0">
                                                                                <p>
                                                                                    <span className="fw-bold"><font size="3">
                                                                                        <FormattedMessage id="App.info.IpAttuale" />
                                                                                    </font> </span>
                                                                                    <span ><font size="3">{arrSim[simIdx].actual_ip}</font> </span>
                                                                                </p>
                                                                            </blockquote>
                                                                            <blockquote className="blockquote mb-0">
                                                                                <p>
                                                                                    <span className="fw-bold"><font size="3">
                                                                                        <FormattedMessage id="App.info.DataUltimoEvento" />
                                                                                    </font> </span>
                                                                                    <span ><font size="3">{arrSim[simIdx].last_event_time}</font> </span>
                                                                                </p>
                                                                            </blockquote>
                                                                            <blockquote className="blockquote mb-0">
                                                                                <p>
                                                                                    <span className="fw-bold"><font size="3">
                                                                                        <FormattedMessage id="App.info.Gestore" />
                                                                                    </font> </span>
                                                                                    <span ><font size="3">{arrSim[simIdx].operator}</font> </span>
                                                                                </p>
                                                                            </blockquote>

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <br>
                                                        </br>
                                                        <div className="row" style={{ marginLeft: '15px' }}>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='rack'>
                                                    <div className="w20"><br></br></div>
                                                    <div className="w60">
                                                        <div style={{ marginLeft: '15px' }}>
                                                            <span className="fw-bold fs-4"><strong><font size="4">
                                                                <FormattedMessage id="App.info.ConsumoDati" />
                                                            </font></strong></span>
                                                            <span><font size="2">
                                                                <FormattedMessage id="App.info.DodiciMesi" />
                                                            </font></span>
                                                        </div>
                                                        <div style={{ marginLeft: '15px' }}>
                                                            <Bar data={data_chart} />
                                                        </div>
                                                    </div>
                                                    <div className="w20"></div>
                                                </div>
                                                <br></br>
                                                <div className='rack'>
                                                    <div className="w20"><br></br></div>
                                                    <div className="w60">
                                                        <div style={{ marginLeft: '15px' }}>
                                                            <span className="fw-bold fs-4"><strong><font size="4">
                                                                <FormattedMessage id="App.info.ConsumoSms" />
                                                            </font></strong></span>
                                                            <span><font size="2">
                                                                <FormattedMessage id="App.info.DodiciMesi" />
                                                            </font></span>
                                                        </div>
                                                        <div style={{ marginLeft: '15px' }}>
                                                            <Bar data={sms_chart} />
                                                        </div>
                                                    </div>
                                                    <div className="w20"></div>
                                                </div>
                                                <br></br>
                                                <div className='rack'>
                                                    <div className="w20"><br></br></div>
                                                    <div className="w60">
                                                        <div style={{ marginLeft: '15px' }}>
                                                            <span className="fw-bold fs-4"><strong><font size="4">
                                                                <FormattedMessage id="App.info.ConsumoVoce" />
                                                            </font></strong></span>
                                                            <span><font size="2">
                                                                <FormattedMessage id="App.info.dodiciMesi" />
                                                            </font></span>
                                                        </div>
                                                        <div style={{ marginLeft: '15px' }}>
                                                            <Bar data={voice_chart} />
                                                        </div>
                                                    </div>
                                                    <div className="w20"></div>
                                                </div>

                                                <div className="rack">

                                                    <div className="w50">
                                                        <div style={{ marginLeft: '15px' }}>

                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                        ) : (
                                            <div style={{}}>

                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </fieldset >
                </form >
            </div >
        </div >
    );


    const renderList = () => (
        <div className="rack">

            {!!errors && errors.length
                ? <Outcome text={errors} type="ko" onClose={setErrors} />
                : <></>
            }
            {
                confirm6 && (
                    <>
                        <Modal
                            title={intl.formatMessage({ id: "App.info.AttivazioneSim" })}
                            text={confirm6}
                            onClose={closeModalActivateSystem}
                        />
                    </>
                )
            }

            <div className="formSection">
                {
                    confirm4 && (
                        <>
                            <div className="overlay">
                                <div className="spinner">
                                    <div className="lds-dual-ring"></div>
                                </div>
                            </div>
                        </>
                    )
                }
                <form>
                    <fieldset>
                        <div className="rack">
                            <div className="w50">
                                <div className="rack" style={{ marginLeft: "15px" }}>
                                    <div className="w40" >
                                        <img src="/static/images/cloud-conn.png" style={{ marginLeft: "40px" }} />
                                    </div>
                                    <div className="w60">
                                        <p align="justify">
                                            <FormattedMessage id="App.info.IlServizio" />
                                            <strong>
                                                <FormattedMessage id="App.info.LinceCloudPremiumPiu" />
                                            </strong>
                                            <FormattedMessage id="App.info.OffreConnessioneSicura" />
                                        </p>
                                        <p align="justify">
                                            <FormattedMessage id="App.info.SottoscrivendoPremium" />                                        </p>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div style={{ display: 'flex' }}>
                                        <div className="row" style={{ marginLeft: "20px", marginTop: "20px" }}>

                                            <p><strong>
                                                <FormattedMessage id="App.info.ScopriVantaggiPremiumPiu" />
                                            </strong></p>
                                            <ul>
                                                <li><FormattedMessage id="App.info.NessunCostoFisso" /></li>
                                                <li><FormattedMessage id="App.info.PossibilitaDiDisdire" /></li>
                                                <li><FormattedMessage id="App.info.MultiOperatore" /></li>
                                            </ul>
                                            <p><FormattedMessage id="App.info.SottoscrivendoIlPiano" /></p>
                                            <ul>
                                                <li><FormattedMessage id="App.info.PossibilitaDiGestire" /></li>
                                                <li><FormattedMessage id="App.info.AttivazioneDiCredenziali" /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div style={{ marginTop: "20px" }}>
                                        <div className="w20">
                                            <img src="/static/images/info.png" style={{ width: '8rem', height: '8rem' }} />
                                        </div>
                                        <div className="w80">
                                            <p align="justify">
                                                <font size="2" >
                                                    <strong>
                                                        <FormattedMessage id="App.info.ServizioUtilizzatoEsclusivamente" />
                                                    </strong>
                                                    <span>
                                                        <FormattedMessage id="App.info.ConAppOPiattaforma" />
                                                    </span>
                                                </font>
                                            </p>

                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="w50">
                                {
                                    !showNewSim
                                        ? (
                                            <div style={{}}>
                                                <div className="row" style={{ marginLeft: "10px" }}>

                                                    <div style={{ marginTop: "15px" }}>

                                                        <img src="/static/images/sim_tele2_iot.jpg" style={{ width: '10rem', height: '7rem' }} />
                                                        <br />
                                                        <br />
                                                        <div className="w75">
                                                            <strong><FormattedMessage id="App.info.PianoTariffario1" /></strong>
                                                            <span style={{ marginLeft: "10px", color: 'red' }}>
                                                                <font size="4" >
                                                                    <FormattedMessage id="App.info.DueQuarantanoveAlMese" />
                                                                </font>
                                                            </span>
                                                            <ul>
                                                                <li>
                                                                    <FormattedMessage id="App.info.CinquantaMegaAlMese" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.UnMinAlMese" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.DieciSmsAlMese" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.GestionePasswordATempo" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.PossibilitaDiGestireOltre" />
                                                                </li>
                                                            </ul>
                                                            <span style={{ marginLeft: "25px" }}><font size="2" >
                                                                <FormattedMessage id="App.info.CostiEccessi" />
                                                            </font></span>
                                                        </div>
                                                    </div>
                                                    <button className="float-end" style={{ marginTop: "10px" }} onClick={() => { setShowNewSim(true); setPianoTariffario('WORLDSIM 4G 50MB 10 SMS 1 Min Voice'); }}>
                                                        <FormattedMessage id="App.info.AttivaNuovaSim" />
                                                    </button>
                                                </div>

                                                <br />
                                                <br />

                                                <div className="row" style={{ marginLeft: "10px" }}>

                                                    <div style={{ marginTop: "15px" }}>

                                                        <img src="/static/images/sim_tele2_iot.jpg" style={{ width: '10rem', height: '7rem' }} />
                                                        <br />
                                                        <br />

                                                        <div className="w75">
                                                            <strong>
                                                                <FormattedMessage id="App.info.AttivaNuovaSim" />
                                                            </strong><span style={{ marginLeft: "10px", color: 'red' }}><font size="4" >
                                                                <FormattedMessage id="App.info.TreENovantanoveAlMese" />
                                                            </font> </span>
                                                            <ul>
                                                                <li>
                                                                    <FormattedMessage id="App.info.CinquantaMegaAlMese" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.CinqueMinAlMese" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.CinquantaSmsAlMese" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.GestionePasswordATempo" />
                                                                </li>
                                                                <li>
                                                                    <FormattedMessage id="App.info.PossibilitàDiGestire" />
                                                                </li>
                                                            </ul>
                                                            <span style={{ marginLeft: "25px" }}><font size="2" >
                                                                <FormattedMessage id="App.info.CostiEccessi" />
                                                            </font></span>
                                                        </div>
                                                    </div>
                                                    <button className="float-end" style={{ marginTop: "10px" }} onClick={() => { setShowNewSim(true); setPianoTariffario('WORLDSIM 4G 50MB 50 SMS 5 Min Voice'); }}>
                                                        <FormattedMessage id="App.info.AttivaNuovaSim" />
                                                    </button>
                                                </div>
                                                <br>
                                                </br>
                                                <br>
                                                </br>

                                                <div className="row" style={{ marginLeft: "-15px" }}>

                                                    <div style={{ marginTop: "15px" }}>
                                                        <div className="w100">
                                                            <span style={{ marginLeft: "25px" }}><font size="2" >
                                                                <FormattedMessage id="App.info.CentroServiziApn" />
                                                            </font></span>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{}}>
                                                <div className="row" style={{ marginLeft: "10px" }}>
                                                    <div style={{ marginTop: "15px" }}>
                                                        <NewSim
                                                            handleChange={handlerChange}
                                                            handleSubmit={handlerSubmit}
                                                            piano={pianoTariffario}
                                                            systems={systems}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                }
                            </div>

                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.PremiumPiu" />
                </h3>
                <br />
                <div className="rack box">
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'active' : 'disabled')}`} onClick={() => tabHandler(true)}>
                            <FormattedMessage id="App.info.SottoscriviNuovoPiano" />
                        </h2>
                    </div>
                    <div className="w50">
                        <h2 className={`tab ${(firstTab ? 'disabled' : 'active')}`} onClick={() => tabHandler(false)}>
                            <FormattedMessage id="App.info.GestioneSim" />
                        </h2>
                    </div>
                </div>
                {
                    firstTab
                        ? renderList()
                        : renderForm()
                }
            </div>


        </section>
    );
};

const Sim = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_Sim));

export default Sim;
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    system: state.system,
});

const _StatoImpianto = ({ system }) => {

    const intl = useIntl();

    const status = !!system ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }

    const stato_centrale = [
        ['generali_1', 'guasto', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Guasto" })],
        ['generali_5', 'fusibile_ven', 'red', 'rgb(40, 170, 40)', intl.formatMessage({ id: "App.info.FusibileUscite" })],
        ['generali_1', 'rete', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Rete220" })],
        ['generali_1', 'batteria_ex', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.StatoDiCarica" })],
        ['generali_1', 'batteria_in', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.StatoDiCaricaBat" })],
        ['generali_1', 'servizio', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.StatoDiServizio" })],
        ['generali_1', 'allarme', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Allarme" })],
    ];
    const espansioni = [
        ['espansioni', 'presente0', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Espansione1" })],
        ['espansioni', 'presente1', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Espansione2" })],
        ['espansioni', 'presente2', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Espansione3" })],
        ['espansioni', 'presente3', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Espansione4" })],
        ['espansioni', 'presente4', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.Espansione5" })],
        ['espansioni', 'tastiera_radio', 'rgb(40, 170, 40)', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.EspansioneRadio" })],
        ['espansioni', 'conflitto', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.ConflittoEspansione" })],
    ];

    const sabotaggi = [
        ['generali_1', 'as24_in', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.SabotaggioCentrale" })],
        ['generali_1', 'as', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.SabotaggioAllarmeIng" })],
        ['generali_4', 'as24', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.SabotaggioIngressi" })],
        ['generali_3', 'as24_remoto', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.SabotaggioDispositiviBus" })],
        ['generali_3', 'bus', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.SabotaggioAllarmeIntegrita" })],
        ['generali_4', 'ingressi_aperti', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.IngressiAperti" })],
        ['generali_4', 'ingressi_esclusi', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.IngressiEsclusi" })],
    ];

    const memorie = [
        ['generali_2', 'mem_as24_in', 'red', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioCentrale" })],
        ['generali_2', 'mem_as_in', '#ffc20a', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioAllarmeIng" })],
        ['generali_4', 'mem_as24_allarme', '#ffc20a', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioIng" })],
        ['generali_2', 'mem_24_inseritori', '#ffc20a', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioDispBus" })],
        ['generali_2', 'mem_bus', '#ffc20a', 'rgb(176, 176, 176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioAllarmeInt" })],
    ];

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.StatoImpianto" />
                </h3>
                <br />
                <div className="rack">
                    <div className="w50 light-boxed">
                        <h2>
                            <FormattedMessage id="App.info.StatoCentrale" />
                        </h2>
                        <div className="padp">
                            {
                                stato_centrale.map((item, index) => (
                                    <p key={`stato_centrale_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                            <br />
                            <div className="rack">
                                <div className="w50">
                                    <p><strong>
                                        <FormattedMessage id="App.info.TensioneBatteria" /><br /><big>{status.vbatt}</big></strong></p>
                                </div>
                                <div className="w50">
                                    <p><strong>
                                        <FormattedMessage id="App.info.RelSwCentrale" />
                                        <br /><big>{`${status.firmware_ver_L}.${(status.firmware_ver_H).toString().padStart(2, '0')}`}</big></strong></p>
                                </div>
                            </div>
                            <div className="rack">
                                <div className="w50">
                                    <p><strong>
                                        <FormattedMessage id="App.info.TensioneBus" />
                                        <br /><big>{status.vbus}</big></strong></p>
                                </div>
                                <div className="w50">
                                    <p><strong><FormattedMessage id="App.info.Temperatura" />
                                        <br /><big>{status.celsius}</big></strong></p>
                                </div>
                            </div>
                            <p><small><em>
                                <FormattedMessage id="App.info.InfoTemperatura" />
                            </em></small></p>
                        </div>
                    </div>
                    <div className="w50 light-boxed">
                        <h2>
                            <FormattedMessage id="App.info.Espansioni" />
                        </h2>
                        <div className="padp">
                            {
                                espansioni.map((item, index) => (
                                    <p key={`espansioni_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <div className="w100">
                        <h2>
                            <FormattedMessage id="App.info.SabInMem" />
                        </h2>
                        <div className="padp">
                            <div className="rack">
                                <div className="w50">
                                    {
                                        sabotaggi.map((item, index) => (
                                            <p key={`sabotaggi_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                        ))
                                    }
                                </div>
                                {
                                    memorie.map((item, index) => (
                                        <p key={`memorie_${index}`}><i className="fa fa-circle" style={(status.structs[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoImpianto = connect(mapStateToProps)(_StatoImpianto);

export default StatoImpianto;
import React, { useCallback, useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import * as Cloud from '../../../../api/Cloud';
import {
    doRequest,
    goldDoRequest,
    goldUpdateDevStats,
    goldResetDevStats,
    getSystem,
    setSocket,
    errorRead,
    resetSystem,
    setStore
} from '../../../../actions';
import { parsePhysicalMap } from '../../../../lib/goldParser/physicalMap';
import { defaultStore } from '../../../../store/defaultStore';
import PannelloControllo from './PannelloControllo';
import IngressiFilari from './IngressiFilari';
import DispositiviRadio from './DispositiviRadio';
import ChiaviCodici from './ChiaviCodici';
import Codice from './Codice';
import Opzioni from './Opzioni';
import Tempi from './Tempi';
import Uscite from './Uscite';
import OpzioniGSM from './OpzioniGSM';
import NumeriTelefono from './NumeriTelefono';
import SiaIp from './SiaIp';
import Orologio from './Orologio';
import Autoinserimento from './Autoinserimento';
import PerifericheBUS from './PerifericheBUS';
import Comandi from './Comandi';
import StatoIngressiFilari from './StatoIngressiFilari';
import StatoDispositiviRadio from './StatoDispositiviRadio';
import StatoPerifericheBUS from './StatoPerifericheBUS';
import StatoChiaviCodici from './StatoChiaviCodici';
import MappaLogica from '../Gold/MappaLogica';
import MemoriaEventi from './MemoriaEventi';
import RegistroEventi from './RegistroEventi';
import StatoGSM from './StatoGSM';
import StatoImpianto from './StatoImpianto';
import Personalizza from '../Europlus/Personalizza';
import PersonalizzaUscite from '../Gold/PersonalizzaUscite';
import NotificheEventi from '../Europlus/NotificheEventi';
import SuperTasti from './SuperTasti';
import Bus from './Bus';
import Chiave from './Chiave';
import Rf from './Rf';
import Display from './Display';
import { FormattedMessage, useIntl } from 'react-intl';

import '../Europlus/index.scss';

const mapStateToProps = state => ({
    data: state.data,
    socket: state.socket,
    stateError: state.error,
    system: state.system,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    getSystem: id => dispatch(getSystem(id, true)),
    resetSystem: () => dispatch(resetSystem()),
    setStore: data => dispatch(setStore(data)),
    setSocket: socket => dispatch(setSocket(socket)),
    deleteSocket: id => dispatch(doRequest('deleteSocket', id)),
    getSessionAuth: id => dispatch(doRequest('getSessionAuth', id)),
    goldExitSystem: payload => dispatch(goldDoRequest('goldExitSystem', payload, false)),
    goldResetDevStats: () => dispatch(goldResetDevStats()),
    goldUpdateDevStats: data => dispatch(goldUpdateDevStats(data))
});

const initBeforeUnLoad = (exitMethod) => {
    window.onbeforeunload = (event) => {
        const e = event || window.event;
        e.preventDefault();
        exitMethod();
        if (e) {
            e.returnValue = ''
        }
        return '';
    };
};

const _Gold = ({ errorRead, match, data, stateError, getSystem, setSocket, setStore, system, socket, user, deleteSocket, getSessionAuth, goldExitSystem, goldUpdateDevStats, goldResetDevStats }) => {
    const systemId = match.params.id || 0;
    const [disconnectAction, setDisconnectAction] = useState(false);
    const [errors, setErrors] = useState(false);
    const [processed1, setProcessed1] = useState(false);
    const [processedSessionAuth, setProcessedSessionAuth] = useState(false);
    const [processedSyncMemMap, setProcessedSyncMemMap] = useState(false);
    const [processedDeleteSocket, setProcessedDeleteSocket] = useState(false);
    const [valid, setValid] = useState(true);
    const [approved, setApproved] = useState(true);
    const [timer, setTimer] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [success, setSuccess] = useState(false);
    const intl = useIntl();

    const umount = useCallback(() => {
        window.onbeforeunload = () => null;
        clearInterval(timer);
    }, [timer]);

    useEffect(
        () => {
            return () => {
                umount();
                return true;
            };
        },
        [umount]
    );

    useEffect(
        () => {
            if (!approved) {
                const interval = setInterval(() => {
                    setProcessedSessionAuth(true);
                    getSessionAuth(systemId);
                }, 20000);
                setTimer(interval);
            }
            return () => null;
        },
        [approved, setTimer, setProcessedSessionAuth]
    );

    useEffect(
        () => {
            if (!processed1 && firstLoad === true) {
                resetSystem();
                getSystem(systemId);
                setProcessed1(true);
                setErrors(false);
                setFirstLoad(false);
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        }, [errorRead, errors, setErrors, stateError, processed1, setProcessed1, getSystem, systemId, firstLoad, setFirstLoad]
    );

    const handleDisconnect = () => {
        deleteSocket(system.id);
        setProcessedDeleteSocket(true);
    }

    if (data && processedDeleteSocket) {
        setErrors(intl.formatMessage({ id: "App.info.DisconnessioneInCorso" }));
        setProcessedDeleteSocket(false);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    if (data && processedSessionAuth) {
        if (parseInt(data.response) === 1) {
            setErrors(intl.formatMessage({ id: "App.info.RiconnessioneInCorso" }));
            setProcessedSessionAuth(false);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }

    if (data && processedSyncMemMap) {
        setProcessedSyncMemMap(false);
    }

    if (system && processed1) {
        setProcessed1(false);
        if (system.valid === 0) {
            setValid(false);
        } else if (!system.approved) {
            setApproved(false);
        } else if (system && system.model != null) {
            if (system.socket_id) {
                if (!!socket && system.socket_id === socket.id) {
                    setErrors(false);
                } else {
                    const { email: otherUserEmail, role: otherUserRoles } = system.session.active;
                    if (otherUserEmail === user.email) {
                        setErrors(intl.formatMessage({ id: "App.info.StaiUtilizzandoGia" }))
                    } else if (!!otherUserEmail) {
                        setErrors(`Pannello di Controllo occupato da ${otherUserEmail}${(!!otherUserRoles ? `(${otherUserRoles})` : ``)}.`);
                    } else {
                        setErrors(intl.formatMessage({ id: "App.info.PannelloDiControlloOccupato" }))
                    }
                    setDisconnectAction(system.session.force_disconnect);
                }
            } else {
                setStore({
                    ...defaultStore
                });
                const _socket = io(process.env.REACT_APP_WS_URL, {
                    query: {
                        token: Cloud.getToken(),
                        system_id: systemId
                    }
                });
                _socket.on('error', error => {
                    setErrors(error);
                    resetSystem();
                });
                _socket.on('connect', () => {
                    setSocket(_socket);
                    setErrors(false);
                    setSuccess(true);
                    initBeforeUnLoad(() => {
                        goldExitSystem({ id_centrale: system.id_centrale })
                    });
                });
                _socket.on('disconnect', () => {
                    setErrors(intl.formatMessage({ id: "App.info.DisconnessioneAvvenuta" }));
                    resetSystem();
                });
                _socket.on('onGoldSync', _data => {
                    const progress = parseInt(_data.progress);
                    setStore({
                        sync_progress: progress
                    });
                });
                _socket.on('onGoldEndSync', _data => {
                    setStore({
                        pm: parsePhysicalMap(JSON.parse(_data))
                    });
                });
                _socket.on('onGoldDevStats', _data => {
                    goldResetDevStats();
                    setTimeout(() => { goldUpdateDevStats(_data); }, 100);
                });
                const storedSystemId = sessionStorage.getItem('systemId');
                if (!storedSystemId || storedSystemId != systemId) {
                    sessionStorage.systemId = systemId;
                }
            }
        } else {
            setErrors(intl.formatMessage({ id: "App.info.ImpiantoNonAncoraIdentificato" }));
        }
    }

    return (
        errors
            ? (
                <section>
                    <div className="innerSection">
                        <br />
                        <br />
                        <h3>
                            <FormattedMessage id="App.info.PannelloDiControllo" />
                        </h3>
                        <div className="rack box">
                            <div className="w100">
                                <blockquote>
                                    <FormattedMessage id="App.info.ErrAccPannelloControllo" />
                                    <p>
                                        {errors}
                                    </p>
                                    {disconnectAction && (
                                        <form>
                                            <p>
                                                <FormattedMessage id="App.info.ProcediComunque" />
                                            </p>
                                            <fieldset>
                                                <button className="ok auto" onClick={handleDisconnect}>
                                                    <FormattedMessage id="App.info.Disconnetti" />
                                                </button>
                                            </fieldset>
                                        </form>
                                    )}
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>
            )
            : approved
                ? success
                    ? valid
                        ? !!system.store.pm
                            ? (
                                <Switch>
                                    <Route exact path="/gold/:id([0-9]+)/system_status" render={(props) => <StatoImpianto {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/wired/:sub([0-9]+)" render={(props) => <IngressiFilari {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/radio/:sub([0-9]+)" render={(props) => <DispositiviRadio {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/code/:sub([0-9]+)" render={(props) => <ChiaviCodici {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/new_code" render={(props) => <Codice {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/options" render={(props) => <Opzioni {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/buttons" render={(props) => <SuperTasti {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/timers" render={(props) => <Tempi {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/outputs" render={(props) => <Uscite {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/gsm" render={(props) => <OpzioniGSM {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/phone/:sub([0-9]+)" render={(props) => <NumeriTelefono {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/siaip/:sub([0-9]+)" render={(props) => <SiaIp {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/clock" render={(props) => <Orologio {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/autoinsertion" render={(props) => <Autoinserimento {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/bus/:sub([0-9]+)" render={(props) => <PerifericheBUS {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/commands" render={(props) => <Comandi {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/wstatus" render={(props) => <StatoIngressiFilari {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/rstatus" render={(props) => <StatoDispositiviRadio {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/bstatus" render={(props) => <StatoPerifericheBUS {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/cstatus" render={(props) => <StatoChiaviCodici {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/gstatus" render={(props) => <StatoGSM {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/memory" render={(props) => <MemoriaEventi {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/registry" render={(props) => <RegistroEventi {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/custom" render={(props) => <Personalizza {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/name_out" render={(props) => <PersonalizzaUscite {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/logic_map" render={(props) => <MappaLogica {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/display" render={(props) => <Display {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/notifications" render={(props) => <NotificheEventi {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/rf" render={(props) => <Rf {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/new_bus" render={(props) => <Bus {...props} />} />
                                    <Route exact path="/gold/:id([0-9]+)/key" render={(props) => <Chiave {...props} />} />
                                    <Route render={() => <PannelloControllo />} />
                                </Switch>
                            ) : (
                                <Switch>
                                    <Route render={() => <PannelloControllo />} />
                                </Switch>
                            )
                        : (
                            <section>
                                <div className="innerSection">
                                    <br />
                                    <br />
                                    <h3>
                                        <FormattedMessage id="App.info.PannelloDiControllo" />
                                    </h3>
                                    <div className="rack box">
                                        <div className="w100">
                                            <blockquote>
                                                <FormattedMessage id="App.info.LimiteImpiantiRaggiunto" />
                                                <p>
                                                    <FormattedMessage id={"App.info.ScopriVantaggi"} />
                                                    <Link to={`/premium`}>
                                                        <FormattedMessage id={"App.info.Abbonati"} />
                                                    </Link>
                                                </p>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    : <p>
                        <FormattedMessage id="App.info.ErroreImprevisto" />
                    </p>
                : (
                    <section>
                        <div className="innerSection">
                            <br />
                            <br />
                            <h3>
                                <FormattedMessage id="App.info.PannelloDiControllo" />
                            </h3>
                            <div className="rack box">
                                <div className="w100">
                                    <blockquote>
                                        <FormattedMessage id="App.info.AccessoInAttesa" />
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </section>
                )
    )
}

const Gold = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Gold));

export default Gold;

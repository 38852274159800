import React, { useCallback, useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import io from 'socket.io-client';

import europlusParser from '../../../../lib/europlusParser';

import * as Cloud from '../../../../api/Cloud';
import {
    doRequest,
    getSystem,
    setSocket,
    errorRead,
    resetSystem,
    setStore,
    setDataSocket
} from '../../../../actions';
import PannelloControllo from './PannelloControllo';
import IngressiFilari from './IngressiFilari';
import IngressiRadio from './IngressiRadio';
import Codici from './Codici';
import Uscite from './Uscite';
import Tempi from './Tempi';
import Opzioni from './Opzioni';
import SuperTasti from './SuperTasti';
import Comandi from './Comandi';
import Codice from './Codice';
import AttivazioniPeriodiche from './AttivazioniPeriodiche';
import AttivazioniNonPeriodiche from './AttivazioniNonPeriodiche';
import StatoImpianto from './StatoImpianto';
import StatoIngressiFilari from './StatoIngressiFilari';
import StatoIngressiRadio from './StatoIngressiRadio';
import MemoriaEventi from './MemoriaEventi';
import NotificheEventi from './NotificheEventi';
//import ImportExport from './ImportExport';
import Rf from './Rf';
import Personalizza from './Personalizza';

import './index.scss';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    socket: state.socket,
    stateError: state.error,
    system: state.system,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    getSystem: id => dispatch(getSystem(id, true)),
    resetSystem: () => dispatch(resetSystem()),
    setSocket: socket => dispatch(setSocket(socket)),
    setStore: data => dispatch(setStore(data)),
    setDataSocket: data => dispatch(setDataSocket(data)),
    deleteSocket: id => dispatch(doRequest('deleteSocket', id)),
    getSessionAuth: id => dispatch(doRequest('getSessionAuth', id))
});

const _Europlus = ({ data, errorRead, match, stateError, getSystem, setSocket, setStore, setDataSocket, socket, system, user, deleteSocket, getSessionAuth }) => {
    const systemId = match.params.id || 0;
    const [disconnectAction, setDisconnectAction] = useState(false);
    const [errors, setErrors] = useState(false);
    const [processed1, setProcessed1] = useState(false);
    const [processedSessionAuth, setProcessedSessionAuth] = useState(false);
    const [processedDeleteSocket, setProcessedDeleteSocket] = useState(false);
    const [valid, setValid] = useState(true);
    const [approved, setApproved] = useState(true);
    const [timer, setTimer] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [success, setSuccess] = useState(false);
    const intl = useIntl();

    const umount = useCallback(() => {
        clearInterval(timer);
    }, [timer]);

    useEffect(
        () => {
            return () => {
                umount();
                return true;
            };
        },
        [umount]
    );

    useEffect(
        () => {
            if (!approved) {
                const interval = setInterval(() => {
                    setProcessedSessionAuth(true);
                    getSessionAuth(systemId);
                }, 20000);
                setTimer(interval);
            }
            return () => null;
        },
        [approved, setTimer, setProcessedSessionAuth]
    );

    useEffect(
        () => {
            if (!processed1 && firstLoad === true) {
                resetSystem();
                getSystem(systemId);
                setProcessed1(true);
                setErrors(false);
                setFirstLoad(false);
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError, processed1, setProcessed1, getSystem, systemId, firstLoad, setFirstLoad]
    );

    const handleDisconnect = () => {
        deleteSocket(system.id);
        setProcessedDeleteSocket(true);
    }

    if (data && processedDeleteSocket) {
        setErrors(intl.formatMessage({ id: 'App.info.DisconnessioneInCorso' }));
        setProcessedDeleteSocket(false);
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    if (data && processedSessionAuth) {
        if (parseInt(data.response) === 1) {
            setErrors(intl.formatMessage({ id: 'App.info.RiconnessioneInCorso' }));
            setProcessedSessionAuth(false);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }

    if (system && processed1) {
        setProcessed1(false);
        if (system.valid === 0) {
            setValid(false);
        } else if (!system.approved) {
            setApproved(false);
        } else if (system.store && system.mac) {
            if (system.socket_id) {
                if (!!socket && system.socket_id === socket.id) {
                    setErrors(false);
                } else {
                    const { email: otherUserEmail, role: otherUserRoles } = system.session.active;
                    if (otherUserEmail === user.email) {
                        setErrors(intl.formatMessage({ id: 'App.info.StaiUtilizzandoGia' }))
                    } else if (!!otherUserEmail) {
                        setErrors(`${intl.formatMessage({ id: "App.info.PannelloDiControlloOccupatoDa" })} ${otherUserEmail}${(!!otherUserRoles ? `(${otherUserRoles})` : ``)}.`);
                    } else {
                        setErrors(intl.formatMessage({ id: 'App.info.PannelloDiControlloOccupato' }))
                    }
                    setDisconnectAction(system.session.force_disconnect);
                }
            } else {
                const _socket = io(process.env.REACT_APP_WS_URL, {
                    query: {
                        token: Cloud.getToken(),
                        system_id: systemId
                    }
                });
                _socket.on('error', error => {
                    setErrors(error);
                    resetSystem();
                });
                _socket.on('connect', () => {
                    setSocket(_socket);
                    setErrors(false);
                    setSuccess(true);
                    if (system.session.force_logout) {
                        _socket.emit('sendCommand', JSON.stringify(
                            {
                                type: 0x00FB,
                                payload: [0, 0, 0, 0, 0, 0]
                            }
                        ));
                    }
                });
                _socket.on('disconnect', () => {
                    setErrors(intl.formatMessage({ id: 'App.info.DisconnessioneAvvenuta' }));
                    resetSystem();
                });
                _socket.on('onConfig', data => {
                    let { type, payload } = data;
                    let store = {};
                    store[type] = payload;
                    setStore(store);
                    setDataSocket({ type, payload });
                });
                _socket.on('onConnect', data => {
                    setStore({ connected: parseInt(data) });
                });
                _socket.on('onStatus', data => {
                    setStore({ status: europlusParser(data) });
                });
                _socket.on('onSyncing', data => {
                    setStore({ sync_cloud_euronet: data == 0 ? 0 : data });
                });
                const storedSystemId = sessionStorage.getItem('systemId');
                if (!storedSystemId || storedSystemId != systemId) {
                    _socket.emit('sendCommand', JSON.stringify(
                        {
                            type: 0x00FB,
                            payload: [0, 0, 0, 0, 0, 0]
                        }
                    ));
                }
            }
        } else {
            setErrors(intl.formatMessage({ id: 'App.info.ImpiantoNonAncoraIdentificato' }))
        }
    }

    return (
        errors
            ? (
                <section>
                    <div className="innerSection">
                        <br />
                        <h3 class="titlePage">
                            <FormattedMessage id="App.info.PannelloDiControllo" />
                        </h3>
                        <div className="rack box">
                            <div className="w100">
                                <blockquote>
                                    <FormattedMessage id="App.info.ErrPannelloDiControllo" />
                                    <p>
                                        {errors}
                                    </p>
                                    {disconnectAction && (
                                        <form>
                                            <p>
                                                <FormattedMessage id="App.info.DisconnettereComunque" />
                                            </p>
                                            <fieldset>
                                                <button className="ok auto" onClick={handleDisconnect}>
                                                    <FormattedMessage id="App.info.Disconnetti" />
                                                </button>
                                            </fieldset>
                                        </form>
                                    )}
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>
            )
            : approved
                ? success
                    ? valid
                        ? (
                            <Switch>
                                <Route exact path="/europlus/:id([0-9]+)/system_status" render={(props) => <StatoImpianto {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/wired/:sub([0-9]+)" render={(props) => <IngressiFilari {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/radio/:sub([0-9]+)" render={(props) => <IngressiRadio {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/code/:sub([0-9]+)" render={(props) => <Codici {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/outputs" render={(props) => <Uscite {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/timers" render={(props) => <Tempi {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/options" render={(props) => <Opzioni {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/buttons" render={(props) => <SuperTasti {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/commands" render={(props) => <Comandi {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/new_code" render={(props) => <Codice {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/periodic_activations" render={(props) => <AttivazioniPeriodiche {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/non_periodic_activations" render={(props) => <AttivazioniNonPeriodiche {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/custom" render={(props) => <Personalizza {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/wstatus" render={(props) => <StatoIngressiFilari {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/rstatus" render={(props) => <StatoIngressiRadio {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/memory" render={(props) => <MemoriaEventi {...props} />} />
                                <Route exact path="/europlus/:id([0-9]+)/notifications" render={(props) => <NotificheEventi {...props} />} />
                                {/*<Route exact path="/europlus/:id([0-9]+)/importexport" render={(props) => <ImportExport {...props} />} />*/}
                                <Route exact path="/europlus/:id([0-9]+)/rf" render={(props) => <Rf {...props} />} />
                                <Route component={PannelloControllo} />
                            </Switch>
                        ) : (
                            <section>
                                <div className="innerSection">
                                    <br />
                                    <br />
                                    <h3>
                                        <FormattedMessage id="App.info.PannelloDiControllo" />
                                    </h3>
                                    <div className="rack box">
                                        <div className="w100">
                                            <blockquote>
                                                <FormattedMessage id="App.info.LimiteImpiantiRaggiunto" />
                                                <p>
                                                    <FormattedMessage id={"App.info.ScopriVantaggi"} />
                                                    <Link to={`/premium`}>
                                                        <FormattedMessage id={"App.info.Abbonati"} />
                                                    </Link>
                                                </p>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    : <p>
                        <FormattedMessage id="App.info.ErroreImprevisto" />
                    </p>
                : (
                    <section>
                        <div className="innerSection">
                            <br />
                            <br />
                            <h3>
                                <FormattedMessage id="App.info.PannelloDiControllo" />
                            </h3>
                            <div className="rack box">
                                <div className="w100">
                                    <blockquote>
                                        <FormattedMessage id="App.info.AccessoInAttesa" />
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </section>
                )
    )
}

const Europlus = withRouter(connect(mapStateToProps, mapDispatchToProps)(_Europlus));

export default Europlus;

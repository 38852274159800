import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bRadio } from '../../../../lib/goldParser/converter';
import { b2jRadio } from '../../../../lib/goldParser/physicalMap';
import Allagamento from './ParametriRadio/Allagamento';
import ContattoMagnetico from './ParametriRadio/ContattoMagnetico';
import ContattoTapparella from './ParametriRadio/ContattoTapparella';
import Fumo from './ParametriRadio/Fumo';
import Nebbiogeno from './ParametriRadio/Nebbiogeno';
import Radiocomando from './ParametriRadio/Radiocomando';
import RilevatoreInterno from './ParametriRadio/RilevatoreInterno';
import RilevatorePerimetraleEsterno from './ParametriRadio/RilevatorePerimetraleEsterno';
import RilevatoreVolumetricoEsterno from './ParametriRadio/RilevatoreVolumetricoEsterno';
import RilevatoreVolumetricoEsterno180 from './ParametriRadio/RilevatoreVolumetricoEsterno180';
import Sirena from './ParametriRadio/Sirena';
import UscitaRadio from './ParametriRadio/UscitaRadio';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldExitSystem, sleep } from '../../../../api/Cloud';


const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data: state.queue_data,
    errors: state.queue_errors
});

const mapDispatchToProps = (dispatch) => ({
    goldAcquirePeripheral: payload => dispatch(goldDoRequest('goldAcquirePeripheral', payload)),
    goldGetOutRadio: payload => dispatch(goldDoRequest('goldGetOutRadio', payload, false)),
    goldModOutRadio: (requests, payloads, login) => dispatch(goldDoRequest(requests, payloads, login)),
    setStore: data => dispatch(setStore(data))
});

const states = [

    'App.info.PremereTastoDispositivoRf',
    "App.info.TimeoutMemorizzazione",
    "App.info.DispositivoPresente",
    "App.info.AttesaParametriSensore",
    "App.info.AcquisizioneInCorso",
    "App.info.ValidazioneParametri",
    "App.info.AcquisizioneAnnullata",
    "App.info.OperazioneInCorso",
    "App.info.ErroreMemorizzazione",
];

const _Rf = ({ system, socket, data, errors, goldAcquirePeripheral, goldGetOutRadio, goldModOutRadio, setStore }) => {
    const hasPrograms = [0x03, 0x02, 0x12, 0x13, 0x22, 0x32, 0x01];
    const radioForm = useRef(null);
    const codeForm = useRef(null);
    const [on, setOn] = useState(false);
    const [rfStateLabel, setRfStateLabel] = useState(false);
    const [rfState, setRfState] = useState(null);
    const [ndisp, setNdisp] = useState(null);
    const [processed2, setProcessed2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [processed4, setProcessed4] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [success, setSuccess] = useState(null);
    const [current, setCurrent] = useState(null);
    const [tempoIngresso, setTempoIngresso] = useState(0);
    const [tempoUscita, setTempoUscita] = useState(0);
    const [antintrusione, setAntintrusione] = useState(0);
    const [mw, setMw] = useState(0);
    const [pir1, setPir1] = useState(0);
    const [pir2, setPir2] = useState(0);
    const [am, setAm] = useState(0);
    const [radioPayload, setRadioPayload] = useState(0);
    const intl = useIntl();

    const umount = useCallback(async () => {
        await goldExitSystem({ id_centrale: system.id_centrale }, true);
        socket.off('onGoldRfState');
        await sleep(1500);
        setStore({ polling_pause: false });
    }, []);

    useEffect(
        () => {
            socket.on('onGoldRfState', response => {
                if (response.state == 3) {
                    setNdisp(parseInt(response.data));
                } else if (response.state < 0) {
                    response.state = 8;
                    setOn(false);
                }
                setRfState(response.state);
            });
            return async () => {
                await umount();
                return true;
            }
        },
        [umount]
    );

    useEffect(() => {
        if (ndisp != null) {
            goldGetOutRadio({
                id_centrale: system.id_centrale,
                idx: ndisp
            });
            setProcessed3(true);
        }
    }, [ndisp])

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 2) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    };

    const start = e => {
        e.preventDefault();
        setButtonDisabled(true);
        setOn(true);
        setNdisp(null);
        setCurrent(null);
        setStore({ polling_pause: true });
        setRfStateLabel(true);
        setTimeout(() => {
            goldAcquirePeripheral({
                id_centrale: system.id_centrale,
                type: 'radio'
            });
            setButtonDisabled(false);
        }, 1500);
    };

    const exit = async () => {
        await goldExitSystem({ id_centrale: system.id_centrale }, true);
        setStore({ polling_pause: false });
    };

    const stop = async e => {
        e.preventDefault();
        setButtonDisabled(true);
        setRfStateLabel(false);
        setRfState(null);
        await exit();
        setOn(false);
        setProcessed2(null);
        setButtonDisabled(false);
    };

    const renderParameters = () => {
        let SubModule;
        switch (current.tipo_spec) {
            case 0x01:
            case 0x11:
                SubModule = Radiocomando;
                break;
            case 0x03:
                SubModule = ContattoMagnetico;
                break;
            case 0x13:
                SubModule = ContattoTapparella;
                break;
            case 0x02:
                SubModule = RilevatoreVolumetricoEsterno;
                break;
            case 0x12:
                SubModule = RilevatorePerimetraleEsterno;
                break;
            case 0x22:
                SubModule = RilevatoreInterno;
                break;
            case 0x32:
                SubModule = RilevatoreVolumetricoEsterno180;
                break;
            case 0x04:
                SubModule = Sirena;
                break;
            case 0x05:
                SubModule = UscitaRadio;
                break;
            case 0x06:
                SubModule = Allagamento;
                break;
            case 0x08:
                SubModule = Nebbiogeno;
                break;
            case 0x16:
                SubModule = Fumo;
                break;
            default:
        }
        if (!!!SubModule) {
            return <></>;
        }

        return (
            <>
                <h1>
                    <FormattedMessage id="App.info.Parametri" />
                </h1>
                <SubModule
                    current={current}
                    auth={system.store.auth}
                    setTempoIngresso={setTempoIngresso}
                    setTempoUscita={setTempoUscita}
                    setAm={setAm}
                    setPir1={setPir1}
                    setPir2={setPir2}
                    setMw={setMw}
                    setAntintrusione={setAntintrusione}
                    system={system}
                />
            </>
        );
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = clone(current);
        for (let j in e.target) {
            if (e.target.hasOwnProperty(j)) {
                let field = e.target[j];
                if (!!field.name) {
                    if (field.type === 'checkbox') {
                        payload[field.name] = !!field.checked;
                    } else {
                        payload[field.name] = field.value;
                    }
                }
                payload.tempo_ingresso = tempoIngresso;
                payload.tempo_uscita = tempoUscita;
                payload.am = parseInt(am) < 25 ? 0 : parseInt(am);
                payload.mw = parseInt(mw);
                payload.pir1 = parseInt(pir1);
                payload.pir2 = parseInt(pir2);
                payload.antintrusione = antintrusione;
            }
        }
        setRadioPayload(payload);
        const requests = [
            'goldModOutRadio',
            'goldConfirmPeripheral',
            'goldSetPeripheral'
        ];
        const payloads = [
            {
                id_centrale: system.id_centrale,
                idx: ndisp,
                edata: j2bRadio(payload)
            },
            {
                id_centrale: system.id_centrale
            },
            {
                id_centrale: system.id_centrale,
                n_disp: ndisp,
                no_update: true
            }
        ];
        goldModOutRadio(requests, payloads, true);
        setProcessed4(true);
    }

    if (data && processed2) {
        setProcessed2(null);
        setSuccess(!!data && !errors);
    }

    if (data && processed3) {
        let _payload = b2jRadio(data[0].data);
        setProcessed3(null);
        setMw(_payload.mw);
        setPir1(_payload.pir1);
        setPir2(_payload.pir2);
        setAm(_payload.am);
        setTempoIngresso(_payload.tempo_ingresso);
        setTempoUscita(_payload.tempo_uscita);
        setAntintrusione(_payload.antintrusione);
        setTimeout(() => {
            setCurrent(_payload);
        }, 1000);
    }

    if (data && processed4) {
        setProcessed4(null);
        const _success = (!!data && !errors) ? 2 : false;
        if (_success === 2) {
            let pm = clone(system.store.pm);
            pm.radio[ndisp] = radioPayload;
            setStore({
                pm
            });
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); }} />}
            {success === 2 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.DispositivoAcquisitoConSucc" })} onClose={() => { setSuccess(null); start({ preventDefault: () => null }); }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); exit(); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {current
                            ? (
                                <form ref={codeForm} className="stackableW33" onSubmit={handleSubmit}>
                                    <h1>
                                        <FormattedMessage id="App.info.IngressiRadio" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w50">
                                                <label>
                                                    <FormattedMessage id="App.info.Tipologia" />
                                                </label>
                                                {current
                                                    ? <p>{current.tipo_periferica}</p>
                                                    : <p>-</p>
                                                }
                                            </div>
                                            <div className="w50">
                                                <label>
                                                    <FormattedMessage id="App.info.Nome" />
                                                </label>
                                                {current
                                                    ? <input type="text" name="nome" defaultValue={current.nome || ``} maxLength={16} />
                                                    : <p>-</p>
                                                }
                                            </div>
                                        </div>
                                        {!!current.num_tipo_periferica && (hasPrograms.includes(current.tipo_spec)) && (
                                            <div className="rack bottomBorder">
                                                <h4>
                                                    <FormattedMessage id="App.info.Programmi" />
                                                </h4>
                                                <div className="w25">
                                                    <div className="switchButton">
                                                        <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" defaultChecked={current.g1} disabled={system.store.auth !== 2} />
                                                        <label htmlFor={`program_label_g1`}>
                                                            <span>
                                                                <span />
                                                            </span>
                                                            <FormattedMessage id="App.info.G1" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w25">
                                                    <div className="switchButton">
                                                        <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" defaultChecked={current.g2} disabled={system.store.auth !== 2} />
                                                        <label htmlFor={`program_label_g2`}>
                                                            <span>
                                                                <span />
                                                            </span>
                                                            <FormattedMessage id="App.info.G2" />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="w25">
                                                    <div className="switchButton">
                                                        <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" defaultChecked={current.g3} disabled={system.store.auth !== 2} />
                                                        <label htmlFor={`program_label_g3`}>
                                                            <span>
                                                                <span />
                                                            </span>
                                                            <FormattedMessage id="App.info.G3" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </fieldset>
                                    <fieldset>
                                        {renderParameters()}
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100 right">
                                                <button className="ok auto spaced" role="submit" disabled={(!current.num_tipo_periferica)}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            ) : (
                                <form ref={radioForm}>
                                    <h1>
                                        <FormattedMessage id="App.info.MemorizzazioneDispositivoRadio" />
                                    </h1>
                                    <fieldset>
                                        <br />
                                        <div className="rack">
                                            <div className="w50">
                                                {on
                                                    ? (
                                                        <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} disabled={buttonDisabled} onClick={stop}>
                                                            <FormattedMessage id="App.info.Interrompi" />
                                                        </button>
                                                    )
                                                    : (
                                                        <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} disabled={buttonDisabled} onClick={start}>
                                                            <FormattedMessage id="App.info.Avvia" />
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w100">
                                                {on && (<p className="loadingText">
                                                    <FormattedMessage id="App.info.RicercaDispositivoInCorso" />
                                                </p>)}
                                            </div>
                                            <div className="w100">
                                                {rfState != null && !!rfStateLabel && (<p>{(intl.formatMessage({ id: states[rfState] }) || ``)}</p>)}
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            )
                        }
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const Rf = connect(mapStateToProps, mapDispatchToProps)(_Rf);

export default Rf;
import React from 'react';
import { connect } from 'react-redux';
import { GOLD_AUTH } from '../../../../constants/gold';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system
});

const _StatoChiaviCodici = ({ system }) => {
    const intl = useIntl();

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.StatoChiaviCodici" />
                </h3>
                <br />
                <div className="rack">
                    <div className="w100">
                        <form>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th><FormattedMessage id="App.info.NomeChiaveCodice" /></th>
                                            <th><FormattedMessage id="App.info.Tipologia" /></th>
                                            <th><FormattedMessage id="App.info.Permessi" /></th>
                                            <th><FormattedMessage id="App.info.Programmi" /></th>
                                            <th><FormattedMessage id="App.info.Attributi" /></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {system.store.pm.codici.map((item, index) => {
                                            if (item.available) {
                                                return <React.Fragment key={`row_${index}`} />;
                                            }
                                            const nome = item.nome;
                                            const tipologia = item.tipo_codice === 128 ? intl.formatMessage({ id: "App.info.Chiave" }) : intl.formatMessage({ id: "App.info.Codice" });
                                            const permessi = intl.formatMessage({ id: GOLD_AUTH[item.tipo_utente] }) || '';
                                            const attributi = [];
                                            item.elettroserratura && attributi.push('E');
                                            item.antipanico && attributi.push('A');
                                            item.ronda && attributi.push('R');
                                            item.silenzioso && attributi.push('S');
                                            return (
                                                <tr key={`row_${index}`}>
                                                    <td><span className="head"><FormattedMessage id="App.info.NomeChiaveCodice" /></span>{nome}</td>
                                                    <td><span className="head"><FormattedMessage id="App.info.Tipologia" /></span>{tipologia}</td>
                                                    <td><span className="head"><FormattedMessage id="App.info.Permessi" /></span>{permessi}</td>
                                                    <td>
                                                        <span className="head"><FormattedMessage id="App.info.Programmi" /></span>
                                                        <i className="fa fa-circle" style={{ color: item.g1 ? "red" : "gray" }} />
                                                        <i className="fa fa-circle" style={{ color: item.g2 ? "#ffc20a" : "gray" }} />
                                                        <i className="fa fa-circle" style={{ color: item.g3 ? "green" : "gray" }} />
                                                    </td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="App.info.Attributi" />
                                                    </span>{(attributi.join(','))}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <h3><FormattedMessage id="App.info.Legenda" /></h3>
                                        <br />
                                        <div className="rack">
                                            <div className="w50">
                                                <p>
                                                    <FormattedMessage id="App.info.ElettroserraturaE" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.AntipanicoA" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.RondaR" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="App.info.SilenziosoS" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoChiaviCodici = connect(mapStateToProps)(_StatoChiaviCodici);

export default StatoChiaviCodici;
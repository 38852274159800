import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const Todo = ({ list = null, handleChange, disabled = false }) => {

    const inputText = useRef(null);
    const [items, setItems] = useState((!!list ? JSON.parse(list) : []));
    const [text, setText] = useState(null);
    const intl = useIntl();

    const onChange = _items => {
        const _ = _items.filter(el => !!el).filter(el => !!el.hidden === false);
        setItems([..._]);
        handleChange({
            target: {
                type: 'input',
                name: 'todo',
                value: JSON.stringify(_)
            }
        });
    };

    const handleChangeText = e => {
        setText(e.target.value);
    };

    const addTodo = () => {
        if (!!text === false) {
            return;
        }
        const input = inputText.current;
        input.value = "";
        const _items = [...items];
        _items.push({ checked: false, text, hidden: false });
        onChange(_items);
    }

    const handleChangeTodo = e => {
        const _items = [...items];
        _items[e.target.value].checked = !items[e.target.value].checked;
        onChange(_items);
    };

    const handleDeleteTodo = index => {
        const _items = [...items];
        _items[index].hidden = true;
        onChange([..._items]);
    };

    return (
        <div className="rack">
            <div className="rack">
                <label>
                    <formattedMessage id="App.info.ListaToDo" />
                </label>
                <div className="w75 nopad">
                    <input ref={inputText} type="text" name="new_item" placeholder={intl.formatMessage({ id: 'App.info.TestoPromemoria' })} maxLength="128" onChange={handleChangeText} disabled={disabled} />
                </div>
                <div className="w25">
                    <button role="link" disabled={disabled} onClick={(e) => {
                        e.preventDefault();
                        if (!disabled) {
                            addTodo();
                        }
                    }}>
                        <small>+</small>
                    </button>
                </div>
            </div>
            <div className="w100" style={{ maxHeight: '160px', overflowY: 'scroll' }}>
                {
                    items.map((el, index) => {
                        return (
                            !!el.hidden
                                ? (
                                    <></>
                                ) : (
                                    <p key={index}>
                                        <input id={`todo_${index}`} type="checkbox" name={`todo_${index}`} value={index} onChange={handleChangeTodo} defaultChecked={el.checked} />
                                        <label className="inline" htmlFor={`todo_${index}`}>
                                            <span />
                                        </label>
                                        {el.text} <Link to={`#`} disabled={disabled} onClick={() => {
                                            if (!disabled) {
                                                handleDeleteTodo(index);
                                            }
                                        }}><i className="fa fa-trash" /></Link>
                                    </p>
                                )
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Todo;
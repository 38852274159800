import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../../elements/Modal';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    system: state.system
});

const _StatoImpianto = ({ system }) => {
    const intl = useIntl();

    const stato_centrale = [
        ['stato', 'servizio', 'blue', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.Servizio" })],
        ['stato', 'allarme_inserito', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.AllarmeInserito_" })],
        ['alim', 'presenza_batteria_interna', 'red', 'rgb(40,170,40)', intl.formatMessage({ id: "App.info.PresenzaBatteriaInterna" })],
        ['alim', 'stato_batteria_esterna', 'red', 'rgb(40,170,40)', intl.formatMessage({ id: "App.info.StatoBatteriaEsterna" })],
        ['alim', 'fusibile', 'red', 'rgb(40,170,40)', intl.formatMessage({ id: "App.info.Fusibile" })],
        ['alim', 'rete_220_vca', 'red', 'rgb(40,170,40)', intl.formatMessage({ id: "App.info.Rete220" })]
    ];
    const allarmi = [
        ['alim', 'allarme_a', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.AllarmeA_" })],
        ['alim', 'allarme_k', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.AllarmeK_" })],
        ['alim', 'allarme_tecnologico', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.AllarmeTecnologico" })],
        ['stato', 'sabotaggio_as_esterno', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.SabotaggioAsEsterno" })],
        ['stato', 'sabotaggio_centrale', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.SabotaggioCentrale" })]
    ];
    const memorie = [
        ['stato', 'memoria_allarme_ingressi', '#ffc20a', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.MemoriaAllarmeIngressi" })],
        ['stato', 'memoria_sabotaggio_ingresso', '#ffc20a', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioIngresso" })],
        ['stato', 'memoria_sabotaggio_as', '#ffc20a', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggioAs" })],
        ['stato', 'memoria_sabotaggio', '#ffc20a', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.MemoriaSabotaggio" })]
    ];
    const stato_ingressi = [
        ['ingr', 'supervisione_ingressi', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.SupervisioneIngressi" })],
        ['ingr', 'guasto_ingressi_radio', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.GuastoIngressiRadio" })],
        ['ingr', 'sabotaggio_ingressi', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.SabotaggioIngressi" })],
        ['bus', 'dispositivo_bus_intruso', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.DispisitivoBusIntruso" })],
        ['bus', 'sabotaggio_hw_bus', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.SabotaggioHwBus" })],
        ['bus', 'tamper_bus', 'red', 'rgb(176,176,176)', intl.formatMessage({ id: "App.info.TamperBus" })],
    ];

    if (!!!system.store || !!!system.store.state) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    const { state } = system.store;

    if (!state || !state.connesso) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.ImpiantoNonConnesso" })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else if (!!!system.store || !!!system.store.auth) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficenti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.StatoImpianto" />
                </h3>
                <br />
                <div className="rack">
                    <div className="w50 light-boxed">
                        <h2>
                            <FormattedMessage id="App.info.StatoCentrale" />
                        </h2>
                        <div className="padp">
                            {
                                stato_centrale.map((item, index) => (
                                    <p key={`stato_centrale_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w50 light-boxed">
                        <h2>
                            <FormattedMessage id="App.info.Allarmi" />
                        </h2>
                        <div className="padp">
                            {
                                allarmi.map((item, index) => (
                                    <p key={`allarmi_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <div className="w50 light-boxed">
                        <h2>
                            <FormattedMessage id="App.info.Memorie" />
                        </h2>
                        <div className="padp">
                            {
                                memorie.map((item, index) => (
                                    <p key={`memorie_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w50 light-boxed">
                        <h2>
                            <FormattedMessage id="App.info.StatoIngressi" />
                        </h2>
                        <div className="padp">
                            {
                                stato_ingressi.map((item, index) => (
                                    <p key={`stato_ingressi_${index}`}><i className="fa fa-circle" style={(state[item[0]][item[1]] ? { color: [item[2]] } : { color: [item[3]] })} /> {item[4]}</p>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <div className="w100">
                        <h2>
                            <FormattedMessage id="App.info.Informazioni" />
                        </h2>
                        <div className="padp">
                            <div className="rack">
                                <div className="w50">
                                    <img src={`/static/images/model/${system.modello}.png`} alt={system.model} />
                                </div>
                                <div className="w50">
                                    <p><strong>
                                        <FormattedMessage id="App.info.TensioneImpianto" />
                                        <br /><big>{(state.vbatt === '0.0' ? 'N.D.' : `${state.vbatt} V`)}</big></strong></p>
                                    <p><strong>
                                        <FormattedMessage id="App.info.CorrenteImpianto" />
                                        <br /><big>{(state.corrente === '0.0' ? 'N.D.' : `${state.corrente} A`)}</big></strong></p>
                                    <p><strong>
                                        <FormattedMessage id="App.info.IdCentrale" />
                                        <br /><big>{system.id_centrale}</big></strong></p>
                                    <p><strong>
                                        <FormattedMessage id="App.info.VersioneFw" />
                                        <br /><big>{state.fw_ver}</big></strong></p>
                                    <p><strong>
                                        <FormattedMessage id="App.info.VersioneFwRf" />
                                        <br /><big>{system.store.pm.rfversion}</big></strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoImpianto = connect(mapStateToProps)(_StatoImpianto);

export default StatoImpianto;
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactSlider from 'react-slider';
import { Multiselect } from 'multiselect-react-dropdown';

import get_bits, { arrayIntToString, stringToArrayInt } from '../../../../lib/bytesFunctions';

import {
    doSocketRequest,
    setStore
} from '../../../../actions';

import Modal from '../../../elements/Modal';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = true) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError)),
    setStore: data => dispatch(setStore(data))
});

const _IngressiFilari = ({ match, system, socket, doSocketVariation, data_socket, setStore }) => {
    const copyForm = useRef(null);
    const wiredForm = useRef(null);
    const sub = match.params.sub && match.params.sub < 35 ? parseInt(match.params.sub) : 1;
    const [trama, setTrama] = useState(sub - 1);
    const [firstLoad, setFirstLoad] = useState(true);
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [ingressi, setIngressi] = useState([]);
    const [ingressiTarget, setIngressiTarget] = useState([]);
    const [tempoIngresso, setTempoIngresso] = useState(0);
    const [tempoUscita, setTempoUscita] = useState(0);
    const [processed, setProcessed] = useState(false);
    const status = !!system && !!socket ? system.store.status : null;
    const intl = useIntl();

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                setRedir(false);
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                let _ingressi = [];
                for (let i = 0x0000, k = 0; i <= 0x0022; i++) {
                    if (!!system.store[i]) {
                        let _x = system.store[i].split(',').map(x => parseInt(x));
                        _ingressi.push({
                            trama: i,
                            index: k,
                            group: Math.floor(k / 8),
                            bit: k % 8,
                            name: arrayIntToString(_x.slice(4, 20)),
                            payload: _x,
                            tempo_ingresso: get_bits(_x[2], 6) && get_bits(_x[1], 1) ? 2 : get_bits(_x[1], 1) ? 1 : 0,
                            tempo_trigger: get_bits(_x[2], 4),
                            logica: get_bits(_x[3], 1),
                            allarmi: get_bits(_x[0], 0, 3),
                        });
                    }
                    k++;
                }
                setIngressi(_ingressi);
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, setIngressi, system, setTempoIngresso, setTempoUscita, trama, doSocketVariation, sub, setRedir, processed, setProcessed, socket]
    );

    useEffect(
        () => {
            if (current) {
                let _ingressiTarget = ingressi.map((value, index) => {
                    value.label = `${index + 1} - ${value.name}`;
                    return value.trama !== current.trama ? value : null;
                }
                ).filter(x => !!x);
                setIngressiTarget(_ingressiTarget);
            }
            return () => null;
        },
        [current]);

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let _current = {
                trama,
                index: trama,
                group: Math.floor(trama / 8),
                bit: trama % 8,
                name: arrayIntToString(_x.slice(4, 20)),
                payload: _x,
                tempo_ingresso: get_bits(_x[2], 6) && get_bits(_x[1], 1) ? 2 : get_bits(_x[1], 1) ? 1 : 0,
                tempo_trigger: get_bits(_x[2], 4),
                logica: get_bits(_x[3], 1),
                allarmi: get_bits(_x[0], 0, 3),
            };
            setCurrent(_current);
            setTempoIngresso(_x[20]);
            setTempoUscita(_x[21]);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                text={intl.formatMessage({ id: 'App.info.ImpiantoNonConnesso' })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                        text={intl.formatMessage({ id: 'App.info.PermessiNonSufficienti' })}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }

        } else {
            return (<section></section>);
        }
    }

    const handleSelectChange = e => {
        const value = parseInt(e.target.value);
        setCurrent(null);
        setFirstLoad(true);
        setTrama(value);
        setRedir(`/europlus/${system.id}/wired/${value}`);
    };

    const makePayload = (name = null) => {
        const form = wiredForm.current;
        const payload = [];
        payload.push((
            parseInt(form['allarmi'].value) +
            (form['escluso'].checked ? 16 : 0) +
            (form['parzializzabile'].checked ? 32 : 0) +
            (form['ronda'].checked ? 64 : 0)
        ));
        payload.push((
            (form['ritardato'].checked ? 1 : 0) +
            (form['tempo_ingresso'].value === "1" || form['tempo_ingresso'].value === "2" ? 2 : 0) +
            (form['test'].checked ? 4 : 0) +
            (form['percorso'].checked ? 8 : 0) +
            (form['a'].checked ? 16 : 0) +
            (form['fuoco'].checked ? 32 : 0) +
            (form['campanello'].checked ? 64 : 0) +
            (form['silenzioso'].checked ? 128 : 0)
        ));
        payload.push((
            (form['g1'].checked ? 1 : 0) +
            (form['g2'].checked ? 2 : 0) +
            (form['g3'].checked ? 4 : 0) +
            (form['gext'].checked ? 8 : 0) +
            (form['tempo_trigger'].value === "1" ? 16 : 0) +
            (form['24h'].checked ? 32 : 0) +
            (form['tempo_ingresso'].value === "2" ? 64 : 0) +
            (form['elettroserratura'].checked ? 128 : 0)
        ));
        payload.push((
            (form['k'].checked ? 1 : 0) +
            (parseInt(form['logica'].value) ? 2 : 0)
        ));
        stringToArrayInt(16, (name != null ? name : form["nome"].value), 0x20).map(x => { payload.push(x); return null; });
        payload.push(tempoIngresso);
        payload.push(tempoUscita);
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, current.trama, payload);
        let store = { [current.trama]: payload.join(',') };
        setStore(store);
        handleSelectChange({ target: { value: current.index } });
    }

    const handleCopy = e => {
        e.preventDefault();
        const targets = copyForm.current.getSelectedItems();
        const settings = [];
        for (let i in targets) {
            settings.push({
                trama: targets[i].trama,
                payload: makePayload(targets[i].name)
            })
        }
        socket.emit('sendQueue', JSON.stringify(settings));
    }

    if (redir) {
        return <Redirect to={redir} />
    }
    if (!!!current) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={wiredForm}>
                            {current && (
                                <>
                                    <h3>
                                        <FormattedMessage id="App.info.IngressiFilari" />
                                    </h3>
                                    <br />

                                    <h1>
                                        <FormattedMessage id="App.info.ParametriDiIngresso" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Numero" />
                                                </label>
                                                <select value={current.index} name="numero" onChange={handleSelectChange}>
                                                    {
                                                        ingressi.map((value, index) => <option key={index} value={index}>{`${index + 1} - ${value.name}`}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Nome" />
                                                </label>
                                                <input type="text" name="nome" defaultValue={current.name || ``} maxLength={16} />
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <h4>
                                                <FormattedMessage id="App.info.Programmi" />
                                            </h4>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" defaultChecked={get_bits(current.payload[2])} onChange={() => null} />
                                                    <label htmlFor={`program_label_g1`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g1}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" defaultChecked={get_bits(current.payload[2], 1)} onChange={() => null} />
                                                    <label htmlFor={`program_label_g2`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g2}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" defaultChecked={get_bits(current.payload[2], 2)} onChange={() => null} />
                                                    <label htmlFor={`program_label_g3`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g3}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_gext" name="gext" defaultValue="0" defaultChecked={get_bits(current.payload[2], 3)} onChange={() => null} />
                                                    <label htmlFor={`program_label_gext`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.gext}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <div className="w25">
                                                <p>
                                                    <FormattedMessage id="App.info.TipoDiIngresso" />
                                                </p>
                                                <select defaultValue={current.tempo_ingresso} name="tempo_ingresso" onChange={() => null} disabled={teknox !== 3}>
                                                    <option key={`tempo_ingresso_0`} value={0}>
                                                        {intl.formatMessage({ id: "App.info.Nc" })}
                                                    </option>
                                                    <option key={`tempo_ingresso_1`} value={1}>
                                                        {intl.formatMessage({ id: "App.info.Bilanciato" })}
                                                    </option>
                                                    <option key={`tempo_ingresso_2`} value={2}>
                                                        {intl.formatMessage({ id: "App.info.DoppioBilanciato" })}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <p>
                                                    <FormattedMessage id="App.info.TempoDiTrigger" />
                                                </p>
                                                <select defaultValue={current.tempo_trigger} name="tempo_trigger" onChange={() => null} disabled={teknox !== 3}>
                                                    <option key={`tempo_trigger_0`} value={0}>
                                                        {intl.formatMessage({ id: "App.info.TrecentoMs" })}
                                                    </option>
                                                    <option key={`tempo_trigger_1`} value={1}>
                                                        {intl.formatMessage({ id: "App.info.CinquecentoMs" })}
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <p>
                                                    <FormattedMessage id="App.info.Logica" />
                                                </p>
                                                <select defaultValue={current.logica} name="logica" onChange={() => null} disabled={teknox !== 3}>
                                                    <option key={`logica_0`} value={0}><FormattedMessage id="App.info.And" /></option>
                                                    <option key={`logica_1`} value={1}><FormattedMessage id="App.info.Or" /></option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <p>
                                                    <FormattedMessage id="App.info.NumeroAllarmi" />
                                                </p>
                                                <select defaultValue={current.allarmi} name="allarmi" onChange={() => null} disabled={teknox !== 3}>
                                                    {
                                                        [...Array(16).keys()].map((value, index) => (
                                                            <option key={`allarmi_${index}`} value={value}>{value || intl.formatMessage({ id: 'App.info.infiniti' })}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <h4>
                                                <FormattedMessage id="App.info.ModuliFilari" />
                                            </h4>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="escluso" name="escluso" defaultValue="0" defaultChecked={get_bits(current.payload[0], 4)} onChange={() => null} />
                                                    <label htmlFor={`escluso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Escluso" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultValue="0" defaultChecked={get_bits(current.payload[1], 7)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`silenzioso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Silenzioso" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="test" name="test" defaultValue="0" defaultChecked={get_bits(current.payload[1], 2)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`test`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Test" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="parzializzabile" name="parzializzabile" defaultValue="0" defaultChecked={get_bits(current.payload[0], 5)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`parzializzabile`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Parzializzabile" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack bottomBorder">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ronda" name="ronda" defaultValue="0" defaultChecked={get_bits(current.payload[0], 6)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`ronda`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Ronda" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="24h" name="24h" defaultValue="0" defaultChecked={get_bits(current.payload[2], 5)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`24h`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.VentiquattroH" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ritardato" name="ritardato" defaultValue="0" defaultChecked={get_bits(current.payload[1], 0)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`ritardato`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Ritardato" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="percorso" name="percorso" defaultValue="0" defaultChecked={get_bits(current.payload[1], 3)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`percorso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Percorso" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rack">
                                            <div className="w50">
                                                <p>
                                                    <FormattedMessage id="App.info.TempoIngresso" />
                                                </p>
                                                <ReactSlider
                                                    disabled={teknox !== 3}
                                                    defaultValue={(current.payload[20])}
                                                    onChange={value => { setTempoIngresso(value); }}
                                                    max={255}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 60),
                                                            parseInt(state.valueNow) % 60,
                                                        ];
                                                        return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                            <div className="w50">
                                                <p>
                                                    <FormattedMessage id="App.info.TempoUscite" />
                                                </p>
                                                <ReactSlider
                                                    disabled={teknox !== 3}
                                                    defaultValue={(current.payload[21])}
                                                    onChange={value => { setTempoUscita(value); }}
                                                    max={255}
                                                    min={0}
                                                    className="horizontal-slider"
                                                    thumbClassName="slider-thumb"
                                                    trackClassName="slider-track"
                                                    renderThumb={(props, state) => {
                                                        const [min, sec] = [
                                                            Math.floor(parseInt(state.valueNow) / 60),
                                                            parseInt(state.valueNow) % 60,
                                                        ];
                                                        return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                                                    }}
                                                />
                                            </div>
                                        </div>


                                    </fieldset>
                                    <h1>
                                        <FormattedMessage id="App.info.AssociazioniUscite" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack bottomBorder">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="a" name="a" defaultValue="0" defaultChecked={get_bits(current.payload[1], 4)} onChange={() => null} disabled={teknox !== 3}
                                                    />
                                                    <label htmlFor={`a`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.AAllUno" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="k" name="k" defaultValue="0" defaultChecked={get_bits(current.payload[3], 0)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`k`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.KAllDue" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="fuoco" name="fuoco" defaultValue="0" defaultChecked={get_bits(current.payload[1], 5)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`fuoco`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Fuoco" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="campanello" name="campanello" defaultValue="0" defaultChecked={get_bits(current.payload[1], 6)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`campanello`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Campanello" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultValue="0" defaultChecked={get_bits(current.payload[2], 7)} onChange={() => null} disabled={teknox !== 3} />
                                                    <label htmlFor={`elettroserratura`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Elettroserratura" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>
                                        <FormattedMessage id="App.info.StatoIngresso" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100">
                                                <p><i className="fa fa-circle" style={(get_bits(status[`filari_oi24_${current.group}`], current.bit) ? { color: 'red' } : { color: 'rgb(176, 176, 176)' })} />
                                                    <FormattedMessage id="App.info.Allarme24h" />
                                                </p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`filari_oi_${current.group}`], current.bit) ? { color: 'red' } : { color: 'rgb(176, 176, 176)' })} />
                                                    <FormattedMessage id="App.info.IngressoAperto" />
                                                </p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`filari_esclusioni_${current.group}`], current.bit) ? { color: 'red' } : { color: 'rgb(176, 176, 176)' })} />
                                                    <FormattedMessage id="App.info.IngressoEscluso" />
                                                </p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`filari_memorie24_${current.group}`], current.bit) ? { color: '#ffc20a' } : { color: 'rgb(176, 176, 176)' })} />
                                                    <FormattedMessage id="App.info.MemoriaAllarme24h" />
                                                </p>
                                                <p><i className="fa fa-circle" style={(get_bits(status[`filari_memorie_${current.group}`], current.bit) ? { color: '#ffc20a' } : { color: 'rgb(176, 176, 176)' })} />
                                                    <FormattedMessage id="App.info.MemoriaIngressoAperto" />
                                                </p>
                                            </div>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={() => { handleSelectChange({ target: { value: current.index, scrollToBottom: true } }); }}>
                                                    <FormattedMessage id="App.info.Annulla" />
                                                </button>
                                                <button className="ok auto spaced" onClick={handleSubmit}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>
                                        <FormattedMessage id="App.info.CopiaImpostazioniIF" />
                                    </h1>
                                    <fieldset>
                                        {
                                            teknox === 3
                                                ? (
                                                    <div className="rack">
                                                        <div className="w50" style={{ marginBottom: "12em" }}>
                                                            <label>
                                                                <FormattedMessage id="App.info.Numero" />
                                                            </label>
                                                            <Multiselect
                                                                closeOnSelect={false}
                                                                displayValue="label"
                                                                emptyRecordMsg={intl.formatMessage({ id: "App.info.NessunIngressoDisponibile" })}
                                                                options={ingressiTarget}
                                                                placeholder={intl.formatMessage({ id: "App.info.SelezionaUnoOPiuIngressi" })}
                                                                ref={copyForm}
                                                            />
                                                        </div>
                                                        <div className="w50 right">
                                                            <p>
                                                                <br />
                                                                <button className="ok auto" onClick={handleCopy}>
                                                                    <FormattedMessage id="App.info.Copia" />
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <p>
                                                        <FormattedMessage id="App.info.NecessariPermessiInstallatore" />
                                                    </p>
                                                )
                                        }
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const IngressiFilari = connect(mapStateToProps, mapDispatchToProps)(_IngressiFilari);

export default IngressiFilari;
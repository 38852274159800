import React from 'react';
import { FormattedMessage } from 'react-intl';

const StatoRilevatoreInterno = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Allarme" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_am ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.AllarmeAm" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.tamper ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Tamper" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.supervisione_led ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Supervisione" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.escluso_led ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Escluso" />
                    </p>
                </div>
            </div>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.memoria ? "#ffc20a" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Memoria" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.dormiente ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Dormiente" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.memoria_tamper ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.MemoriaTamper" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "#ffc20a" : "rgb(176, 176, 176)") }} />
                        <FormattedMessage id="App.info.BatteriaScarica" />
                    </p>
                </div>
            </div>
        </>
    );
};

export default StatoRilevatoreInterno;
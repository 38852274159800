import React from 'react';
import { FormattedMessage } from 'react-intl';

const StatoTapparella = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_aux ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.AllarmeAux" /></p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.supervisione_led ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Supervisione" /></p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.escluso_led ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Escluso" /></p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.dormiente ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Dormiente" /></p>
                </div>
            </div>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.memoria_aux ? "#ffc20a" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.MemoriaAux" /></p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "#ffc20a" : "rgb(176, 176, 176)") }} />
                        <FormattedMessage id="App.info.BatteriaScarica" /></p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.guasto ? "red" : "rgb(176, 176, 176)" }} />
                        <FormattedMessage id="App.info.Guasto" /></p>
                </div>
            </div>
        </>
    );
};

export default StatoTapparella;
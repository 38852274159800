import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketCommand: (socket, trama, noSuccess = false) => dispatch(doSocketRequest(socket, trama, [], true, noSuccess)),
});

const _Comandi = ({ system, doSocketCommand, socket }) => {

    const intl = useIntl();
    const status = !!system && !!system.store && !!socket ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }

    var teknox = 0;
    var comandicentrale = null;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={intl.FormattedMessage({ id: 'App.info.LinceCloudImpianto' })}
                text={intl.FormattedMessage({ id: 'App.info.ImpiantoNonConnesso' })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={intl.FormattedMessage({ id: 'App.info.LinceCloudImpianto' })}
                        text={intl.FormattedMessage({ id: 'App.info.PermessiNonSufficienti' })}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
            comandicentrale = status.structs.comandicentrale;
        } else {
            return (<section></section>);
        }
    }

    const sendCommand = (e, trama) => {
        e.preventDefault();
        doSocketCommand(socket, trama);
    }

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="App.info.ComandiDiCentrale" />
                </h3>
                <br />
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={(!!comandicentrale.ccsi ? { background: "red" } : {})} onClick={e => { sendCommand(e, 0x00F2); }}>
                                <FormattedMessage id="App.info.StatoDiServizio" />
                            </button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <Link className="superButton" to={`/europlus/${system.id}/rf`} disabled={teknox != 3}>
                                <FormattedMessage id="App.info.MemorizzazioneRf" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00F3); }}>
                                <FormattedMessage id="App.info.ResetDelleMemorie" />
                            </button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={(!!comandicentrale.ccvt ? { background: "green" } : {})} onClick={e => { sendCommand(e, 0x00F6); }} disabled={teknox == 2}>
                                <FormattedMessage id="App.info.VisualizzazioneTamper" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={(!!comandicentrale.ccmc ? { background: "green" } : {})} onClick={e => { sendCommand(e, 0x00F4); }}>
                                <FormattedMessage id="App.info.MemorizzazioneChiavi" />
                            </button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <Link className="superButton" to={`/europlus/${system.id}/new_code`}>
                                <FormattedMessage id="App.info.GenerazioneCodice" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00F5); }} disabled={teknox == 2}>
                                <FormattedMessage id="App.info.AcquisizionePeriferiche" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w50">
                    <div className="padp">
                        <button className="superButton" onClick={e => { sendCommand(e, 0x00F7); }} disabled={teknox == 2}>
                            <FormattedMessage id="App.info.SyncCentraleEuronet" />
                        </button>
                    </div>
                </div>
                <div className="w50">
                    <div className="padp">
                        <button className="superButton" onClick={e => { e.preventDefault(); socket.emit('resetChecksum'); }}>
                            <FormattedMessage id="App.info.SyncCloudEuronet" />
                        </button>
                    </div>
                </div>
            </div>
            {
                /*
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00FC); }} disabled={teknox == 2}>Cancellazione totale dispositivi Radio</button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" onClick={e => { sendCommand(e, 0x00FD); }} disabled={teknox == 2}>Cancellazione totale Codici</button>
                        </div>
                    </div>
                </div>
                */
            }
            <p>&nbsp;</p>
        </section>
    );
};

const Comandi = connect(mapStateToProps, mapDispatchToProps)(_Comandi);

export default Comandi;
import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import { FormattedMessage, useIntl } from 'react-intl';

const RilevatoreInterno = ({ current, auth, setMw, setPir1, setAm, setTempoIngresso, setTempoUscita }) => {
    const [ritardatoChecked, setRitardatoChecked] = useState(current.ritardato);
    const [percorsoChecked, setPercorsoChecked] = useState(current.percorso);
    const intl = useIntl();

    return (
        <>
            <div className="rack">
                <div className="w25">
                    <label>
                        <FormattedMessage id="App.info.NumeroMaxAllarmi" />
                    </label>
                    <select defaultValue={current.cicli_autoesclusione} name="cicli_autoesclusione" disabled={auth !== 2}>
                        <option value={0}>
                            <FormattedMessage id="App.info.Infiniti" />
                        </option>
                        {(Array.from({ length: 15 }, (_, i) => i + 1).map(x => <option key={`numero_allarmi_${x}`} value={x}>{x}</option>))}
                    </select>
                </div>
                <div className="w25">
                    <label>
                        <FormattedMessage id="App.info.Logica" />
                    </label>
                    <select defaultValue={current.logica} name="logica" disabled={auth !== 2}>
                        <option value={0}>
                            <FormattedMessage id="App.info.PirAndMw" />

                        </option>
                        <option value={0x40}><FormattedMessage id="App.info.Pir" /></option>
                    </select>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <p><FormattedMessage id="App.info.Mw" /></p>
                    <ReactSlider
                        defaultValue={current.mw}
                        onChange={value => { setMw(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={25}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow}%`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <p><FormattedMessage id="App.info.Pir" /></p>
                    <ReactSlider
                        defaultValue={current.pir1}
                        onChange={value => { setPir1(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={25}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow}%`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <p><FormattedMessage id="App.info.Am" /></p>
                    <ReactSlider
                        defaultValue={current.am}
                        onChange={value => { setAm(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={20}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{(state.valueNow < 25 ? intl.formatMessage({ id: "App.info.Disattivo" }) : `${state.valueNow}%`)}</div>);
                        }}
                    />
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="escluso" name="escluso" defaultValue="0" defaultChecked={current.escluso} />
                        <label htmlFor={`escluso`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Escluso" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultValue="0" defaultChecked={current.silenzioso} disabled={auth !== 2} />
                        <label htmlFor={`silenzioso`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Silenzioso" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="test" name="test" defaultValue="0" defaultChecked={current.test} disabled={auth !== 2} />
                        <label htmlFor={`test`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Test" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="parzializzabile" name="parzializzabile" defaultValue="0" defaultChecked={current.parzializzabile} disabled={auth !== 2} />
                        <label htmlFor={`parzializzabile`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Parzializzabile" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="ronda" name="ronda" defaultValue="0" defaultChecked={current.ronda} disabled={auth !== 2} />
                        <label htmlFor={`ronda`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Ronda" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="24h" name="sempre_attivo" defaultValue="0" defaultChecked={current.sempre_attivo} disabled={auth !== 2} />
                        <label htmlFor={`24h`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.VentiquattroH" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Supervisione" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.LedOn" />
                        </label>
                    </div>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="ritardato" name="ritardato" defaultValue="0" checked={ritardatoChecked} disabled={auth !== 2} onChange={e => { let _checked = e.target.checked; setRitardatoChecked(_checked); if (_checked) { setPercorsoChecked(false); } }} />
                        <label htmlFor={`ritardato`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Ritardato" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="percorso" name="percorso" defaultValue="0" disabled={auth !== 2} checked={percorsoChecked} onChange={e => { let _checked = e.target.checked; setPercorsoChecked(_checked); if (_checked) { setRitardatoChecked(false); } }} />
                        <label htmlFor={`percorso`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Percorso" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <p>
                        <FormattedMessage id="App.info.TempoIngresso" />
                    </p>
                    <ReactSlider
                        defaultValue={current.tempo_ingresso}
                        onChange={value => { setTempoIngresso(value); }}
                        disabled={auth !== 2}
                        max={239}
                        min={0}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            const [min, sec] = [
                                Math.floor(parseInt(state.valueNow) / 60),
                                parseInt(state.valueNow) % 60,
                            ];
                            return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <p>
                        <FormattedMessage id="App.info.TempoUscita" />
                    </p>
                    <ReactSlider
                        defaultValue={current.tempo_uscita}
                        onChange={value => { setTempoUscita(value); }}
                        disabled={auth !== 2}
                        max={239}
                        min={0}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            const [min, sec] = [
                                Math.floor(parseInt(state.valueNow) / 60),
                                parseInt(state.valueNow) % 60,
                            ];
                            return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                        }}
                    />
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="a" name="a" defaultValue="0" defaultChecked={current.a} disabled={auth !== 2} />
                        <label htmlFor={`a`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.A" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="k" name="k" defaultValue="0" defaultChecked={current.k} disabled={auth !== 2} />
                        <label htmlFor={`k`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.K" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="fuoco" name="fuoco" defaultValue="0" defaultChecked={current.fuoco} disabled={auth !== 2} />
                        <label htmlFor={`fuoco`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Fuoco" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="campanello" name="campanello" defaultValue="0" defaultChecked={current.campanello} disabled={auth !== 2} />
                        <label htmlFor={`campanello`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Campanello" />
                        </label>
                    </div>
                </div>
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultValue="0" defaultChecked={current.elettroserratura} disabled={auth !== 2} />
                        <label htmlFor={`elettroserratura`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Elettroserratura" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RilevatoreInterno;
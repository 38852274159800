import React from 'react';
import { connect } from 'react-redux';
import get_bits, { arrayIntToString } from '../../../../lib/bytesFunctions';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    system: state.system,
});


const _StatoIngressiRadio = ({ system }) => {

    const intl = useIntl();
    const status = !!system ? system.store.status : null;
    if (!status) {
        return (<section></section>);
    }

    const ingressiRadio = [];
    for (let i = 0x23; i <= 0x62; i++) {
        ingressiRadio.push(system.store[i]);
    }

    return (
        <section>
            <div className="innerSection">
                <h3>
                    <FormattedMessage id="app.info.StatoIngressiRadio" />
                </h3>
                <br />
                <div className="rack">
                    <div className="w100">
                        <form>
                            <fieldset>
                                <table className="controlPanelTable responsiveTable">
                                    <thead>
                                        <tr>
                                            <th><FormattedMessage id="app.info.NomeIngresso" /></th>
                                            <th><FormattedMessage id="app.info.Allarme24h" /></th>
                                            <th><FormattedMessage id="app.info.Memoria24h" /></th>
                                            <th><FormattedMessage id="app.info.IngressoAllarme" /></th>
                                            <th><FormattedMessage id="app.info.MemoriaAllarme" /></th>
                                            <th><FormattedMessage id="app.info.Supervisione" /></th>
                                            <th><FormattedMessage id="app.info.BatteriaScarica" /></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {ingressiRadio.map((item, index) => {
                                            let name = arrayIntToString(item.split(',').slice(3, 19));
                                            let group = Math.floor(index / 8);
                                            let bit = index % 8;
                                            return (
                                                <tr key={`row_${index}`}>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.NomeIngresso" />
                                                    </span>{`${index + 1} - ${!!name ? name : `${intl.formatMessage({ id: 'App.info.IngressoRadio' })} ${index + 1}`}`}</td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.Allarme24h" />
                                                    </span>{(get_bits(status[`as_radio_${group}`], bit) ? 'A' : '')}</td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.Memoria24h" />
                                                    </span>{(get_bits(status[`mem_as_radio_${group}`], bit) ? 'M' : '')}</td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.IngressoAperto" />
                                                    </span>{(get_bits(status[`oi_radio_${group}`], bit) ? 'A' : '')}</td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.MemoriaAllarme" />
                                                    </span>{(get_bits(status[`mem_oi_radio_${group}`], bit) ? 'M' : '')}</td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.Supervisione" />
                                                    </span>{(get_bits(status[`supervisioni_radio_${group}`], bit) ? 'S' : '')}</td>
                                                    <td><span className="head">
                                                        <FormattedMessage id="app.info.BatteriaScarica" />
                                                    </span>{(get_bits(status[`lo_batt_radio_${group}`], bit) ? 'S' : '')}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const StatoIngressiRadio = connect(mapStateToProps)(_StatoIngressiRadio);

export default StatoIngressiRadio;
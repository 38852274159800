import { hexstringToBCD, bcd2int, arrayIntToString, hexstringToArrayInt } from '../bytesFunctions';
import { MCC } from '../../constants/gold';

const msg_etichette = ["benvenuto", "allarme", "sabotaggio", "antipanico", "silenzioso", "coda"];

const rf_periferiche = [
    { nome: 'non disponibile', specs: [null] },
    { nome: 'radiocomando', specs: ['normale', 'antipanico'] },
    { nome: 'movimento', specs: ['bobby', 'baby', 'dt', '180'] },
    { nome: 'contatto', specs: ['magnetico', 'tapparella'] },
    { nome: 'sirena', specs: [null] },
    { nome: 'uscita', specs: [null] },
    { nome: 'tecnologico', specs: ['allagamento', 'fumo', 'gas', 'corrente'] },
    { nome: 'ripetitore', specs: [null] },
    { nome: 'nebbiogeno', specs: [null] }
];

const bus_periferiche = {
    6: 'tastiera',
    7: 'espansione uscite',
    8: 'espansione ingressi',
    9: 'inseritore',
    11: 'tastiera touch',
};

export const b2jCodice = arr => {
    arr[0] = hexstringToArrayInt(arr[0], true);
    if(arr[0][4] === 0 || arr[0][4] === 0xFF) {
        return {
            available: true,
            g1: false,
            g2: false,
            g3: false,
            elettroserratura: false,
            ronda: false,
            silenzioso: false,
            antipanico: false,
            tipo_utente: false,
            tipo_codice: false,
            nome: "Non disponibile"
        };
    }
    return {
        available: false,
        g1: !!(arr[0][3] & 1),
        g2: !!(arr[0][3] & 2),
        g3: !!(arr[0][3] & 4),
        elettroserratura: !!(arr[0][3] & 16),
        ronda: !!(arr[0][3] & 32),
        silenzioso: !!(arr[0][3] & 64),
        antipanico: !!(arr[0][3] & 128),
        tipo_utente: arr[0][4] & 7,
        tipo_codice: arr[0][4] & 128,
        nome: !!arr[1] ? arr[1].trim() : ""
    }
};

export const b2jChiave = arr => {
    arr[0] = hexstringToArrayInt(arr[0], true);
    return {
        codice: ((arr[0][2] << 16) | (arr[0][1] << 8) | (arr[0][0])),
        available: false,
        g1: !!(arr[0][3] & 1),
        g2: !!(arr[0][3] & 2),
        g3: !!(arr[0][3] & 4),
        elettroserratura: !!(arr[0][3] & 16),
        ronda: !!(arr[0][3] & 32),
        silenzioso: !!(arr[0][3] & 64),
        antipanico: !!(arr[0][3] & 128),
        tipo_utente: arr[0][4] & 7,
        tipo_codice: arr[0][4] & 128,
        nome: !!arr[1] ? arr[1].trim() : ""
    }
};

const b2jFilareRadio = arr => ({
    test: !!(arr[0] & 1),
    escluso: !!(arr[0] & 2),
    ronda: !!(arr[0] & 4),
    fuoco: !!(arr[0] & 8),
    campanello: !!(arr[0] & 16),
    silenzioso: !!(arr[0] & 32),
    elettroserratura: !!(arr[0] & 64),
    parzializzabile: !!(arr[0] & 128),
    g1: !!(arr[1] & 1), //USCITA/NEBBIOGENO A
    g2: !!(arr[1] & 2), //USCITA/NEBBIOGENO Fuoco
    g3: !!(arr[1] & 4), //USCITA/NEBBIOGENO Silenzioso
    a: !!(arr[1] & 8), //USCITA/NEBBIOGENO Campanello
    k: !!(arr[1] & 16), //USCITA/NEBBIOGENO/RADIOCOMANDO Elettroserratura
    ritardato: !!(arr[1] & 32), //USCITA/NEBBIOGENO K /RADIOCOMANDO Ronda
    percorso: !!(arr[1] & 64), //USCITA/NEBBIOGENO GSM /RADIOCOMANDO Silenzioso
    sempre_attivo: !!(arr[1] & 128), //RADIOCOMANDO Antipanico
    antintrusione: arr[1]
});

const b2jFilare = arr => {
    arr[0] = hexstringToArrayInt(arr[0], true);
    let generali = b2jFilareRadio([arr[0][0],arr[0][1]]);
    let parametri = {
        tempo_trigger: (arr[0][2] & 1),
        tipologia_ingresso: (arr[0][2] & 6),
        doppio_impulso: !!(arr[0][2] & 8),
        tempo_ingresso: arr[0][3],
        tempo_uscita: arr[0][4],
        cicli_autoesclusione: arr[0][5] & 15,
        nome: arr[1].trim()   
    };
    return {
        ...generali,
        ...parametri
    };
};

export const b2jRadio = arr => {
    arr[0] = hexstringToArrayInt(arr[0], true);
    if(!!!arr[0][8] || arr[0][8] > 8) {
        return {
            num_tipo_periferica: 0,
            num_spec_periferica: 0,
            tipo_spec: 0,
            nome: 'Non disponibile'    
        }
    }
    let generali = b2jFilareRadio([arr[0][0],arr[0][1]]);
    let parametri = {
        tempo_ingresso: arr[0][3],
        tempo_uscita: arr[0][4],
        cicli_autoesclusione: arr[0][5],
        indirizzo_periferica: ((arr[0][7] << 8) + arr[0][6]),
        num_tipo_periferica: arr[0][8],
        num_spec_periferica: arr[0][9],
        tipo_spec: (arr[0][9] << 4) + arr[0][8],
        tipo_periferica: rf_periferiche[arr[0][8]].nome,
        specializzazione_periferica: rf_periferiche[arr[0][8]].specs[arr[0][9]],
        mw: arr[0][10],
        pir1: arr[0][11],
        pir2: arr[0][12],
        am: arr[0][13],
        nome: arr[1].trim()
    };
    parametri.gruppo_spec = parametri.tipo_spec === 0x11 ? 0x01 : parametri.tipo_spec;
    var sub = "";
    switch(arr[0][8]) {
        case 1: //'radiocomando'
            sub = !!arr[0][9] ? "Radiocomando Antipanico" : "Radiocomando";
            break;
        case 2: // 'movimento':
            parametri.supervisione = !!(arr[0][2] & 1);
            parametri.led_on = !!(arr[0][2] & 2);
            parametri.cd_cs = arr[0][2] & 24;
            if(parametri.tipo_spec === 0x22) { // DT
                parametri.logica = (arr[0][2] & 224) != 0 ? 0x40 : 0;
            } else if(parametri.tipo_spec === 0x32) { // 180
                parametri.reed = !!(arr[0][2] & 4) ? 1 : 0;
                parametri.prog = !!(arr[0][2] & 32);
                parametri.logica = arr[0][2] & 192;
            } else { // BOBBY BABY 180
                parametri.logica = arr[0][2] & 224;
            }
            sub = arr[0][9] === 1 ? "Tenda" : (arr[0][9] === 2 ? "DT" : "Vol. Esterno");
            break;
        case 3: //'contatto':
            parametri.supervisione = !!(arr[0][2] & 1);
            parametri.led_on = !!(arr[0][2] & 2);
            parametri.reed = !!(arr[0][2] & 4) ? 0 : 1;
            parametri.aux1 = !!(arr[0][2] & 8);
            parametri.aux2 = !!(arr[0][2] & 16);
            parametri.prog = !!(arr[0][2] & 32);
            parametri.impulsi = (arr[0][2] & 192);
            parametri.aux = 4;
            if(parametri.aux1) {
                parametri.aux = 0;
            } else {
                parametri.aux = parametri.impulsi === 0 ? 1 : (parametri.impulsi === 64 ? 2 : 3)
            }
            parametri.associazione = ((arr[0][2] >> 4) & 0x03);
            sub = !!arr[0][9] ? "Tapparella" : "Contatto";
            break;
        case 4: //'sirena':
            parametri.supervisione = !!(arr[0][2] & 1);
            parametri.led_on = !!(arr[0][2] & 2);
            parametri.allarme_a = !!(arr[0][2] & 4);
            parametri.ring = !!(arr[0][2] & 8);
            parametri.sabotaggio = !!(arr[0][2] & 16);
            parametri.tamper = !!(arr[0][2] & 32);
            parametri.tipo_suono = (arr[0][2] & 64);
            parametri.allarme_k = !!(arr[0][2] & 128);
            sub = "Sirena";
            break;
        case 5: // 'uscita':
            parametri.supervisione = !!(arr[0][2] & 1);
            parametri.led_on = !!(arr[0][2] & 2);
            parametri.abilita_impianto_attivo = !!(arr[0][2] & 4);
            parametri.abilita_ingressi = !!(arr[0][2] & 8);
            parametri.abilita_and_mode = !!(arr[0][2] & 16);
            parametri.nc = arr[0][2] & 32;
            parametri.attributi = !!(arr[0][2] & 128);
            sub = "Uscita";
            break;
        case 6: // 'tecnologico'
            parametri.supervisione = !!(arr[0][2] & 1);
            parametri.led_on = !!(arr[0][2] & 2);
            sub = !!arr[0][9] ? "Fumo" : "Allagamento";
            break;
        case 7: //'ripetitore'
            break;
        case 8: //'nebbiogeno'
            parametri.supervisione = !!(arr[0][2] & 1);
            parametri.led_on = !!(arr[0][2] & 2);    
            parametri.abilita_ingressi = !!(arr[0][2] & 8);
            parametri.and = !!(arr[0][2] & 16);
            parametri.antipanico = !!(arr[0][2] & 32);    
            sub = "Nebbiogeno";
        break;
    }
    return {
        ...generali,
        ...parametri,
        sub
    };
};

const b2jTel = arr => {
    arr[1] = hexstringToArrayInt(arr[1], true);
    return {
        ripetizioni: arr[1][0] & 15,
        sms_credito_scadenza: !!(arr[1][0] & 16),
        abilitazione: !!(arr[1][0] & 32),
        vocale_a: !!(arr[1][1] & 1),
        vocale_k: !!(arr[1][1] & 2),
        vocale_sabotaggio: !!(arr[1][1] & 4),
        vocale_silenzioso: !!(arr[1][1] & 8),
        sms_a: !!(arr[1][1] & 16),
        sms_k: !!(arr[1][1] & 32),
        sms_sabotaggio: !!(arr[1][1] & 64),
        sms_silenzioso: !!(arr[1][1] & 128),
        sms_batteria_centrale_carica: !!(arr[1][2] & 1),
        sms_batteria_radio_carica: !!(arr[1][2] & 2),
        sms_rete_elettrica_assente: !!(arr[1][2] & 4),
        sms_rete_elettrica_ripristinata: !!(arr[1][2] & 8),
        sms_variazione_programmi:!!(arr[1][2] & 16),
        sms_accesso_sistema: !!(arr[1][2] & 32),
        squillo_esistenza_vita: !!(arr[1][3] & 1),
        sms_esistenza_vita: !!(arr[1][3] & 2),
        squillo_conferma_uscite: !!(arr[1][3] & 4),
        sms_conferma_uscite: !!(arr[1][3] & 8),
        vocale_conferma_uscite: !!(arr[1][3] & 16),
        apri_cancello_na: !!(arr[1][3] & 32),
        apri_cancello_out1: !!(arr[1][3] & 64),
        impulsato: !!(arr[1][3] & 128),
        durata_impulso: arr[1][4],
        numero: arr[0].trim(),
        nome: arr[2].trim(),
    };
};

export const b2jBus = arr => {
    arr[0] = hexstringToArrayInt(arr[0], true);   
    if(arr[0][2] === 0 || arr[0][2] === 0xFF) {
        return {
            identificativo: 0,
            tipo: '',
            num_tipo_periferica: 0,
            espansione: 0,
            nome: 'Non disponibile'    
        }
    }
    return {
        identificativo: ((arr[0][1] << 8) + arr[0][0]),
        tipo: bus_periferiche[arr[0][2]] || '',
        num_tipo_periferica: arr[0][2],
        espansione: arr[0][3],
        nome: arr[1].trim()
    };
};

const b2jTempi = hexstring => {
    const arr = hexstringToArrayInt(hexstring, false);
    return {
        allarme: ((arr[0] << 8)) + (arr[1] & 0xFF), // 2 byte secondi
        fuoco: arr[2], // 1 byte secondi
        silenzioso: arr[3] / 2, // 1 byte secondi
        campanello: arr[4] / 2, // 1 byte 50ms
        elettroserratura: arr[5] / 2, // 1 byte 50ms
        ronda: ((arr[6] << 8)) + (arr[7] & 0xFF) // 2 byte secondi
    };
};

const b2jOpzioni = hexstring => {
    const arr = hexstringToArrayInt(hexstring, false);
    return {
        toni_ins: !!(arr[0] & 1),
        toni_ingr: !!(arr[0] & 2),
        led_on: !!(arr[0] & 4),
        autoreset: !!(arr[0] & 8),
        rit_no_rete: !!(arr[0] & 16),
        all_falsa_chiave: !!(arr[0] & 32),
        chiave_base: !!(arr[0] & 64),
        buzzer: !!(arr[0] & 128),
        abil_campanello: !!(arr[1] & 1),
        abil_asterisco: !!(arr[1] & 2),
        des: !!(arr[1] & 4),
        inversione: !!(arr[1] & 8),
        antimask: !!(arr[1] & 16),
        supervisione: !!(arr[1] & 32)
    };
};

const b2jSupertasti = int => ({
    supertasto1: !!(int & 2),
    supertasto2: !!(int & 4),
    supertasto3: !!(int & 8),
    supertasto4: !!(int & 16)
});

const b2jUscite = hexstring => {
    const arr = hexstringToArrayInt(hexstring, true);
    return {
        uscita0: b2jUscita(arr[1]),
        uscita1: b2jUscita(arr[2]),
        uscita2: b2jUscita(arr[3]),
        uscita3: b2jUscita(arr[4]),
        uscita4: b2jUscita(arr[0]),
    };
};

const b2jGsm = hexstring => {
    const arr = hexstringToArrayInt(hexstring, true);
    return {
        opzioni: { 
            accesso_telegestione: !!(arr[0] & 1),
            visualizzazione_chiamate: !!(arr[0] & 2), 
            on: !!(arr[0] & 4),
            gestione_credito: !!(arr[0] & 16),
            gestione_disturbo: !!(arr[0] & 32),
        },
        numero_tentativi_chiamate: arr[1],
        numero_squilli_risposta: arr[2],
        esistenza_in_vita: b2jDHM(arr[5], arr[3], arr[4]), // byte 5 (giorni) byte 3 (ore) byte 4 (minuti)
        scadenza_sim: b2jDate(bcd2int(arr[8]), bcd2int(arr[7]-1), bcd2int(arr[6])), // byte 8 (anno) byte 7 (mese) byte 6 (giorni)
        giorni_scadenza_sim: arr[9], // byte 9
        giorni_credito_minimo: arr[10], // byte 10
        credito_minimo: arr[11] // byte 11
    };
};

const b2jAutoIns = hexstring => {
    const arr = hexstringToArrayInt(hexstring, false);
    return {
        totale: {
            tempo: (bcd2int((arr[0] & 0x7F))*60) + bcd2int(arr[1]),
            abilitato: !!(arr[0] & 0x80)
        },
        g1: {
            tempo: (bcd2int((arr[2] & 0x7F))*60) + bcd2int(arr[3]),
            abilitato: !!(arr[2] & 0x80)
        },
        g2: {
            tempo: (bcd2int((arr[4] & 0x7F))*60) + bcd2int(arr[5]),
            abilitato: !!(arr[4] & 0x80)
        },
        g3: {
            tempo: (bcd2int((arr[6] & 0x7F))*60) + bcd2int(arr[7]),
            abilitato: !!(arr[6] & 0x80)
        },
    };
};

const b2jDate = (y,m,d) => new Date(2000+y, m, d);

const b2jDHM = (d, h, m) => (bcd2int(d)*24*60)+b2jHM(h,m);

const b2jHM = (h,m) => (bcd2int(h)*60)+bcd2int(m);

const b2jUscita = int => ({
    polarita: (int & 128) === 0 ? 'normale' : 'invertita', // bit 7    
    attributo: int & (~(1<<7)), // bit 6-0    
});

export function parsePhysicalMap(physicalMap) {
    const {
        model,
        rfversion,
        version,
        ora_ls,
        tempi,
        opzioni,
        suptasti : supertasti,
        uscite,
        pos_eventi,
        autoins,
        gsm,
        cicli_sup,
        intest,
        wifi_set,
        app_name,
        codici,
        filari,
        radio,
        bus,
        tel,
        msg
    } = physicalMap;

    return {
        model, // model table
        rfversion: hexstringToBCD(rfversion), //bcd
        version: hexstringToBCD(version), //bcd
        ora_ls, //1: Ora Legale con cambio manuale ora 2: Ora Solare con cambio manuale ora 130: Ora Solare con cambio automatico ora 129: Ora Legale con cambio automatico ora
        tempi: b2jTempi(tempi),
        opzioni: b2jOpzioni(opzioni),
        supertasti: b2jSupertasti(supertasti),
        uscite: b2jUscite(uscite),
        pos_eventi: (arr => ((arr[0] << 8) + arr[1]))(hexstringToArrayInt(pos_eventi), true),
        autoins: b2jAutoIns(autoins),
        gsm: b2jGsm(gsm),
        cicli_sup, // int
        intest: arrayIntToString(hexstringToArrayInt(intest)), // string
        wifi_set: { // int
            on: !!(wifi_set & 1), // bit 0
            conn: !!(wifi_set & 2) ? 'locale' : 'cloud', // bit 1
            gprs: !!(wifi_set & 4), // bit 2
            value: wifi_set
        },
        app_name, // string
        codici: codici.map(x => b2jCodice(x)), // array
        filari: filari.map(x => b2jFilare(x)), // array
        radio: radio.map(x => b2jRadio(x)).filter(x => !!x), // array
        bus: bus.map(x => b2jBus(x)), // array
        tel: tel.map(x => b2jTel(x)), // array
        msg: msg.reduce((acc, x, i) => { acc[`gr${(Math.floor(i / 2))}`][msg_etichette[i]] = x[0].trim(); return acc; }, {gr0:{},gr1:{},gr2:{}}) // array
    };
}

export const b2jGsmState = obj => {
    const stati = {
        0: "Spento",
        1: "Attivo",
        2: "Chiamata in corso",
        3: "Invio in corso"
    };
    const stati_sim = {
        1: "PIN richiesto ",
        2: "PUK richiesto",
        3: "Pronta",
        4: "Assente",
        5: "Difettosa",
        6: "Occupata",
        7: "Sconosciuta"
    }
    const stati_network = {
        1: "Non Registrato",
        2: "Registrato",
        3: "Ricerca Rete",
        4: "Registrazione negata",
        5: "Rete Sconosciuta",
        6: "Registrato in Roaming"
    }
    let versione_modulo = '-';
    switch(obj.versione_modulo) {
        case 0:
            versione_modulo = 'GSMv1';
            break;
        case 1:
            versione_modulo = 'GSMv2';
            break;
        case 2:
            versione_modulo = 'GPRS';
            break;
        case 4:
            versione_modulo = 'Non presente';
            break;
    };
    let operatore = '-';
    if(obj.mcc == 222) {
        switch(obj.mnc) {
            case 1:
                operatore = 'TIM';
                break;
            case 10:
                operatore = 'VODAFONE';
                break;
            case 88:
                operatore = 'WINDTRE';
                break;
            default:
                operatore = 'Sconosciuto (IT)';
        }
    } else {
        operatore = `Sconosciuto (${(MCC[obj.mcc] || '-')})`;
    }
    return {
        stato_gsm: stati[obj.stato_gsm] || `Assente`,
        stato_sim: stati_sim[obj.stato_sim] || stati_sim[7],
        stato_sim_codice: obj.stato_sim,
        stato_network: stati_network[obj.stato_network] || stati_network[1],
        segnale: parseInt(obj.segnale),
        credito_residuo: parseFloat(obj.credito_residuo / 100).toFixed(2),
        operatore,
        scadenza_anno: obj.scadenza_anno,
        scadenza_mese: obj.scadenza_mese,
        scadenza_giorno: obj.scadenza_giorno,
        versione_modulo,
        versione_modulo_codice: obj.versione_modulo
     };
};

export const b2jBusStat = (num, bus) => ({
    dispositivo_presente: !!bus.num_tipo_periferica ? (!!(num & 0x01)) : null,
    as: (bus.num_tipo_periferica === 7 || bus.num_tipo_periferica === 8) ? (!!(num & 0x02)) : null,
    sabotaggio: !!(num & 0x08)
});

export const b2jFilareStat = (num) => ({
    allarme_24: !!(num & 0x02),
    ingresso_aperto: !!(num & 0x01),
    ingresso_escluso: !!(num & 0x10),
    memoria_allarme: !!(num & 0x20),
    memoria_allarme_24: !!(num & 0x0800),
});

export const b2jRadioStat = (num, radio) => {
    const stat = {};
    switch(radio.num_tipo_periferica) {
        case 1: // radiocomando
            stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
            break;
        case 2: // movimento
            stat.allarme = !!(num & 0x01);
            stat.tamper = !!(num & 0x02);
            stat.supervisione_led = !!(num & 0x04);
            stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
            stat.escluso_led = !!(num & 0x10);
            stat.memoria = !!(num & 0x20);
            stat.dormiente = !!(num & 0x0800);
            stat.allarme_am = !!(num & 0x2000);
            stat.memoria_tamper = !!(num & 0x8000);
            stat.allarme_sx = !!(num & 0x0200);
            stat.allarme_dx = !!(num & 0x0400);
            stat.allarme_frontale = !!(num & 0x4000);
            break;
        case 3: 
            if(radio.num_spec_periferica === 0) { //contatto
                stat.allarme_reed = !!(num & 0x0200);
                stat.allarme_aux = !!(num & 0x0400);
                stat.tamper = !!(num & 0x02);
                stat.supervisione_led = !!(num & 0x04);
                stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
                stat.escluso_led = !!(num & 0x10);
                stat.memoria_reed = !!(num & 0x20);
                stat.dormiente = !!(num & 0x0800);
                stat.memoria_tamper = !!(num & 0x8000);
                stat.memoria_aux = false;
            } else if(radio.num_spec_periferica === 1) { // tapparella
                stat.allarme_aux = !!(num & 0x01);
                stat.supervisione_led = !!(num & 0x04);
                stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
                stat.escluso_led = !!(num & 0x10);
                stat.guasto = false;
                stat.dormiente = !!(num & 0x0800);
                stat.memoria_aux = !!(num & 0x20);
            }
            break;
        case 4: // sirena
            stat.tamper = !!(num & 0x02);
            stat.supervisione_led = !!(num & 0x04);
            stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
            stat.escluso_led = !!(num & 0x10);
            stat.guasto = !!(num & 0x1000);
            stat.dormiente = !!(num & 0x0800);
            stat.memoria_tamper = !!(num & 0x8000);
            break;
        case 5: // uscita
            stat.supervisione_led = !!(num & 0x04);
            stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
            stat.escluso_led = !!(num & 0x10);
            stat.dormiente = !!(num & 0x0800);
            stat.stato_ingresso = !!(num & 0x4000);
            stat.stato_uscita = !!(num & 0x2000);
            stat.tamper = !!(num & 0x02);
            stat.memoria_tamper = !!(num & 0x8000);
            break;
        case 6: // tecnologico
            switch(radio.num_spec_periferica) {
                case 0: // allagamento
                    stat.supervisione_led = !!(num & 0x04);
                    stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
                    stat.dormiente = !!(num & 0x0800);
                    stat.guasto = !!(num & 0x1000);
                    stat.tamper = !!(num & 0x02);
                    stat.allagamento = !!(num & 0x4000);
                    stat.memoria_tamper = !!(num & 0x8000);
                    break;
                case 1: // fumo
                    stat.supervisione_led = !!(num & 0x04);
                    stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
                    stat.dormiente = !!(num & 0x0800);
                    stat.guasto = !!(num & 0x1000);
                    stat.fumo = !!(num & 0x4000);
                    break;
            }
            break;
        case 8: // nebbiogeno
            stat.supervisione_led = !!(num & 0x04);
            stat.batteria_scarica = !!(num & 0x0100) ? true : (!!(num & 0x08) ? false : null);
            stat.escluso_led = !!(num & 0x10);
            stat.dormiente = !!(num & 0x0800);
            stat.guasto = !!(num & 0x1000);
            stat.tamper = !!(num & 0x02);
            stat.memoria_tamper = !!(num & 0x8000);
            stat.bombola_scarica = !!(num & 0x0200);
            stat.rete_assente = !!(num & 0x2000);
            stat.pronto = !!(num & 0x4000);
            break;
    }
    return stat;
};

export const b2jEvent = (type, cod1, cod2, pm) => {
    const event = {
        name: 'n.d.',
        description: ''
    };
    let programs = [];
    let origin = '';
    let label = '';
    let name = '';
    let _index = cod1 & 0x3F;
    switch(type) {
        case 0:
            !!(cod2 & 2) && programs.push("G1");
            !!(cod2 & 4) && programs.push("G2");
            !!(cod2 & 8) && programs.push("G3");
            !!(cod2 & 128) && programs.push("(r)");
            let _desc0 = "";
            name = pm.radio[_index].nome;
            switch(cod1) {
                case 0xFF:
                    _desc0 = "dal segnale esterno";
                    break;
                case 0xFE:
                    _desc0 = "dall'autoinserimento";
                    break;
                case 0xFD:
                    _desc0 = `da Tastiera Touch`;
                    break;
                default:
                    if(!!(cod1 & 0x40)) {
                        _desc0 = `dal telecomando ${name}`;
                    } else {
                        _index = cod1 & 0x1F;
                        name = pm.codici[_index].nome;
                        _desc0 = `da ${name}`;
                    }
                    break;    
            }
            event.name = "Attivazione/Disattivazione";
            event.description = `Commutazione fatta ${_desc0}. ${(programs.join(", "))}`;
            break;
        case 0x01:
        case 0x1D:
            name = cod2 === 0 ? pm.filari[_index].nome : pm.radio[_index].nome;
            event.name = "Allarme A";
            event.description = `E' avvenuto l'allarme A dell'ingresso ${(cod2 === 0 ? 'filare' : 'radio')} ${(!!(cod2 & 128) ? ' [AUX]' : '')} ${_index+1} - ${name}`;
            break;
        case 0x02:
            name = cod2 === 1 ? pm.radio[_index].nome : cod2 === 3 ? pm.bus[_index].nome : pm.filare[_index].nome;
            origin = cod2 === 1 ? 'radio' : cod2 === 3 ? 'bus' : 'filare';
            event.name = "Sabotaggio Dispositivi";
            event.description = `E' stato sabotato ingresso ${origin} ${_index+1} - ${name}`;
            break;
        case 0x03:
        case 0x07:
            event.name = "Sabotaggio Centrale";
            event.description = `E' stata sabotata la centrale`;
            break;
        case 0x04:
            event.name = "Sabotaggio Dispositivi";
            event.description = "E' stato sabotato il BUS di comunicazione"
            break;
        case 0x05:
            name = cod2 === 1 ? pm.radio[_index].nome : pm.bus[_index].nome;
            origin = cod2 === 1 ? 'radio' : 'bus';
            event.name = "Dispositivo BUS assente";
            event.description = `Il dispositivo ${origin} ${_index+1} - ${name} ha perso il collegamento`
            break;
        case 0x06:
            event.name = "Dispositivo BUS intruso";
            event.description = `sul BUS e' stato trovato un dispositivo estraneo`;
            break;
/* is commented in src/goldsoft/MemoriaEventi.java:353
        case 0x07:
            event.name = "AS Centrale";
            break;
*/
        case 0x08:
            event.name = "Chiave Falsa";
            event.description = "E' stato rilevato un tentativo di accesso con codice errato";
            break;
        case 0x09:
            event.name = "Disturbo Radio";
            event.description = "E' stato rilevato un tentativo di accecamento radio";
            break;
        case 0x0A:
            name = pm.radio[_index].nome;
            event.name = "Supervisione Radio";
            event.description = `Il dispositivo radio ${_index+1} ${name} ha perso la supervisione`;
            break;
        case 0x0B:
            event.name = "Supertasto attivato";
            event.description = `Il supertasto numero ${cod1+1} è stato azionato`;
            break;
        case 0x0C:
            event.name = "Assenza Rete 230V";
            event.description = "E' stata rilevata l'assenza della tensione di rete" ;
            break;
        case 0x0D:
            event.name = "Ripristino Rete 230V";
            event.description = "E' stato rilevato uil ritorno della tensione di rete";
            break;
        case 0x0E:
            event.name = "Fusibile guasto";
            event.description = "E' stato rilevato il guasto del fusibile";
            break;
        case 0x0F:
            event.name = "Fusibile ripristinato";
            event.description = "E' stato rilevato il ripristino del fusibile";
            break;
        case 0x10:
            name = pm.radio[_index].nome;
            event.name = "Batteria RF KO";
            event.description = `La batteria del dispositivo radio ${_index+1} - ${name} si è scaricata`;
            break;
        case 0x11:
            name = pm.radio[_index].nome;
            event.name = "Batteria RF OK";
            event.description = `La batteria del dispositivo radio ${_index+1} - ${name} è stata ripristinata`;
            break;
        case 0x12:
            event.name = "Batteria interna KO";
            event.description = `La batteria della centrale si è scaricata`;
            break;
        case 0x13:
            event.name = "Batteria interna OK";
            event.description = `La batteria della centrale è stata ricaricata`;
            break;
        case 0x14:
            event.name = "Batteria esterna KO";
            event.description = `La batteria del dispositivo esterno si è scaricata`;
            break;
        case 0x15:
            event.name = "Batteria esterna OK";
            event.description = `La batteria del dispositivo esterno è stata ripristinata`;
            break;
        case 0x16:
            label = cod2 === 1 ? 'radio' : 'codice';
            name = cod2 === 1 ? pm.radio[_index].nome : pm.codici[_index].nome;
            event.name = "Passaggio Ronda";
            event.description = `E' stato attivato il passaggio ronda da ${label} ${_index+1} - ${name} `;
            break;
        case 0x17:
            label = cod2 === 1 ? 'radio' : cod2 === 2 ? 'codice' : 'filare';
            name = cod2 === 1 ? pm.radio[_index].nome : cod2 === 2 ? pm.codici[_index].nome : pm.filari[_index].nome;
            event.name = "Attivazione elettroserratura";
            event.description = `E' stato attivato l'elettroserratura da ${label} ${_index+1} - ${name} `;
            break;
        case 0x18:
            label = cod2 === 1 ? 'radio' : cod2 === 2 ? 'codice' : 'filare';
            name = cod2 === 1 ? pm.radio[_index].nome : cod2 === 2 ? pm.codici[_index].nome : pm.filari[_index].nome;
            event.name = "Allarme antipanico";
            event.description = `E' stato attivato l'antipanico da ${label} ${_index+1} - ${name} `;
            break;
        case 0x19:
            origin = !!(cod2 & 1) ? 'radio' :'filare';
            name = cod2 === 1 ? pm.radio[_index].nome : pm.filari[_index].nome;
            event.name = "Allarme fuoco";
            event.description = `Allarme fuoco generato da ${origin} ${_index+1} - ${name}`;
            break;
        case 0x1A:
            origin = !!(cod2 & 1) ? 'radio' : 'filare';
            name = cod2 === 1 ? pm.radio[_index].nome : pm.filari[_index].nome;
            event.name = "Attivazione campanello";
            event.description = `E' scattato il campanello azionato da ${origin} ${_index+1} - ${name}`;
            break;
        case 0x1B:
            event.name = "Batteria interna guasta";
            event.description = `La batteria della centrale si è guastata`;
            break;
        case 0x1C:
            event.name = "Segnale AS espansione";
            event.description = `L'espansione degli ingressi filari è stata sabotata`;
            break;
/* is commented in src/goldsoft/MemoriaEventi.java:530
        case 0x1D:
            origin = !!(cod2 & 1) ? 'radio' : (!!(cod2 & 128) ? 'zona ausiliaria' : 'filare');
            event.name = "Allarme K";
            event.description = `Numero ingresso: ${cod1}, Provenienza: ${origin}`;
            break;
*/
        case 0x1E:
            event.name = "Esistenza in vita";
            event.description = `Il combinatore GSM ha generato l'esistenza in vita`;
            break;
        case 0x1F:
            event.name = "Azionata uscita N/A";
            event.description = `L'uscita +N +A è stata attivata`;
            break;
        case 0x20:
            event.name = "Azionata uscita OUT";
            event.description = `L'uscita OUT è stata attivata`;
            break;        
        case 0x21:
            origin = !!(cod2 & 1) ? 'radio' : !!(cod2 & 2) ? 'codice' : 'filare';
            name = cod2 === 1 ? pm.radio[_index].nome : !!(cod2 & 2) ? pm.codici[_index].nome : pm.filari[_index].nome;
            event.name = "Allarme silenzioso";
            event.description = `E' stato attivato l'allarme silenzioso da ${origin} ${_index+1} - ${name}`;
            break;
        case 0x22:
            event.name = "Credito minimo GSM raggiunto";
            event.description = `Il combinatore GSM ha superato la soglia del credito minimo. Credito residuo: ${((cod1 << 8 & 0xFF00) + (cod2 & 0xFF))} euro`;
            break;
        case 0x23:
            event.name = "Scadenza SIM GSM";
            event.description = `Il combinatore GSM ha la SIM in scadenza`;
            break;    
        case 0x24:
            event.name = "Chiamata test GSM";
            event.description = `Il combinatore GSM ha generato l'esistenza in vita di test`;
            break;
        case 0x25:
            event.name = "Attivazione nebbiogeno";
            break;
        case 0x26:
            event.name = "Riavvio sistema";
            break;
        case 0x27:
            event.name = "Sabotaggio WiFi";
            break;
        case 0x28:
            name = pm.radio[_index].nome;
            event.name = `Nebbia non pronta`;
            event.description = `dispositivo radio ${_index+1} - ${name}`;
            break;
        case 0x29:
            name = pm.radio[_index].nome;
            event.name = `Controllare batteria radiocomando`;
            event.name = `dispositivo radio ${_index+1} - ${name}`;
            break;
        case 0x2A:
            event.name = "Evento Allagamento";
            break;
        case 0x2B:
            event.name = "Fine Allagamento";
            break;
        case 0x2C:
            name = pm.radio[_index].nome;
            event.name = "Test Fumo";
            event.description = `dispositivo radio ${_index+1} ${name}`;
            break;
        case 0x2D:
            name = cod2 === 1 ? pm.radio[_index].nome : pm.filari[_index].nome;
            origin = !!(cod2 & 1) ? 'radio' : 'filare';
            event.name = "Test Allarme";
            event.description = `Evento di test ${origin} ${_index+1} - ${name}`;
            break;
        case 0x2E:
        case 0x2F:
        case 0x30:
            event.name = "Test GSM";
            event.description = "Il combinatore GSM ha generato l'esistenza in vita di test";
            break;
        case 0x31:
            event.name = "Squillo GSM";
            event.description = `Il combinatore GSM ha chiamato il numero ${pm.tel[_index].numero}`
            break;
        case 0x32:
            event.name = "SMS GSM";
            event.description = `Il combinatore GSM ha inviato un SMS al numero ${pm.tel[_index].numero}`
            break;
        case 0x33:
            event.name = "Chiamata GSM";
            event.description = `Il combinatore GSM ha effettuato una chiamata vocale al numero ${pm.tel[_index].numero}`
            break;
        case 0x34:
            let _index2 = ((cod2 & 0xFC) >> 2);
            name = pm.radio[_index].nome;
            event.name = "Guasto Dispositivo RF";
            const sirena_eventi = [
                "Batterie al piombo bassa",
                "Alimentazione supplementare WIN bassa",
                "Altoparlante guasto",
                "Barriera IR antischiuma guasta",
                "LED guasto"
            ];
            let tipo_evento = "Codice: "+ _index2;
            if(pm.radio[_index].num_tipo_periferica === 4) {
                tipo_evento = sirena_eventi[_index2] || "";
            }
            event.description = `La periferica radio ${_index+1} - ${name} ha segnalato il guasto ${tipo_evento}`;
            break;
        case 0x35:
            name = pm.radio[_index].nome;
            event.name = "Mascheramento dispositivo RF";
            event.description = `La periferica radio ${_index+1} - ${name} ha segnalato il mascheramento`;
            break;                                
        case 0x36:
            name = pm.radio[_index].nome;
            event.name = "Allarme DES";
            event.description = `La periferica radio ${_index+1} - ${name} ha segnalato l'allarme storico`;
            break;
        case 0x37:
            name = pm.radio[_index].nome;
            event.name = "Sabotaggio antischiuma";
            event.description = `La periferica radio ${_index+1} - ${name} ha segnalato l'antischiuma`;
            break;
        case 0x38:
            name = pm.radio[_index].nome;
            event.name = "Sabotaggio antifiamma";
            event.description = `La periferica radio ${_index+1} - ${name} ha segnalato l'antifiamma`;
            break;
        case 0x39:
            let _index39 = cod1 & 0x1F;
            name = pm.codici[_index39].nome;
            event.name = "Ingresso sistema";
            event.description = `E' stato effettuato l'accesso al sistema con il codice assegnato a: ${_index39+1} - ${name}`;
            break;                                
        case 0x3A:
            let _index3A = cod1 & 0x1F;
            name = pm.codici[_index3A].nome;
            event.name = "Cancellazione eventi";
            event.description = `E' stato cancellata la memoria eventi con il codice assegnato a: ${_index3A+1} - ${name}`;
            break;
        case 0x3B:
            let _index3B = cod1 & 0x1F;
            name = pm.codici[_index3B].nome;
            event.name = "Ripristino sistema";
            event.description = `E' stata inizializzata la centrale con il codice assegnato a: ${_index3B+1} - ${name}`;
            break;
        case 0xF0:
            event.name = "Connessione al Cloud";
            event.description = `${(!!cod2 ? 'Disconnesso' : 'Connesso')}`;
            break;
        case 0xFF:
            break;
        default:
            event.name = "Sconosciuto";
            event.description = `E' avvenuto un evento sconosciuto`;
    }
    return event;
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    errorRead,
    getProfile,
    login
} from '../../../actions';
import Error from '../../elements/Error';
import SchemaValidator from '../../../lib/SchemaValidator';


const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    getProfile: () => dispatch(getProfile()),
    login: payload => dispatch(login(payload))
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const _Login = ({ data, errorRead, login, stateError }) => {
    const initialPayload = {
        email: "",
        password: "",
    };

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const intl = useIntl();

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const isDisabled = () => !(payload.email.length > 0 && payload.password.length > 0);

    const handleLogin = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload, 'login');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => el.schema.message).join("\n"));
        } else {
            login(payload);
        }
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        getProfile();
    }

    return (
        <section>
            <div>
                <img className="mainLogo" src="/static/images/testalince.png" alt="Lince" title="Lince Clouding" />
            </div>
            <div>
                <form name="login">
                    <fieldset>
                        <p>
                            <input type="text" placeholder={intl.formatMessage({ id: "App.info.Email" })} name="email" onChange={handleChange} />
                        </p>
                        <p>
                            <input type="password" placeholder={intl.formatMessage({ id: "App.info.Password" })} name="password" onChange={handleChange} />
                        </p>
                        <Error text={errors} />
                        <p>
                            <button disabled={isDisabled()} className="ok" onClick={handleLogin}>
                                <FormattedMessage id="App.info.Login" />
                            </button>
                        </p>
                        <p className="sameLine">
                            <small>
                                <span>
                                    <Link to={'/password-recovery'}>
                                        <FormattedMessage id="App.info.RecuperaPassword" />
                                    </Link>
                                </span>
                                <span>
                                    <Link to={'/signup'}>
                                        <FormattedMessage id="App.info.Register" />
                                    </Link>
                                </span>
                            </small>
                        </p>
                        <p style={{ textAlign: 'left', clear: 'both', marginTop: '3em' }}>
                            <small>
                                 <span>
                                    <Link style={{ color: 'red' }} to={'/elimina-account'}> <FormattedMessage id="App.info.EliminaAccount" /></Link>
                                 </span>
                             </small>
                         </p>
                    </fieldset>
                </form>
            </div>
        </section>
    );
};

const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);

export default Login;
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import './Modal.scss';

const mapStateToProps = state => ({
    system: state.system,
});

const _Modal = ({ title, text = ``, subtext = ``, onClose, onConfirm = null, redirect = null, syncing = null, system, redirectText = null }) => {

    const intl = useIntl();

    const [progress, total] = (!!system && !!system.store)
        ? (!!system.store.sync_cloud_euronet && system.store.sync_cloud_euronet != 0)
            ? system.store.sync_cloud_euronet.split(',')
            : !!system.store.state && !!system.store.sync_progress
                ? [100 - (parseInt(system.store.sync_progress)), 100]
                : [100, 100]
        : [0, 0]
        ;
    const progressW = total > 0
        ? (100 / parseInt(total)) * (parseInt(total) - parseInt(progress))
        : 100
        ;

    return (
        <div className="dialog">
            <div className="content">
                <h3>{title}</h3>
                <form>
                    <fieldset>
                        <p>{text}</p>
                        {!!subtext && <p>{subtext}</p>}
                        {!!onConfirm && (
                            <p>
                                <button className="ok" onClick={onConfirm}>
                                    <FormattedMessage id="App.info.Ok" />
                                </button>
                                <br />
                            </p>
                        )}
                        {!!syncing && (
                            <>
                                <div className="bar">
                                    <div className="progress" style={{ width: `${progressW}%` }} />
                                </div>
                            </>
                        )}
                        <p>
                            {redirect
                                ?
                                (
                                    <Link onClick={onClose} to={redirect}><button className="ok">{(!!redirectText ? redirectText : intl.formatMessage({ id: 'App.info.Chiudi' }))}</button></Link>
                                )
                                : (!!onClose
                                    ? <button className="ok" onClick={onClose}>{!!onConfirm ? intl.formatMessage({ id: 'App.info.Annulla' }) : (!!redirectText ? redirectText : intl.formatMessage({ id: 'App.info.Chiudi' }))}</button>
                                    : <></>
                                )
                            }
                        </p>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

const Modal = connect(mapStateToProps)(_Modal);

export default Modal;

import React, { useState } from 'react';
import FileBase64 from 'react-file-base64';

import Geocoder from '../../elements/Geocoder';
import Todo from '../../elements/Forms/Todo';
import { FormattedMessage, useIntl } from 'react-intl';

import { states } from '../../../constants';

const System = (
    {
        system,
        handleChange,
        handleSubmit,
        saveButton = '',
        cancelButton = null,
        handleCancel,
        isAddressOfOwner = false,
        ownerGeodata = null,
        lockMAC = false,
        handlerGeodata = null,
        address = ``,
        permissions,
        models,
        modelRequired,
        modelShow = null
    }
) => {

    const intl = useIntl();
    if (saveButton == '') {
        intl.formatMessage({ id: "App.info.Salva" });
    }

    const [addressOfOwner, setAddressOfOwner] = useState(isAddressOfOwner);
    const [photoError, setPhotError] = useState(null);
    const maintainer_pic = permissions === 1
        ? system.maintainer_pic
            ? `${process.env.REACT_APP_API_URL}/download/${system.maintainer_pic}`
            : null
        : null
        ;
    const pic = maintainer_pic || (system.pic ? `${process.env.REACT_APP_API_URL}/download/${system.pic}` : null);
    const [photoURL, setPhotoURL] = useState(pic);
    const [hasremovedPic, setHasRemovedPic] = useState(false);

    const handleAddressOfOwner = () => {
        setAddressOfOwner(!addressOfOwner);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (addressOfOwner) {
            handleChange({
                target: { type: 'input', name: 'geodata', value: ownerGeodata }
            });
        }
        if (photoURL && photoURL != pic) {
            const base64 = (photoURL.split(','))[1];
            handleChange({
                target: { type: 'input', name: 'pic', value: base64 }
            });
        } else if (photoURL == null) {
            handleChange({
                target: { type: 'input', name: 'pic', value: null }
            });
            if (hasremovedPic) {
                handleChange({
                    target: { type: 'input', name: 'hasremovedpic', value: 1 }
                });
            }
        }
        handleSubmit(e);
    }

    return (
        <div className="formSection">
            <form>
                <fieldset>
                    <h3>
                        <FormattedMessage id="App.info.Dati" />
                    </h3>
                    <div className="rack">
                        {!lockMAC &&
                            <div className="w33">
                                <label>
                                    <FormattedMessage id="App.info.IdUnivoco*" />
                                </label>
                                <input type="text" name="password" defaultValue={``} onChange={handleChange} />
                                <small>
                                    <FormattedMessage id="App.info.InsertCode" />
                                </small>
                            </div>
                        }
                        <div className="w33">
                            <label>
                                <FormattedMessage id="App.info.NomeImpianto*" />
                            </label>
                            <input type="text" name="name" defaultValue={system.name || ``} onChange={handleChange} disabled={(!(permissions & 2))} maxLength={30} />
                        </div>
                        {modelRequired && (
                            <div className="w33">
                                <label>
                                    <FormattedMessage id="App.info.ModelloImpianto*" />
                                </label>
                                <select defaultValue={system.model || ""} name="model" onChange={handleChange} disabled={(!(permissions & 1))}>
                                    <option value="" disabled></option>
                                    {models.map((value, index) => <option key={index} value={value.id}>{value.code} {value.name}</option>)}
                                </select>
                            </div>
                        )}
                        {!!modelShow && (
                            <div className="w33">
                                <label>
                                    <FormattedMessage id="App.info.ModelloImpianto" />
                                </label>
                                <p>{modelShow.code} {modelShow.name}</p>
                            </div>
                        )}
                    </div>
                    {handlerGeodata && (
                        <>
                            <h3>
                                <FormattedMessage id="App.info.Indirizzo" />
                            </h3>
                            <div className="rack">
                                {ownerGeodata && (
                                    <div className="w100">
                                        <input type="checkbox" id="address_of_owner" name="address_of_owner" defaultValue="0" defaultChecked={0} onChange={handleAddressOfOwner} disabled={(!(permissions & 1))} />
                                        <label htmlFor={`address_of_owner`}>
                                            <span />
                                            <FormattedMessage id="App.info.AddressOwner" />
                                        </label>
                                    </div>
                                )}
                                {!addressOfOwner && (
                                    <div className="w100">
                                        <Geocoder
                                            handlerGeodata={handlerGeodata}
                                            initialAddress={address}
                                            disabled={(!(permissions & 2))}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {((permissions & 2) > 0) && (
                        <>
                            <h3>
                                <FormattedMessage id="App.info.AddressOwner" />
                            </h3>
                            <div className="rack">
                                <div className="w50 switchButton">
                                    <input type="checkbox" className="switch" id="options" name="options" defaultValue="1" defaultChecked={((system.options & 1) > 0)} onChange={handleChange} />
                                    <label htmlFor={`options`}>
                                        <span>
                                            <span />
                                        </span>
                                        <FormattedMessage id="App.info.AccessoAutomaticoManutentore" />
                                    </label>
                                    <p>
                                        <small><em>
                                            <FormattedMessage id="App.info.AccessoManutentoreAutorizzato" />
                                        </em></small>
                                    </p>
                                </div>
                                {lockMAC &&
                                    <div className="w50 switchButton">
                                        <input type="checkbox" className="switch" id="also_maintainer" name="also_maintainer" defaultValue="1" defaultChecked={((permissions & 1) > 0)} onChange={handleChange} />
                                        <label htmlFor={`also_maintainer`}>
                                            <span>
                                                <span />
                                            </span>
                                            <FormattedMessage id="App.info.DiventaManutentore" />
                                        </label>
                                    </div>
                                }
                            </div>
                        </>
                    )}
                    <h3>
                        <FormattedMessage id="App.info.InfoAggiuntive" />
                    </h3>
                    <div className="rack">
                        <div className="w50">
                            <label>
                                <FormattedMessage id="App.info.ComposizioneImpianto" />

                            </label>
                            <textarea rows="5" name="description" defaultValue={system.description || ``} onChange={handleChange} disabled={(!(permissions & 1))} />
                        </div>
                        <div className="w50">
                            <label>
                                <FormattedMessage id="App.info.Note" />
                            </label>
                            <textarea rows="5" name="notes" defaultValue={system.notes || ``} onChange={handleChange} disabled={(!(permissions & 1))} />
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w50">
                            <Todo list={system.todo} handleChange={handleChange} disabled={(!(permissions & 1))} />
                        </div>
                        <div className="w50">
                            <label>
                                <FormattedMessage id="App.info.StatoImpianto" />
                            </label>
                            <div className="rack">
                                {
                                    states.map((value, index) => {
                                        return (
                                            <div className="w50" key={index}>
                                                <input id={`opt_${index}`} type="radio" name="state" value={value} onChange={handleChange} defaultChecked={system.state === value} disabled={(!(permissions & 1))} />
                                                <label className="inline" htmlFor={`opt_${index}`}>
                                                    <span />
                                                    {value}
                                                </label>
                                            </div>
                                        );
                                    })
                                }
                                {(!(permissions & 1)) &&
                                    <p>
                                        <small><em>
                                            <FormattedMessage id="App.info.SoloManutentore" />
                                        </em></small>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="rack">
                        <h3>
                            <FormattedMessage id="App.info.UploadPhoto" />
                        </h3>
                        <div className="w75">
                            {(permissions & 3)
                                ? <>
                                    <label>
                                        <FormattedMessage id="App.info.File" />
                                    </label>
                                    <FileBase64 onDone={(img) => {
                                        if (img.type != "image/jpeg") {
                                            setPhotError(
                                                intl.formatMessage({ id: 'App.info.FormatoNonValido' })
                                            );
                                        } else if (parseInt(img.size) > 10240) {
                                            setPhotError(
                                                intl.formatMessage({ id: 'App.info.ImmagineTroppoPesante' })
                                            );
                                        } else {
                                            setPhotError(null);
                                            setPhotoURL(img.base64);
                                        }
                                    }} />
                                    <br /><small>
                                        <FormattedMessage id="App.info.LimiteJpeg" />
                                    </small>
                                </>
                                : <p>
                                    <FormattedMessage id="App.info.NoPermessi" />
                                </p>
                            }
                        </div>
                        <div className="w25">
                            {photoError &&
                                <p style={{ color: 'red' }}><em>{photoError}</em></p>
                            }
                            {!!photoURL
                                ?
                                (
                                    <>
                                        <img className="preview" src={photoURL} alt="preview" />
                                        <p>
                                            <button className="ok auto" onClick={e => { e.preventDefault(); setPhotoURL(null); if (!!pic) { setHasRemovedPic(true); } }}>Elimina</button>
                                        </p>
                                    </>
                                )
                                :
                                (
                                    <p><em>
                                        <FormattedMessage id="App.info.NoUploadedPhoto" />
                                    </em></p>
                                )
                            }
                        </div>
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            {
                                cancelButton && (<button className="ok auto" onClick={handleCancel}>{cancelButton}</button>)
                            }
                        </div>
                        <div className="w50 right">
                            {
                                saveButton && (
                                    <>
                                        <p><small>
                                            <FormattedMessage id="App.info.CampiObbligatori" />
                                        </small></p>
                                        <button className="ok auto" onClick={e => onSubmit(e)}>{saveButton}</button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default System;
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { useIntl } from 'react-intl';

import './Geocoder.scss';

const Geocoder = ({ handlerGeodata, initialAddress = ``, disabled = false }) => {

    const [address, setAddress] = useState(initialAddress);
    const intl = useIntl();

    const handleChange = str => {
        setAddress(str)
    };

    const onError = (status, clearSuggestions) => {
        console.log('Google Maps API returned error with status: ', status)
        clearSuggestions()
    }

    const handleSelect = async _address => {
        const results = await geocodeByAddress(_address);
        setAddress(_address)
        handlerGeodata(results);
    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            onError={onError}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: intl.formatMessage({ id: 'App.info.InserireIndirizzo' }),
                            className: intl.formatMessage({ id: 'App.info.LocationSearchInput' }),
                        })}
                        disabled={disabled}
                    />
                    <div className="autocomplete-dropdown-container">
                        {loading && <div>
                            {intl.useIntl({ id: 'App.info.LocationSearchInput' })}
                        </div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item suggestion-item-active'
                                : 'suggestion-item';
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
}

export default Geocoder;
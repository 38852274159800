import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import get_bits from '../../../../lib/bytesFunctions';
import {
    doSocketRequest,
} from '../../../../actions';
import Modal from '../../../elements/Modal';
import { FormattedMessage, useIntl } from 'react-intl';

registerLocale('it', it)

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess)),
});

const _AttivazioniNonPeriodiche = ({ system, socket, doSocketVariation, data_socket }) => {
    const codeForm = useRef(null);
    const trama = 0x00E8;
    const [firstLoad, setFirstLoad] = useState(true);
    const [current, setCurrent] = useState(null);
    const [processed, setProcessed] = useState(false);
    const [selectedDates, setSelectedDates] = useState([null, null, null, null]);
    const status = !!system && !!socket ? system.store.status : null;
    const programs = ['G1', 'G2', 'G3', 'GExt'];
    const programsColor = ['red', '#ffc20a', 'green', 'blue'];
    const intervals = [0, 6, 12, 18];
    const maxDate = new Date(2064, 11, 31, 23, 59, 0);
    const minDate = new Date(2000, 0, 1, 0, 0, 0);

    const intl = useIntl();

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true);
                    setProcessed(true);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, trama, current, doSocketVariation, processed, setProcessed, socket]
    );

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(byte => parseInt(byte));
            let _current = {
                trama,
                payload: _x
            };
            setCurrent(_current);
            const a = [];
            intervals.map(byte => {
                let d = new Date();
                d.setHours(_x[byte]);
                d.setMinutes(_x[byte + 1]);
                d.setDate(_x[byte + 2] || 1);
                d.setMonth((_x[byte + 3] > 0 ? _x[byte + 3] - 1 : 0));
                d.setFullYear((_x[byte + 4]) + 2000);
                a.push(d);
                return;
            });
            setSelectedDates(a);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                text={intl.formatMessage({ id: 'App.info.ImpiantoNonConnesso' })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox != 3) {
                return (
                    <Modal
                        title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                        text={intl.formatMessage({ id: 'App.info.NecessariPermessiInstallatore' })}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const selectDate = (date, index) => {
        let a = [...selectedDates];
        a[index] = date;
        setSelectedDates(a);
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = [];
        intervals.map((byte, i) => {
            payload.push(selectedDates[i].getHours());
            payload.push(selectedDates[i].getMinutes());
            payload.push(selectedDates[i].getDate());
            payload.push(selectedDates[i].getMonth() + 1);
            payload.push(selectedDates[i].getFullYear() - 2000);
            var _programs = 0;
            programs.map((program, index) => {
                _programs += (form[`program_${i}_${index}`].checked ? Math.pow(2, index) : 0);
                return null;
            });
            _programs += (form[`enabled_${i}`].checked ? 128 : 0);
            payload.push(_programs);
            return null;
        });
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        doSocketVariation(socket, current.trama, payload);
    }

    if (!!!current || firstLoad) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            {current && (
                                <>
                                    <h1>
                                        <FormattedMessage id="App.info.AttivazioneNonPeriodica" />
                                    </h1>
                                    <fieldset className="outputsRack">
                                        {intervals.map((byte, i) => {
                                            return (
                                                <div className="rack borderBottom" key={`rack_${byte}`}>
                                                    <div className="w40">
                                                        <h4>
                                                            <FormattedMessage id="App.info.Data" />
                                                        </h4>
                                                        <DatePicker
                                                            onChange={e => { selectDate(e, i); }}
                                                            selected={selectedDates[i]}
                                                            showTimeSelect
                                                            timeIntervals={1}
                                                            timeCaption={intl.formatMessage({ id: 'App.info.Orario' })}
                                                            dateFormat={intl.formatMessage({ id: 'App.info.FormatoData' })}
                                                            locale="it"
                                                            maxDate={maxDate}
                                                            minDate={minDate}
                                                            monthsShown={2}
                                                            showYearDropdown
                                                            todayButton={intl.formatMessage({ id: 'App.info.SelezionaOggi' })}
                                                        />
                                                    </div>
                                                    <div className="w60">
                                                        <div className="rack">
                                                            <div className="w25" style={{ padding: "0" }}>
                                                                &nbsp;
                                                            </div>
                                                            <div className="w50 checkBoxInline" style={{ padding: "0" }}>
                                                                <br />
                                                                {
                                                                    programs.map((program, index) => (
                                                                        <p key={`program_${i}_${index}`}>
                                                                            <input type="checkbox" id={`program_${i}_${index}`} name={`program_${i}_${index}`} defaultValue="0" defaultChecked={get_bits(current.payload[byte + 5], index)} onChange={() => null} />
                                                                            <label htmlFor={`program_${i}_${index}`}>
                                                                                <strong className={`programLabel ${programsColor[index]}`}>{program}</strong>
                                                                                <br />
                                                                                <br />
                                                                                <span />
                                                                            </label>
                                                                        </p>

                                                                    ))
                                                                }
                                                            </div>
                                                            <div className="w25">
                                                                <br />
                                                                <div className="switchButton">
                                                                    <input type="checkbox" className="switch" id={`enabled_${i}`} name={`enabled_${i}`} defaultValue="0" defaultChecked={get_bits(current.payload[byte + 5], 7)} onChange={() => null} />
                                                                    <label htmlFor={`enabled_${i}`}>
                                                                        <span style={{ marginBottom: "1em" }}>
                                                                            <span />
                                                                        </span>
                                                                        <FormattedMessage id="App.info.Abilitato" />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack" style={{ marginTop: "4em" }}>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setFirstLoad(true); }}>
                                                    <FormattedMessage id="App.info.Annulla" />
                                                </button>
                                                <button className="ok auto spaced" onClick={handleSubmit}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const AttivazioniNonPeriodiche = connect(mapStateToProps, mapDispatchToProps)(_AttivazioniNonPeriodiche);

export default AttivazioniNonPeriodiche;
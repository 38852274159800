import React from 'react';
import ReactSlider from 'react-slider';
import { FormattedMessage } from 'react-intl';

const Sirena = ({ current, auth, setTempoIngresso }) => {
    return (
        <>
            <div className="rack bottomBorder">
                <div className="w25">
                    <p>
                        <FormattedMessage id="App.info.Volume" />
                    </p>
                    <ReactSlider
                        defaultValue={current.tempo_ingresso}
                        onChange={value => { setTempoIngresso(value); }}
                        disabled={auth !== 2}
                        max={100}
                        min={25}
                        step={5}
                        className="horizontal-slider"
                        thumbClassName="slider-thumb"
                        trackClassName="slider-track"
                        renderThumb={(props, state) => {
                            return (<div {...props}>{`${state.valueNow}%`}</div>);
                        }}
                    />
                </div>
                <div className="w25">
                    <label><FormattedMessage id="App.info.TipoSuono" /></label>
                    <select defaultValue={current.tipo_suono} name="tipo_suono" disabled={auth !== 2}>
                        <option value={0}>B</option>
                        <option value={0x40}>A</option>
                    </select>
                </div>
            </div>
            <div className="rack bottomBorder">
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Supervisione" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.LedOn" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="a" name="allarme_a" defaultValue="0" defaultChecked={current.allarme_a} disabled={auth !== 2} />
                        <label htmlFor={`a`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.A" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="k" name="allarme_k" defaultValue="0" defaultChecked={current.allarme_k} disabled={auth !== 2} />
                        <label htmlFor={`k`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.K" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="tamper_locale" name="tamper" defaultValue="0" defaultChecked={current.tamper} disabled={auth !== 2} />
                        <label htmlFor={`tamper_locale`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.TamperLocale" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="funzioni_avanzate" name="sabotaggio" defaultValue="0" defaultChecked={current.sabotaggio} disabled={auth !== 2} />
                        <label htmlFor={`funzioni_avanzate`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.FunzioniAvanzate" />
                        </label>
                    </div>
                </div>
                <div className="w25">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="beep" name="ring" defaultValue="0" defaultChecked={current.ring} disabled={auth !== 2} />
                        <label htmlFor={`beep`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Beep" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sirena;
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Modal from '../../../elements/Modal';
import Outcome from '../../../elements/Outcome';
import { j2bCodice } from '../../../../lib/goldParser/converter';
import { clone } from '../../../../lib/clone';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldCheck: payload => dispatch(goldDoRequest('goldCheckSync', payload)),
    goldWriteCode: payload => dispatch(goldDoRequest('goldWriteCode', payload)),
    setStore: data => dispatch(setStore(data))
});

const _Codice = ({ data, errors, system, goldCheck, goldWriteCode, setStore }) => {
    const codeForm = useRef(null);
    const _system = clone(system);
    const [firstLoad, setFirstLoad] = useState(true);
    const [redir, setRedir] = useState(null);
    const [processed, setProcessed] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [available, setAvailable] = useState(null);
    const [codeAuth, setCodeAuth] = useState(1);
    const [current, setCurrent] = useState(null);
    const intl = useIntl();

    useEffect(
        () => {
            if (firstLoad) {
                goldCheck({ id_centrale: system.id_centrale });
                let found = null;
                for (let i = 0; i < system.store.pm.codici.length; i++) {
                    if (system.store.pm.codici[i].available) {
                        found = i;
                        break;
                    }
                }
                if (found != null) {
                    setAvailable(found);
                } else {
                    setAvailable(false);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad]
    );

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 4) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        const payload = {
            codice: form.codice.value,
            tipo_codice: 0,
            nome: form.nome.value,
            g1: form.g1.checked,
            g2: form.g2.checked,
            g3: form.g3.checked,
            elettroserratura: form.elettroserratura.checked,
            ronda: form.ronda.checked,
            silenzioso: form.silenzioso.checked,
            antipanico: form.antipanico.checked,
            tipo_utente: parseInt(form.tipo_utente.value)
        };
        if (codeAuth === 2) {
            payload.elettroserratura = false;
            payload.antipanico = false;
            payload.ronda = false;
            payload.silenzioso = false;
            payload.g1 = false;
            payload.g2 = false;
            payload.g3 = false;
        }
        return payload;
    };

    const handleSubmit = e => {
        e.preventDefault();
        const form = codeForm.current;
        if (!!!form["nome"].value) {
            setError(intl.formatMessage({ id: "App.info.InserireUnNome" }));
        } else if (!!!form["codice"].value) {
            setError(intl.formatMessage({ id: "App.info.InserireUnCodice" }));
        } else if (form["codice"].value != form["codice2"].value) {
            setError(intl.formatMessage({ id: "App.info.LaConfermaDelCodiceNonCorrisponde" }));
        } else {
            const payload = makePayload();
            const code = payload.codice;
            const _current = j2bCodice(payload, code);
            goldWriteCode({
                id_centrale: system.id_centrale,
                idx: available,
                edata: _current,
                code: code
            });
            setCurrent(_current);
            setProcessed(true);
        }
    }

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    if (data && processed) {
        setProcessed(null);
        const _success = !!data && !errors
        if (_success) {
            let pm = clone(_system.store.pm);
            pm.codici[available] = { ...pm.codici[available], ...current };
            setStore({
                pm
            });
        }
        setSuccess(_success);

    }

    if (available === null) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    } else if (available === false) {
        return <>
            <FormattedMessage id="App.info.SlotNonDisponibili" />
        </>;
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}/commands`); }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <>
                                <h1>
                                    <FormattedMessage id="App.info.NuovoCodice" />
                                </h1>
                                {
                                    error && (
                                        <Outcome text={error} type="ko" onClose={setError} />
                                    )
                                }
                                <fieldset>
                                    <div className="rack">
                                        <div className="w25">
                                            <label>
                                                <FormattedMessage id="App.info.Codice" />
                                            </label>
                                            <input
                                                type="password"
                                                onKeyPress={e => {
                                                    if (e.which < 48 || e.which > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                maxLength={6}
                                                autoComplete={`off`}
                                                placeholder={intl.formatMessage({ id: "App.info.InserisciCodice" })}
                                                name="codice"
                                                defaultValue={``}
                                            />
                                        </div>
                                        <div className="w25">
                                            <label>
                                                <FormattedMessage id="App.info.RipetiCodice" />
                                            </label>
                                            <input
                                                type="password"
                                                onKeyPress={e => {
                                                    if (e.which < 48 || e.which > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                maxLength={6}
                                                autoComplete={`off`}
                                                placeholder=""
                                                name="codice2"
                                                defaultValue={``}
                                            />
                                        </div>
                                        <div className="w25">
                                            <label>
                                                <FormattedMessage id="App.info.Nome" />
                                            </label>
                                            <input type="text" name="nome" defaultValue={``} maxLength={16} />
                                        </div>
                                        <div className="w25">
                                            <label>
                                                <FormattedMessage id="App.info.Privilegi" />
                                            </label>
                                            <select defaultValue={``} name="tipo_utente" onChange={e => { setCodeAuth(parseInt(e.target.value)) }}>
                                                <option value={1}>
                                                    {intl.formatMessage({ id: "App.info.Utente" })}
                                                </option>
                                                <option value={2}>
                                                    {intl.formatMessage({ id: "App.info.Installatore" })}
                                                </option>
                                                <option value={4}>
                                                    {intl.formatMessage({ id: "App.info.Amministratore" })}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="w25">
                                            <label>
                                                <FormattedMessage id="App.info.Tipologia" />
                                            </label>
                                            <select defaultValue={``} name="tipo_codice" disabled={true}>
                                                <option value={0}>
                                                    {intl.formatMessage({ id: "App.info.Codice" })}
                                                </option>
                                                <option value={1}>
                                                    {intl.formatMessage({ id: "App.info.Chiave" })}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rack">
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`elettroserratura`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <FormattedMessage id="App.info.Elettroserratura" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="antipanico" name="antipanico" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`antipanico`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <FormattedMessage id="App.info.Antipanico" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="ronda" name="ronda" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`ronda`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <FormattedMessage id="App.info.Ronda" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`silenzioso`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <FormattedMessage id="App.info.Silenzioso" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <h1>
                                    <FormattedMessage id="App.info.AssociazioniAProgrammi" />
                                </h1>
                                <fieldset>
                                    <div className="rack">
                                        <h4>
                                            <FormattedMessage id="App.info.Programmi" />
                                        </h4>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`program_label_g1`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g1}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`program_label_g2`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g2}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultChecked={0} defaultValue="0" disabled={codeAuth === 2} />
                                                <label htmlFor={`program_label_g3`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g3}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rack" style={{ marginTop: "4em" }}>
                                        <div className="w100 right">
                                            <button className="ok auto spaced" onClick={handleSubmit}>
                                                <FormattedMessage id="App.info.Crea" />
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Codice = connect(mapStateToProps, mapDispatchToProps)(_Codice);

export default Codice;
import React from 'react';
import { Provider } from "react-redux";
import { configureUrlQuery } from 'react-url-query';

import store from "./store/index";
import RoutedApp from './RoutedApp';
import CacheBuster from './components/elements/CacheBuster';

import { IntlProvider } from 'react-intl';

import it from './translations/it.json';
import en from './translations/en.json';

const history = require("history").createBrowserHistory();
configureUrlQuery({ history });

const lang = navigator.language || navigator.userLanguage;
const messages = lang.startsWith('it') ? it : en;

const App = () => (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) {
                return null;
            }
            if (!loading && !isLatestVersion) {
                refreshCacheAndReload();
            }
            return (
                <Provider store={store}>
                    <IntlProvider messages={messages} locale={lang} defaultLocale='en'>
                        <RoutedApp />
                    </IntlProvider>
                </Provider>    
            );
        }}
    </CacheBuster>
); 

export default App;
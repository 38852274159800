import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bBus } from '../../../../lib/goldParser/converter';
import { b2jBus, b2jBusStat } from '../../../../lib/goldParser/physicalMap';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldDelPeripheral: payloads => dispatch(goldDoRequest(['goldDelPeripheral', 'goldModOutBus', 'goldCheckSync'], payloads)),
    goldModOutBus: payload => dispatch(goldDoRequest('goldModOutBus', payload)),
    setStore: data => dispatch(setStore(data)),
});

const _PerifericheBUS = ({ match, data, errors, system, goldDelPeripheral, goldModOutBus, setStore, reset_dev_stats }) => {
    const codeForm = useRef(null);
    const _system = clone(system);
    const [sub, setSub] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [ingressi, setIngressi] = useState([]);
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(null);
    const [stat, setStat] = useState(b2jBusStat(0, { num_tipo_periferica: 0 }));
    const [processed3, setProcessed3] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const intl = useIntl();

    useEffect(
        () => {
            setFirstLoad(true);
            setSub(match.params.sub > 0 ? parseInt(match.params.sub) : 1);
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        [match.params.sub]
    );

    useEffect(
        () => {
            if (firstLoad) {
                setRedir(null);
                setCurrent(null);
                setTimeout(() => {
                    let _ingressi = [];
                    for (let i = 0; i < _system.store.pm.bus.length; i++) {
                        _ingressi.push({
                            idx: i,
                            group: Math.floor(i / 16),
                            ..._system.store.pm.bus[i]
                        });
                    }
                    setIngressi(_ingressi);
                    setFirstLoad(false);
                    setCurrent(_ingressi[(sub - 1)]);
                    setStore({
                        polling_requests: ['goldDevStat'],
                        polling_payloads: [{ id_centrale: system.id_centrale, type: 'bus', group: _ingressi[(sub - 1)].group }]
                    });
                }, 1000);
            }
            return () => null;
        },
        [firstLoad]
    );

    useEffect(
        () => {
            if (current) {
                setStat(b2jBusStat(system.store.dev_stats.bus[current.group][current.idx], { num_tipo_periferica: current.num_tipo_periferica }));
            }
        },
        [system.store.dev_stats.bus, reset_dev_stats, current]
    );

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 2) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            nome: form.nome.value,
        };
    };

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setRedir(`/gold/${system.id}/bus/${value}`);
    };

    const confirmDelete = e => {
        e.preventDefault();
        setConfirm(false);
        goldDelPeripheral([
            {
                id_centrale: system.id_centrale,
                type: 'bus',
                n_disp: current.idx
            },
            {
                id_centrale: system.id_centrale,
                idx: current.idx,
                edit: false,
                edata: [0xFF, 0xFF, 0xFF, 0x0, ...(Array(16).fill(0x20))]
            },
            {
                id_centrale: system.id_centrale
            }
        ]);
        setProcessed3(true);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldModOutBus({
            id_centrale: system.id_centrale,
            idx: current.idx,
            edit: true,
            edata: j2bBus(payload, true)
        });
        setProcessed(true);
    }

    const handleReset = e => {
        e.preventDefault();
        const idx = current.idx;
        setCurrent(null);
        setTimeout(() => setCurrent(ingressi[idx]), 100);
    };

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    if (!!!current) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    if (data && processed) {
        setProcessed(null);
        if (!!data && !errors) {
            const form = codeForm.current;
            const nome = form.nome.value;
            const _ingressi = clone(ingressi);
            _ingressi[current.idx].nome = nome;
            setIngressi(_ingressi);
        }
        setSuccess(!!data && !errors);
    }

    if (data && processed3) {
        setProcessed3(null);
        let _success = (!!data && !errors) ? 2 : false;
        if (!!errors && errors.length > 0 && errors[0] == 'goldCheckSync') {
            _success = 3;
        }
        if (_success === 2 || _success === 3) {
            let pm = clone(_system.store.pm);
            pm.bus[current.idx] = b2jBus(['000000']);
            setStore({
                pm
            });
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuc" })} onClose={() => { setSuccess(null); }} />}
            {success === 2 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.DispositivoElimConSucc" })} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}`) }} />}
            {success === 3 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.DispositivoElimConSucc" })} redirectText={intl.formatMessage({ id: "App.info.Sincronizza" })} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}`) }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {
                            confirm && (
                                <>
                                    <Modal
                                        title={intl.formatMessage({ id: "App.info.CancellazioneDispositivo" })}
                                        text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitiva" })}
                                        onClose={() => { setConfirm(false); }}
                                        onConfirm={confirmDelete}
                                    />
                                </>
                            )
                        }
                        <form ref={codeForm} className="stackableW33">
                            {current && (
                                <>
                                    <h3>
                                        <FormattedMessage id="App.info.PerifericheBus" />
                                    </h3>
                                    <br />
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w33">
                                                <label>
                                                    <FormattedMessage id="App.info.Numero" />
                                                </label>
                                                <select value={current.idx} name="numero" onChange={handleSelectChange}>
                                                    {
                                                        ingressi.map((value, index) => (
                                                            !!value.num_tipo_periferica || index === (sub - 1)
                                                                ? <option key={`bus_${index}`} value={index}>{`${index + 1} - ${value.nome}`}</option>
                                                                : <React.Fragment key={`bus_${index}`} />
                                                        )
                                                        )}
                                                </select>
                                            </div>
                                            <div className="w33">
                                                <label>
                                                    <FormattedMessage id="App.info.Tipologia" />
                                                </label>
                                                {current.tipo}
                                            </div>
                                            <div className="w33">
                                                <label>
                                                    <FormattedMessage id="App.info.NomeDispositivo" />
                                                </label>
                                                <input type="text" name="nome" defaultValue={current.nome} maxLength={16} />
                                            </div>
                                        </div>
                                        <p>&nbsp;</p>
                                        <div className="rack">
                                            <div className="w100">
                                                <p><i className="fa fa-circle" style={{ color: stat.dispositivo_presente === null ? "gray" : (stat.dispositivo_presente ? "red" : "green") }} />
                                                    <FormattedMessage id="App.info.DispositivoPresente" />
                                                </p>
                                                {stat.as != null && <p><i className="fa fa-circle" style={{ color: stat.as ? "red" : "gray" }} />AS</p>}
                                                <p><i className="fa fa-circle" style={{ color: stat.sabotaggio ? "red" : "gray" }} />
                                                    <FormattedMessage id="App.info.SabotaggioInCorso" />
                                                </p>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setConfirm(true); }}>
                                                    <FormattedMessage id="App.info.Elimina" />
                                                </button>
                                                <button className="ok auto spaced" onClick={handleReset} disabled={(!current.num_tipo_periferica)}>
                                                    <FormattedMessage id="App.info.Annulla" />
                                                </button>
                                                <button className="ok auto spaced" onClick={handleSubmit} disabled={((!current.num_tipo_periferica) || (system.store.state.prog_active))}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const PerifericheBUS = connect(mapStateToProps, mapDispatchToProps)(_PerifericheBUS);

export default PerifericheBUS;
import React, { useState } from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import Modal from '../../../elements/Modal';
import { b2jEvent } from '../../../../lib/goldParser/physicalMap';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest
} from '../../../../actions';

registerLocale('it', it);

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldGetEvents: payload => dispatch(goldDoRequest('goldGetEvents', payload)),
});

const _RegistroEventi = ({ data, errors, system, goldGetEvents }) => {
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(null);
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState("0");
    const [sort, setSort] = useState(1);
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(now);
    const intl = useIntl();

    const getEvents = e => {
        e.preventDefault();
        goldGetEvents({ id_centrale: system.id_centrale, ts_from: startDate.getTime(), ts_to: endDate.getTime(), offset: ((new Date()).getTimezoneOffset()) });
        setProcessed(true);
    };

    const download = () => {
        const element = document.createElement("a");
        const content = intl.formatMessage({ id: "App.info.DataOraCentraleData" });
        const file = new Blob([`${content}\n${(
            events.map(ev => {
                let _ev = b2jEvent(parseInt('0x' + ev.evento), parseInt('0x' + ev.cod1), parseInt('0x' + ev.cod2), system.store.pm);
                let date_cloud = !!ev.notification_date ? ((new Date(ev.notification_date)).toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })) : '-';
                return `${ev.data} ${ev.ora};${date_cloud};${_ev.name};${_ev.description}`;
            }).join('\n'))}`], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = "RegistroEventi.csv";
        document.body.appendChild(element);
        element.click();
    };

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                text={intl.formatMessage({ id: 'App.info.PermessiNonSufficienti' })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (data && processed) {
        setProcessed(null);
        if (Array.isArray(data[0].events)) {
            setEvents(data[0].events);
        }
        setSuccess(!errors);
    }

    return (
        <section>
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form>
                            <h3>
                                <FormattedMessage id="App.info.RegistroEventi" />
                            </h3>
                            <br />
                            <fieldset>
                                <br />
                                <div className="rack">
                                    <div className="w25">
                                        <label>
                                            <FormattedMessage id="App.info.Data" />
                                        </label>
                                        <DatePicker
                                            dateFormat={intl.formatMessage({ id: "App.info.FormatDateExtended" })}
                                            locale="it"
                                            maxDate={now}
                                            startDate={startDate}
                                            selected={startDate}
                                            selectsStart
                                            onChange={date => {
                                                setStartDate(date);
                                            }}
                                            showYearDropdown={true}
                                            placeholderText="Data inizio"
                                        />
                                        <DatePicker
                                            dateFormat={intl.formatMessage({ id: "App.info.FormatDateExtended" })}
                                            locale="it"
                                            maxDate={now}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selected={endDate}
                                            selectsStart
                                            onChange={date => {
                                                setEndDate(date);
                                            }}
                                            showYearDropdown={true}
                                            placeholderText={intl.formatMessage({ id: "App.info.DataFine" })}
                                            minDate={startDate}
                                        />

                                    </div>
                                    <div className="w25">
                                        <label>
                                            <FormattedMessage id="App.info.Filtro" />
                                        </label>
                                        <select defaultValue="0" name="filter" onChange={e => { setFilter(e.target.value); }}>
                                            <option value="0">
                                                {intl.formatMessage({ id: "App.info.NessunFiltro" })}                                            </option>
                                            <option value="a">
                                                {intl.formatMessage({ id: "App.info.Allarmi" })}
                                            </option>
                                            <option value="b">
                                                {intl.formatMessage({ id: "App.info.AttivazioniDisattivazioni" })}
                                            </option>
                                            <option value="c">
                                                {intl.formatMessage({ id: "App.info.Sabotaggi" })}
                                            </option>
                                            <option value="d">
                                                {intl.formatMessage({ id: "App.info.Supertasti" })}
                                            </option>
                                            <option value="e">
                                                {intl.formatMessage({ id: "App.info.Guasti" })}
                                            </option>
                                            <option value="f">
                                                {intl.formatMessage({ id: "App.info.PassaggioRonda" })}
                                            </option>
                                            <option value="g">
                                                {intl.formatMessage({ id: "App.info.Elettroserratura" })}
                                            </option>
                                            <option value="h">
                                                {intl.formatMessage({ id: "App.info.Sistema" })}
                                            </option>
                                            <option value="i">
                                                {intl.formatMessage({ id: "App.info.Campanello" })}
                                            </option>
                                            <option value="j">
                                                {intl.formatMessage({ id: "App.info.Connessioni" })}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="w25">
                                        <label>
                                            <FormattedMessage id="App.info.Ordina" />
                                        </label>
                                        <select defaultValue="1" onChange={e => { setSort(parseInt(e.target.value)) }}>
                                            <option value="1">
                                                {intl.formatMessage({ id: "App.info.PiuRecenti" })}
                                            </option>
                                            <option value="0">
                                                {intl.formatMessage({ id: "App.info.MenoRecenti" })}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w50">&nbsp;</div>
                                    <div className="w50" style={{ marginTop: "1.75em", textAlign: "right" }}>
                                        <button className="yellowButton ok auto spaced" onClick={getEvents}>
                                            <FormattedMessage id="App.info.ScaricaMemoria" />
                                        </button>
                                        {` `}
                                        <button className="ok auto spaced" disabled={events.length === 0} onClick={e => { e.preventDefault(); download(); }}>
                                            <FormattedMessage id="App.info.EsportaCsv" />
                                        </button>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100">
                                        <fieldset>
                                            <table className="controlPanelTable responsiveTable">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <FormattedMessage id="App.info.DataOraCentrale" />
                                                        </th>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <FormattedMessage id="App.info.DataOraCloud" />
                                                        </th>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <FormattedMessage id="App.info.Tipo" />
                                                        </th>
                                                        <th style={{ textAlign: 'left' }}>
                                                            <FormattedMessage id="App.info.Descrizione" />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {events
                                                        .filter(ev => filter === "0" || filter === ev.f)
                                                        .sort((a, b) => {
                                                            const comparison = (new Date(a.notification_date) > new Date(b.notification_date)) ? -1 : 1;
                                                            return comparison * (sort ? 1 : -1);
                                                        })
                                                        .map((ev, i) => {
                                                            let _ev = b2jEvent(parseInt('0x' + ev.evento), parseInt('0x' + ev.cod1), parseInt('0x' + ev.cod2), system.store.pm);
                                                            let date_cloud = !!ev.notification_date ? ((new Date(ev.notification_date)).toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })) : '-';
                                                            return (
                                                                <tr key={`ev_${i}`}>
                                                                    <td><span className="head">
                                                                        <FormattedMessage id="App.info.DataOraCentrale" />
                                                                    </span>{ev.data} {ev.ora}</td>
                                                                    <td><span className="head">
                                                                        <FormattedMessage id="App.info.DataOraCloud" />
                                                                    </span>{date_cloud}</td>
                                                                    <td><span className="head">
                                                                        <FormattedMessage id="App.info.Tipo" />
                                                                    </span>{_ev.name}</td>
                                                                    <td><span className="head">
                                                                        <FormattedMessage id="App.info.Descrizione" />
                                                                    </span>{`${_ev.description}`}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const RegistroEventi = connect(mapStateToProps, mapDispatchToProps)(_RegistroEventi);

export default RegistroEventi;
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    errorRead,
    doRequest,
} from '../../../actions';
import Association from '../../elements/Forms/Association';
import Modal from '../../elements/Modal';
import Outcome from '../../elements/Outcome';
import { roles } from '../../../constants';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
});

const mapDispatchToProps = (dispatch) => ({
    createAssociation: payload => dispatch(doRequest('createAssociation', payload)),
    deleteAssociation: id => dispatch(doRequest('deleteAssociation', id)),
    errorRead: () => dispatch(errorRead()),
    getAllSystem: () => dispatch(doRequest('getAllSystem')),
    getAssociations: () => dispatch(doRequest('getAssociations')),
});

const _AddUser = ({ data, stateError, deleteAssociation, errorRead, getAllSystem, getAssociations, createAssociation }) => {

    const initialPayload = {
        associated_email: null,
        system_id: null,
        profile: null
    };

    const intl = useIntl();
    const [confirm, setConfirm] = useState(false);
    const [errors, setErrors] = useState("");
    const [firstTab, setFirstTab] = useState(true);
    const [processedAssociations, setProcessedAssociations] = useState(false);
    const [processedDelete, setProcessedDelete] = useState(false);
    const [processedSystems, setProcessedSystems] = useState(false);
    const [processedAdd, setProcessedAdd] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [success, setSuccess] = useState(false);
    const [associations, setAssociations] = useState(false);
    const [associationId, setAssociationId] = useState(false);
    const [systems, setSystems] = useState(false);
    const [filters, setFilters] = useState(
        {
            name: null,
            mac: null,
            address: null,
            profile: null,
            email: null
        }
    );

    useEffect(
        () => {
            if (firstTab && !processedAssociations && associations === false) {
                getAssociations();
                setProcessedAssociations(true);
                setErrors("");
            }
            if (!firstTab && !processedSystems && systems === false) {
                getAllSystem();
                setProcessedSystems(true);
                setErrors("");

            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError, processedAssociations, setProcessedAssociations, getAssociations, associations, setProcessedSystems, setSystems, getAllSystem, systems, processedSystems, firstTab]
    );

    const tabHandler = value => setFirstTab(value);

    const handleChangeFilter = (e) => {
        let _filters = filters;
        _filters[e.target.name] = e.target.value.length ? e.target.value : null;
        setFilters({
            ...filters,
            ..._filters,
        });
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
        setAssociationId(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        setProcessedDelete(true);
        deleteAssociation(associationId);
    }

    const handlerChange = (e) => {
        const _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        setErrors("");
        setSuccess(false);
        setProcessedAdd(true);
        createAssociation(payload);
        setTimeout(() => {
            getAssociations();
            setProcessedAssociations(true);
        }, 500);
    };

    const renderForm = () => (
        <Association
            handleChange={handlerChange}
            handleSubmit={handlerSubmit}
            systems={systems}
        />
    );

    const renderList = () => (
        <div className="rack">
            <div className="w100">
                <form>
                    <fieldset>
                        <table className="responsiveTable">
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="App.info.Utente" /></th>
                                    <th><FormattedMessage id="App.info.Ruolo" /></th>
                                    <th><FormattedMessage id="App.info.Impianto" /></th>
                                    <th><FormattedMessage id="App.info.Indirizzo" /></th>
                                    <th><FormattedMessage id="App.info.Azioni" /></th>
                                </tr>
                                <tr className="filters">
                                    <td>
                                        <span className="head">
                                            <FormattedMessage id="App.info.Utente" />
                                        </span>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Cerca" })} name="email" onChange={handleChangeFilter} />
                                    </td>
                                    <td>
                                        <span className="head">
                                            <FormattedMessage id="App.info.Ruolo" />
                                        </span>
                                        <select defaultValue="" name="profile" onChange={handleChangeFilter}>
                                            <option value=""></option>
                                            {
                                                roles.map((value, index) => value === 'proprietario' ? null : <option key={index} value={value}>{value}</option>).filter(el => el != null)
                                            }
                                        </select>
                                    </td>
                                    <td>
                                        <span className="head">
                                            <FormattedMessage id="App.info.Impianto" />
                                        </span>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Cerca" })} name="name" onChange={handleChangeFilter} />
                                    </td>
                                    <td>
                                        <span className="head">
                                            <FormattedMessage id="App.info.Indirizzo" />
                                        </span>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Cerca" })} name="mac" onChange={handleChangeFilter} />
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    associations && (
                                        associations.map((value, index) => {
                                            let filtered = false;
                                            let decodedGeodata = value.geodata ? JSON.parse(value.geodata) : null;
                                            value.address = decodedGeodata && decodedGeodata[0] != null ? decodedGeodata[0].formatted_address : '';
                                            for (let j in filters) {
                                                if (filters[j] != null) {
                                                    filtered = !value[j].includes(filters[j]);
                                                    if (filtered) {
                                                        break;
                                                    }
                                                }
                                            }
                                            return filtered
                                                ? <tr key={index} style={{ border: 0 }}></tr>
                                                : (
                                                    <tr key={`a_${value.id}`} style={{ textAlign: "center", minHeight: "3em" }}>
                                                        <td><span className="head"><FormattedMessage id="App.info.Utente" /></span><strong>{value.email}</strong></td>
                                                        <td><span className="head"><FormattedMessage id="App.info.Ruolo" /></span>{value.profile}</td>
                                                        <td><span className="head"><FormattedMessage id="App.info.Impianto" /></span>{value.name}</td>
                                                        <td><span className="head"><FormattedMessage id="App.info.Indirizzo" /></span>{value.address}</td>
                                                        <td>
                                                            <span className="head">
                                                                <FormattedMessage id="App.info.Azioni" />
                                                            </span>
                                                            <Link title={intl.formatMessage({ id: "App.info.EliminaAbilitazione" })} to={`/`} onClick={(e) => { e.preventDefault(); setAssociationId(value.id); setConfirm(true); }} >
                                                                <i className="fa fa-trash" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                        })
                                    )
                                }
                            </tbody>
                        </table>
                    </fieldset>
                </form>
            </div>
        </div>
    );

    if (data && processedAssociations) {
        setProcessedAssociations(false);
        setAssociations(data);
    }

    if (data && processedDelete) {
        setProcessedAssociations(false);
        setProcessedDelete(false);
        setAssociations(false);
        setAssociationId(false);
        setConfirm(false);
    }

    if (data && processedSystems) {
        setProcessedSystems(false);
        setSystems(data);
    }

    if (data && processedAdd) {
        setProcessedAdd(false);
        setSuccess(true);
        setAssociations(false);
        setProcessedAssociations(false);
        setFirstTab(true);
    }

    return (
        <section>
            <div className="innerSection">
                <br />
                <h3>
                    <FormattedMessage id="App.info.AbilitazioneUtenti" />
                </h3>
                {
                    success
                        ? (
                            <Outcome text={intl.formatMessage({ id: "App.info.OperazioneEffettuata" })} type="ok" onClose={setSuccess} />
                        )
                        : !!errors && errors.length
                            ? <Outcome text={errors} type="ko" onClose={setErrors} />
                            : <></>
                }
                {
                    confirm && (
                        <>
                            <Modal
                                title={intl.formatMessage({ id: "App.info.CancellazioneAbilitazione" })}
                                text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitivaAbilitazione" })}
                                onClose={closeModal}
                                onConfirm={confirmDelete}
                            />
                        </>
                    )
                }
                <div className="rack box">
                    <div className="w50">
                        <h2 className={`tab tastiImpianti ${(firstTab ? 'active' : 'disabled')}`} style={{ borderBottomLeftRadius: '1em', borderTopLeftRadius: '1em', borderBottomRightRadius: '0em', borderTopRightRadius: '0em' }} onClick={() => tabHandler(true)}>
                            <FormattedMessage id="App.info.UtentiAbilitati" />
                        </h2>
                    </div>
                    <div className="w50">
                        <h2 className={`tab tastiImpianti ${(firstTab ? 'disabled' : 'active')}`} style={{ borderBottomLeftRadius: '0em', borderTopLeftRadius: '0em', borderBottomRightRadius: '1em', borderTopRightRadius: '1em' }} onClick={() => tabHandler(false)}>
                            <FormattedMessage id="App.info.NuovaAbilitazione" />
                        </h2>
                    </div>
                </div>
                {
                    firstTab
                        ? renderList()
                        : renderForm()
                }
            </div>
        </section>
    );
};

const AddUser = connect(mapStateToProps, mapDispatchToProps)(_AddUser);

export default AddUser;
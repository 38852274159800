import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { stringToArrayInt } from '../../../../lib/bytesFunctions';
import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.queue_data,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldSetDisplay: payload => dispatch(goldDoRequest('goldSetDisplay', payload, false)),
    setStore: data => dispatch(setStore(data))
});

const _Display = ({ data, system, goldSetDisplay, setStore }) => {
    const codeForm = useRef(null);
    const [processed1, setProcessed1] = useState(null);
    const [success, setSuccess] = useState(null);
    const intl = useIntl();

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const handleSubmit = e => {
        e.preventDefault();
        const form = codeForm.current;
        goldSetDisplay({
            id_centrale: system.id_centrale,
            nome: stringToArrayInt(16, form.nome.value)
        });
        let pm = clone(system.store.pm);
        pm.intest = form.nome.value;
        setStore({
            pm
        });
        setProcessed1(true);
    };

    if (data && processed1) {
        setProcessed1(null);
        if (!!data[0] && data[0].status === 'OK') {
            setSuccess(true);
        } else {
            setSuccess(false);
        }
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm} className="stackableW33">
                            <h1>
                                <FormattedMessage id="App.info.NomeDisplay" />
                            </h1>
                            <fieldset>
                                <div className="w50">
                                    <label>
                                        <FormattedMessage id="App.info.Intestazione" />
                                    </label>
                                    <input type="text" name="nome" defaultValue={system.store.pm.intest} maxLength={16} />
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.state.prog_active)}>
                                            <FormattedMessage id="App.info.Applica" />
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Display = connect(mapStateToProps, mapDispatchToProps)(_Display);

export default Display;
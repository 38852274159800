import React from 'react';
import { FormattedMessage } from 'react-intl';

const StatoRilevatoreVolumetricoEsterno180 = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme ? "red" : "gray" }} /> 
                         <FormattedMessage id="App.info.Allarme" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_dx ? "red" : "gray" }} /> 
                         <FormattedMessage id="App.info.AllarmeDX" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_frontale ? "#red" : "gray" }} />
                         <FormattedMessage id="App.info.AllarmeFrontale" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "#ffc20a" : "gray") }} /> 
                         <FormattedMessage id="App.info.BatteriaScarica" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.tamper ? "red" : "gray" }} /> 
                         <FormattedMessage id="App.info.Tamper" />
                    </p>
                </div>
            </div>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_am ? "red" : "gray" }} /> 
                         <FormattedMessage id="App.info.AllarmeAm" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.allarme_sx ? "red" : "gray" }} />
                         <FormattedMessage id="App.info.AllarmeSX" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.supervisione_led ? "red" : "gray" }} />
                         <FormattedMessage id="App.info.Supervisione" />
                    </p>
                </div>
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.dormiente ? "red" : "gray" }} />
                         <FormattedMessage id="App.info.Dormiente" />
                    </p>
                </div>
            </div>
        </>
    );
};

export default StatoRilevatoreVolumetricoEsterno180;
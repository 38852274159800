import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    errorRead,
    doRequest
} from '../../../actions';
import Error from '../../elements/Error';
import { FormattedMessage, useIntl } from 'react-intl';

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    doRequest: payload => dispatch(doRequest('passwordRecovery', payload)),
});

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const _PasswordRecovery = ({ data, errorRead, doRequest, stateError }) => {
    const initialPayload = {
        email: "",
        newsletter: 0,
        privacy: 0,
    };

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [success, setSuccess] = useState(false);
    const intl = useIntl();

    useEffect(
        () => {
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError]
    );

    const isDisabled = () => !(payload.email.length > 0);

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlePasswordRecovery = (e) => {
        e.preventDefault();
        setProcessed(true);
        doRequest(payload);
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
    }

    return (
        <section>
            <div>
                <img className="mainLogo" src="/static/images/testalince.png" alt="Lince" title="Lince Clouding" />
            </div>
            <div>
                <form name="passwordRecovery">
                    <fieldset>
                        {
                            success
                                ?
                                <div style={{ textAlign: "left" }}>
                                    <p>
                                        <strong>
                                            <FormattedMessage id="App.info.RipristinoPassword" />
                                        </strong>
                                    </p>
                                    <p>
                                        <FormattedMessage id="App.info.SeIndirizzoCorretto" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="App.info.SeMessaggioNonRicevuto" />
                                    </p>
                                    <p>
                                        <span>
                                            <Link to={'/'}>
                                                <FormattedMessage id="App.info.TornaAImpianti" />
                                            </Link>
                                        </span>
                                    </p>
                                </div>
                                :
                                <>
                                    <p>
                                        <input type="text" placeholder={intl.formatMessage({ id: "App.info.Email" })} name="email" onChange={handleChange} />
                                    </p>
                                    <Error text={errors} />
                                    <p>
                                        <button disabled={isDisabled()} className="ok" onClick={handlePasswordRecovery}>
                                            <FormattedMessage id="App.info.RecuperaPassword" />
                                        </button>
                                    </p>
                                    <p className="sameLine">
                                        <small>
                                            <span>
                                                <Link to={'/'}>
                                                    <FormattedMessage id="App.info.Login" />
                                                </Link>
                                            </span>
                                        </small>
                                    </p>
                                </>
                        }
                    </fieldset>
                </form>
            </div>
        </section>
    );
};

const PasswordRecovery = connect(mapStateToProps, mapDispatchToProps)(_PasswordRecovery);

export default PasswordRecovery;
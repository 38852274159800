import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    login, unsetToken, deleteAccount, setToken
} from '../../../api/Cloud'

import Error from '../../elements/Error';

const mapDispatchToProps = () => ({
});

const mapStateToProps = () => ({
});

const _DeleteAccount = () => {
    const intl = useIntl();
    const initialPayload = {
        email: "",
        password: "",
    };

    const [errors, setErrors] = useState("");
    const [success, setSuccess] = useState(false);
    const [payload, setPayload] = useState(initialPayload);

    const handleChange = (e) => {
        let _payload = payload;
        _payload[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const isDisabled = () => !(payload.email.length > 0 && payload.password.length > 0);

    const handleLogin = async (e) => {
        e.preventDefault();
        const [_data, _code] = await login(payload.email, payload.password);
        if (_code === 201) {
            let { token } = _data;
            setToken(token);
           // const [_, __code] = await deleteAccount();
           const [_, __code] = await deleteAccount(payload.password);
           if (__code === 200) {
                setSuccess(true);
            } else {
                setErrors(intl.formatMessage({id:"App.error.EliminaAccount"}))
            }
            unsetToken();
        } else {
            setErrors(intl.formatMessage({id:"App.error.Credenziali"}))
        }
    }

    return (
        <section>
            <div>
                <img className="mainLogo" src="/static/images/testalince.png" alt="Lince" title="Lince Clouding" />
            </div>
            <div>
                {
                    success
                        ? (
                            <>
                                <p><FormattedMessage id="App.info.EliminaAccountSuccesso"/></p>
                                <p className="sameLine">
                                    <small>
                                        <span>
                                            <Link to={'/'}><FormattedMessage id="App.info.Home"/></Link>
                                        </span>
                                    </small>
                                </p>
                            </>

                        ) : (
                            <form name="login">
                                <div style={{ textAlign: "justify", padding: "1em" }}>
                                    <p>
                                        <strong><FormattedMessage id="App.info.Attenzione"/></strong>
                                    </p>
                                    <p>
                                        <FormattedMessage id="App.info.SicuriDiProcedere" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="App.info.EliminaAccountProcedura" />
                                    </p>
                                </div>
                                <fieldset>
                                    <p>
                                        <input type="text" placeholder="Email" name="email" onChange={handleChange} />
                                    </p>
                                    <p>
                                        <input type="password" placeholder="Password" name="password" onChange={handleChange} />
                                    </p>
                                    <Error text={errors} />
                                    <p>
                                        <button disabled={isDisabled()} className="ok" onClick={handleLogin}><FormattedMessage id="App.info.EliminaAccount" /></button>
                                    </p>
                                    <p className="sameLine">
                                        <small>
                                            <span>
                                                <Link to={'/'}><FormattedMessage id="App.info.Home"/></Link>
                                            </span>
                                        </small>
                                    </p>
                                </fieldset>
                            </form>
                        )

                }

            </div>
        </section>
    );
};

const DeleteAccount = connect(mapStateToProps, mapDispatchToProps)(_DeleteAccount);

export default DeleteAccount;
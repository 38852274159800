import React from 'react';
import ReactSlider from 'react-slider';
import { FormattedMessage, useIntl } from 'react-intl';

import { roles } from '../../../constants';

const NewSim = ({ association = null, systems, handleChange, handleSubmit, saveButton = '', cancelButton = null, handleCancel = null, piano = false }) => {

    const intl = useIntl();
    if (saveButton == '') {
        saveButton = intl.formatMessage({ id: "App.info.Compra" });
    }

    return (
        <div className="formSection">
            <div className="rack">
                {
                    piano
                        ? (
                            <div className="w75">
                                <label>
                                    <FormattedMessage id="App.info.PianoTariffario" />
                                </label>
                                <input type="text" disabled={true} defaultValue={piano} />
                            </div>
                        )
                        : (
                            <div className="w75">
                                <label>
                                    <FormattedMessage id="App.info.PianoTariffario*" />
                                </label>
                                <input type="text" defaultValue={``} name="associated_piano" onChange={handleChange} />
                            </div>
                        )
                }
            </div>
            <div className="rack">
                <div className="w75">
                    <label>
                        <FormattedMessage id="App.info.impianto" />
                    </label>
                    <select defaultValue={association != null ? association.system_id : 0} name="id_centrale" onChange={handleChange}>
                        <option value="0">
                            <FormattedMessage id="App.info.SelezionaInSeguito" />
                        </option>
                        {
                            systems && (
                                systems
                                    .filter(el => el.profiles.split(','))
                                    .filter(el => el.valid === 1)
                                    .map((value, index) => {
                                        return <option key={index} value={value.id_centrale}>{value.name} - {value.profiles}</option>
                                    })
                            )
                        }
                    </select>
                </div>
            </div>
            <div className="rack">
                <div className="w75">
                    <label>
                        <FormattedMessage id="App.info.NewNumber*" />
                    </label>
                    <input type="text" defaultValue={``} name="msisdn" onChange={handleChange} maxLength="15" onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} />
                </div>
            </div>
            <div className="rack buttonPanel">
                <div className="w50 left">
                    <p>&nbsp;</p>
                    {
                        cancelButton && (<button className="ok auto" onClick={handleCancel}>{cancelButton}</button>)
                    }
                </div>
                <div className="w50 right">
                    {
                        saveButton && (
                            <>
                                <p><small>
                                    <FormattedMessage id="App.info.CampiObbligatori" />
                                </small></p>
                                <button className="ok auto" onClick={handleSubmit}>{saveButton}</button>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default NewSim;
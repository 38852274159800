import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactSlider from 'react-slider';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bGsm, j2bTel } from '../../../../lib/goldParser/converter';
import { arrayIntToString, hexstringToArrayInt, stringToArrayInt } from '../../../../lib/bytesFunctions';
import { b2jGsmState } from '../../../../lib/goldParser/physicalMap';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldGetGsmStat, goldGetGprsAPN, goldGetRec, goldSetRec, sleep } from '../../../../api/Cloud';

registerLocale('it', it);

const mapStateToProps = state => ({
    data: state.queue_data,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldGsm: (requests, payloads) => dispatch(goldDoRequest(requests, payloads)),
    goldSetGsmConfig: payload => dispatch(goldDoRequest('goldSetGsmConfig', payload, false)),
    setStore: data => dispatch(setStore(data))
});

const _OpzioniGSM = ({ data, system, goldGsm, goldSetGsmConfig, setStore }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(null);
    const [esistenzaVita, setEsistenzaVita] = useState(0);
    const [tentativiChiamate, setTentativiChiamate] = useState(0);
    const [scadenzaSim, setScadenzaSim] = useState(null);
    const [processed, setProcessed] = useState(null);
    const [processed1, setProcessed1] = useState(null);
    const [redir, setRedir] = useState(null);
    const [error, setError] = useState(null);
    const [apn, setAPN] = useState(false);
    const [gprs, setGprs] = useState(!!_system.store ? _system.store.pm.wifi_set.gprs : false);
    const [success, setSuccess] = useState(null);
    const [recording, setRecording] = useState(false);
    const [timer, setTimer] = useState(null);
    const [recError, setRecError] = useState(null);
    const intl = useIntl();

    const gsmCheck = useCallback(async () => {
        await sleep(1500);
        const [gsmData, gsmCode] = await goldGetGsmStat({ id_centrale: system.id_centrale });
        if (gsmCode === 200 && !!gsmData.status && gsmData.status === 'OK') {
            const gsmState = b2jGsmState(gsmData);
            setCurrent({
                gsm: _system.store.pm.gsm,
                gsm_state: gsmState,
                msg: _system.store.pm.msg,
                tel: {
                    centro_servizi_sms: _system.store.pm.tel[16],
                    centro_servizi_credito: _system.store.pm.tel[17],
                },
            });
            setTentativiChiamate(_system.store.pm.gsm.numero_tentativi_chiamate);
            setEsistenzaVita(_system.store.pm.gsm.esistenza_in_vita);
            setScadenzaSim(new Date(_system.store.pm.gsm.scadenza_sim));
            if (gsmState.versione_modulo === 'GPRS') {
                await sleep(1500);
                const [apnData, apnCode] = await goldGetGprsAPN({ id_centrale: system.id_centrale })
                if (apnCode === 200 && !!apnData.status && apnData.status === 'OK') {
                    setAPN(arrayIntToString(hexstringToArrayInt(apnData.data[0])));
                }
            }
            await sleep(500);
        } else {
            setError(true);
        }
    }, []);

    const umount = useCallback(async () => {
        clearInterval(timer);
        await goldSetRec({ id_centrale: system.id_centrale, command: 2 });
    }, [timer]);

    useEffect(() => {
        const fetchData = async () => {
            return await gsmCheck();
        };
        fetchData();
        return async () => {
            await umount();
            startPolling();
            return true;
        }
    }, []);

    const makePayload = () => {
        const form = codeForm.current;
        return {
            gsm: {
                opzioni: {
                    accesso_telegestione: form.accesso_telegestione.checked,
                    visualizzazione_chiamate: (system.model < 5 || system.model > 10) ? form.visualizzazione_chiamate.checked : current.gsm.opzioni.visualizzazione_chiamate,
                    on: form.on.checked,
                    gestione_credito: form.gestione_credito.checked,
                    gestione_disturbo: form.gestione_disturbo.checked
                },
                numero_tentativi_chiamate: tentativiChiamate,
                numero_squilli_risposta: current.gsm.numero_squilli_risposta,
                esistenza_in_vita: esistenzaVita,
                scadenza_sim: scadenzaSim,
                giorni_scadenza_sim: parseInt(form.giorni_scadenza_sim.value),
                giorni_credito_minimo: parseInt(form.giorni_credito_minimo.value),
                credito_minimo: parseInt(form.credito_minimo.value)
            },
            tel: {
                centro_servizi_sms: form.centro_servizi_sms.value,
                centro_servizi_credito: form.centro_servizi_credito.value
            },
            msg: {
                gr0: {
                    benvenuto: form.messaggio_benvenuto.value,
                    allarme: form.messaggio_allarme.value
                },
                gr1: {
                    sabotaggio: form.messaggio_sabotaggio.value,
                    antipanico: form.messaggio_antipanico.value
                },
                gr2: {
                    silenzioso: form.messaggio_silenzioso.value,
                    coda: form.messaggio_coda.value
                },
            }
        };
    };

    const handleReset = e => {
        e.preventDefault();
        setCurrent(null);
        setTimeout(() => setCurrent({ gsm: _system.store.pm.gsm, msg: _system.store.pm.msg }), 100);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        const requests = [];
        const payloads = [];
        requests.push('goldSetGsmOptions');
        payloads.push({
            id_centrale: system.id_centrale,
            edata: j2bGsm(payload.gsm)
        });
        for (let i = 0; i < 3; i++) {
            requests.push('goldSetGsmMessages');
            payloads.push({
                id_centrale: system.id_centrale,
                idx: i,
                edata: (Object.values(payload.msg[`gr${i}`]).map(x => stringToArrayInt(32, x.toString()))).reduce((acc, cur) => { acc.push(...cur); return acc; }, [])
            });
        }
        let _tel0 = clone(current.tel.centro_servizi_sms);
        _tel0.numero = payload.tel.centro_servizi_sms;
        requests.push('goldSetPhoneNumbers');
        payloads.push({
            id_centrale: system.id_centrale,
            idx: 16,
            edata: j2bTel(_tel0)
        });
        let _tel1 = clone(current.tel.centro_servizi_credito);
        _tel1.numero = payload.tel.centro_servizi_credito;
        requests.push('goldSetPhoneNumbers');
        payloads.push({
            id_centrale: system.id_centrale,
            idx: 17,
            edata: j2bTel(_tel1)
        });
        goldGsm(requests, payloads);
        setProcessed(true);
    };

    const handleSubmitGprs = e => {
        e.preventDefault();
        const requests = ['goldSetWifi'];
        const payloads = [
            {
                id_centrale: system.id_centrale,
                edata: [1 + (gprs ? 4 : 0)]
            }
        ];
        if (gprs) {
            requests.push('goldSetGprsAPN');
            payloads.push({
                id_centrale: system.id_centrale,
                edata: gprs ? stringToArrayInt(32, apn) : stringToArrayInt(32, "")
            });
        }
        goldGsm(requests, payloads);
        setProcessed(2);
    };

    const startPolling = () => {
        setStore({
            polling_pause: false
        });
    };

    const stopPolling = () => {
        setStore({
            polling_pause: true
        });
    };

    const pollRec = async () => {
        const [__data, __code] = await goldGetRec({ id_centrale: system.id_centrale });
        if (__code === 200 && __data.status === 'OK') {
            const arr = hexstringToArrayInt(__data.data[0]);
            if ((arr[0] === 0)) {
                await stopRec();
            }
        }
    };

    const startRec = async e => {
        e.preventDefault();
        setRecError(null);
        stopPolling();
        setRecording(true);
        await sleep(1500);
        const [__data, __code] = await goldSetRec({ id_centrale: system.id_centrale, command: 1 });
        if (__code === 200 && __data.status === 'OK') {
            const interval = setInterval(
                async () => {
                    await pollRec();
                },
                3000
            );
            setTimer(interval);
        } else {
            setRecError(intl.formatMessage({ id: "App.info.ImpossibileProcedereAllaRegistrazione" }));
            setRecording(false);
        }
    };

    const stopRec = async e => {
        if (!!e) {
            e.preventDefault();
        }
        await umount();
        setTimer(null);
        startPolling();
        setRecording(false);
    };

    if (data && processed) {
        let _processed = processed;
        setProcessed(null);
        setTimeout(async () => {
            goldSetGsmConfig({
                id_centrale: system.id_centrale
            });
            setProcessed1(_processed);
        }, 400);
    }

    if (data && processed1) {
        let _success = !!data[0] && data[0].status === "OK";
        setProcessed1(null);
        setSuccess(processed1 === 2 && _success ? 2 : _success);
    }

    if (error === true) {
        return <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ErroreRiconnessione" })} onClose={async () => { setError(null); await gsmCheck(); }} />;
    } else if (!!!current) {
        return <p>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </p>;
    }

    if (redir) {
        return <Redirect to={redir} />
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSucc" })} onClose={() => { setSuccess(null); }} />}
            {success === 2 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSucc" })} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}/gstatus`) }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm} className="stackableW33">
                            <h3>
                                <FormattedMessage id="App.info.OpzioniGsm" />
                            </h3>
                            <br />
                            <fieldset>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="accesso_telegestione" name="accesso_telegestione" defaultValue="0" defaultChecked={current.gsm.opzioni.accesso_telegestione} />
                                            <label htmlFor={`accesso_telegestione`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AccessoTelegestione" />
                                            </label>
                                        </div>
                                    </div>
                                    {(system.model < 5 || system.model > 10) && (
                                        <div className="w33">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="visualizzazione_chiamate" name="visualizzazione_chiamate" defaultValue="0" defaultChecked={current.gsm.opzioni.visualizzazione_chiamate} />
                                                <label htmlFor={`visualizzazione_chiamate`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    <FormattedMessage id="App.info.VisualizzaChiamate" />
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="on" name="on" defaultValue="0" defaultChecked={current.gsm.opzioni.on} />
                                            <label htmlFor={`on`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.GsmOn" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack switchRack bottomBorder">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="gestione_credito" name="gestione_credito" defaultValue="0" defaultChecked={current.gsm.opzioni.gestione_credito} />
                                            <label htmlFor={`gestione_credito`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.GestioneCredito" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="gestione_disturbo" name="gestione_disturbo" defaultValue="0" defaultChecked={current.gsm.opzioni.gestione_disturbo} />
                                            <label htmlFor={`gestione_disturbo`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.DisturboRadio" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack bottomBorder">
                                    <div className="w33">
                                        <p>
                                            <FormattedMessage id="App.info.TentativiChiamate" />
                                        </p>
                                        <ReactSlider
                                            value={tentativiChiamate}
                                            onChange={value => { setTentativiChiamate(value); }}
                                            max={8}
                                            min={0}
                                            step={1}
                                            className="horizontal-slider"
                                            thumbClassName="slider-thumb"
                                            trackClassName="slider-track"
                                            renderThumb={(props, state) => {
                                                return (<div {...props}>{`${(state.valueNow + 1)}`}</div>);
                                            }}
                                        />
                                    </div>
                                    <div className="w33">
                                        <p>
                                            <FormattedMessage id="App.info.EsistenzaInVita" />
                                        </p>
                                        <ReactSlider
                                            value={esistenzaVita}
                                            onChange={value => { setEsistenzaVita(value); }}
                                            max={14399}
                                            min={1440}
                                            className="horizontal-slider"
                                            thumbClassName="slider-thumb"                   
                                            trackClassName="slider-track"
                                            renderThumb={(props, state) => {
                                                const [day, hrs, min] = [
                                                    Math.floor(parseInt(state.valueNow) / (24 * 60)),
                                                    Math.floor((parseInt(state.valueNow) % (24 * 60)) / 60),
                                                    ((parseInt(state.valueNow) % (24 * 60)) % 60),
                                                ];
                                                return (<div {...props}>{day} {`${(day > 1 ? intl.formatMessage({ id: "App.info.Giorni" }) : intl.formatMessage({ id: "App.info.Giorno" }))} ${(hrs == 1 ? intl.formatMessage({ id: "App.info.UnOra" }) : `${hrs} ${intl.formatMessage({ id: "App.info.Ore" })}`)} ${min} ${intl.formatMessage({ id: "App.info.Min" })}`}</div>);
                                            }}
                                        />
                                    </div>
                                    <div className="w33">
                                        <p>
                                            <FormattedMessage id="App.info.ScadenzaSim" />
                                        </p>
                                        <p>&nbsp;</p>
                                        <DatePicker
                                            selected={scadenzaSim}
                                            dateFormat={intl.formatMessage({ id: "App.info.FormatDate" })}
                                            locale="it"
                                            onChange={e => { setScadenzaSim(e); }}
                                            onSelect={e => null}
                                            showYearDropdown={true}
                                            placeholderText={intl.formatMessage({ id: "App.info.DataScadenzaFormato" })}
                                        />
                                    </div>
                                </div>
                                <div className="rack bottomBorder">
                                    <div className="w33">
                                        <p>
                                            <FormattedMessage id="App.info.GiorniScadenzaSim" />
                                        </p>
                                        <select defaultValue={current.gsm.giorni_scadenza_sim} name="giorni_scadenza_sim">
                                            <option value={0}>Disabilitato</option>
                                            {(Array.from({ length: 30 }, (_, i) => i + 1).map(x => <option key={`giorni_scadenza_sim_${x}`} value={x}>{x}</option>))}
                                        </select>
                                    </div>
                                    <div className="w33">
                                        <p>
                                            <FormattedMessage id="App.info.GiorniCreditoMinimo" />
                                        </p>
                                        <select defaultValue={current.gsm.giorni_credito_minimo} name="giorni_credito_minimo">
                                            <option value={0}>
                                                <FormattedMessage id="App.info.Disabilitato" />
                                            </option>
                                            {(Array.from({ length: 30 }, (_, i) => i + 1).map(x => <option key={`giorni_credito_minimo_${x}`} value={x}>{x}</option>))}
                                        </select>
                                    </div>
                                    <div className="w33">
                                        <p>
                                            <FormattedMessage id="App.info.CreditoMinimo" />
                                            &euro;</p>
                                        <select defaultValue={current.gsm.credito_minimo} name="credito_minimo">
                                            <option value={0}>
                                                <FormattedMessage id="App.info.Disabilitato" />
                                            </option>
                                            {(Array.from({ length: 30 }, (_, i) => i + 1).map(x => <option key={`credito_minimo_${x}`} value={x}>{x}</option>))}
                                        </select>
                                    </div>
                                </div>
                                <div className="rack bottomBorder">
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.CentroServiziSms" />
                                        </label>
                                        <input type="text" name="centro_servizi_sms" defaultValue={current.tel.centro_servizi_sms.numero} maxLength={16} onKeyPress={e => { if (e.which < 48 || e.which > 57) { e.preventDefault(); } }} />
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.CentroServiziCredito" />
                                        </label>
                                        <input type="text" name="centro_servizi_credito" defaultValue={current.tel.centro_servizi_credito.numero} maxLength={16} onKeyPress={e => { if (e.which < 48 || e.which > 57) { e.preventDefault(); } }} />
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.VersioneModuloGsm" />
                                        </label>
                                        <input type="text" name="versione_modulo_gsm" value={current.gsm_state.versione_modulo} disabled={true} />
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.MessaggioDiBenvenuto" />
                                        </label>
                                        <input type="text" name="messaggio_benvenuto" defaultValue={current.msg.gr0.benvenuto} maxLength={32} />
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.MessaggioAllarme" />
                                        </label>
                                        <input type="text" name="messaggio_allarme" defaultValue={current.msg.gr0.allarme} maxLength={32} />
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.MessaggioDiSabotaggio" />
                                        </label>
                                        <input type="text" name="messaggio_sabotaggio" defaultValue={current.msg.gr1.sabotaggio} maxLength={32} />
                                    </div>
                                </div>
                                <div className="rack bottomBorder">
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.MessaggioAntipanico" />
                                        </label>
                                        <input type="text" name="messaggio_antipanico" defaultValue={current.msg.gr1.antipanico} maxLength={32} />
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.MessaggioSilenzioso" />
                                        </label>
                                        <input type="text" name="messaggio_silenzioso" defaultValue={current.msg.gr2.silenzioso} maxLength={32} />
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.MessaggioDiCoda" />
                                        </label>
                                        <input type="text" name="messaggio_coda" defaultValue={current.msg.gr2.coda} maxLength={32} />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset}>
                                            <FormattedMessage id="App.info.Annulla" />
                                        </button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>
                                            <FormattedMessage id="App.info.Applica" />
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    {current.gsm_state.versione_modulo === 'GPRS' && (
                        <div className="w100 formSection">
                            <form className="stackableW33">
                                <h3><FormattedMessage id="App.info.OpzioniGprs" /></h3>
                                <br />
                                <fieldset>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="abilita_gprs" name="abilita_gprs" defaultValue="0" defaultChecked={gprs} onChange={e => { setGprs(e.target.checked); }} />
                                            <label htmlFor={`abilita_gprs`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AbilitaGprs" />
                                            </label>
                                        </div>
                                    </div>
                                    {apn === false
                                        ? (
                                            <div className="w33">
                                                <label><FormattedMessage id="App.info.Apn" /></label>
                                                <p className="loadingText">
                                                    <FormattedMessage id="App.info.Loading" />
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="w33">
                                                <label><FormattedMessage id="App.info.Apn" /></label>
                                                <input type="text" name="apn" disabled={!gprs} defaultValue={apn} maxLength={32} onChange={e => { setAPN(e.target.value); }} />
                                            </div>
                                        )
                                    }
                                </fieldset>
                                <fieldset>
                                    <div className="rack">
                                        <div className="w100 right">
                                            <button className="ok auto spaced" onClick={handleSubmitGprs}>
                                                <FormattedMessage id="App.info.Applica" />
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <form className="stackableW33">
                                <h1><FormattedMessage id="App.info.RegistrazioneVocale" /></h1>
                                <fieldset>
                                    <div className="rack">
                                        <div className="w50">
                                            <br />
                                            {!!recording
                                                ? <p className="loadingText">
                                                    <FormattedMessage id="App.info.RegistrazioneInCorso" />
                                                </p>
                                                : recError
                                                    ? <p>{recError}</p>
                                                    : <p>
                                                        <FormattedMessage id="App.info.DispositivoProntoPerRegistrazione" />
                                                    </p>
                                            }
                                        </div>
                                        <div className="w50 right">
                                            <p>&nbsp;</p>
                                            <button className="ok auto spaced" disabled={!!recording} onClick={startRec}>
                                                <FormattedMessage id="App.info.Start" />
                                            </button>
                                            <button className="ok auto spaced" disabled={!recording} onClick={stopRec}>
                                                <FormattedMessage id="App.info.Stop" />
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    )}
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const OpzioniGSM = connect(mapStateToProps, mapDispatchToProps)(_OpzioniGSM);

export default OpzioniGSM;
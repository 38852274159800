import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    doRequest,
    setStore
} from '../../../../actions';
import { clone } from '../../../../lib/clone';
import Modal from '../../../elements/Modal';
import Popup from '../../../elements/Modal/Popup';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    system: state.system,
});

const mapDispatchToProps = (dispatch) => ({
    setLogicMap: payload => dispatch(doRequest('goldSetLogicMap', payload)),
    setStore: data => dispatch(setStore(data))
});

const Ambiente = ({ name, devs, moveFunc, renameFunc, deleteFunc, zones }) => {
    const [edit, setEdit] = useState(false);
    const [valid, setValid] = useState(true);
    const [zoneName, setZoneName] = useState(name);
    const [open, setOpen] = useState(false);
    const intl = useIntl();

    return (
        <div className="w100">
            <div className="fsb">
                <div>
                    <h3>
                        {edit
                            ? (
                                <>
                                    <input style={{ display: "inline-block", width: '75%', border: valid ? '1px solid gray' : '2px solid red' }} type="text" name="zoneName" value={zoneName} onChange={e => {
                                        let newName = e.target.value;
                                        setZoneName(newName);
                                        setValid(!zones.includes(newName) && newName.length > 0 && newName.length < 30);
                                    }} />
                                    {valid && (
                                        <Link title={intl.formatMessage({ id: "App.info.ModificaNome" })} to={'#'} onClick={e => { e.preventDefault(); setEdit(false); renameFunc(name, zoneName); }}>
                                            <i className={`fa fa-check`} />
                                        </Link>
                                    )}
                                </>
                            ) : <>{name} ({devs.length})</>
                        }
                    </h3>
                </div>
                <div>
                    <Link title={intl.formatMessage({ id: "App.info.Apri" })} to={'#'} onClick={e => { e.preventDefault(); setOpen(!open); }}>
                        <i className={`fa ${(open ? `fa-chevron-up` : `fa-chevron-down`)}`} />
                    </Link>
                    {(name !== "Default" && !edit) && (
                        <>
                            <Link title={intl.formatMessage({ id: "App.info.ModificaNome" })} to={'#'} onClick={e => { e.preventDefault(); setEdit(true); }}>
                                <i className={`fa fa-pencil`} />
                            </Link>
                            <Link title={intl.formatMessage({ id: "App.info.EliminaAmbiente" })} to={'#'} onClick={e => { e.preventDefault(); deleteFunc(name); }}>
                                <i className="fa fa-trash" />
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className="w100">
                <div>
                    {open && devs.map((x, idx) => <Ingresso key={`ingresso_${idx}`} {...x} moveFunc={moveFunc} />)}
                </div>
            </div>
        </div>
    );
};

const Ingresso = ({ nome, tipo, pos, zona, moveFunc }) => {
    const intl = useIntl();

    return (
        <div className="fsb hov">
            <div>
                {tipo === "filare"
                    ? <img src={`/static/images/device/filari.png`} />
                    : <i className="fa fa-wifi" />
                }
                {nome}
            </div>
            <div>
                <Link title={intl.formatMessage({ id: "App.info.VaiAllIngresso" })} to={`${(tipo === intl.formatMessage({ id: "App.info.Filare" }) ? intl.formatMessage({ id: "App.info.Wired" }) : intl.formatMessage({ id: "App.info.Radio" }))}/${(pos + 1)}`}>
                    <i className="fa fa-arrow-right" />
                </Link>
                <Link title={intl.formatMessage({ id: "App.info.CambiaAmbiente" })} to={'#'} onClick={e => { e.preventDefault(); moveFunc({ nome, pos, tipo, zona }); }}>
                    <i className="fa fa-arrows" />
                </Link>
            </div>
        </div>
    );
}

const _MappaLogica = ({ system, setLogicMap, setStore, data }) => {
    const espansioni = [];
    const offset = system.model < 5 ? 2 : 5;
    for (let i = 0; i < system.store.pm.bus.length; i++) {
        if (system.store.pm.bus[i].num_tipo_periferica === 8) {
            espansioni.push(system.store.pm.bus[i]);
        }
    }
    const [lm, setLm] = useState(system.store.lm || null);
    const [movePopup, setMovePopup] = useState(false);
    const [destZone, setDestZone] = useState(false);
    const [validNew, setValidNew] = useState(false);
    const [newZoneName, setNewZoneName] = useState('');
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(null);
    const intl = useIntl();

    const makeLm = () => {
        const elems = [
            ...system.store.pm.filari.map((x, idx) => ({ nome: x.nome, tipo: intl.formatMessage({ id: "App.info.Filare" }), sub: "", pos: idx, zona: "Default" })).filter((_, idx) => (idx < offset ? true : (espansioni[Math.floor(idx / 5)] || null))),
            ...system.store.pm.radio.filter(x => x.num_tipo_periferica > 0 && x.num_tipo_periferica <= 8).map((x, idx) => ({ nome: x.nome, tipo: intl.formatMessage({ id: "App.info.Radio" }), sub: x.sub, pos: idx, zona: "Default" })),
            { nome: intl.formatMessage({ id: "App.info.Allarme" }), tipo: intl.formatMessage({ id: "App.info.Filare" }), sub: "", pos: 100, zona: "Default" },
            { nome: system.out1 || intl.formatMessage({ id: "App.info.Out1" }), tipo: intl.formatMessage({ id: "App.info.Filare" }), sub: "", pos: 101, zona: "Default" },
            { nome: system.out2 || intl.formatMessage({ id: "App.info.Out2" }), tipo: intl.formatMessage({ id: "App.info.Filare" }), sub: "", pos: 102, zona: "Default" },
            { nome: system.out3 || intl.formatMessage({ id: "App.info.Out3" }), tipo: intl.formatMessage({ id: "App.info.Filare" }), sub: "", pos: 103, zona: "Default" },
            { nome: system.out4 || intl.formatMessage({ id: "App.info.Out4" }), tipo: intl.formatMessage({ id: "App.info.Filare" }), sub: "", pos: 104, zona: "Default" }
        ];
        const _lm = {};
        _lm.elementi = elems;
        _lm.zone = [
            { nome: intl.formatMessage({ id: "App.info.Interno" }), tipo: 1 },
            { nome: intl.formatMessage({ id: "App.info.Garage" }), tipo: 1 },
            { nome: intl.formatMessage({ id: "App.info.Giardino" }), tipo: 1 },
            { nome: intl.formatMessage({ id: "App.info.Default" }), tipo: 1 }
        ];
        _lm.model = system.model;
        _lm.map_type = "logical";
        return _lm;
    };

    const moveElem = (pos, tipo) => {
        const _lm = clone(lm);
        let findIndex = _lm.elementi.findIndex(x => x.tipo === tipo && x.pos === pos);
        if (findIndex >= 0) {
            _lm.elementi[findIndex].zona = destZone;
        }
        setLm(_lm);
    };

    const renameZone = (zone, name) => {
        const _lm = clone(lm);
        let findIndex = _lm.zone.findIndex(x => x.nome === zone);
        if (findIndex >= 0) {
            _lm.zone[findIndex].nome = name;
            for (let i = 0; i < _lm.elementi.length; i++) {
                if (_lm.elementi[i].zona === zone) {
                    _lm.elementi[i].zona = name;
                }
            }
        }
        setLm(_lm);
    };

    const deleteZone = zone => {
        const _lm = clone(lm);
        _lm.zone = _lm.zone.filter(x => x.nome != zone);
        for (let i = 0; i < _lm.elementi.length; i++) {
            if (_lm.elementi[i].zona === zone) {
                _lm.elementi[i].zona = intl.formatMessage({ id: "App.info.Default" });
            }
        }
        setLm(_lm);
    };

    const addZone = zone => {
        const _lm = clone(lm);
        _lm.zone.push({ nome: zone, tipo: 1 });
        setLm(_lm);
    };

    const handleSubmit = e => {
        e.preventDefault();
        setLogicMap({
            id_centrale: system.id_centrale,
            logic_map: lm
        });
        setProcessed(true);
    }

    const handleCreate = e => {
        e.preventDefault();
        const _lm = makeLm();
        setLm(_lm);
        const payload = {
            id_centrale: system.id_centrale,
            logic_map: _lm
        };
        setLogicMap(payload);
        setProcessed(true);
    }

    if (data && processed) {
        setProcessed(null);
        if (!!data && !!data.result.ok) {
            setSuccess(true);
            setStore({ lm });
        } else {
            setSuccess(false);
        }
    }
    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    {movePopup && (
                        <Popup
                            title={intl.formatMessage({ id: "App.info.AssociaIngressoAdAltroAmbiente" })}
                            text={``}
                            onConfirm={() => { moveElem(movePopup.pos, movePopup.tipo); setMovePopup(false); }}
                            onClose={() => { setMovePopup(false); }}
                        >
                            <div className="w100 formSection">
                                <form>
                                    <p>
                                        <FormattedMessage id="App.info.SelezionaAmbiente" />
                                    </p>
                                    <fieldset>
                                        {lm.zone.map((x, idx) => {
                                            return (
                                                <div key={`checkbox_${idx}`} className="w100">
                                                    <input type="radio" id={`checkbox_${idx}`} name={intl.formatMessage({ id: "App.info.Ambienti_" })} defaultValue="1" defaultChecked={movePopup.zona === x.nome} onChange={() => { setDestZone(x.nome) }} />
                                                    <label htmlFor={`checkbox_${idx}`}>
                                                        <span />
                                                        {x.nome}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </fieldset>
                                </form>
                            </div>
                        </Popup>
                    )}
                    <div className="w100 formSection">
                        <form>
                            <h3>
                                <FormattedMessage id="App.info.Ambienti" />
                            </h3>
                            <br />
                            {
                                (!!!system.store.lm)
                                    ? (
                                        <>
                                            <fieldset>
                                                <p>
                                                    <FormattedMessage id="App.info.NoMappaLogicaAmbienti" />
                                                </p>
                                            </fieldset>
                                            <fieldset>
                                                <div className="rack">
                                                    <div className="w100 right">
                                                        <button className="ok auto spaced" onClick={handleCreate}>
                                                            <FormattedMessage id="App.info.Crea" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </>
                                    ) : (
                                        <>
                                            <fieldset>
                                                <div className="rack">
                                                    {lm.zone.map((x, idx) => {
                                                        return <Ambiente
                                                            key={`ambienti_${idx}`}
                                                            moveFunc={setMovePopup}
                                                            renameFunc={renameZone}
                                                            deleteFunc={deleteZone}
                                                            name={x.nome}
                                                            devs={(clone(lm.elementi)).filter(y => y.zona === x.nome)}
                                                            zones={lm.zone.map(x => x.nome)}
                                                        />
                                                    })}
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="rack">
                                                    <div className="w33">
                                                        <label htmlFor={intl.formatMessage({ id: "App.info.AddZone" })}>
                                                            <FormattedMessage id="App.info.AggiungiAmbiente" />
                                                        </label>
                                                        <input id="addZone" style={{ display: "inline-block", width: '75%' }} type="text" name="zoneName" value={newZoneName} onChange={e => {
                                                            let newName = e.target.value;
                                                            setNewZoneName(newName);
                                                            setValidNew(!(lm.zone.map(x => x.nome)).includes(newName) && newName.length > 0 && newName.length < 30);
                                                        }} />
                                                        <Link title={intl.formatMessage({ id: "App.info.AggiungiAmbiente" })} to={'#'} onClick={e => { e.preventDefault(); if (validNew) { addZone(newZoneName); setNewZoneName(''); setValidNew(false); } }}>
                                                            <i className={`fa fa-plus-square`} style={{ margin: ".25em .5em 0", verticalAlign: "sub", cursor: "pointer", fontSize: "2em", color: validNew ? "darkgreen" : "gray" }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset>
                                                <div className="rack">
                                                    <div className="w100 right">
                                                        <button className="ok auto spaced" onClick={e => { e.preventDefault(); setLm(system.store.lm); }}>
                                                            <FormattedMessage id="App.info.Annulla" />
                                                        </button>
                                                        <button className="ok auto spaced" onClick={handleSubmit}>
                                                            <FormattedMessage id="App.info.Applica" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </>
                                    )
                            }
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const MappaLogica = connect(mapStateToProps, mapDispatchToProps)(_MappaLogica);

export default MappaLogica;
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bOpzioni } from '../../../../lib/goldParser/converter';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldOptions: (requests, payloads) => dispatch(goldDoRequest(requests, payloads))
});

const _Opzioni = ({ data, errors, system, goldOptions }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(!!_system.store ? { opzioni: _system.store.pm.opzioni, cicli_sup: _system.store.pm.cicli_sup } : null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);
    const intl = useIntl();

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (!!!current) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            opzioni: {
                toni_ins: form.toni_ins.checked,
                toni_ingr: form.toni_ingr.checked,
                led_on: form.led_on.checked,
                autoreset: form.autoreset.checked,
                rit_no_rete: form.rit_no_rete.checked,
                all_falsa_chiave: form.all_falsa_chiave.checked,
                chiave_base: form.chiave_base.checked,
                buzzer: form.buzzer.checked,
                abil_campanello: form.abil_campanello.checked,
                abil_asterisco: form.abil_asterisco.checked,
                des: form.des.checked,
                inversione: form.inversione.checked,
                antimask: form.antimask.checked,
                supervisione: !form.supervisione.checked
            },
            cicli_sup: form.cicli_sup.value
        };
    };

    const handleReset = e => {
        e.preventDefault();
        setCurrent(null);
        setTimeout(() => setCurrent({
            opzioni: _system.store.pm.opzioni,
            cicli_sup: _system.store.pm.cicli_sup
        }), 100);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        const requests = [];
        const payloads = [];
        requests.push('goldWriteOptions');
        payloads.push({
            id_centrale: system.id_centrale,
            edata: j2bOpzioni(payload.opzioni)
        });
        requests.push('goldWriteCycles');
        payloads.push({
            id_centrale: system.id_centrale,
            edata: [parseInt(payload.cicli_sup)]
        });
        goldOptions(requests, payloads);
        setProcessed(true);
    };

    if (data && processed) {
        setProcessed(null);
        setSuccess(!!data && !errors);
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuc" })} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm} className="stackableW33">
                            <h3>
                                <FormattedMessage id="App.info.Opzioni" />
                            </h3>
                            <br />
                            <fieldset>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="toni_ins" name="toni_ins" defaultValue="0" defaultChecked={current.opzioni.toni_ins} />
                                            <label htmlFor={`toni_ins`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.ToniInserimentoDisinserimento" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="toni_ingr" name="toni_ingr" defaultValue="0" defaultChecked={current.opzioni.toni_ingr} />
                                            <label htmlFor={`toni_ingr`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.ToniIngressoUscita" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="buzzer" name="buzzer" defaultValue="0" defaultChecked={current.opzioni.buzzer} />
                                            <label htmlFor={`buzzer`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.BuzzerTastieraIngressoUscita" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.opzioni.led_on} />
                                            <label htmlFor={`led_on`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.LedOn" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="chiave_base" name="chiave_base" defaultValue="0" defaultChecked={current.opzioni.chiave_base} />
                                            <label htmlFor={`chiave_base`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.ChiaveBase" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="autoreset" name="autoreset" defaultValue="0" defaultChecked={current.opzioni.autoreset} />
                                            <label htmlFor={`autoreset`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.Autoreset" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="rit_no_rete" name="rit_no_rete" defaultValue="0" defaultChecked={current.opzioni.rit_no_rete} />
                                            <label htmlFor={`rit_no_rete`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.RitardoAssenzaRete" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="all_falsa_chiave" name="all_falsa_chiave" defaultValue="0" defaultChecked={current.opzioni.all_falsa_chiave} />
                                            <label htmlFor={`all_falsa_chiave`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AllarmeChiaveFalsa" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={!current.opzioni.supervisione} />
                                            <label htmlFor={`supervisione`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.SupervisioneSilenziosa" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="antimask" name="antimask" defaultValue="0" defaultChecked={current.opzioni.antimask} />
                                            <label htmlFor={`antimask`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AntimaskSilenzioso" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="abil_campanello" name="abil_campanello" defaultValue="0" defaultChecked={current.opzioni.abil_campanello} />
                                            <label htmlFor={`abil_campanello`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AbilitazioneCampanello" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="abil_asterisco" name="abil_asterisco" defaultValue="0" defaultChecked={current.opzioni.abil_asterisco} />
                                            <label htmlFor={`abil_asterisco`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AbilitazioneTasto" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack switchRack">
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="des" name="des" defaultValue="0" defaultChecked={current.opzioni.des} />
                                            <label htmlFor={`des`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AllarmeDes" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="inversione" name="inversione" defaultValue="0" defaultChecked={current.opzioni.inversione} />
                                            <label htmlFor={`inversione`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.InversionePolarita" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w33">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="wifi" name="wifi" defaultValue="0" defaultChecked={!!system.store.pm.wifi_set.on} disabled={true} />
                                            <label htmlFor={`wifi`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.AbilitaWifi" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w25">
                                        <label>
                                            <FormattedMessage id="App.info.CicliSupervisione" />
                                        </label>
                                        <select defaultValue={current.cicli_sup} name="cicli_sup">
                                            <option value={0}>
                                                1
                                            </option>
                                            <option value={1}>
                                                2
                                            </option>
                                            <option value={2}>
                                                3
                                            </option>
                                            <option value={3}>
                                                4
                                            </option>
                                            <option value={4}>
                                                5
                                            </option>
                                            <option value={5}>
                                                6
                                            </option >
                                        </select>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset}>
                                            <FormattedMessage id="App.info.Annulla" />
                                        </button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>
                                            <FormattedMessage id="App.info.Applica" />
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const Opzioni = connect(mapStateToProps, mapDispatchToProps)(_Opzioni);

export default Opzioni;
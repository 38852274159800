import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    errorRead,
    doRequest,
    getProfile
} from '../../../actions';
import Outcome from '../../elements/Outcome';
import SchemaValidator from '../../../lib/SchemaValidator';
import System from '../../elements/Forms/System';
import User from '../../elements/Forms/User';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    stateError: state.error,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    errorRead: () => dispatch(errorRead()),
    createSystem: payload => dispatch(doRequest('createSystem', payload)),
    checkOTP: payload => dispatch(doRequest('checkOTP', payload)),
    createOTP: payload => dispatch(doRequest('createOTP', payload)),
    getModels: () => dispatch(doRequest('getModels')),
    getProfile: () => dispatch(getProfile()),
});

const _NewSystem = ({ getProfile, data, errorRead, createOTP, stateError, user, createSystem, checkOTP, getModels }) => {
    const systemModel = {
        model: null,
        name: '',
        email: '',
        password: '',
        description: '',
        notes: '',
        firmware: '',
        geodata: '',
        todo: '',
        state: '',
        options: 0
    };

    const initialPayload = {
        maintainer_is_owner: false,
        email_of_owner: null,
        maintainer: { ...user },
        owner: {
            email: null,
            geodata: null,
            otp: null
        },
        system: { ...systemModel }
    }
    initialPayload.maintainer.email = user.email;
    const decodedMaintainerGeodata = user.geodata ? JSON.parse(user.geodata) : null;

    const [errors, setErrors] = useState("");
    const [processed, setProcessed] = useState(false);
    const [processedStep2, setProcessedStep2] = useState(false);
    const [processedStep3, setProcessedStep3] = useState(false);
    const [payload, setPayload] = useState(initialPayload);
    const [success, setSuccess] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [wizard, setWizard] = useState(1);
    const [samePerson, setSamePerson] = useState(false);
    const [maintainerAddress, setMaintainerAddress] = useState(decodedMaintainerGeodata && decodedMaintainerGeodata[0] != null ? decodedMaintainerGeodata[0].formatted_address : '');
    const [brand, setBrand] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [models, setModels] = useState([]);
    const [processedModels, setProcessedModels] = useState(false);
    const intl = useIntl();

    useEffect(
        () => {
            if (firstLoad) {
                getModels();
                setProcessedModels(true);
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, setProcessedModels, getModels]
    );

    useEffect(
        () => {
            if (stateError) {
                if (processedStep2) {
                    errorRead();
                    setErrors("")
                    setWizard(2);
                    setProcessedStep2(false);
                } else if (processedStep3) {
                    errorRead();
                    setErrors("")
                    setWizard(3);
                    setProcessedStep3(false);
                } else {
                    setErrors(stateError);
                    errorRead();
                }
            }
            return () => null;
        },
        [errorRead, errors, setErrors, stateError, processedStep2, setProcessedStep2, setProcessedStep3, processedStep3]
    );

    const handleWizard = step => step < wizard ? setWizard(step) : null;

    const handlerMaintainerGeodata = results => {
        let _payload = payload;
        _payload.maintainer.geodata = JSON.stringify(results);

        const _decodedMaintainerGeodata = _payload.maintainer.geodata ? JSON.parse(_payload.maintainer.geodata) : null;
        setMaintainerAddress(_decodedMaintainerGeodata && _decodedMaintainerGeodata[0] != null ? _decodedMaintainerGeodata[0].formatted_address : '');

        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSystemGeodata = results => {
        let _payload = payload;
        _payload.system.geodata = JSON.stringify(results);
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerChangeStep1 = (e) => {
        let _payload = payload;
        if (e instanceof Date) {
            _payload.maintainer.birth_date = new Date(e.getTime() - (e.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        } else if (e.target.type === 'checkbox') {
            _payload.maintainer[e.target.name] = e.target.checked ? 1 : 0;
        } else {
            _payload.maintainer[e.target.name] = e.target.value;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmitStep1 = (e) => {
        e.preventDefault();
        let v = SchemaValidator(payload.maintainer, 'enroll');
        if (v.errors.length > 0) {
            setErrors(v.errors.map(el => !!el.schema.message === true ? el.schema.message : null).filter(el => !!el).join("\n"));
        } else {
            setErrors("");
            setWizard(2);
            window.scrollTo(0, 0);
        }
    };

    const handlerChangeStep2 = (e) => {
        let _payload = payload;
        if (e.target.type === 'checkbox') {
            _payload.maintainer_is_owner = !!e.target.checked;
            _payload.email_of_owner = !!e.target.checked ? payload.maintainer.email : null;
            setSamePerson(!!e.target.checked)
        } else {
            _payload[e.target.name] = e.target.value;
            if (e.target.name === 'email_of_owner' && e.target.value === payload.maintainer.email) {
                _payload.maintainer_is_owner = true;
            }
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmitStep2 = (e) => {
        e.preventDefault();
        setSuccess(false);
        if (payload.maintainer_is_owner) {
            let _payload = payload;
            _payload.owner.email = user.email;
            _payload.owner.geodata = _payload.maintainer.geodata;
            _payload.owner.otp = null;
            setPayload({
                ...payload,
                ..._payload,
            });
            setWizard(4);
            window.scrollTo(0, 0);
        } else {
            let _payload = payload;
            _payload.owner.email = _payload.email_of_owner;
            setPayload({
                ...payload,
                ..._payload,
            });
            setProcessedStep2(true);
            createOTP({ email: payload.email_of_owner });
        }
    };

    const handlerChangeStep3 = (e) => {
        let _payload = payload;
        _payload.owner[e.target.name] = e.target.value;
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmitStep3 = (e) => {
        e.preventDefault();
        setErrors("");
        setProcessedStep3(true);
        checkOTP({ email: payload.email_of_owner, otp: payload.owner.otp });
    };

    const handlerChangeStep5 = (e) => {
        let _payload = payload;
        if (e.target.type === 'radio') {
            if (e.target.checked) {
                _payload.system[e.target.name] = e.target.value;
            }
        } else if (e.target.type === 'checkbox') {
            if (e.target.checked) {
                _payload[e.target.name] = parseInt(e.target.value);
            } else {
                _payload[e.target.name] = 0;
            }
        } else if (e.target.name === 'model') {
            _payload.system[e.target.name] = parseInt(e.target.value);
        } else {
            _payload.system[e.target.name] = e.target.value;
        }
        setPayload({
            ...payload,
            ..._payload,
        });
    };

    const handlerSubmitStep5 = e => {
        e.preventDefault();
        if (brand === 'lince-europlus' && !!!payload.system.model) {
            setErrors(intl.formatMessage({ id: "App.info.SelezionareModelloImpianto" }));
        } else {
            let v = SchemaValidator(payload.system, 'system');
            if (v.errors.length > 0) {
                setErrors(v.errors.map(el => !!el.schema.message === true ? el.schema.message : null).filter(el => !!el).join(" - \n"));
            } else {
                setErrors("");
                setSuccess(false);
                setProcessed(true);
                let _payload = payload;
                createSystem(_payload);
            }
        }
        window.scrollTo(0, 0);
    };

    if (data && processedModels) {
        setProcessedModels(false);
        setModels(data);
    }

    if (data && processedStep2) {
        setErrors("");
        setProcessedStep2(false);
        setWizard(3);
    }

    if (data && processedStep3) {
        setErrors("");
        let _payload = payload;
        _payload.owner.email = data.email;
        _payload.owner.geodata = data.geodata;
        _payload.owner.otp = null;
        setPayload({
            ...payload,
            ..._payload,
        });
        setProcessedStep3(false);
        setWizard(4);
    }

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        setSuccess(true);
        setSuccessModal(true);
        setWizard(6);
        setTimeout(() => {
            getProfile();
        }, 500);
    }

    const renderWizardPath = () => (
        <div className="wizard rack">
            <div className={`w20${wizard === 1 ? ` step-enabled` : ``}`}>
                <i className={`fa fa-user enabled`} onClick={() => handleWizard(1)} />
                <span>
                    <FormattedMessage id="App.info.AnagraficaManutentore" />
                </span>
            </div>
            <div className={`w20${wizard === 2 ? ` step-enabled` : ``}`}>
                <i className={`fa fa-search${wizard > 1 ? ` enabled` : ``}`} onClick={() => handleWizard(2)} />
                <span>
                    <FormattedMessage id="App.info.RicercaProprietario" />
                </span>
            </div>
            <div className={`w20${wizard === 3 ? ` step-enabled` : ``}`}>
                <i className={`fa fa-key${wizard > 2 ? ` enabled` : ``}`} onClick={() => { let step = payload.maintainer_is_owner ? 2 : 3; handleWizard(step); }} />
                <span>
                    <FormattedMessage id="App.info.OtpProprietario" />
                </span>
            </div>
            <div className={`w20${wizard === 4 ? ` step-enabled` : ``}`}>
                <i className={`fa fa-server${wizard > 3 ? ` enabled` : ``}`} onClick={() => handleWizard(4)} />
                <span>
                    <FormattedMessage id="App.info.TipoImpianto" />
                </span>
            </div>
            <div className={`w20${wizard === 5 ? ` step-enabled` : ``}`}>
                <i className={`fa fa-cogs${wizard > 4 ? ` enabled` : ``}`} onClick={() => handleWizard(5)} />
                <span>
                    <FormattedMessage id="App.info.AnagraficaImpianto" />
                </span>
            </div>
        </div>
    );

    const renderStep1 = () => (
        <User
            user={user}
            handleChange={handlerChangeStep1}
            handleSubmit={handlerSubmitStep1}
            saveButton={intl.formatMessage({ id: "App.info.Avanti" })}
            handlerGeodata={handlerMaintainerGeodata}
            address={maintainerAddress}
            picture={true}
        />
    );

    const renderStep2 = () => (
        <div className="formSection">
            <form>
                <fieldset>
                    <h3>
                        <FormattedMessage id="App.info.Dati" />
                    </h3>
                    <div className="rack">
                        <div className="w100">
                            <p>
                                <strong>
                                    <FormattedMessage id="App.info.IdentificaProprietario" />
                                </strong>
                            </p>
                        </div>
                        <div className="w33">
                            <label>
                                <FormattedMessage id="App.info.Email*" />
                            </label>
                            <input type="email" disabled={samePerson} name="email_of_owner" value={samePerson ? payload.maintainer.email : payload.email_of_owner || ``} onChange={handlerChangeStep2} />
                        </div>
                        <div className="w50">
                            < br />
                            <input type="checkbox" id="maintainerIsOwner" name="maintainer_is_owner" defaultValue="1" defaultChecked={payload.maintainer_is_owner ? 1 : 0} onChange={handlerChangeStep2} />
                            <label htmlFor={`maintainerIsOwner`}>
                                <span />
                                <FormattedMessage id="App.info.ManutentoreProprietario" />
                            </label>
                        </div>
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            <button className="ok auto" onClick={() => { setWizard(1); window.scrollTo(0, 0); }}>
                                <FormattedMessage id="App.info.Indietro" />
                            </button>
                        </div>
                        <div className="w50 right">
                            <p><small>
                                <FormattedMessage id="App.info.CampiObbligatori" />
                            </small></p>
                            <button className="ok auto" onClick={handlerSubmitStep2}>
                                <FormattedMessage id="App.info.Avanti" />
                            </button>
                            <br />
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );

    const renderStep3 = () => (
        <div className="formSection">
            <form>
                <fieldset>
                    <h3>
                        <FormattedMessage id="App.info.CodiceProprietario" />
                    </h3>
                    <div className="rack">
                        <div className="w100">
                            <p>
                                <strong>
                                    <FormattedMessage id="App.info.OtpInviato" />
                                </strong>
                            </p>
                        </div>
                        <div className="w33">
                            <label>
                                <FormattedMessage id="App.info.CodiceOtp*" />
                            </label>
                            <input type="password" name="otp" onChange={handlerChangeStep3} />
                        </div>
                    </div>
                    <div className="rack buttonPanel">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            <button className="ok auto" onClick={() => { setWizard(2); window.scrollTo(0, 0); }}>
                                <FormattedMessage id="App.info.Indietro" />
                            </button>
                        </div>
                        <div className="w50 right">
                            <p><small>
                                <FormattedMessage id="App.info.CampiObbligatori" />
                            </small></p>
                            <button className="ok auto" onClick={handlerSubmitStep3}>
                                <FormattedMessage id="App.info.Avanti" />
                            </button>
                            <br />
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );

    const renderStep4 = () => (
        <div className="formSection">
            <form>
                <fieldset>
                    <h3>
                        <FormattedMessage id="App.info.TipoImpianto" /></h3>
                    <div className="rack">
                        <div className="w100">
                            <p>
                                <strong>
                                    <FormattedMessage id="App.info.SelezionaTipologiaImpianto" />
                                </strong>
                            </p>
                        </div>
                        <div className="w50" style={{ textAlign: "center" }}>
                            <Link to={`/`} onClick={(e) => { e.preventDefault(); setBrand('lince-europlus'); setWizard(5); window.scrollTo(0, 0); }}>
                                <img src="/static/images/lince-europlus.png" alt="Lince Europlus" title={intl.formatMessage({ id: "App.info.NuovoImpiantoEuroplus" })} />
                            </Link>
                        </div>
                        <div className="w50" style={{ textAlign: "center" }}>
                            <Link to={`/`} onClick={(e) => { e.preventDefault(); setBrand('lince-gold'); setWizard(5); window.scrollTo(0, 0); }}>
                                <img src="/static/images/lince-gold.png" alt="Lince Gold" title={intl.formatMessage({ id: "App.info.NuovoImpiantoGold" })} />
                            </Link>
                        </div>
                        <div className="w50" style={{ textAlign: "center" }}>
                            <Link to={`/`} onClick={(e) => { e.preventDefault(); setBrand('italiana-sensori'); setWizard(5); window.scrollTo(0, 0); }}>
                                <img src="/static/images/italiana-sensori.png" alt="Italiana Sensori" title={intl.formatMessage({ id: "App.info.NuovoImpiantoItalianaSensori" })} />
                            </Link>
                        </div>
                        <div className="w50" style={{ textAlign: "center" }}>
                            <Link to={`/`} onClick={(e) => { e.preventDefault(); setBrand('lince-pro'); setWizard(5); window.scrollTo(0, 0); }}>
                                <img src="/static/images/lince-pro.png" alt="Lince Pro" title={intl.formatMessage({ id: "App.info.NuovoImpiantoLincePro" })} />
                            </Link>
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w50 left">
                            <p>&nbsp;</p>
                            <button className="ok auto" onClick={() => { setWizard(2); window.scrollTo(0, 0); }}>
                                <FormattedMessage id="App.info.Indietro" />
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );

    const renderStep5 = () => (
        <System
            system={{ ...systemModel }}
            handleCancel={() => { setWizard(4); window.scrollTo(0, 0); }}
            handleChange={handlerChangeStep5}
            handleSubmit={handlerSubmitStep5}
            cancelButton={intl.formatMessage({ id: "App.info.Indietro" })}
            ownerGeodata={payload.owner.geodata}
            address={``}
            handlerGeodata={handlerSystemGeodata}
            permissions={3}
            models={models.map(model => model.brand === brand ? model : null).filter(x => !!x)}
            modelRequired={(brand === 'lince-europlus')}
        />
    );

    if (!user.can_create_system && wizard != 6) {
        return (
            <section>
                <div className="innerSection">
                    <br />
                    <h3>
                        <FormattedMessage id="App.info.NuovoImpianto" />
                    </h3>
                    <div className="rack box">
                        <div className="w100">
                            <blockquote>
                                <FormattedMessage id="App.info.LimiteImpiantiRaggiunto" />
                                <p>
                                    <FormattedMessage id={"App.info.ScopriVantaggi"} />
                                    <Link to={`/premium`}>
                                        <FormattedMessage id={"App.info.Abbonati"} />
                                    </Link>
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="innerSection">
                <br />
                <h3>
                    <FormattedMessage id="App.info.NuovoImpianto" />
                </h3>
                {successModal
                    ? (
                        <Outcome text="Impianto creato con successo" type="ok" onClose={() => setSuccessModal(false)} />
                    )
                    : !!errors && errors.length
                        ? <Outcome text={errors} type="ko" onClose={setErrors} />
                        : <></>
                }
                {success && (
                    <div className="formSection">
                        <form>
                            <fieldset>
                                <div className="rack">
                                    <div className="w100">
                                        <p>
                                            <FormattedMessage id="App.info.NuovoImpiantoRegistrato" />
                                        </p>
                                        {
                                            !payload.maintainer_is_owner && (
                                                <p>
                                                    <formatMessage id="App.info.NotificaAlProprietario" /> <strong>{payload.owner.email}.</strong>
                                                </p>
                                            )
                                        }
                                        <p><Link to={`/`}>
                                            <FormattedMessage id="App.info.TornaAgliImpianti" />
                                        </Link></p>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                )}
                {wizard !== 6 && renderWizardPath()}
                {wizard === 1 && renderStep1()}
                {wizard === 2 && renderStep2()}
                {wizard === 3 && renderStep3()}
                {wizard === 4 && renderStep4()}
                {wizard === 5 && renderStep5()}
            </div>
        </section>
    );
};


const NewSystem = connect(mapStateToProps, mapDispatchToProps)(_NewSystem);

export default NewSystem;
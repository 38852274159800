export const GOLD_AUTH = {
    1: 'App.info.Utente',
    2: 'App.info.Installatore',
    4: 'App.info.Amministratore'
}

export const USCITE = [
    'App.info.Nessuno',
    'App.info.AllarmeA_',
    'App.info.AllarmeK_',
    'App.info.StatoImpianto',
    'App.info.Fuoco',
    'App.info.Silenzioso',
    'App.info.Campanello',
    'App.info.Elettroserratura',
    'App.info.ProgG1',
    'App.info.ProgG2',
    'App.info.ProgG3',
    'App.info.DisturboRadio',
    'App.info.Remoto',
    'App.info.Guasto',
    'App.info.Timer1',
    'App.info.Timer2',
    'App.info.Allagamento',
    'App.info.IngressiAperti'
];

const MCC_code = [
    202, 204, 206, 208, 212, 213, 214, 216, 218, 219, 220, 222, 225, 226, 228, 230, 231, 232, 234, 235,
    238, 240, 242, 244, 246, 247, 248, 250, 255, 257, 259, 260, 262, 266, 268, 270, 272, 274, 276, 278
];
const MCC_desc = [
    "GR", "NL", "BE", "FR", "MC", "AD", "ES", "HU", "BA", "HR", "RS", "IT", "VA", "RO", "CH", "CZ", "SK", "AT", "GB", "GB",
    "DK", "SE", "NO", "FI", "LT", "LV", "EE", "RU", "UA", "BY", "MD", "PL", "DE", "GI", "PT", "LU", "IE", "IS", "AL", "MT"
];

export const MCC = ((keys, values) => {
    let obj = {};
    for (let i = 0; i < keys.length; i++) {
        obj[keys[i]] = values[i];
    }
    return obj
})(MCC_code, MCC_desc);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { loadStripe } from '@stripe/stripe-js';
import { doRequest, errorRead } from '../../../actions';
import Modal from '../../elements/Modal';
import Outcome from '../../elements/Outcome';
import { FormattedMessage, useIntl } from 'react-intl';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PK);

const urlPropsQueryConfig = {
    session_id: { type: UrlQueryParamTypes.string },
}

const mapDispatchToProps = (dispatch) => ({
    deleteSubscription: () => dispatch(doRequest('deleteSubscription')),
    getCheckoutSession: type => dispatch(doRequest('getCheckoutSession', type)),
    verifyCheckoutSession: payload => dispatch(doRequest('verifyCheckoutSession', payload)),
    errorRead: () => dispatch(errorRead()),
});

const mapStateToProps = state => ({
    user: state.user,
    data: state.data,
    stateError: state.error
});

const _Premium = ({ data, errorRead, getCheckoutSession, stateError, session_id, user, verifyCheckoutSession, deleteSubscription }) => {
    const [confirm, setConfirm] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processedDelete, setProcessedDelete] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [errors, setErrors] = useState("");
    const isPremium = user.premium_date;
    const isSimPremium = user.sim_premium_date;
    const intl = useIntl();

    useEffect(
        () => {
            if (!!session_id && firstLoad) {
                verifyCheckoutSession({ session_id });
                setProcessed2(true);
                setFirstLoad(false);
            }
            if (stateError) {
                setErrors(stateError);
                errorRead();
            }
            return () => setFirstLoad(false);
        },
        [errorRead, setErrors, stateError, session_id, setFirstLoad, firstLoad, verifyCheckoutSession, setProcessed2]
    );

    const handleClickMontlhy = async (e) => {
        e.preventDefault();
        getCheckoutSession('checkout-monthly');
        setProcessed(true);
    }

    const handleClickYearly = async (e) => {
        e.preventDefault();
        getCheckoutSession('checkout-yearly');
        setProcessed(true);
    }

    const handleClickDelete = async (e) => {
        e.preventDefault();
        setConfirm(true);
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        deleteSubscription();
        setProcessedDelete(true);
        setConfirm(false);
    }

    const handleAfterClick = async sessionId => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
            setErrors(error.message);
        }
    };

    if (data && processed) {
        setErrors("");
        setProcessed(false);
        const { id } = data;
        handleAfterClick(id);
    }

    if (data && processed2) {
        setErrors("");
        setProcessed2(false);
        window.location.replace(window.location.pathname);
        return (<></>);
    }

    if (data && processedDelete) {
        setErrors("");
        setProcessedDelete(false);
        window.location.replace(window.location.pathname);
        return (<></>);
    }

    return (
        <section>
            <div className="innerSection">
                <h1>
                    <FormattedMessage id="App.info.ServiziAPagamento" />
                </h1>
                {!!errors && errors.length
                    ? <Outcome text={errors} type="ko" onClose={setErrors} />
                    : <></>
                }
                <div className="formSection">
                    {
                        confirm && (
                            <>
                                <Modal
                                    title={intl.formatMessage({ id: "App.info.EliminaAbbonamento" })}
                                    text={intl.formatMessage({ id: "App.info.LePasswordNonCoincidono" })}
                                    onClose={closeModal}
                                    onConfirm={confirmDelete}
                                />
                            </>
                        )
                    }
                    <form>
                        <fieldset>
                            <div className="rack">
                                <div className="w50">
                                    <p><strong>
                                        <FormattedMessage id="App.info.ScopriVantaggiPremium" />
                                    </strong></p>
                                    <ul>
                                        <li>
                                            <FormattedMessage id="App.info.GestioneSenzaLimiti" />
                                        </li>
                                        {/*<li>Import/Export della configurazioni degli impianti</li>*/}
                                        <li>
                                            <FormattedMessage id="App.info.ManutenzioneImpiantoInst" />
                                        </li>
                                    </ul>
                                    <p><strong>
                                        <FormattedMessage id="App.info.CostiEPagamenti" />
                                    </strong></p>
                                    <ul>
                                        <li><FormattedMessage id="App.info.AttivazionePro1" /></li>
                                        <li><FormattedMessage id="App.info.AttivazionePro2" /></li>
                                        <li><FormattedMessage id="App.info.AttivazionePro3" /></li>
                                    </ul>
                                </div>
                                {
                                    isSimPremium
                                        ? (
                                            <div className="w50">
                                                <h3>
                                                    <FormattedMessage id="App.info.PremiumAttivo" />
                                                </h3>
                                                <p>
                                                    <FormattedMessage id="App.info.SeiPremium" /></p>
                                                <Link title={`Gestione SIM`} to={`/sim`}>
                                                    <FormattedMessage id="App.info.VaiAGestioneSim" />
                                                </Link>
                                            </div>
                                        ) : (
                                            isPremium
                                                ? (
                                                    <div className="w50">
                                                        <h3>
                                                            <FormattedMessage id="App.info.PremiumAttivo" />
                                                        </h3>
                                                        <p>
                                                            <FormattedMessage id="App.info.Scadenza" />
                                                            {((new Date(isPremium)).toLocaleDateString('it-it', { year: 'numeric', month: 'long', day: 'numeric' }))}</p>
                                                        <button role="link" onClick={handleClickDelete}>
                                                            <FormattedMessage id="App.info.InterrompiAbbonamento" />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="w50">
                                                        <div>
                                                            <h3>
                                                                <FormattedMessage id="App.info.AbbonamentoMensile" />
                                                            </h3>
                                                            <button role="link" onClick={handleClickMontlhy}>
                                                                <FormattedMessage id="App.info.VaiAlPagamento" />
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <h3>
                                                                <FormattedMessage id="App.info.AbbonamentoAnnuale" />
                                                            </h3>
                                                            <button role="link" onClick={handleClickYearly}>
                                                                <FormattedMessage id="App.info.VaiAlPagamento" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )

                                        )
                                }
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </section>
    );
};

const Premium = addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(_Premium));

export default Premium;
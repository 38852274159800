import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bSupertasti } from '../../../../lib/goldParser/converter';
import {
    goldDoRequest
} from '../../../../actions';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.data,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldWriteSuperButtons: payload => dispatch(goldDoRequest('goldWriteSuperButtons', payload))
});

const _SuperTasti = ({ data, system, goldWriteSuperButtons }) => {
    const _system = clone(system);
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(!!_system.store ? _system.store.pm.supertasti : null);
    const [processed, setProcessed] = useState(null);
    const [success, setSuccess] = useState(null);
    const intl = useIntl();

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (!!!current) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            supertasto1: form.supertasto1.checked,
            supertasto2: form.supertasto2.checked,
            supertasto3: form.supertasto3.checked,
            supertasto4: form.supertasto4.checked
        };
    };

    const handleReset = e => {
        e.preventDefault();
        setCurrent(null);
        setTimeout(() => setCurrent(_system.store.pm.supertasti), 100);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        goldWriteSuperButtons({
            id_centrale: system.id_centrale,
            edata: j2bSupertasti(payload)
        });
        setProcessed(true);
    };

    if (data && processed) {
        setProcessed(null);
        setSuccess(!!(data.status === 'OK'));
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <br />
                <br />
                <br />
                <h3>
                    <FormattedMessage id="App.info.Supertasti" />
                </h3>
                <br />
                <div className="rack">
                    <div className="w100 formSection">
                        <form ref={codeForm}>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto1" name="supertasto1" defaultValue="0" defaultChecked={current.supertasto1} onChange={() => null} />
                                            <label htmlFor={`supertasto1`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.Supertasto1AttUscRele" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto2" name="supertasto2" defaultValue="0" defaultChecked={current.supertasto2} onChange={() => null} />
                                            <label htmlFor={`supertasto2`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.Supertasto2AttUscOut1" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto3" name="supertasto3" defaultValue="0" defaultChecked={current.supertasto3} onChange={() => null} />
                                            <label htmlFor={`supertasto3`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.Supertasto3AttUscUscOut" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w50">
                                        <div className="switchButton">
                                            <input type="checkbox" className="switch" id="supertasto4" name="supertasto4" defaultValue="0" defaultChecked={current.supertasto4} onChange={() => null} />
                                            <label htmlFor={`supertasto4`}>
                                                <span>
                                                    <span />
                                                </span>
                                                <FormattedMessage id="App.info.Supert4InserimTot" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className="rack" style={{ marginTop: "4em" }}>
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={handleReset}>
                                            <FormattedMessage id="App.info.Annulla" />
                                        </button>
                                        <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>
                                            <FormattedMessage id="App.info.Applica" />
                                        </button>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const SuperTasti = connect(mapStateToProps, mapDispatchToProps)(_SuperTasti);

export default SuperTasti;
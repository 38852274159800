import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bRadio } from '../../../../lib/goldParser/converter';
import { b2jRadio, b2jRadioStat } from '../../../../lib/goldParser/physicalMap';
import { hexstringToArrayInt } from '../../../../lib/bytesFunctions';

import Allagamento from './ParametriRadio/Allagamento';
import ContattoMagnetico from './ParametriRadio/ContattoMagnetico';
import ContattoTapparella from './ParametriRadio/ContattoTapparella';
import Fumo from './ParametriRadio/Fumo';
import Nebbiogeno from './ParametriRadio/Nebbiogeno';
import Radiocomando from './ParametriRadio/Radiocomando';
import RilevatoreInterno from './ParametriRadio/RilevatoreInterno';
import RilevatorePerimetraleEsterno from './ParametriRadio/RilevatorePerimetraleEsterno';
import RilevatoreVolumetricoEsterno from './ParametriRadio/RilevatoreVolumetricoEsterno';
import RilevatoreVolumetricoEsterno180 from './ParametriRadio/RilevatoreVolumetricoEsterno180';
import Sirena from './ParametriRadio/Sirena';
import UscitaRadio from './ParametriRadio/UscitaRadio';

import StatoAllagamento from './StatoRadio/StatoAllagamento';
import StatoContattoMagnetico from './StatoRadio/StatoContattoMagnetico';
import StatoFumo from './StatoRadio/StatoFumo';
import StatoRadioComando from './StatoRadio/StatoRadioComando';
import StatoRilevatoreVolumetricoEsterno from './StatoRadio/StatoRilevatoreVolumetricoEsterno';
import StatoRilevatorePerimetraleEsterno from './StatoRadio/StatoRilevatorePerimetraleEsterno';
import StatoRilevatoreVolumetricoEsterno180 from './StatoRadio/StatoRilevatoreVolumetricoEsterno180';
import StatoRilevatoreInterno from './StatoRadio/StatoRilevatoreInterno';
import StatoSirena from './StatoRadio/StatoSirena';
import StatoTapparella from './StatoRadio/StatoTapparella';
import StatoUscitaRadio from './StatoRadio/StatoUscitaRadio';
import StatoNebbiogeno from './StatoRadio/StatoNebbiogeno';

import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldGetRadioAnalysis, goldSetPeripheral, goldExitSystem, sleep } from '../../../../api/Cloud';


const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    reset_dev_stats: state.reset_dev_stats,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldDelPeripheral: payloads => dispatch(goldDoRequest(['goldDelPeripheral', 'goldModOutRadio', 'goldCheckSync'], payloads)),
    goldModOutRadio: payload => dispatch(goldDoRequest('goldModOutRadio', payload)),
    setStore: data => dispatch(setStore(data))
});

const _DispositiviRadio = ({ match, data, errors, system, goldModOutRadio, goldDelPeripheral, setStore, reset_dev_stats }) => {
    const memorization = ["", "Memorizzazione in corso...", "Memorizzazione riuscita", "Memorizzazione fallita"];
    const codeForm = useRef(null);
    const _system = clone(system);
    const [sub, setSub] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [ingressi, setIngressi] = useState([]);
    const [processed, setProcessed] = useState(false);
    const [success, setSuccess] = useState(null);
    const [tempoIngresso, setTempoIngresso] = useState(0);
    const [tempoUscita, setTempoUscita] = useState(0);
    const [antintrusione, setAntintrusione] = useState(null);
    const [mw, setMw] = useState(0);
    const [pir1, setPir1] = useState(0);
    const [pir2, setPir2] = useState(0);
    const [am, setAm] = useState(0);
    const [nome, setNome] = useState(``);
    const [filter, setFilter] = useState(0);
    const intl = useIntl();
    const filters = [
        { gruppo_spec: 0x03, name: intl.formatMessage({ id: "App.info.ContattoMagnetico" }) },
        { gruppo_spec: 0x13, name: intl.formatMessage({ id: "App.info.ContattoTapparella" }) },
        { gruppo_spec: 0x02, name: intl.formatMessage({ id: "App.info.RilevatoreVolumetricoEsterno" }) },
        { gruppo_spec: 0x12, name: intl.formatMessage({ id: "App.info.RilevatorePerimetraleEsterno" }) },
        { gruppo_spec: 0x22, name: intl.formatMessage({ id: "App.info.RilevatoreInterno" }) },
        { gruppo_spec: 0x01, name: intl.formatMessage({ id: "App.info.Radiocomando" }) },
        { gruppo_spec: 0x04, name: intl.formatMessage({ id: "App.info.Sirena" }) },
        { gruppo_spec: 0x05, name: intl.formatMessage({ id: "App.info.UscitaRadio" }) },
        { gruppo_spec: 0x08, name: intl.formatMessage({ id: "App.info.Nebbiogeno" }) },
        { gruppo_spec: 0x06, name: intl.formatMessage({ id: "App.info.Allagamento" }) },
        { gruppo_spec: 0x16, name: intl.formatMessage({ id: "App.info.SensoreDiFumo" }) },
    ];
    const hasPrograms = [0x03, 0x02, 0x12, 0x13, 0x22, 0x32, 0x01];
    const [stat, setStat] = useState(b2jRadioStat(0, { num_tipo_periferica: 0 }));
    const [processed2, setProcessed2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [confirm, setConfirm] = useState(false);

    useEffect(
        () => {
            setFirstLoad(true);
            setSub(match.params.sub > 0 ? parseInt(match.params.sub) : 1);
            return () => {
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
            };
        },
        [match.params.sub]
    );

    useEffect(
        () => {
            if (firstLoad) {
                setRedir(null);
                setCurrent(null);
                setTimeout(() => {
                    let _ingressi = [];
                    for (let i = 0; i < _system.store.pm.radio.length; i++) {
                        _ingressi.push({
                            idx: i,
                            group: Math.floor(i / 16),
                            ..._system.store.pm.radio[i]
                        });
                    }
                    setIngressi(_ingressi);
                    setFirstLoad(false);
                    setCurrent(_ingressi[(sub - 1)]);
                    setStore({
                        polling_requests: ['goldDevStat'],
                        polling_payloads: [{ id_centrale: system.id_centrale, type: 'radio', group: _ingressi[(sub - 1)].group }]
                    });
                    setTempoIngresso(_ingressi[(sub - 1)].tempo_ingresso || 0);
                    setTempoUscita(_ingressi[(sub - 1)].tempo_uscita || 0);
                    setMw(_ingressi[(sub - 1)].mw || 0);
                    setPir1(_ingressi[(sub - 1)].pir1 || 0);
                    setPir2(_ingressi[(sub - 1)].pir2 || 0);
                    setAm(_ingressi[(sub - 1)].am || 0);
                    setNome(_ingressi[(sub - 1)].nome);
                }, 1000);
            }
            return () => null;
        },
        [firstLoad]
    );

    useEffect(
        () => {
            if (current && !!system.store.dev_stats.radio[current.group]) {
                const __i = current.idx - (current.group * 16);
                setStat(b2jRadioStat(system.store.dev_stats.radio[current.group][__i], { num_tipo_periferica: current.num_tipo_periferica, num_spec_periferica: current.num_spec_periferica }));
            }
        },
        [system.store.dev_stats.radio, reset_dev_stats, current]
    );

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const startPolling = _group => {
        setStore({
            polling_requests: ['goldDevStat'],
            polling_payloads: [{ id_centrale: system.id_centrale, type: 'radio', group: current.group }],
            polling_pause: false
        });
    };

    const stopPolling = () => {
        setStore({
            polling_requests: [],
            polling_payloads: [],
            polling_pause: true
        });
    };

    const handleTypeChange = e => {
        const value = parseInt(e.target.value);
        let _ingressi = [];
        for (let i = 0; i < _system.store.pm.radio.length; i++) {
            _ingressi.push({
                idx: i,
                ..._system.store.pm.radio[i]
            });
        }
        if (value) {
            _ingressi = _ingressi.filter(x => x.gruppo_spec === value);
        }
        setFilter(value);
        setIngressi(_ingressi);
        if (!!!_ingressi[0]) {
            setCurrent(false);
        } else if ((sub - 1) != _ingressi[0].idx) {
            setCurrent(null);
            setRedir(`/gold/${system.id}/radio/${(_ingressi[0].idx + 1)}`);
        } else {
            setCurrent(_ingressi[0]);
        }
    }

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setRedir(`/gold/${system.id}/radio/${value}`);
    };

    const confirmDelete = e => {
        e.preventDefault();
        setConfirm(false);
        const payload = clone(current);
        payload.indirizzo_periferica = 0xFFFF;
        payload.num_tipo_periferica = 0xFF;
        payload.num_spec_periferica = 0;
        goldDelPeripheral([
            {
                id_centrale: system.id_centrale,
                type: 'radio',
                n_disp: current.idx
            },
            {
                id_centrale: system.id_centrale,
                idx: current.idx,
                edata: j2bRadio(payload).map(x => isNaN(x) ? 0 : x)
            },
            {
                id_centrale: system.id_centrale
            }
        ]);
        setProcessed3(true);
    };

    const renderParameters = () => {
        let SubModule;
        let SubModule2;
        switch (current.tipo_spec) {
            case 0x01:
            case 0x11:
                SubModule = Radiocomando;
                SubModule2 = StatoRadioComando;
                break;
            case 0x03:
                SubModule = ContattoMagnetico;
                SubModule2 = StatoContattoMagnetico;
                break;
            case 0x13:
                SubModule = ContattoTapparella;
                SubModule2 = StatoTapparella;
                break;
            case 0x02:
                SubModule = RilevatoreVolumetricoEsterno;
                SubModule2 = StatoRilevatoreVolumetricoEsterno;
                break;
            case 0x12:
                SubModule = RilevatorePerimetraleEsterno;
                SubModule2 = StatoRilevatorePerimetraleEsterno;
                break;
            case 0x22:
                SubModule = RilevatoreInterno;
                SubModule2 = StatoRilevatoreInterno;
                break;
            case 0x32:
                SubModule = RilevatoreVolumetricoEsterno180;
                SubModule2 = StatoRilevatoreVolumetricoEsterno180;
                break;
                case 0x04:
                SubModule = Sirena;
                SubModule2 = StatoSirena;
                break;
            case 0x05:
                SubModule = UscitaRadio;
                SubModule2 = StatoUscitaRadio;
                break;
            case 0x06:
                SubModule = Allagamento;
                SubModule2 = StatoAllagamento;
                break;
            case 0x08:
                SubModule = Nebbiogeno;
                SubModule2 = StatoNebbiogeno;
                break;
            case 0x16:
                SubModule = Fumo;
                SubModule2 = StatoFumo;
                break;
            default:
        }

        if (!!!SubModule) {
            return <></>;
        }

        return (
            <>
                <h1>
                    <FormattedMessage id="App.info.Parametri" />
                </h1>
                <SubModule
                    current={current}
                    auth={system.store.auth}
                    setTempoIngresso={setTempoIngresso}
                    setTempoUscita={setTempoUscita}
                    setAntintrusione={setAntintrusione}
                    setAm={setAm}
                    setPir1={setPir1}
                    setPir2={setPir2}
                    setMw={setMw}
                    system={system}
                />
                {!!SubModule2 && (
                    <SubModule2 stat={stat} />
                )}
            </>
        );
    }

    const handleSubmit = e => {
        e.preventDefault();
        const payload = clone(current);
        for (let j in e.target) {
            if (e.target.hasOwnProperty(j)) {
                let field = e.target[j];
                if (!!field.name) {
                    if (field.type === 'checkbox') {
                        payload[field.name] = !!field.checked;
                    } else {
                        payload[field.name] = field.value;
                    }
                }
            }
        }
        payload.tempo_ingresso = parseInt(tempoIngresso);
        payload.tempo_uscita = parseInt(tempoUscita);
        payload.am = parseInt(am) < 25 ? 0 : parseInt(am);
        payload.mw = parseInt(mw);
        payload.pir1 = parseInt(pir1);
        payload.pir2 = parseInt(pir2);
        payload.antintrusione = antintrusione;
        goldModOutRadio({
            id_centrale: system.id_centrale,
            idx: current.idx,
            edata: j2bRadio(payload)
        });
        setProcessed(true);
        setProcessed2(1);
    }

    const handleReset = () => {
        const idx = current.idx;
        setCurrent(null);
        setTimeout(() => setCurrent(ingressi[idx]), 100);
    };

    const afterSubmit = async () => {
        let outcome = 3;
        stopPolling();
        await sleep(1500);
        const [_data, _code] = await goldSetPeripheral({ id_centrale: system.id_centrale, n_disp: current.idx, no_update: true });
        if (_code === 200 && _data.status === 'OK') {
            if (current.tipo_spec === 0x01 || current.tipo_spec === 0x11) {
                outcome = 2;
            } else {
                for (let i = 0; i < 10; i++) {
                    let [__data, __code] = await goldGetRadioAnalysis({ id_centrale: system.id_centrale, type: 0 });
                    if (__code === 200 && __data.status === 'OK') {
                        const arr = hexstringToArrayInt(__data.data[0]);
                        if (arr[2] > 0 || arr[3] > 0) {
                            await sleep(1500);
                            outcome = 2;
                            break;
                        }
                    }
                    await sleep(1500);
                }
            }
        }
        await sleep(1500);
        await goldExitSystem({ id_centrale: system.id_centrale }, true);
        await sleep(1500);
        startPolling();
        setProcessed2(outcome);
    }

    if (redir) {
        const _redir = redir;
        return <Redirect to={_redir} />;
    }

    if (data && processed) {
        setProcessed(null);
        const _success = !!data && !errors;
        const _ingressi = clone(ingressi);
        const _nome = nome;
        if (_success && !!_nome) {
            _ingressi[current.idx].nome = _nome;
            setIngressi(_ingressi);
        }
        setNome(``);
        if (_success) {
            afterSubmit();
        }
        setSuccess(!!data && !errors);
    }

    if (data && processed3) {
        setProcessed3(null);
        let _success = (!!data && !errors) ? 2 : false;
        if (!!errors && errors.length > 0 && errors[0] == 'goldCheckSync') {
            _success = 3;
        }
        if (_success === 2 || _success === 3) {
            let pm = clone(_system.store.pm);
            pm.radio[current.idx] = b2jRadio(['000000000000ffffff0000000000']);
            setStore({
                pm
            });
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={``} subtext={processed2 ? memorization[processed2] : null} onClose={processed2 === 1 ? null : () => { setSuccess(null); setProcessed2(false); }} />}
            {success === 2 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.DispositivoElimConSucc" })} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}`) }} />}
            {success === 3 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.DispositivoElimConSucc" })} redirectText={intl.formatMessage({ id: "App.info.Sincronizza" })} onClose={() => { setSuccess(null); setRedir(`/gold/${system.id}`) }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={`Si é verificato un errore`} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {
                            confirm && (
                                <>
                                    <Modal
                                        title={intl.formatMessage({ id: "App.info.Cancellazione" })}
                                        text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitiva" })}
                                        onClose={() => { setConfirm(false); }}
                                        onConfirm={confirmDelete}
                                    />
                                </>
                            )
                        }
                        <form ref={codeForm} className="stackableW33" onSubmit={handleSubmit}>
                            <h3>
                                <FormattedMessage id="App.info.DispositiviRadio" />
                            </h3>
                            <br />
                            <h1>
                                <FormattedMessage id="App.info.IngressiRadio" />
                            </h1>
                            <fieldset>
                                <div className="rack">
                                    <div className="w50">
                                        <label>
                                            <FormattedMessage id="App.info.FiltraPerTipologia" />
                                        </label>
                                        <select value={filter || 0} name="filter" onChange={handleTypeChange}>
                                            <option value={0}>
                                                {intl.formatMessage({ id: "App.info.Tutti" })}
                                            </option>
                                            {filters.map(value => <option key={`filter_${value.gruppo_spec}`} value={value.gruppo_spec}>{`${value.name}`}</option>)}
                                        </select>
                                    </div>
                                    <div className="w50">
                                        <label>
                                            <FormattedMessage id="App.info.Tipologia" />
                                        </label>
                                        {current
                                            ? <p>{current.tipo_periferica}</p>
                                            : <p>-</p>
                                        }
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w50">
                                        <label>
                                            <FormattedMessage id="App.info.SceltaDispositivo" />
                                        </label>
                                        {current
                                            ? (
                                                <select value={current.idx || 0} name="device" onChange={handleSelectChange}>
                                                    {ingressi.map((value, index) => (
                                                        (filter === 0 || filter === value.gruppo_spec) && (!!value.num_tipo_periferica || index === (sub - 1))
                                                            ? <option key={`device_${index}`} value={value.idx}>{`${value.idx + 1} - ${value.nome}`}</option>
                                                            : <React.Fragment key={`device_${index}`} />
                                                    ))}
                                                </select>
                                            ) : (
                                                <p>
                                                    <FormattedMessage id="App.info.NessunDispositivoRadio" />
                                                </p>
                                            )
                                        }
                                    </div>
                                    <div className="w50">
                                        <label>
                                            <FormattedMessage id="App.info.Nome" />
                                        </label>
                                        {current
                                            ? <input type="text" name="nome" defaultValue={current.nome || ``} maxLength={16} onChange={e => { setNome(e.target.value); }} />
                                            : <p>-</p>
                                        }
                                    </div>
                                </div>
                                {current && !!current.num_tipo_periferica && (hasPrograms.includes(current.tipo_spec)) && (
                                    <div className="rack bottomBorder">
                                        <h4>
                                            <FormattedMessage id="App.info.Programmi" />
                                        </h4>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" defaultChecked={current.g1} disabled={system.store.auth !== 2} />
                                                <label htmlFor={`program_label_g1`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g1}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" defaultChecked={current.g2} disabled={system.store.auth !== 2} />
                                                <label htmlFor={`program_label_g2`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g2}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w25">
                                            <div className="switchButton">
                                                <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" defaultChecked={current.g3} disabled={system.store.auth !== 2} />
                                                <label htmlFor={`program_label_g3`}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {system.g3}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                            <fieldset>
                                {current && renderParameters()}
                            </fieldset>
                            {current && (
                                <fieldset>
                                    <div className="rack">
                                        <div className="w100 right">
                                            <button className="ok auto spaced" onClick={e => { e.preventDefault(); handleReset(); }}>
                                                <FormattedMessage id="App.info.Annulla" />
                                            </button>
                                            <button className="ok auto spaced" onClick={e => { e.preventDefault(); setConfirm(true); }} disabled={system.store.auth !== 2 || ((!current.num_tipo_periferica) || (system.store.state.prog_active))}>
                                                <FormattedMessage id="App.info.Elimina" />
                                            </button>
                                            <button className="ok auto spaced" role="submit" disabled={((!current.num_tipo_periferica) || (system.store.state.prog_active))}>
                                                <FormattedMessage id="App.info.Applica" />
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const DispositiviRadio = connect(mapStateToProps, mapDispatchToProps)(_DispositiviRadio);

export default DispositiviRadio;
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Modal from '../../../elements/Modal';
import Popup from '../../../elements/Modal/Popup';
import { clone } from '../../../../lib/clone';
import { b2jBus, b2jCodice, b2jRadio } from '../../../../lib/goldParser/physicalMap';
import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldGetRadioAnalysis, goldTestRadio, sleep } from '../../../../api/Cloud';
import { hexstringToArrayInt } from '../../../../lib/bytesFunctions';
import { j2bRadio } from '../../../../lib/goldParser/converter';
import { FormattedMessage, useIntl } from 'react-intl';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldDoRequest: (requests, payloads) => dispatch(goldDoRequest(requests, payloads)),
    setStore: data => dispatch(setStore(data))
});

const _Comandi = ({ data, errors, system, goldDoRequest, setStore }) => {
    const codeOtpForm = useRef(null);
    const [serviceStatus, setServiceStatus] = useState(!!system.store.state.stato.servizio);
    const [confirm, setConfirm] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [processed, setProcessed] = useState(null);
    const [processedOTP, setProcessedOTP] = useState(null);
    const [success, setSuccess] = useState(null);
    const [redir, setRedir] = useState(null);
    const [testRadio, setTestRadio] = useState([]);
    const [testRadioPopup, setTestRadioPopup] = useState(null);
    const [error, setError] = useState(false);
    const [timer, setTimer] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const intl = useIntl();

    const umount = useCallback(async () => {
        clearInterval(timer);
        if (testRadioPopup) {
            await goldTestRadio({ id_centrale: system.id_centrale, type: 0 }, true);
        }
    }, [timer, testRadioPopup]);

    useEffect(() => {
        return async () => {
            await umount();
            setTimer(null);
            await sleep(1500)
            startPolling();
            return true;
        }
    }, []);

    useEffect(() => {
        setServiceStatus(system.store.state.stato.servizio);
        return () => null;
    }, [system.store.state.stato.servizio]);

    const startPolling = () => {
        setStore({
            polling_pause: false
        });
    };

    const stopPolling = () => {
        setStore({
            polling_pause: true
        });
    };

    const pollTestRadio = async () => {
        const [__data, __code] = await goldGetRadioAnalysis({ id_centrale: system.id_centrale, type: 0 });
        if (__code === 200 && __data.status === 'OK') {
            const arr = hexstringToArrayInt(__data.data[0]);
            if (!(arr[2] === 0 && arr[3] === 0)) {
                const device = system.store.pm.radio[parseInt(arr[0])];
                if (!!!device) {
                    return;
                }
                const rssi = ((arr[1] & 0xFF) * 10) / 13;
                const str = `${device.nome} ${intl.formatMessage({ id: "App.info.Segnale_" })} ${Math.floor(rssi > 100 ? 100 : rssi)} ${intl.formatMessage({ id: "App.info.Frequenza86" })} ${(arr[5] & 0x01) != 0 ? 9 : 8}`;
                setTestRadio(arr => [...arr, str]);
            }
        }
    };

    const startPollTestRadio = async () => {
        const interval = setInterval(
            async () => {
                await pollTestRadio();
            },
            10000
        );
        setTimer(interval);
    };

    const startTestRadio = async () => {
        setTestRadio([]);
        setError(false);
        stopPolling();
        setButtonDisabled(true);
        setTimer(null);
        await sleep(3000);
        const [_data, _code] = await goldTestRadio({ id_centrale: system.id_centrale, type: 1 }, true);
        if (_code === 200 && _data.status === 'OK') {
            await startPollTestRadio();
            setTestRadioPopup(true);
        } else {
            setTestRadioPopup(false);
            startPolling();
            setError(intl.FormatMessage({ id: "App.info.ErroreImprevistoRiprovare" }));
        }
    };

    const stopTestRadio = async () => {
        setTestRadioPopup(false);
        clearInterval(timer);
        setTimer(null);
        await goldTestRadio({ id_centrale: system.id_centrale, type: 0 }, true);
        startPolling();
        await sleep(3000);
        setButtonDisabled(false);
    };

    const download = async () => {
        await stopTestRadio();
        const element = document.createElement("a");
        const content = `
Lince Clouding
---------------------------------------------------
Test Radio del ${(new Date()).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
Impianto ${system.name}
---------------------------------------------------
    `;
        const file = new Blob([`${content}\n${testRadio.join('\n')}`], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = "TestRadio.csv";
        document.body.appendChild(element);
        element.click();
    };

    const sendCommand = (e, command) => {
        e.preventDefault();
        const _confirm = { text: null, command }
        switch (command) {
            case intl.formatMessage({ id: "App.info.AttivaServizio" }):
                _confirm.text = intl.formatMessage({ id: "App.info.AttivareLaModalitaServizio" });
                break;
            case intl.formatMessage({ id: "App.info.CancellaAllarmi" }):
                _confirm.text = intl.formatMessage({ id: "App.info.LeMemorieDiAllarme" });
                break;
            case intl.formatMessage({ id: "App.info.CancellaBus" }):
                _confirm.text = intl.formatMessage({ id: "App.info.TuttiIDispisitiviSuBus" });
                break;
            case intl.formatMessage({ id: "App.info.CancellaChiavi_" }):
                _confirm.text = intl.formatMessage({ id: "App.info.TutteLeChiaviMemorizzate" });
                break;
            case intl.formatMessage({ id: "App.info.CancellaRadio" }):
                _confirm.text = intl.formatMessage({ id: "App.info.TuttiIDispositiviRadio" });
                break;
            case intl.formatMessage({ id: "App.info.DisattivaServizio_" }):
                _confirm.text = intl.formatMessage({ id: "App.info.DisattivareLaModalitaServizio" });
                break;
            case intl.formatMessage({ id: "App.info.InitSistema" }):
                _confirm.text = intl.formatMessage({ id: "App.info.IlSistemaVerraRipristinato" });
                break;
            case intl.formatMessage({ id: "App.info.SpegnimentoRf" }):
                _confirm.text = intl.formatMessage({ id: "App.info.TuttiIDispositiviRf" });
                break;
        }
        if (_confirm.text != null) {
            setConfirm(_confirm);
        }
    };

    const handleConfirm = command => {
        setConfirm(false);
        const requests = [];
        const payloads = [];
        let payload = null;
        switch (command) {
            case intl.formatMessage({ id: "App.info.AttivaServizio" }):
                payload = { id_centrale: system.id_centrale, value: 1 };
                goldDoRequest('goldSetService', payload);
                setStore({
                    polling_requests: ['goldSetService'],
                    polling_payloads: [payload]
                });
                setProcessed(command);
                break;
            case intl.formatMessage({ id: "App.info.CancellaAllarmi" }):
                payload = { id_centrale: system.id_centrale, value: 0 };
                goldDoRequest('goldClearMemory', payload);
                setProcessed(command);
                break;
            case intl.formatMessage({ id: "App.info.CancellaBus" }):
                system.store.pm.bus.map((el, idx) => {
                    if (parseInt(el.num_tipo_periferica) > 0) {
                        payloads.push({
                            id_centrale: system.id_centrale,
                            type: 'bus',
                            n_disp: idx
                        });
                        requests.push('goldDelPeripheral');
                    }
                })
                break;
            case intl.formatMessage({ id: "App.info.CancellaChiavi" }):
                system.store.pm.codici.map((el, idx) => {
                    if (parseInt(el.tipo_codice) === 128) {
                        payloads.push({
                            id_centrale: system.id_centrale,
                            type: 'chiave',
                            n_disp: idx
                        });
                        requests.push('goldDelPeripheral');
                    }
                })
                break;
            case intl.formatMessage({ id: "App.info.CancellaRadio" }):
                system.store.pm.radio.map((el, idx) => {
                    if (parseInt(el.num_tipo_periferica) > 0) {
                        const payload = clone(el);
                        payload.indirizzo_periferica = 0xFFFF;
                        payload.num_tipo_periferica = 0xFF;
                        payload.num_spec_periferica = 0;
                        requests.push('goldDelPeripheral');
                        payloads.push({
                            id_centrale: system.id_centrale,
                            type: 'radio',
                            n_disp: idx
                        });
                        requests.push('goldModOutRadio');
                        payloads.push({
                            id_centrale: system.id_centrale,
                            idx: idx,
                            edata: j2bRadio(payload).map(x => isNaN(x) ? 0 : x)
                        });
                    }
                })
                break;
            case intl.formatMessage({ id: "App.info.DisattivaServizio" }):
                payload = { id_centrale: system.id_centrale, value: 0 };
                goldDoRequest('goldSetService', payload);
                setStore({
                    polling_requests: [],
                    polling_payloads: []
                });
                setProcessed(command);
                break;
            case intl.formatMessage({ id: "App.info.InitSistema" }):
                payload = { id_centrale: system.id_centrale };
                goldDoRequest('goldSetOtpSystem', payload);
                setProcessedOTP(true);
                break;
            case intl.formatMessage({ id: "App.info.spegnimentoRF" }):
                payload = { id_centrale: system.id_centrale, value: 2 };
                goldDoRequest('goldSetService', payload);
                setProcessed(command);
                break;
        }
        if (payloads.length > 0) {
            goldDoRequest(requests, payloads);
            setProcessed(command);
        }
    }

    const handleOtp = () => {
        const form = codeOtpForm.current;
        const payload = { id_centrale: system.id_centrale, value: 2, otp: form['codice'].value };
        goldDoRequest('goldClearMemory', payload);
        setProcessed('initSistema');
    }

    if (data && processed) {
        let _success = !!data && !errors && (!!data[0] ? data[0].status === "OK" : data.status === "OK");
        switch (processed) {
            case intl.formatMessage({ id: "App.info.AttivaServizio" }):
                _success && setServiceStatus(true);
                break;
            case intl.formatMessage({ id: "App.info.CancellaAllarmi" }):
            case intl.formatMessage({ id: "App.info.SpegnimentoRf" }):
                if (_success) _success = 3;
                break;
            case intl.formatMessage({ id: "App.info.CancellaBus" }):
                if (_success) _success = 1;
                for (let i = 0; i < data.length; i++) {
                    let pm = clone(system.store.pm);
                    pm.bus[data[i].n_disp] = b2jBus(['000000']);
                    setStore({
                        pm
                    });
                }
                break;
            case intl.formatMessage({ id: "App.info.CancellaChiavi" }):
                if (_success) _success = 1;
                for (let i = 0; i < data.length; i++) {
                    let pm = clone(system.store.pm);
                    pm.codici[data[i].n_disp] = b2jCodice(['00']);
                    setStore({
                        pm
                    });
                }
                break;
            case intl.formatMessage({ id: "App.info.CancellaRadio" }):
                if (_success) _success = 1;
                for (let i = 0; i < data.length; i++) {
                    let pm = clone(system.store.pm);
                    pm.radio[data[i].n_disp] = b2jRadio(['000000000000000000000000']);
                    setStore({
                        pm
                    });
                }
                break;
            case intl.formatMessage({ id: "App.info.DisattivaServizio" }):
                _success && setServiceStatus(false);
                break;
            case intl.formatMessage({ id: "App.info.InitSistema" }):
                if (_success) {
                    _success = 2;
                } else if (!!data.error) {
                    _success = data.error;
                }
                break;
        }
        setSuccess(_success);
        setProcessed(null);
    }

    if (data && processedOTP) {
        if (!!data && !errors && !!data[0] && data[0].status === 'OK') {
            setOtpBox(true);
        } else {
            setSuccess(false);
        }
        setProcessedOTP(false);
    }

    if (redir) {
        return <Redirect to={redir} />
    }

    return (
        <section>
            {success === 1 && <Modal title={intl.FormatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.FormatMessage({ id: "App.info.ComandoInviatoConSuc" })} onClose={() => { setSuccess(null); }} />}
            {success === 2 && <Modal title={intl.FormatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.FormatMessage({ id: "App.info.InizializzazioneAvviata" })} onClose={() => { setSuccess(null); setRedir('/'); }} />}
            {success === 3 && <Modal title={intl.FormatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.FormatMessage({ id: "App.info.ComandoInviatoConSuccesso_" })} onClose={() => { setSuccess(null); }} />}
            {success === false && <Modal title={intl.FormatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.FormatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            {typeof success === 'string' && <Modal title={intl.FormatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={success} onClose={() => { setSuccess(null); }} />}
            {error && (
                <Modal
                    title={`Errore`}
                    text={error}
                    onClose={() => { setError(false); stopTestRadio(); }}
                />
            )}
            {
                otpBox && (
                    <Popup
                        title={intl.formatMessage({ id: "App.info.InserisciOtp" })}
                        onClose={() => {
                            setOtpBox(false);
                        }}
                        onConfirm={() => {
                            setOtpBox(false);
                            handleOtp();
                        }}
                    >
                        <div className="rack">
                            <div className="w100 formSection">
                                <form ref={codeOtpForm}>
                                    <fieldset>
                                        <div className="rack">
                                            <p style={{ textAlign: 'left' }}>
                                                <FormattedMessage id="App.info.OtpAlProprietario" />
                                            </p>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <label><FormattedMessage id="App.info.CodiceOtp" /></label>
                                                <input
                                                    type="password"
                                                    maxLength={6}
                                                    autoComplete={`off`}
                                                    placeholder={intl.FormatMessage({ id: "App.info.InserisciOtp" })}
                                                    name={intl.formatMessage({ id: "App.info.Codice_" })}
                                                    defaultValue={``}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </Popup>
                )
            }
            {testRadioPopup && (
                <Popup
                    title={intl.formatMessage({ id: "App.info.TestRadio" })}
                    text={``}
                    onConfirmLabel={intl.formatMessage({ id: "App.info.Esporta" })}
                    onConfirm={testRadio.length > 0 ? () => { download() } : null}
                    onClose={() => { stopTestRadio(); }}
                >
                    <p className="loadingText">
                        <FormattedMessage id="App.info.AttesaDispRadio" />
                    </p>
                    {testRadio.length > 0 &&
                        <div style={{ background: '#fcfcfc', width: '100%', height: '150px', overflowY: 'scroll' }}>
                            {testRadio.map((el, idx) => (
                                <p key={idx} style={{ textAlign: "left", margin: '.75em 0' }}>{el}</p>
                            ))}
                        </div>
                    }
                </Popup>
            )}
            <div className="innerSection formSection">
                {confirm && (
                    <Modal
                        title={intl.formatMessage({ id: "App.info.SicuroDiVoler" })}
                        text={confirm.text}
                        onClose={() => { setConfirm(false); }}
                        onConfirm={() => { handleConfirm(confirm.command); }}
                    />
                )}
                <h3>
                    <FormattedMessage id="App.info.ComandiDiCentrale" />
                </h3>
                <br />
                <div className="rack">
                    <h4>
                        <FormattedMessage id="App.info.Setup" />
                    </h4><br />
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, 'initSistema'); }}>
                                <FormattedMessage id="App.info.InizializzaSistema" />
                            </button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            {!!serviceStatus
                                ? <button className="superButton" onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.DisattivaServizio" })); }}>
                                    <FormattedMessage id="App.info.DisattivaServizio" />
                                </button>
                                : <button className="superButton" onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.AttivaServizio" })); }}>
                                    <FormattedMessage id="App.info.AttivaServizio" />
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={buttonDisabled} onClick={e => { e.preventDefault(); startTestRadio(); }}><span className={(buttonDisabled ? 'loadingText' : '')}>
                                <FormattedMessage id="App.info.TestRadio" />
                            </span></button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.SpegnimentoRf_" })); }}>
                                <FormattedMessage id="App.info.SpegnimentoRf" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <h4>
                        <FormattedMessage id="App.info.SetupDispositivi" />
                    </h4><br />
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 2
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>
                                    <FormattedMessage id="App.info.MemorizzaDispositiviBus" />

                                </button>
                                : <Link className="superButton" to={`/gold/${system.id}/new_bus`}>
                                    <FormattedMessage id="App.info.MemorizzaDispositiviBus" />
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 2
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>
                                    <FormattedMessage id="App.info.MemorizzaDispositiviRadio" />
                                </button>
                                : <Link className="superButton" to={`/gold/${system.id}/rf`}>
                                    <FormattedMessage id="App.info.MemorizzaDispositiviRadio" />
                                </Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 4
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>
                                    <FormattedMessage id="App.info.MemorizzaChiavi" />
                                </button>
                                : <Link className="superButton" disabled={true} to={`/gold/${system.id}/key`}>
                                    <FormattedMessage id="App.info.MemorizzaChiavi" />
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            {!!!system.store || !!!system.store.auth || system.store.auth !== 4
                                ? <button className="superButton" disabled={true} onClick={e => { e.preventDefault(); }}>
                                    <FormattedMessage id="App.info.MemorizzaCodici" />
                                </button>
                                : <Link className="superButton" to={`/gold/${system.id}/new_code`}>
                                    <FormattedMessage id="App.info.MemorizzaCodici" />
                                </Link>
                            }
                        </div>
                    </div>
                </div>
                <div className="rack">
                    <h4>
                        <FormattedMessage id="App.info.Elimina" />
                    </h4><br />
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.cancellaBus" })); }}>
                                <FormattedMessage id="App.info.CancellaDispositiviBUS" />
                            </button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 2} onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.cancellaRadio" })); }}>
                                <FormattedMessage id="App.info.CancellaDispositiviRadio" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rack bottomBorder">
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" style={{}} onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.cancellaAllarmi" })); }}>
                                <FormattedMessage id="App.info.CancellaMemoriaAllarmi" />
                            </button>
                        </div>
                    </div>
                    <div className="w50">
                        <div className="padp">
                            <button className="superButton" disabled={!!!system.store.auth || system.store.auth !== 4} onClick={e => { sendCommand(e, intl.formatMessage({ id: "App.info.cancellaChiavi" })); }}>
                                <FormattedMessage id="App.info.CancellaChiavi" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Comandi = connect(mapStateToProps, mapDispatchToProps)(_Comandi);

export default Comandi;
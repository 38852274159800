import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { reverseBits } from '../../../../lib/bytesFunctions';
import { j2bTempi } from '../../../../lib/goldParser/converter';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest
} from '../../../../actions';

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldGetTimer: payload => dispatch(goldDoRequest('goldGetTimer', payload)),
    goldSetTimer: payload => dispatch(goldDoRequest('goldSetTimer', payload)),
    goldSetTimers: payload => dispatch(goldDoRequest('goldSetTimers', payload))
});

const _Tempi = ({ data, errors, system, goldGetTimer, goldSetTimer, goldSetTimers }) => {
    const _system = clone(system);
    const intl = useIntl();
    const days = [
        intl.formatMessage({ id: "App.info.Dom" }),
        intl.formatMessage({ id: "App.info.Lun" }),
        intl.formatMessage({ id: "App.info.Mar" }),
        intl.formatMessage({ id: "App.info.Mer" }),
        intl.formatMessage({ id: "App.info.Gio" }),
        intl.formatMessage({ id: "App.info.Ven" }),
        intl.formatMessage({ id: "App.info.Sab" })];
    const intervals = Array.from({ length: 96 }, (_, i) => [
        parseInt((i * 15) / 60).toString().padStart(2, '0'),
        parseInt((i * 15) % 60).toString().padStart(2, '0'),
    ].join(':')).map((v, i, a) => `${v} - ${(a[i + 1] ?? '00:00')}`);
    const invBoolArray2int = arr => arr.reduce((acc, cur, idx) => acc |= (!cur ? (Math.pow(2, idx)) : 0), 0);
    const invInt2boolArray = int => (new Array(8)).fill(null).map((_, idx) => !!!(int & (Math.pow(2, idx))));
    const codeForm = useRef(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [current, setCurrent] = useState(!!_system.store ? _system.store.pm.tempi : null);
    const [tempoAllarme, setTempoAllarme] = useState(0);
    const [tempoCampanello, setTempoCampanello] = useState(0);
    const [tempoElettroserratura, setTempoElettroserratura] = useState(0);
    const [tempoFuoco, setTempoFuoco] = useState(0);
    const [tempoRonda, setTempoRonda] = useState(0);
    const [tempoSilenzioso, setTempoSilenzioso] = useState(0);
    const [success, setSuccess] = useState(null);
    const [processed, setProcessed] = useState(null);
    const [tab, setTab] = useState(2);
    const [timer, setTimer] = useState(null);
    const [readTimer, setReadTimer] = useState(null);

    useEffect(() => {
        if (current && !firstLoad) {
            setTempoAllarme(current.allarme);
            setTempoCampanello(current.campanello);
            setTempoSilenzioso(current.silenzioso);
            setTempoElettroserratura(current.elettroserratura);
            setTempoFuoco(current.fuoco);
            setTempoRonda(current.ronda);
            setTimeout(() => setFirstLoad(true), 100);
        }
        return () => null;
    }, [current, firstLoad]);

    useEffect(() => {
        if (tab < 2) {
            goldGetTimer({ id_centrale: system.id_centrale, pos: tab });
            setTimer(null);
            setReadTimer(true);
        }
    }, [tab]);

    const makePayload = () => {
        return {
            allarme: tempoAllarme,
            fuoco: tempoFuoco,
            silenzioso: tempoSilenzioso,
            campanello: tempoCampanello,
            elettroserratura: tempoElettroserratura,
            ronda: tempoRonda
        };
    };

    const refreshTimer = () => {
        goldGetTimer({ id_centrale: system.id_centrale, pos: tab });
        setTimer(null);
        setReadTimer(true);
    }

    const handleReset = e => {
        e.preventDefault();
        setFirstLoad(false);
        setCurrent(null);
        setTimeout(() => setCurrent(_system.store.pm.tempi), 100);
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = [{
            id_centrale: system.id_centrale,
            edata: j2bTempi(makePayload())
        }];
        goldSetTimers(payload);
        setProcessed(true);
    };

    const handleResetTimer = e => {
        e.preventDefault();
        const payload = [{
            id_centrale: system.id_centrale,
            pos: tab,
            timer: (new Array(84)).fill(0xFF)
        }];
        goldSetTimer(payload);
        setProcessed(2);
    };

    const changeTimer = e => {
        const value = parseInt(e.target.value);
        const byte = Math.floor(value / 8);
        const bit = value % 8;
        const _timer = clone(timer);
        _timer[byte][bit] = !!e.target.checked;
        setTimer(_timer);
    };

    const handleSubmitTimer = e => {
        e.preventDefault();
        const payload = [{
            id_centrale: system.id_centrale,
            pos: tab,
            timer: timer.map(x => reverseBits(invBoolArray2int(x)))
        }];
        goldSetTimer(payload);
        setProcessed(true);
    };

    const renderTimers = () => (
        <div className="w100 formSection">
            <form ref={codeForm}>
                <fieldset>
                    <div className="rack">
                        <div className="w50">
                            <p>
                                <FormattedMessage id="App.info.DurataAllarme" />
                            </p>
                            <ReactSlider
                                defaultValue={tempoAllarme}
                                onChange={value => { setTempoAllarme(value); }}
                                max={5999}
                                min={0}
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                renderThumb={(props, state) => {
                                    const [hrs, min, sec] = [
                                        Math.floor(parseInt(state.valueNow) / 3600),
                                        Math.floor((parseInt(state.valueNow) % 3600) / 60),
                                        ((parseInt(state.valueNow) % 3600) % 60),
                                    ];
                                    return (<div {...props}>{`${(hrs ? `${hrs} ${intl.formatMessage({ id: "App.info.Ora" })}` : ``)} ${min} ${intl.formatMessage({ id: "App.info.Min" })} ${sec} ${intl.formatMessage({ id: "App.info.Sec" })}`}</div>);
                                }}
                            />
                        </div>
                        <div className="w50">
                            <p>
                                <FormattedMessage id="App.info.DurataAllarmeFuoco" />
                            </p>
                            <ReactSlider
                                defaultValue={tempoFuoco}
                                onChange={value => { setTempoFuoco(value); }}
                                max={255}
                                min={0}
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                renderThumb={(props, state) => {
                                    const [min, sec] = [
                                        Math.floor(parseInt(state.valueNow) / 60),
                                        parseInt(state.valueNow) % 60,
                                    ];
                                    return (<div {...props}>{`${min} min ${sec} sec`}</div>);
                                }}
                            />
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w50">
                            <p>
                                <FormattedMessage id="App.info.DurataAllarmeSilenzioso" />
                            </p>
                            <ReactSlider
                                defaultValue={tempoSilenzioso}
                                onChange={value => { setTempoSilenzioso(value); }}
                                max={99}
                                min={0}
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                renderThumb={(props, state) => {
                                    const [sec, dec] = [
                                        Math.floor(parseInt(state.valueNow) / 10),
                                        parseInt(state.valueNow) % 10,
                                    ];
                                    return (<div {...props}>{`${sec} sec ${dec} dec`}</div>);
                                }}
                            />
                        </div>
                        <div className="w50">
                            <p>
                                <FormattedMessage id="App.info.Campanello" />
                            </p>
                            <ReactSlider
                                defaultValue={tempoCampanello}
                                onChange={value => { setTempoCampanello(value); }}
                                max={99}
                                min={0}
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                renderThumb={(props, state) => {
                                    const [sec, dec] = [
                                        Math.floor(parseInt(state.valueNow) / 10),
                                        parseInt(state.valueNow) % 10,
                                    ];
                                    return (<div {...props}>{`${sec} sec ${dec} dec`}</div>);
                                }}
                            />
                        </div>
                    </div>
                    <div className="rack">
                        <div className="w50">
                            <p>
                                <FormattedMessage id="App.info.Elettroserratura" />
                            </p>
                            <ReactSlider
                                defaultValue={tempoElettroserratura}
                                onChange={value => { setTempoElettroserratura(value); }}
                                max={99}
                                min={0}
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                renderThumb={(props, state) => {
                                    const [sec, dec] = [
                                        Math.floor(parseInt(state.valueNow) / 10),
                                        parseInt(state.valueNow) % 10,
                                    ];
                                    return (<div {...props}>{`${sec} sec ${dec} dec`}</div>);
                                }}
                            />
                        </div>
                        <div className="w50">
                            <p>
                                <FormattedMessage id="App.info.Ronda" />
                            </p>
                            <ReactSlider
                                defaultValue={tempoRonda}
                                onChange={value => { setTempoRonda(value); }}
                                max={5999}
                                min={0}
                                className="horizontal-slider"
                                thumbClassName="slider-thumb"
                                trackClassName="slider-track"
                                renderThumb={(props, state) => {
                                    const [hrs, min, sec] = [
                                        Math.floor(parseInt(state.valueNow) / 3600),
                                        Math.floor((parseInt(state.valueNow) % 3600) / 60),
                                        ((parseInt(state.valueNow) % 3600) % 60),
                                    ];
                                    return (<div {...props}>{`${(hrs ? `${hrs} ora` : ``)} ${min} min ${sec} sec`}</div>);
                                }}
                            />
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <div className="rack">
                        <div className="w100 right">
                            <button className="ok auto spaced" onClick={handleReset}>
                                <FormattedMessage id="App.info.Annulla" />
                            </button>
                            <button className="ok auto spaced" onClick={handleSubmit} disabled={system.store.auth === 4}>
                                <FormattedMessage id="App.info.Applica" />
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );

    const renderTimer = () => {
        return (
            !!!timer
                ? <></>
                : <div className="w100 formSection">
                    <form>
                        <fieldset>
                            <div className="rack">
                                <div className="w20">
                                    <FormattedMessage id="App.info.Orario" />
                                </div>
                                <div className="w60 checkBoxInline spacedP">
                                    {days.map((day, index) => (
                                        <p key={`day_${index}`} style={{ margin: 0, padding: 0, fontWeight: 'bold', width: '14%', textAlign: 'center' }}>
                                            <label>{day}</label>
                                        </p>
                                    ))}
                                </div>
                            </div>
                            {intervals.map((interval, idx) => {
                                return (
                                    <div key={`row_${idx}`} className="rack grayHover" style={{ margin: 0, padding: 0 }}>
                                        <div className="w20">
                                            <p style={{ margin: 0, padding: ".5em 0 0 0" }}>{interval}</p>
                                        </div>
                                        <div className="w60 checkBoxInline spacedP">
                                            {days.map((_, index) => {
                                                const _key = `day_${idx}_${index}`;
                                                const value = (idx + (index * 96));
                                                const byte = Math.floor(value / 8);
                                                const bit = value % 8;
                                                return (
                                                    <p key={_key} style={{ margin: 0, padding: 0, width: '14%' }}>
                                                        <input type="checkbox" id={_key} name={_key} defaultValue={value} checked={timer[byte][bit]} onChange={changeTimer} />
                                                        <label htmlFor={_key} style={{ width: "auto" }}>
                                                            <span />
                                                        </label>
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </fieldset>
                        <fieldset>
                            <div className="rack">
                                <div className="w100 right">
                                    <button className="ok auto spaced" onClick={handleResetTimer}>
                                        <FormattedMessage id="App.info.DeselezionaTutto" />
                                    </button>
                                    <button className="ok auto spaced" onClick={handleSubmitTimer}>
                                        <FormattedMessage id="App.info.Applica" />
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
        );
    };

    if (!!!system.store || !!!system.store.auth || (!(system.store.auth & 6))) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficienti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    if (!!!current || !!!firstLoad) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    if (data && processed) {
        let _processed = processed;
        setProcessed(null);
        let _success = !!data && !errors;
        setSuccess(_processed === 2 && _success ? 2 : _success);
    }

    if (data && readTimer) {
        if (!!data && !errors) {
            setTimer(data[0].timer.map(x => invInt2boolArray(x).reverse()));
        }
        setReadTimer(null);
    }

    return (
        <section>
            {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); }} />}
            {success === 2 && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuccesso" })} onClose={() => { setSuccess(null); setTimeout(() => refreshTimer(), 100) }} />}
            {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
            <div className="innerSection">
                <div className="rack box">
                    <div className="w33">
                        <h2 className={`tab tastiImpianti ${(tab === 2 ? 'active' : 'disabled')}`} onClick={() => setTab(2)}>
                            <FormattedMessage id="App.info.Tempi" />
                        </h2>
                    </div>
                    <div className="w33">
                        <h2 className={`tab tastiImpianti ${(tab === 0 ? 'active' : 'disabled')}`} onClick={() => setTab(0)}>
                            <FormattedMessage id="App.info.Timer1" />
                        </h2>
                    </div>
                    <div className="w33">
                        <h2 className={`tab tastiImpianti ${(tab === 1 ? 'active' : 'disabled')}`} onClick={() => setTab(1)}>
                            <FormattedMessage id="App.info.Timer2" />
                        </h2>
                    </div>
                </div>
                <div className="rack box">
                    {tab === 2 && renderTimers()}
                    {tab === 0 && renderTimer()}
                    {tab === 1 && renderTimer()}
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Tempi = connect(mapStateToProps, mapDispatchToProps)(_Tempi);

export default Tempi;
import React, { useState, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Clock from './Clock';
import Modal from '../../elements/Modal';
import { doRequest, logout, resetSystem, socketRequestReset, setStore, goldPollingStart } from '../../../actions';
import { GOLD_AUTH } from '../../../constants/gold';
import { clone } from '../../../lib/clone';
import europlusParser from '../../../lib/europlusParser';
import { stateParser } from '../../../lib/goldParser/stateParser';

import './Header.scss';

const mapStateToProps = state => ({
    data: state.data,
    system: state.system,
    socket: state.socket,
    socketError: state.socket_error,
    socketRedirect: state.socket_redirect,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    resetSystem: () => dispatch(resetSystem()),
    socketRequestReset: () => dispatch(socketRequestReset()),
    goldPolling: (payloads, requests) => dispatch(goldPollingStart(payloads, requests)),
    goldGetState: payload => dispatch(doRequest('goldGetState', payload)),
    goldSyncMemMap: payload => dispatch(doRequest('goldSyncMemMap', payload)),
    setStore: data => dispatch(setStore(data))
});

const Header = ({ email, pic = null, handlerWide, history, logout, system, isControlPanel, resetSystem, socket, socketError, socketRedirect, socketRequestReset, user, goldPolling, goldSyncMemMap, goldGetState, setStore, data }) => {
    const [menuClosed, setMenuClosed] = useState(window.screen.width < 1024);
    const [profileOpen, setProfileOpen] = useState(false);
    const [status, setStatus] = useState(europlusParser(null));
    const [goldStatus, setGoldStatus] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [processedGetState, setProcessedGetState] = useState(false);
    const [systemMenus, setSystemMenus] = useState([]);
    const [menu1, setMenu1] = useState(false);
    const [menu2, setMenu2] = useState(false);
    const [menu3, setMenu3] = useState(false);
    const [menu4, setMenu4] = useState(false);
    const [menu5, setMenu5] = useState(false);
    const isCat = user.expiration_date != null;
    const [timer, setTimer] = useState(null);

    const intl = useIntl();

    const url = isControlPanel;
    const makeSystemMenus = ((url, system) => {
        const conf = [
            {
                name: intl.formatMessage({ id: 'App.info.ConfigurazioneSistema' }),
                children: {
                    wired: intl.formatMessage({ id: 'App.info.IngressiFilari' }),
                    radio: url === intl.formatMessage({ id: 'App.info.Gold' }) ? intl.formatMessage({ id: 'App.info.DispositiviRadio' }) : intl.formatMessage({ id: 'App.info.IngressiRadio' }),
                    bus: intl.formatMessage({ id: 'App.info.PerifericheBus' }),
                    code: url === intl.formatMessage({ id: 'App.info.Gold' }) ? intl.formatMessage({ id: 'App.info.ChiaviCodici' }) : intl.formatMessage({ id: 'App.info.Codici' })
                },
                subs: [intl.formatMessage({ id: 'App.info.Wired' }), intl.formatMessage({ id: 'App.info.Radio' }), intl.formatMessage({ id: 'App.info.Code' }), intl.formatMessage({ id: 'App.info.Phone' }), intl.formatMessage({ id: 'App.info.Bus' }), intl.formatMessage({ id: 'App.info.SiaIp' })],
                open: false,
                func: setMenu1
            },
            {
                name: intl.formatMessage({ id: 'App.info.AmministrazioneSistema' }),
                children: {
                },
                subs: [],
                open: false,
                func: setMenu2
            },
            {
                name: intl.formatMessage({ id: 'App.info.StatoSistema' }),
                children: {
                    system_status: intl.formatMessage({ id: 'App.info.StatoImpianto' }),
                    wstatus: intl.formatMessage({ id: 'App.info.StatoIngressiFilari' }),
                    rstatus: url === intl.formatMessage({ id: 'App.info.Gold' }) ? intl.formatMessage({ id: 'App.info.StatoDispositiviRadio' }) : intl.formatMessage({ id: 'App.info.StatoIngressiRadio' })
                },
                subs: [],
                open: false,
                func: setMenu3
            },
            {
                name: intl.formatMessage({ id: 'App.info.MemoriaEventi' }),
                children: {
                    memory: intl.formatMessage({ id: 'App.info.DaCentrale' })
                },
                subs: [],
                open: false,
                func: setMenu4
            },
            {
                name: intl.formatMessage({ id: 'App.info.Personalizza' }),
                children: {
                    custom: intl.formatMessage({ id: 'App.info.NomeProgrammi' })
                },
                subs: [],
                open: false,
                func: setMenu5
            }
        ];
        if (url === 'gold') {
            conf[0].children['buttons'] = intl.formatMessage({ id: 'App.info.Supertasti' });
            conf[0].children['timers'] = intl.formatMessage({ id: 'App.info.Tempi' });
            conf[0].children['options'] = intl.formatMessage({ id: 'App.info.Opzioni' });
            conf[0].children['outputs'] = intl.formatMessage({ id: 'App.info.Uscite' });
            conf[0].children['gsm'] = intl.formatMessage({ id: 'App.info.OpzioniGsm' });
            conf[0].children['phone'] = intl.formatMessage({ id: 'App.info.NumeriDiTelefono' });
            conf[0].children['siaip'] = intl.formatMessage({ id: 'App.info.SiaIp__' });
            conf[1].children['clock'] = intl.formatMessage({ id: 'App.info.Orologio' });
            conf[1].children['autoinsertion'] = intl.formatMessage({ id: 'App.info.Autoinserimento' });
            conf[1].children['commands'] = intl.formatMessage({ id: 'App.info.ComandiDiCentrale' });
            conf[2].children['bstatus'] = intl.formatMessage({ id: 'App.info.StatoPerifericheBus' });
            conf[2].children['cstatus'] = intl.formatMessage({ id: 'App.info.StatoChiaviCodici' });
            conf[2].children['gstatus'] = intl.formatMessage({ id: 'App.info.StatoGsm' });
            conf[3].children['registry'] = intl.formatMessage({ id: 'App.info.DaCloud' });
            conf[4].children['name_out'] = intl.formatMessage({ id: 'App.info.NomeUscite' });
            if (system.brand === 'lince-pro') {
                conf[4].children['display'] = intl.formatMessage({ id: 'App.info.NomeDisplay' });
            }
            conf[4].children['logic_map'] = intl.formatMessage({ id: 'App.info.Ambienti' });
        } else {
            delete conf[0].children['bus'];
            conf[0].children['outputs'] = intl.formatMessage({ id: 'App.info.Uscite' });
            conf[0].children['buttons'] = intl.formatMessage({ id: 'App.info.Supertasti' });
            conf[0].children['timers'] = intl.formatMessage({ id: 'App.info.Tempi' });
            conf[0].children['options'] = intl.formatMessage({ id: 'App.info.Opzioni' });
            conf[1].children['commands'] = intl.formatMessage({ id: 'App.info.ComandiDiCentrale' });
            conf[1].children['periodic_activations'] = intl.formatMessage({ id: 'App.info.AttivazioniPeriodiche' });
            conf[1].children['non_periodic_activations'] = intl.formatMessage({ id: 'App.info.AttivazioniNonPeriodiche' });
        }
        return conf;
    });

    const refresh = useCallback(() => {
        setTimeout(
            () => {
                const _systemMenus = makeSystemMenus(url, system);
                for (let j in _systemMenus) {
                    let slugs = Object.keys(_systemMenus[j].children);
                    for (let i in slugs) {
                        let _path = `/${url}/${system.id}/${slugs[i]}`;
                        if (history.location.pathname.indexOf(_path) === 0) {
                            _systemMenus[j].open = true;
                            _systemMenus[j].func(true);
                            break;
                        } else {
                            _systemMenus[j].open = false;
                            _systemMenus[j].func(false);
                        }
                    }
                }
                setSystemMenus(_systemMenus);
            },
            200
        );
    }, [history, system]);

    const umount = useCallback(() => {
        clearInterval(timer);
    }, [timer]);

    useEffect(
        () => {
            return () => {
                umount();
                return true;
            };
        },
        [umount]
    );

    useEffect(
        () => {
            if (!firstLoad) {
                if (!!system) {
                    refresh();
                    setFirstLoad(true);
                    if (isControlPanel === 'gold') {
                        goldGetState({ id_centrale: system.id_centrale });
                        setProcessedGetState(true);
                    }
                }
            }
            return () => null;

        },
        [system, refresh, firstLoad, setFirstLoad, isControlPanel]
    );

    useEffect(
        () => {
            if (isControlPanel) {
                setStatus(!!system && !!system.store && !!system.store.status ? system.store.status : europlusParser(null));
                if (isControlPanel === 'gold' && !!system && !!system.store && !!system.store.state) {
                    if (!!system.store.auth) {
                        setGoldStatus(system.store.state);
                        if (system.store.state.sync === 0) {
                            const _state = clone(system.store.state);
                            _state.sync = 1;
                            setStore({
                                state: _state
                            });
                            goldSyncMemMap({ id_centrale: system.id_centrale });
                        }
                        if (!timer) {
                            setStore({
                                polling_start: new Date()
                            });
                            const interval = setInterval(() => {
                                if (!!system.store.polling_start && !system.store.polling_pause) {
                                    const now = new Date();
                                    const diff = Math.floor(parseInt(now.getTime()) / 1000) - Math.floor(parseInt(system.store.polling_start.getTime()) / 1000);
                                    const requests = [];
                                    const payloads = [];
                                    if (diff % 60 === 0) {
                                        requests.push('goldCheckSync');
                                        payloads.push({ id_centrale: system.id_centrale });
                                    }
                                    if (system.store.state.sync === 2 && (!!system.store.polling_requests && !!system.store.polling_payloads)) {
                                        for (let i = 0; i < system.store.polling_requests.length; i++) {
                                            requests.push(system.store.polling_requests[i]);
                                            payloads.push(system.store.polling_payloads[i]);
                                        }
                                    }
                                    goldPolling(payloads, requests);
                                }
                            }, 15000);
                            setTimer(interval);
                        }
                    } else {
                        setGoldStatus(null);
                    }
                }
            } else {
                resetSystem();
                if (timer) {
                    clearInterval(timer);
                    setTimer(null);
                }
                return () => null;
            }
        },
        [isControlPanel, resetSystem, setStatus, system]
    );

    const activeLink = (pathname, _class = 'selected') => {
        if (pathname === '/') {
            return history.location.pathname === pathname ? _class : '';
        } else if (pathname === '/control-panel') {
            return history.location.pathname === `/${url}/${system.id}` ? _class : '';
        } else {
            return history.location.pathname.indexOf(pathname) === 0 ? _class : '';
        }
    };

    const renderMenu = menuIndex => {
        const _systemMenus = makeSystemMenus(url, system);
        let slugs = Object.keys(_systemMenus[menuIndex].children);
        let values = Object.values(_systemMenus[menuIndex].children);
        return (
            <>
                {slugs.map((slug, idx) => {
                    let _path = `/${url}/${system.id}/${slug}`;
                    let full = _systemMenus[menuIndex].subs.includes(slug) ? _path + '/1' : _path;
                    return <li key={`${slug}_${idx}`} className={('sub ' + activeLink(_path, 'selected2'))}>
                        <Link to={full} onClick={() => { refresh(); closeMobileMenu(); }}>{values[idx]}</Link>
                    </li>;
                })}
            </>
        );
    }

    const toggleMenu = () => {
        setMenuClosed(!menuClosed);
        handlerWide(!menuClosed);
    };

    const closeMobileMenu = () => {
        if (window.screen.width < 1024) {
            toggleMenu();
        }
    };

    const toggleProfile = () => {
        setProfileOpen(!profileOpen);
    };

    const closeModal = (e) => {
        socketRequestReset();
    };

    if (data && processedGetState) {
        setProcessedGetState(false);
        setStore({
            state: stateParser(data)
        });
    }

    return (
        (
            <>
                {system && !!system.store && (
                    !!system.store.sync_cloud_euronet
                        ? (
                            <Modal
                                text={intl.formatMessage({ id: 'App.info.AttendereFineOperazioni' })}
                                title={intl.formatMessage({ id: 'App.info.SincronizzazioneInCorso' })}
                                syncing={true}
                                onClose={false}
                            />
                        ) : (!!system.store.state && !!system.store.state.sync && system.store.state.sync === 1)
                            ? (
                                <Modal
                                    text={intl.formatMessage({ id: 'App.info.AttendereFineOperazioni' })}
                                    title={intl.formatMessage({ id: 'App.info.SincronizzazioneInCorso' })}
                                    syncing={true}
                                    onClose={false}
                                />
                            ) : (
                                <></>
                            )
                )}
                <header className={menuClosed ? 'wide' : ''}>
                    <div id="menu_toggle">
                        <button onClick={toggleMenu}>
                            <i className="fa fa-bars" />
                        </button>
                    </div>
                    <Clock />
                    {
                        system && !!system.store && socket && (
                            <>
                                {!!socketError && (
                                    <Modal
                                        title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                                        text={socketError}
                                        onClose={closeModal}
                                        redirect={socketRedirect}
                                    />
                                )}
                                <div id="leds">
                                    {
                                        isControlPanel === 'gold'
                                            ? (
                                                goldStatus && (
                                                    <div id="program_leds">
                                                        <i className="fa fa-circle" style={(goldStatus.prog.g1) ? { color: 'red' } : {}} />
                                                        <i className="fa fa-circle" style={(goldStatus.prog.g2) ? { color: '#ffc20a' } : {}} />
                                                        <i className="fa fa-circle" style={(goldStatus.prog.g3) ? { color: '#28aa28' } : {}} />
                                                    </div>
                                                )
                                            ) : (
                                                <div id="program_leds">
                                                    <i className="fa fa-circle" style={(status.structs.generali_3.attivo_g1 ? { color: "red" } : {})} />
                                                    <i className="fa fa-circle" style={(status.structs.generali_3.attivo_g2 ? { color: "#ffc20a" } : {})} />
                                                    <i className="fa fa-circle" style={(status.structs.generali_3.attivo_g3 ? { color: "#28aa28" } : {})} />
                                                    <i className="fa fa-circle" style={(status.structs.generali_3.attivo_gext ? { color: "lightblue" } : {})} />
                                                </div>
                                            )
                                    }
                                    <div id="connection">
                                        {
                                            isControlPanel === 'gold'
                                                ? (
                                                    goldStatus && <i className={`fa fa-${goldStatus.conn_type}`} id={(goldStatus.connesso ? "connection_on" : "connection_off")} title={(!!goldStatus && goldStatus.connesso ? `${intl.formatMessage({ id: "App.info.ImpiantoConnesso" })} ${goldStatus.conn_type === "signal" ? `${intl.formatMessage({ id: 'App.info.Gprs' })}` : `${intl.formatMessage({ id: 'App.info.Wifi' })}`}` : intl.formatMessage({ id: 'App.info.ImpiantoNonConnesso' }))} />
                                                ) : (
                                                    <i className={`fa fa-cloud`} id={(!!system.store.connected ? "connection_on" : "connection_off")} title={(!!system.store.connected ? intl.formatMessage({ id: 'App.info.ImpiantoConnesso' }) : intl.formatMessage({ id: 'App.info.ImpiantoNonConnesso' }))} />
                                                )
                                        }
                                    </div>
                                    <div id="syncing">
                                        {
                                            isControlPanel === 'gold'
                                                ? (
                                                    goldStatus && <i className={`fa fa-exchange`} id={goldStatus.sync === 1 ? "syncing_on" : "syncing_off"} title={(!!goldStatus && goldStatus.sync === 1 ? intl.formatMessage({ id: 'App.info.SincronizzazioneInCorso' }) : intl.formatMessage({ id: 'App.info.ImpiantoSincronizzato' }))} />
                                                ) : (
                                                    <i className={`fa fa-exchange`} id={((!!system.store.sync_cloud_euronet || !!status.structs.comandicentrale.sync_euronet_cloud) ? "syncing_on" : "syncing_off")} title={((!!system.store.sync_cloud_euronet || !!status.structs.comandicentrale.sync_euronet_cloud) ? intl.formatMessage({ id: 'App.info.SincronizzazioneInCorso' }) : intl.formatMessage({ id: 'App.info.ImpiantoSincronizzato' }))} />
                                                )
                                        }
                                    </div>
                                </div>
                                {
                                    isControlPanel === 'gold'
                                        ? (
                                            goldStatus && (
                                                <div class="teknox" id="teknox">
                                                    <>
                                                        {(intl.formatMessage({ id: "App.info.Accesso" }))} {(intl.formatMessage({ id: GOLD_AUTH[system.store.auth] }))}
                                                    </>
                                                </div>
                                            )
                                        ) : (
                                            <div class="teknox" id="teknox">
                                                {status.structs.isTeknoxAuthorized.auth_level === 0 && (<>
                                                    <FormattedMessage id="App.info.AccessoNonEffettuato" />
                                                </>)}
                                                {status.structs.isTeknoxAuthorized.auth_level === 1 && (<>
                                                    <FormattedMessage id="App.info.AccessoUtente" />
                                                </>)}
                                                {status.structs.isTeknoxAuthorized.auth_level === 2 && (<>
                                                    <FormattedMessage id="App.info.AccessoAmministratore" />
                                                </>)}
                                                {status.structs.isTeknoxAuthorized.auth_level === 3 && (<>
                                                    <FormattedMessage id="App.info.AccessoInstallatore" />
                                                </>)}
                                            </div>
                                        )
                                }
                            </>
                        )
                    }
                    <div id="profile" className={profileOpen ? 'full' : ''} onClick={toggleProfile}>
                        {
                            pic
                                ? (
                                    <div className={((user.premium_date || user.sim_premium_date) ? `premiumAvatar` : ``)} style={{
                                        height: "30px",
                                        width: "30px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        marginRight: ".5em"
                                    }}>
                                        <img src={`${process.env.REACT_APP_API_URL}/download/${pic}`} style={{
                                            display: "block",
                                            borderRadius: '100%',
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain"
                                        }} />
                                    </div>
                                ) : (
                                    <div className={((user.premium_date || user.sim_premium_date) ? `premiumAvatar` : ``)} style={{
                                        height: "30px",
                                        width: "30px",
                                        display: "inline-block",
                                        verticalAlign: "middle",
                                        margin: "0"
                                    }}>
                                        <i className="big fa fa-user-circle" style={{ width: "24px", fontSize: "1.5em", margin: "0", display: "flex" }} />
                                    </div>
                                )
                        }
                        {email}
                        <button>
                            <i className={profileOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
                        </button>
                        <div id="user_menu">
                            {!isCat && (
                                <ul>
                                    <li>
                                        <i className="fa fa-user" />
                                        <Link to={`/profile`} onClick={toggleProfile}>
                                            <FormattedMessage id="App.info.Profilo" />
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-star-o" />
                                        <Link to={`/premium`} onClick={toggleProfile}>
                                            <FormattedMessage id="App.info.Premium" />
                                        </Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-cog" />
                                        <Link to={`/settings`} onClick={toggleProfile}>
                                            <FormattedMessage id="App.info.Impostazioni" />
                                        </Link>
                                    </li>
                                </ul>
                            )}
                            <form>
                                <fieldset>
                                    <button className="ok" onClick={() => { logout(); }}>
                                        <FormattedMessage id="App.info.Logout" />
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </header>
                <nav className={menuClosed ? 'closed' : ''}>
                    <div className="close right">
                        <big onClick={toggleMenu}>
                            <i className="fa fa-window-close" />
                        </big>
                    </div>
                    <div>
                        <br />
                        <img src="/static/images/new_menu_logo.png" width="105%" />
                    </div>
                    {
                        system && socket
                            ? (
                                <ul className="controlPanel">
                                    <li className="back">
                                        <Link to={`/`} onClick={() => {
                                            setMenu1(false);
                                            setMenu2(false);
                                            setMenu3(false);
                                            setMenu4(false);
                                            setMenu5(false);
                                            let __systemMenus = clone(systemMenus);
                                            for (let j in __systemMenus) {
                                                __systemMenus[j].open = false;
                                            }
                                            setSystemMenus(__systemMenus);
                                            closeMobileMenu();
                                        }}>
                                            <i className="fa fa-chevron-left" /> {system.name}
                                        </Link>
                                    </li>
                                    <li className={activeLink('/control-panel', 'selected1')}>
                                        <Link to={`/${url}/${system.id}`} onClick={() => { refresh(); closeMobileMenu(); }}>
                                            <FormattedMessage id="App.info.PannelloDiControllo" />
                                        </Link>
                                    </li>
                                    <li className={(!!systemMenus[0] && systemMenus[0].open ? 'selected1' : 'parentClosed')}>
                                        <Link to={`#`} onClick={e => { e.preventDefault(); setMenu1(!menu1); }}>
                                            {(!!systemMenus[0] ? systemMenus[0].name : '')}
                                            {menu1
                                                ? <i className="fa fa-chevron-down" />
                                                : <i className="fa fa-chevron-right" />
                                            }
                                        </Link>
                                    </li>
                                    {menu1 && renderMenu(0)}
                                    <li className={(!!systemMenus[1] && systemMenus[1].open ? 'selected1' : 'parentClosed')}>
                                        <Link to={`#`} onClick={e => { e.preventDefault(); setMenu2(!menu2); }}>
                                            {(!!systemMenus[1] ? systemMenus[1].name : '')}
                                            {(menu2)
                                                ? <i className="fa fa-chevron-down" />
                                                : <i className="fa fa-chevron-right" />
                                            }
                                        </Link>
                                    </li>
                                    {menu2 && renderMenu(1)}
                                    <li className={(!!systemMenus[2] && systemMenus[2].open ? 'selected1' : 'parentClosed')}>
                                        <Link to={`#`} onClick={e => { e.preventDefault(); setMenu3(!menu3); }}>
                                            {(!!systemMenus[2] ? systemMenus[2].name : '')}
                                            {(menu3)
                                                ? <i className="fa fa-chevron-down" />
                                                : <i className="fa fa-chevron-right" />
                                            }
                                        </Link>
                                    </li>
                                    {menu3 && renderMenu(2)}
                                    <li className={(!!systemMenus[3] && systemMenus[3].open ? 'selected1' : 'parentClosed')}>
                                        <Link to={`#`} onClick={e => { e.preventDefault(); setMenu4(!menu4); }}>
                                            {(!!systemMenus[3] ? systemMenus[3].name : '')}
                                            {(menu4)
                                                ? <i className="fa fa-chevron-down" />
                                                : <i className="fa fa-chevron-right" />
                                            }
                                        </Link>
                                    </li>
                                    {menu4 && renderMenu(3)}
                                    <li className={(!!systemMenus[4] && systemMenus[4].open ? 'selected1' : 'parentClosed')}>
                                        <Link to={`#`} onClick={e => { e.preventDefault(); setMenu5(!menu5); }}>
                                            {(!!systemMenus[4] ? systemMenus[4].name : '')}
                                            {(menu5)
                                                ? <i className="fa fa-chevron-down" />
                                                : <i className="fa fa-chevron-right" />
                                            }
                                        </Link>
                                    </li>
                                    {menu5 && renderMenu(4)}
                                    {url === 'europlus' && (system.profiles.indexOf('proprietario') >= 0) && (
                                        <li className={activeLink(`/${url}/${system.id}/notifications`, 'selected1')}>
                                            <Link to={`/${url}/${system.id}/notifications`} onClick={() => { refresh(); closeMobileMenu(); }}>
                                                <FormattedMessage id="App.info.NotificheEventi" />
                                            </Link>
                                        </li>
                                    )}
                                    {/*<li className={activeLink(`/${url}/${system.id}/importexport`, 'selected1')}>
                                        <Link to={`/${url}/${system.id}/importexport`} onClick={() => { refresh(); closeMobileMenu(); }}>Import Export configurazione</Link>
                                    </li>*/}
                                </ul>
                            ) : (
                                <ul>
                                    <li className={activeLink('/')}>
                                        <i className="fa fa-tachometer" />
                                        <Link to={`/`} onClick={closeMobileMenu}>
                                            <FormattedMessage id="App.info.Impianti" />
                                        </Link>
                                    </li>
                                    {!isCat && (
                                        <>
                                            <li className={activeLink('/new-system')}>
                                                <i className="fa fa-server" />
                                                <Link to={`/new-system`} onClick={closeMobileMenu}>
                                                    <FormattedMessage id="App.info.NuovoImpianto" />
                                                </Link>
                                            </li>
                                            <li className={activeLink('/add-user')}>
                                                <i className="fa fa-user-plus" />
                                                <Link to={`/add-user`} onClick={closeMobileMenu}>
                                                    <FormattedMessage id="App.info.AbilitazioneUtenti" />
                                                </Link>
                                            </li>
                                            <li className={activeLink('/sim')}>


                                                <div style={{ display: 'flex' }}>
                                                    <i className="fa fa-cloud" />
                                                    <Link to={`/sim`} onClick={closeMobileMenu}>
                                                        <FormattedMessage id="App.info.Premium+" />
                                                    </Link>
                                                </div>


                                            </li>
                                            <li className={activeLink('/cat')}>
                                                <i className="fa fa-vcard-o" />
                                                <Link to={`/cat`} onClick={closeMobileMenu}>
                                                    <FormattedMessage id="App.info.AssistenzaTecnica" />
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            )
                    }
                </nav>
            </>
        )
    )

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Fumo = ({ current, auth }) => {
    return (
        <>
            <div className="rack bottomBorder">
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="supervisione" name="supervisione" defaultValue="0" defaultChecked={current.supervisione} disabled={auth !== 2} />
                        <label htmlFor={`supervisione`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.Supervisione" />
                        </label>
                    </div>
                </div>
                <div className="w33">
                    <div className="switchButton">
                        <input type="checkbox" className="switch" id="led_on" name="led_on" defaultValue="0" defaultChecked={current.led_on} disabled={auth !== 2} />
                        <label htmlFor={`led_on`}>
                            <span>
                                <span />
                            </span>
                            <FormattedMessage id="App.info.LedOn" />
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Fumo;
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import Modal from '../../../elements/Modal';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    goldDoRequest
} from '../../../../actions';

registerLocale('it', it)

const mapStateToProps = state => ({
    data: state.queue_data,
    errors: state.queue_errors,
    system: state.system
});

const mapDispatchToProps = (dispatch) => ({
    goldGetTime: payload => dispatch(goldDoRequest('goldGetTime', payload, false)),
    goldSetTime: payload => dispatch(goldDoRequest('goldSetTime', payload)),
    goldSyncTime: payload => dispatch(goldDoRequest('goldSyncTime', payload)),
});

const _Orologio = ({ data, errors, system, goldGetTime, goldSetTime, goldSyncTime }) => {
    const codeForm = useRef(null);
    const [current, setCurrent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [processed1, setProcessed1] = useState(null);
    const [processed2, setProcessed2] = useState(null);
    const [processed3, setProcessed3] = useState(null);
    const [success, setSuccess] = useState(null);
    const intl = useIntl();

    useEffect(() => {
        goldGetTime({ id_centrale: system.id_centrale });
        setProcessed1(true);
    }, []);

    if (!!!system.store || !!!system.store.auth || !(system.store.auth & 6)) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficenti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const handleSubmit = e => {
        e.preventDefault();
        goldSetTime({
            id_centrale: system.id_centrale,
            date: selectedDate.toString()
        });
        setProcessed3(true);
    }

    const handleSync = e => {
        e.preventDefault();
        goldSyncTime({ id_centrale: system.id_centrale });
        setProcessed2(true);
    }

    if (data && processed1) {
        setProcessed1(null);
        if (!!data[0] && data[0].status === 'OK') {
            setCurrent(data[0]);
            setSelectedDate(
                new Date(
                    data[0].year,
                    parseInt(data[0].month) - 1,
                    parseInt(data[0].day),
                    parseInt(data[0].hours),
                    parseInt(data[0].minutes)
                )
            );
        }
    }

    if (data && processed2) {
        setProcessed2(null);
        if (!!data[0] && data[0].status === 'OK') {
            setSuccess(true);
            setSelectedDate(
                new Date(
                    data[0].year,
                    parseInt(data[0].month) - 1,
                    parseInt(data[0].day),
                    parseInt(data[0].hours),
                    parseInt(data[0].minutes)
                )
            )
        } else if (!errors) {
            setSuccess(false);
        }
    }

    if (data && processed3) {
        setProcessed3(null);
        if (!!data[0] && data[0].status === 'OK') {
            setSuccess(true);
        } else if (!errors) {
            setSuccess(false);
        }
    }

    return (
        <>
            {!!!current
                ? <>
                    <FormattedMessage id="App.info.CaricamentoInCorso" />
                </>
                : (
                    <section>
                        {success === true && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuc" })} onClose={() => { setSuccess(null); }} />}
                        {success === false && <Modal title={intl.formatMessage({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); }} />}
                        <div className="innerSection">
                            <div className="rack">
                                <div className="w100 formSection">
                                    <form ref={codeForm} className="stackableW33">
                                        <h3>
                                            <FormattedMessage id="App.info.Orologio" />
                                        </h3>
                                        <br />
                                        <fieldset>
                                            <div className="rack">
                                                <div className="w50">
                                                    <p>
                                                        <FormattedMessage id="App.info.DataOra" />
                                                    </p>
                                                    <DatePicker
                                                        showTimeSelect
                                                        timeIntervals={1}
                                                        timeCaption={intl.formatMessage({ id: "App.info.Orario" })}
                                                        dateFormat={intl.formatMessage({ id: "App.info.FormatDateExtended" })}
                                                        locale="it"
                                                        monthsShown={2}
                                                        showYearDropdown
                                                        todayButton={intl.formatMessage({ id: "App.info.SelezionaOggi" })}
                                                        onChange={e => { setSelectedDate(e); }}
                                                        onSelect={e => { setSelectedDate(e); }}
                                                        selected={selectedDate}
                                                        placeholderText={intl.formatMessage({ id: "App.info.InserireDataEOra" })}
                                                    />
                                                </div>
                                                <div className="w50">
                                                    <p>
                                                        <FormattedMessage id="App.info.OreDiAttivita" />
                                                    </p>
                                                    <p>{current.activity}</p>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset>
                                            <div className="rack">
                                                <div className="w100 right">
                                                    <button className="ok auto spaced" onClick={handleSync} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>
                                                        <FormattedMessage id="App.info.ApplicaOraCloud" />
                                                    </button>
                                                    <button className="ok auto spaced" onClick={handleSubmit} disabled={(system.store.auth === 2 && system.store.state.prog_active)}>
                                                        <FormattedMessage id="App.info.Applica" />
                                                    </button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <p>&nbsp;</p>
                    </section>
                )
            }
        </>
    );
};

const Orologio = connect(mapStateToProps, mapDispatchToProps)(_Orologio);

export default Orologio;
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Multiselect } from 'multiselect-react-dropdown';
import { FormattedMessage, useIntl } from 'react-intl';

import get_bits, { arrayIntToString, stringToArrayInt } from '../../../../lib/bytesFunctions';

import Modal from '../../../elements/Modal';
import Outcome from '../../../elements/Outcome';

import {
    doSocketRequest,
    setStore
} from '../../../../actions';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data_socket: state.data_socket
});

const mapDispatchToProps = (dispatch) => ({
    doSocketVariation: (socket, trama, payload, noSuccess = false, noError = false, redirect = null) => dispatch(doSocketRequest(socket, trama, payload, true, noSuccess, noError, redirect)),
    setStore: data => dispatch(setStore(data))
});

const _Codici = ({ match, system, socket, doSocketVariation, data_socket, setStore }) => {
    const codeForm = useRef(null);
    const copyForm = useRef(null);
    const sub = match.params.sub && match.params.sub <= 128 ? parseInt(match.params.sub) : 1;
    const [errors, setErrors] = useState("");
    const [trama, setTrama] = useState(sub + 98);
    const [firstLoad, setFirstLoad] = useState(true);
    const [redir, setRedir] = useState(null);
    const [current, setCurrent] = useState(null);
    const [codici, setCodici] = useState([]);
    const [codiciTarget, setCodiciTarget] = useState([]);
    const [processed, setProcessed] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [codeTeknox, setCodeTeknox] = useState(0);
    const status = !!system && !!socket ? system.store.status : null;
    const intl = useIntl();

    useEffect(
        () => {
            let _codici = [];
            for (let i = 0x0063, k = 0; i <= 0x00E2; i++) {
                if (!!system.store[i]) {
                    let _x = system.store[i].split(',').map(x => parseInt(x));
                    let name_array = _x.slice(5, 21);
                    let sum = _x.slice(0, 5).reduce((sum, x) => sum + x);
                    _codici.push({
                        trama: i,
                        index: k,
                        name: _x[4] != 0xFF ? arrayIntToString(name_array) : '',
                        label_name: (sum && _x[4] != 0xFF) ? arrayIntToString(name_array) : intl.formatMessage({ id: 'App.info.NonDisponibile' }),
                        payload: _x,
                        registered: (sum && _x[4] != 0xFF)
                    });
                }
                k++;
            }
            setCodici(_codici);
        }, []
    );

    useEffect(
        () => {
            if (firstLoad && !!system.store.status) {
                setRedir(false);
                if (!processed) {
                    doSocketVariation(socket, (trama + 0x0100), [], true, true);
                    setProcessed(true);
                }
                setFirstLoad(false);
            }
            return () => null;
        },
        [firstLoad, setFirstLoad, system, trama, doSocketVariation, setRedir, processed, setProcessed, socket]
    );

    useEffect(
        () => {
            if (current) {
                let _codiciTarget = codici.map((value, index) => {
                    value.label = `${index + 1} - ${value.label_name}`;
                    return value.trama !== current.trama && !!value.registered ? value : null;
                }
                ).filter(x => !!x);
                setCodiciTarget(_codiciTarget);
                handleChangeTeknox({
                    target: {
                        value: current.payload[4]
                    }
                });
            }
            return () => null;
        },
        [current]
    );

    if (data_socket && processed) {
        setProcessed(false);
        if (parseInt(data_socket.type) === parseInt(trama)) {
            let _x = data_socket.payload.split(',').map(x => parseInt(x));
            let name_array = _x.slice(5, 21);
            let sum = name_array.reduce((sum, x) => sum + x);
            let _sub = trama - 99;
            let _current = {
                trama,
                index: _sub,
                name: _x[4] !== 0xFF ? arrayIntToString(name_array) : '',
                label_name: (sum && _x[4] !== 0xFF) ? arrayIntToString(name_array) : intl.formatMessage({ id: 'App.info.NonDisponibile' }),
                payload: _x,
                registered: (sum && _x[4] !== 0xFF)
            };
            setCurrent(_current);
            let _codici = [...codici];
            _codici[_current.index] = { ..._current }
            if (!_current.registered) {
                _codici[_current.index].label_name = intl.formatMessage({ id: 'App.info.NonDisponibile' });
            }
            setCodici(_codici);
        }
    }

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                text={intl.formatMessage({ id: 'App.info.ImpiantoNonConnesso' })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={intl.formatMessage({ id: 'App.info.LinceCloudImpianto' })}
                        text={intl.formatMessage({ id: 'App.info.PermessiNonSufficienti' })}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const handleChangeTeknox = e => {
        setCodeTeknox(parseInt(e.target.value));
        if (e.target.value == 2) {
            const form = codeForm.current;
            for (let i = 1; i < 4; i++) {
                form[`g${i}`].checked = false;
            }
            form[`gext`].checked = false;
        }
    };

    const handleSelectChange = e => {
        const value = parseInt(e.target.value) + 1;
        setCurrent(null);
        setFirstLoad(true);
        setTrama(value + 98);
        setRedir(`/europlus/${system.id}/code/${value}`);
    };

    const makePayload = (name = null) => {
        const form = codeForm.current;
        const payload = [
            current.payload[0],
            current.payload[1],
            current.payload[2],
        ];
        payload.push((
            (form['g1'].checked ? 1 : 0) +
            (form['g2'].checked ? 2 : 0) +
            (form['g3'].checked ? 4 : 0) +
            (form['gext'].checked ? 8 : 0) +
            (form['elettroserratura'].checked ? 16 : 0) +
            (form['ronda'].checked ? 32 : 0) +
            (form['silenzioso'].checked ? 64 : 0) +
            (form['antipanico'].checked ? 128 : 0)
        ));
        payload.push((
            parseInt(form['privilegi'].value) +
            (get_bits(current.payload[4], 7) ? 128 : 0)
        ));
        stringToArrayInt(16, (name != null ? name : form["nome"].value), 0x20).map(x => { payload.push(x); return null; });
        return payload;
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (current.registered) {
            const payload = makePayload();
            doSocketVariation(socket, current.trama, payload);
            let store = { [current.trama]: payload.join(',') };
            setStore(store);
            handleSelectChange({ target: { value: current.index } });
        } else {
            setErrors(intl.formatMessage({ id: 'App.info.CodiceNonUtilizzabile' }));
        }
    }

    const handleCopy = e => {
        e.preventDefault();
        const targets = copyForm.current.getSelectedItems();
        const settings = [];
        for (let i in targets) {
            let _payload = makePayload(targets[i].nome);
            _payload[0] = targets[i].payload[0];
            _payload[1] = targets[i].payload[1];
            _payload[2] = targets[i].payload[2];
            _payload[4] = targets[i].payload[4];
            settings.push({
                trama: targets[i].trama,
                payload: _payload
            });
        }
        socket.emit('sendQueue', JSON.stringify(settings));
    }

    const handleProgramChange = e => {
        const form = codeForm.current;
        if (e.target.checked) {
            if (e.target.name == "gext") {
                for (let i = 1; i < 4; i++) {
                    form[`g${i}`].checked = false;
                }
            } else {
                form[`gext`].checked = !e.target.checked;
            }
        }
    }

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        let _current = { ...current };
        let _payload = (new Array(21)).fill(255);
        let store = { [_current.trama]: _payload.join(',') };
        setStore(store);
        setCurrent(null);
        doSocketVariation(socket, 0x00FA, [_current.index], false, false, `/europlus/${system.id}`);
        setConfirm(false);
    }

    if (redir) {
        return <Redirect to={redir} />
    }

    if (!!!current) {
        return <>
            <FormattedMessage id="App.info.CaricamentoInCorso" />
        </>;
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {!!errors && (
                            <Outcome text={errors} type="ko" onClose={setErrors} />
                        )}
                        {
                            confirm && (
                                <>
                                    <Modal
                                        title={intl.formatMessage({ id: 'App.info.CancellazioneCodice' })}
                                        text={intl.formatMessage({ id: 'App.info.CeonfermareEliminazioneDefinitva' })}
                                        onClose={closeModal}
                                        onConfirm={confirmDelete}
                                    />
                                </>
                            )
                        }
                        <form ref={codeForm}>
                            {current && (
                                <>
                                    <h3>
                                        <FormattedMessage id="App.info.ChiaviCodici" />
                                    </h3>
                                    <br />
                                    <h1>
                                        <FormattedMessage id="App.info.Parametri" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Numero" />
                                                </label>
                                                <select value={current.index} name="numero" onChange={handleSelectChange}>
                                                    {
                                                        codici.map((value, index) => <option key={`numero_${index}`} value={index}>{`${index + 1} - ${value.label_name} `}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Nome" />
                                                </label>
                                                <input type="text" name="nome" defaultValue={current.name || ``} maxLength={16} />
                                            </div>
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Privilegi" />
                                                </label>
                                                <select defaultValue={get_bits(current.payload[4], 0, 3)} name="privilegi" onChange={handleChangeTeknox}>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Utente" />
                                                    </option>
                                                    <option value={2}>
                                                        <FormattedMessage id="App.info.Installatore" />
                                                    </option>
                                                    <option value={4}>
                                                        <FormattedMessage id="App.info.Amministratore" />
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="w25">
                                                <label>
                                                    <FormattedMessage id="App.info.Tipologia" />
                                                </label>
                                                <select defaultValue={get_bits(current.payload[4], 7)} name="tipologia" disabled={true}>
                                                    <option value={0}>
                                                        <FormattedMessage id="App.info.Numerico" />
                                                    </option>
                                                    <option value={1}>
                                                        <FormattedMessage id="App.info.Chiave" />
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="elettroserratura" name="elettroserratura" defaultValue="0" defaultChecked={get_bits(current.payload[3], 4)} onChange={() => null} />
                                                    <label htmlFor={`elettroserratura`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Elettroserratura" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="ronda" name="ronda" defaultValue="0" defaultChecked={get_bits(current.payload[3], 5)} onChange={() => null} />
                                                    <label htmlFor={`ronda`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Ronda" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="silenzioso" name="silenzioso" defaultValue="0" defaultChecked={get_bits(current.payload[3], 6)} onChange={() => null} />
                                                    <label htmlFor={`silenzioso`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Silenzioso" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="antipanico" name="antipanico" defaultValue="0" defaultChecked={get_bits(current.payload[3], 7)} onChange={() => null} />
                                                    <label htmlFor={`antipanico`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        <FormattedMessage id="App.info.Antipanico" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>
                                        <FormattedMessage id="App.info.AssociazioniAProgrammi" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack">
                                            <h4>
                                                <FormattedMessage id="App.info.Programmi" />
                                            </h4>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g1" name="g1" defaultValue="0" defaultChecked={get_bits(current.payload[3])} onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                    <label htmlFor={`program_label_g1`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g1}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g2" name="g2" defaultValue="0" defaultChecked={get_bits(current.payload[3], 1)} onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                    <label htmlFor={`program_label_g2`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g2}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_g3" name="g3" defaultValue="0" defaultChecked={get_bits(current.payload[3], 2)} onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                    <label htmlFor={`program_label_g3`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.g3}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="w25">
                                                <div className="switchButton">
                                                    <input type="checkbox" className="switch" id="program_label_gext" name="gext" defaultValue="0" defaultChecked={get_bits(current.payload[3], 3)} onChange={handleProgramChange} disabled={(codeTeknox == 2)} />
                                                    <label htmlFor={`program_label_gext`}>
                                                        <span>
                                                            <span />
                                                        </span>
                                                        {system.gext}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rack" style={{ marginTop: "4em" }}>
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={e => { e.preventDefault(); setConfirm(true); }} disabled={!current.registered}>
                                                    <FormattedMessage id="App.info.Elimina" />
                                                </button>
                                                <button className="ok auto spaced" onClick={() => { handleSelectChange({ target: { value: current.index, scrollToBottom: true } }); }}>
                                                    <FormattedMessage id="App.info.Annulla" />
                                                </button>
                                                <button className="ok auto spaced" onClick={handleSubmit} disabled={!current.registered}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <h1>
                                        <FormattedMessage id="App.info.CopiaImpostazioniChiaviCodici" />
                                    </h1>
                                    <fieldset>
                                        {
                                            teknox === 3
                                                ? (
                                                    <div className="rack">
                                                        <div className="w50" style={{ marginBottom: "12em" }}>
                                                            <label>
                                                                <FormattedMessage id="App.info.Numero" />
                                                            </label>
                                                            <Multiselect
                                                                closeOnSelect={false}
                                                                displayValue="label"
                                                                emptyRecordMsg={intl.formatMessage({ id: 'App.info.NessunCodiceDisponibile' })}
                                                                options={codiciTarget}
                                                                placeholder={intl.formatMessage({ id: 'App.info.SelezionaUnoOPiuCodici' })}
                                                                ref={copyForm}
                                                            />
                                                        </div>
                                                        <div className="w50 right">
                                                            <p>
                                                                <br />
                                                                <button className="ok auto" onClick={handleCopy} disabled={!current.registered}>
                                                                    <FormattedMessage id="App.info.Copia" />
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    <p>
                                                        <FormattedMessage id="App.info.NecessariPermessiInstallatore" />
                                                    </p>
                                                )
                                        }
                                    </fieldset>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section>
    );
};

const Codici = connect(mapStateToProps, mapDispatchToProps)(_Codici);

export default Codici;
import React from 'react';
import { FormattedMessage } from 'react-intl';

const StatoRadioComando = ({ stat }) => {
    return (
        <>
            <div className="rack">
                <div className="w20">
                    <p><i className="fa fa-circle" style={{ color: stat.batteria_scarica ? "red" : (stat.batteria_scarica === false ? "#ffc20a" : "rgb(176, 176, 176)") }} />
                        <FormattedMessage id="App.info.BatteriaScarica" />
                    </p>
                </div>
            </div>
        </>
    );
};

export default StatoRadioComando;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Modal from '../../../elements/Modal';
import { clone } from '../../../../lib/clone';
import { j2bBus } from '../../../../lib/goldParser/converter';
import { b2jBus } from '../../../../lib/goldParser/physicalMap';

import {
    goldDoRequest,
    setStore
} from '../../../../actions';
import { goldExitSystem, sleep } from '../../../../api/Cloud';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
    data: state.queue_data,
    errors: state.queue_errors
});

const mapDispatchToProps = (dispatch) => ({
    goldAcquirePeripheral: payload => dispatch(goldDoRequest('goldAcquirePeripheral', payload)),
    goldGetOutBus: (requests, payloads) => dispatch(goldDoRequest(requests, payloads, false)),
    goldModOutBus: (requests, payloads) => dispatch(goldDoRequest(requests, payloads, false)),
    setStore: data => dispatch(setStore(data))
});

const states = [
    "App.info.AprireTamper",
    "App.info.DispositivoPresente",
    "App.info.DispositivoAcquisito",
    "App.info.AcquisizioneFallita",
];

const _Bus = ({ system, socket, data, errors, goldAcquirePeripheral, goldGetOutBus, goldModOutBus, setStore }) => {
    const busForm = useRef(null);
    const codeForm = useRef(null);
    const _system = clone(system);
    const [on, setOn] = useState(false);
    const [rfState, setRfState] = useState(null);
    const [ndisp, setNdisp] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [processed2, setProcessed2] = useState(false);
    const [processed3, setProcessed3] = useState(false);
    const [processed4, setProcessed4] = useState(false);
    const [success, setSuccess] = useState(null);
    const [current, setCurrent] = useState(null);
    const intl = useIntl();

    const umount = useCallback(async () => {
        await goldExitSystem({ id_centrale: system.id_centrale }, true);
        socket.off('onGoldRfState');
        await sleep(1500);
        setStore({ polling_pause: false });
    }, []);

    useEffect(
        () => {
            socket.on('onGoldRfState', response => {
                setRfState(response.state);
                if (response.state == 2) {
                    setNdisp(parseInt(response.data) - 1);
                } else if (response.state < 0) {
                    response.state = 3;
                    setOn(false);
                }
            });
            return async () => {
                await umount();
                return true;
            }
        },
        [umount]
    );

    useEffect(() => {
        if (ndisp != null) {
            let requests = [
                'goldGetOutBus'
            ];
            let payloads = [
                {
                    id_centrale: system.id_centrale,
                    idx: ndisp
                }
            ];
            goldGetOutBus(requests, payloads);
            setProcessed3(true);
        }
    }, [ndisp])

    const start = e => {
        e.preventDefault();
        setButtonDisabled(true);
        setOn(true);
        setCurrent(null);
        setNdisp(null);
        setStore({ polling_pause: true });
        setTimeout(() => {
            goldAcquirePeripheral({
                id_centrale: system.id_centrale,
                type: 'bus'
            });
            setButtonDisabled(false);
        }, 1500);
    }

    const exit = async () => {
        await goldExitSystem({ id_centrale: system.id_centrale }, true);
        setStore({ polling_pause: false });
    };

    const stop = async e => {
        e.preventDefault();
        setButtonDisabled(true);
        await exit();
        setOn(false);
        setRfState(null);
        setProcessed2(null);
        setButtonDisabled(false);
    }

    if (!!!system.store || !!!system.store.auth || system.store.auth !== 2) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.PermessiInsufficenti" })}
                redirect={`/gold/${system.id}`}
            />
        );
    }

    const makePayload = () => {
        const form = codeForm.current;
        return {
            ...current,
            nome: form.nome.value,
        };
    };

    const handleSubmit = e => {
        e.preventDefault();
        const payload = makePayload();
        const requests = [
            'goldModOutBus',
            'goldSetPeripheral'
        ];
        const payloads = [
            {
                id_centrale: system.id_centrale,
                idx: ndisp,
                edit: false,
                edata: j2bBus(payload, false)
            },
            {
                id_centrale: system.id_centrale
            }
        ];
        goldModOutBus(requests, payloads);
        setProcessed4(true);
    }

    if (data && processed2) {
        setProcessed2(null);
        setSuccess(!!data && !errors);
    }

    if (data && processed3) {
        setProcessed3(null);
        const _current = b2jBus(data[0].data);
        let pm = clone(_system.store.pm);
        pm.bus[ndisp] = _current;
        setStore({
            pm
        });
        setCurrent(_current);
    }

    if (data && processed4) {
        setProcessed4(null);
        const _success = (!!data && !errors) ? 2 : false;
        if (_success === 2) {
            let pm = clone(_system.store.pm);
            const form = codeForm.current;
            pm.bus[ndisp].nome = form.nome.value;
            setStore({
                pm
            });
        }
        setSuccess(_success);
    }

    return (
        <section>
            {success === true && <Modal title={intl.useIntl({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.ComandoInviatoConSuc" })} onClose={() => { setSuccess(null); }} />}
            {success === 2 && <Modal title={intl.useIntl({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.DispositivoAcquisitoConSucc" })} onClose={() => { setSuccess(null); start({ preventDefault: () => null }); }} />}
            {success === false && <Modal title={intl.useIntl({ id: "App.info.ImpiantoLinceCloud" })} text={intl.formatMessage({ id: "App.info.Errore" })} onClose={() => { setSuccess(null); exit(); }} />}
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {current
                            ? (
                                <form ref={codeForm} className="stackableW33">
                                    <h1>
                                        <FormattedMessage id="App.info.PerifericheBus" />
                                    </h1>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w33">
                                                <label>
                                                    <FormattedMessage id="App.info.Tipologia" />
                                                </label>
                                                <br />
                                                {current.tipo}
                                            </div>
                                            <div className="w33">
                                                <label>
                                                    <FormattedMessage id="App.info.NomeDispositivo" />
                                                </label>
                                                <input type="text" name="nome" defaultValue={current.nome} maxLength={16} />
                                            </div>
                                        </div>
                                        <p>&nbsp;</p>
                                    </fieldset>
                                    <fieldset>
                                        <div className="rack">
                                            <div className="w100 right">
                                                <button className="ok auto spaced" onClick={handleSubmit}>
                                                    <FormattedMessage id="App.info.Applica" />
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            ) : (
                                <form ref={busForm}>
                                    <h1>
                                        <FormattedMessage id="App.info.MemorizzazioneDispositivoBus" />
                                    </h1>
                                    <fieldset>
                                        <br />
                                        <div className="rack">
                                            <div className="w50">
                                                {on
                                                    ? (
                                                        <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} disabled={buttonDisabled} onClick={stop}>
                                                            <FormattedMessage id="App.info.Interrompi" />
                                                        </button>
                                                    )
                                                    : (
                                                        <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} disabled={buttonDisabled} onClick={start}>
                                                            <FormattedMessage id="App.info.Avvia" />
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="rack">
                                            <div className="w100">
                                                {on && (<p className="loadingText">
                                                    <FormattedMessage id="App.info.RicercaDispositivoInCorso" />
                                                </p>)}
                                            </div>
                                            <div className="w100">
                                                {rfState != null && (<p>{(intl.formatMessage({ id: states[rfState] }) || ``)}</p>)}
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            )
                        }
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const Bus = connect(mapStateToProps, mapDispatchToProps)(_Bus);

export default Bus;
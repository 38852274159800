import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { arrayIntToString } from '../../../../lib/bytesFunctions';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    doSocketRequest,
} from '../../../../actions';

import Modal from '../../../elements/Modal';

const mapStateToProps = state => ({
    system: state.system,
    socket: state.socket,
});

const mapDispatchToProps = (dispatch) => ({
    doSocketCommand: (socket, trama, noSuccess = false) => dispatch(doSocketRequest(socket, trama, [], true, noSuccess)),
});

const _MemoriaEventi = ({ system, socket, doSocketCommand }) => {
    const codeForm = useRef(null);

    const [confirm, setConfirm] = useState(false);
    const [counter, setCounter] = useState(0);
    const [emptyCells, setEmptyCells] = useState(0);
    const [events, setEvents] = useState([]);
    const [finished, setFinished] = useState(false);
    const [on, setOn] = useState(false);
    const [timer, setTimer] = useState(null);
    const intl = useIntl();

    const umount = useCallback(() => {
        socket.emit('eventMemoryStop');
        socket.off('onMemoryEventReceived');
        clearInterval(timer);
    }, [timer, socket]);

    useEffect(
        () => {
            socket.on('onMemoryEventReceived', data => {
                const payload = data.payload.split(',');
                parseEvent(payload, setCounter);
            });
            return () => {
                umount();
                return true;
            };
        },
        [umount]
    );

    useEffect(
        () => {
            if (counter >= 512 || emptyCells >= 3) {
                clearInterval(timer);
                setFinished(true);
                setOn(false);
            }
        },
        [counter, emptyCells, setFinished, setOn]
    );

    const types = [
        intl.formatMessage({ id: "App.info.AllarmeDellIngresso" }),
        intl.formatMessage({ id: "App.info.AttDisattImpianto" }),
        intl.formatMessage({ id: "App.info.AllarmeVentiquattroH" }),
        intl.formatMessage({ id: "App.info.SabotaggioTamperCentrale" }),
        intl.formatMessage({ id: "App.info.SabotaggioTamperDispRemoto" }),
        intl.formatMessage({ id: "App.info.SabotaggioBusDiComunicazione" }),
        intl.formatMessage({ id: "App.info.SabotaggioIngressoAS" }),
        intl.formatMessage({ id: "App.info.SabotaggioConChiaveFalsa" }),
        intl.formatMessage({ id: "App.info.SabotaggioIngressoConChiave" }),
        intl.formatMessage({ id: "App.info.SupertastoZero" }),
        intl.formatMessage({ id: "App.info.SupertastoUno" }),
        intl.formatMessage({ id: "App.info.SupertastoDue" }),
        intl.formatMessage({ id: "App.info.SupertastoTre" }),
        intl.formatMessage({ id: "App.info.SupertastoQuattro" }),
        intl.formatMessage({ id: "App.info.SupertastoCinque" }),
        intl.formatMessage({ id: "App.info.Rete220Assente" }),
        intl.formatMessage({ id: "App.info.RitornoRete220" }),
        intl.formatMessage({ id: "App.info.GuastoFusibile" }),
        intl.formatMessage({ id: "App.info.RipristinoFusibile" }),
        intl.formatMessage({ id: "App.info.BattDispEstScarica" }),
        intl.formatMessage({ id: "App.info.BattDispEstRipristinata" }),
        intl.formatMessage({ id: "App.info.BattCentrScarica" }),
        intl.formatMessage({ id: "App.info.BattCentrRipristinata" }),
        intl.formatMessage({ id: "App.info.PassaggioRondaEffettuatoDa" }),
        intl.formatMessage({ id: "App.info.ElettroserraturaAperta" }),
        intl.formatMessage({ id: "App.info.AllarmeAntipanicoAzionatoDa" }),
        intl.formatMessage({ id: "App.info.AllarmeDiSaturazioneRadio" }),
        intl.formatMessage({ id: "App.info.MancataSuperVisioneModuloRadio" }),
    ];

    const closeModal = e => {
        e.preventDefault();
        setConfirm(false);
    }

    const confirmDelete = e => {
        e.preventDefault();
        setConfirm(false);
        doSocketCommand(socket, 0x00F8);
    }

    const start = e => {
        e.preventDefault();
        setEvents([]);
        setOn(true);
        setFinished(false);
        setCounter(0);
        setEmptyCells(0);
        const interval = setInterval(
            () => {
                socket.emit('eventMemory');
            },
            1000
        );
        setTimer(interval);
    }

    const stop = e => {
        e.preventDefault();
        setOn(false);
        clearInterval(timer);
        setTimer(null);
    }

    const parseEvent = (payload, _setCounter) => {
        const slice7 = payload.slice(0, 8).join(',');
        const type = parseInt(payload[7]);
        _setCounter((counter) => parseInt(counter) + 1);
        if (type === 255) {
            setEmptyCells((emptyCells) => emptyCells + 1);
        } else {
            const datetime = new Date((2000 + parseInt(payload[2])), (payload[1] - 1), payload[0], payload[3], payload[4]);
            const datestring = datetime.toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
            const event = arrayIntToString(payload.slice(9, 38));
            const struct = {
                datetime,
                slice7,
                type,
                timestamp: datetime.getTime(),
                value: `${datestring}, ${types[type]} ${event}`
            };
            setEvents(events => [...events, struct]);
        }
    }

    const status = !!system && !!socket ? system.store.status : null;

    var teknox = 0;
    if (!status || !system.store.connected) {
        return (
            <Modal
                title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                text={intl.formatMessage({ id: "App.info.ImpiantoNonConnesso" })}
                redirect={`/europlus/${system.id}`}
            />
        );
    } else {
        if (!!status.structs) {
            teknox = status.structs.isTeknoxAuthorized.auth_level;
            if (teknox < 2) {
                return (
                    <Modal
                        title={intl.formatMessage({ id: "App.info.LinceCloudImpianto" })}
                        text={intl.formatMessage({ id: "App.info.NecessariPermessiDiInstOAmm" })}
                        redirect={`/europlus/${system.id}`}
                    />
                );
            }
        } else {
            return (<section></section>);
        }
    }

    const filters = [
        [], // none
        [0, 2, 25, 26, 27], // Allarmi
        [1], // Attivazioni
        [3, 4, 5, 6, 7], // Sabotaggi
        [9, 10, 11, 12, 13, 14], // Supertasti
        [15, 16, 17, 18, 19, 20, 21, 22], // Alimentazione
        [23], // Ronda
        [24], // Elettroserratura
        [8], // Accesso
    ];

    const renderEvents = (data, html = true) => {
        let _events = data.filter(
            (s => o =>
                (k => !s.has(k) && s.add(k))
                    (['slice7'].map(k => o[k]).join('|'))
            )
                (new Set()))
            .filter(x => {
                let filter = parseInt(codeForm.current['filter'].value);
                return filter === 0 || filters[filter].includes(x.type)
            })
            .sort((a, b) => parseInt(codeForm.current['sorting'].value) ? (a.datetime > b.datetime) : (a.datetime < b.datetime));

        return html
            ? _events.map((el, index) => (<p key={`event_${index}`} style={{ padding: '0 0.5em' }}>{el.value}</p>))
            : _events.map(el => (`${el.value}`))
            ;

    };

    const download = () => {
        const element = document.createElement("a");
        const file = new Blob([`${renderEvents(events, false).join('\n')}`], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${intl.formatMessage({ id: "App.info.MemoriaEventi_" })} ${system.name} - ${(new Date()).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}.csv`;
        document.body.appendChild(element);
        element.click();
    }

    return (
        <section>
            <div className="innerSection">
                <div className="rack">
                    <div className="w100 formSection">
                        {
                            confirm && (
                                <>
                                    <Modal
                                        title={intl.formatMessage({ id: "App.info.CancellazioneMemoriaEventi" })}
                                        text={intl.formatMessage({ id: "App.info.ConfermareEliminazioneDefinitiva" })}
                                        onClose={closeModal}
                                        onConfirm={confirmDelete}
                                    />
                                </>
                            )
                        }
                        <form ref={codeForm}>
                            <h3>
                                <FormattedMessage id="App.info.MemoriaEventi" />
                            </h3>
                            <br />
                            <fieldset>
                                <br />
                                <div className="rack">
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.Filtro" />
                                        </label>
                                        <select defaultValue="0" name="filter">
                                            <option value="0">
                                                {intl.formatMessage({ id: "App.info.NessunFiltro" })}
                                            </option>
                                            <option value="1">
                                                {intl.formatMessage({ id: "App.info.Allarmi" })}
                                            </option>
                                            <option value="2">
                                                {intl.formatMessage({ id: "App.info.AttivazioniDisattivazioni" })}
                                            </option>
                                            <option value="3">
                                                {intl.formatMessage({ id: "App.info.Sabotaggi" })}
                                            </option>
                                            <option value="4">
                                                {intl.formatMessage({ id: "App.info.Supertasti" })}
                                            </option>
                                            <option value="5">
                                                {intl.formatMessage({ id: "App.info.Alimentazione" })}
                                            </option>
                                            <option value="6">
                                                {intl.formatMessage({ id: "App.info.Ronda" })}
                                            </option>
                                            <option value="7">
                                                {intl.formatMessage({ id: "App.info.Elettroserratura" })}
                                            </option>
                                            <option value="8">
                                                {intl.formatMessage({ id: "App.info.Accesso" })}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="w33">
                                        <label>
                                            <FormattedMessage id="App.info.Ordina" />
                                        </label>
                                        <select defaultValue="" name="sorting">
                                            <option value="0">
                                                {intl.formatMessage({ id: "App.info.PiuRecenti" })}
                                            </option>
                                            <option value="1">
                                                {intl.formatMessage({ id: "App.info.MenoRecenti" })}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="w33">
                                        {on
                                            ? (
                                                <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} onClick={stop}>
                                                    <FormattedMessage id="App.info.Interrompi" />
                                                </button>
                                            )
                                            : (
                                                <button className="yellowButton" style={{ margin: '1.5em', width: '120px' }} onClick={start}>
                                                    <FormattedMessage id="App.info.Avvia" />
                                                </button>
                                            )
                                        }
                                        &nbsp;
                                        <button className="ok auto spaced" style={{ margin: '1.5em', padding: '.75em', width: '120px' }} disabled={(on || !(events.length > 0))} onClick={e => { e.preventDefault(); download(); }}>
                                            <FormattedMessage id="App.info.Salva" />
                                        </button>
                                    </div>
                                </div>
                                <div className="rack">
                                    <div className="w100">
                                        {finished
                                            ? (
                                                <p><FormattedMessage id="App.info.ScaricamentoMemoriaEventiCompletato" /></p>
                                            ) : on
                                                ? (
                                                    <p className="loadingText">
                                                        <FormattedMessage id="App.info.ScaricamentoMemoriaEventiInCorso" />
                                                    </p>
                                                ) :
                                                (
                                                    <p><FormattedMessage id="App.info.AttesaAvvio" /></p>
                                                )
                                        }
                                        {events.length > 0 && (
                                            <div className="rack">
                                                <div className="w100" style={{ background: 'lightgray', maxHeight: '400px', overflowY: 'auto' }}>
                                                    {events.length > 0 && renderEvents(events)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="rack" style={{ marginTop: "2em" }}>
                                    <div className="w100 right">
                                        <button className="ok auto spaced" onClick={e => { e.preventDefault(); setConfirm(true); }}>
                                            <FormattedMessage id="App.info.Svuota" />
                                        </button>
                                    </div>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>
        </section >
    );
};

const MemoriaEventi = connect(mapStateToProps, mapDispatchToProps)(_MemoriaEventi);

export default MemoriaEventi;